import React, { useMemo, useState } from 'react';
import { User } from '../../../../models';
import { useSelector } from 'react-redux';
import VitalItem from '../../../../data/model/VitalItem';
import HighlightChanges from '../HighlightChanges';
import { formatForMatchingUnits } from '../../../../data/model/EquipmentItem';
import HighlightChangesRow from '../HighlightChangesRow';
interface FolderComparisonProps {
  focusItem: VitalItem;
  changes: any[];
}

const VitalComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  changes,
}) => {
  console.log(changes);
  const vitalOptions = useMemo(() => {
    return {
      currentOptions: focusItem.options,
      beforeOptions: focusItem.activeItem?.options || [],
    };
  }, [focusItem]);

  const findBeforeVitalValue = (changes: any, key: string) => {
    const change = changes.find((change: any) => change.key === key);
    return change ? change.changes : null;
  };

  const findCurrentVitalValue = (changes: any, key: string) => {
    const change = changes.find((change: any) => change.key === key);
    return change ? change.changes : null;
  };

  const optionHasValues = (change: any, isBefore: boolean) => {
    if (isBefore) {
      return (
        (change.archiveValue?.rangeLow !== undefined &&
          change.archiveValue?.rangeLow !== '') ||
        (change.archiveValue?.rangeHigh !== undefined &&
          change.archiveValue?.rangeHigh !== '') ||
        (change.archiveValue?.amntLow !== undefined &&
          change.archiveValue?.amntLow !== '')
      );
    } else {
      return (
        (change.currentValue?.rangeLow !== undefined &&
          change.currentValue?.rangeLow !== '') ||
        (change.currentValue?.rangeHigh !== undefined &&
          change.currentValue?.rangeHigh !== '') ||
        (change.currentValue?.amntLow !== undefined &&
          change.currentValue?.amntLow !== '')
      );
    }
  };
  const formatOptions = (optionsChanges: any, isBefore: boolean) => {
    return optionsChanges.map((change: any, index: number) => {
      const hasValues = optionHasValues(change, isBefore);

      if (!hasValues) {
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '0.5fr 1fr 1fr 1.75fr',
              gap: '15px',
              padding: '10px 0px',
              minHeight: '72px',
              backgroundColor: index % 2 === 0 ? '#fafbfb' : 'white',
              borderTopLeftRadius: index === 0 ? '8px' : '',
              borderTopRightRadius: index === 0 ? '8px' : '',
              borderBottomLeftRadius:
                index === optionsChanges.length - 1 ? '8px' : '',
              borderBottomRightRadius:
                index === optionsChanges.length - 1 ? '8px' : '',
              borderBottom:
                index !== optionsChanges.length - 1 ? '1px solid #eaeded' : '',
            }}
          ></div>
        );
      }
      return (
        <div
          className=""
          style={{
            display: 'grid',
            gridTemplateColumns: '0.25fr 1fr 1fr 1.75fr',
            gap: '15px',
            padding: '10px 0px',
            backgroundColor: index % 2 === 0 ? '#fafbfb' : 'white',
            borderTopLeftRadius: index === 0 ? '8px' : '',
            borderTopRightRadius: index === 0 ? '8px' : '',
            borderBottomLeftRadius:
              index === optionsChanges.length - 1 ? '8px' : '',
            borderBottomRightRadius:
              index === optionsChanges.length - 1 ? '8px' : '',
            borderBottom:
              index !== optionsChanges.length - 1 ? '1px solid #eaeded' : '',
          }}
          key={index}
        >
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#gray',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {index + 1}:
            </div>
          </div>
          {
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={` input-label`}>
                Range Low
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={change.archiveValue?.rangeLow || ''}
                  afterText={change.currentValue?.rangeLow || ''}
                  onlyPrevious={isBefore}
                  onlyCurrent={!isBefore}
                />
              </div>
            </div>
          }
          {
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={` input-label`}>
                Range High
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={change.archiveValue?.rangeHigh || ''}
                  afterText={change.currentValue?.rangeHigh || ''}
                  onlyPrevious={isBefore}
                  onlyCurrent={!isBefore}
                />
              </div>
            </div>
          }
          {
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                marginRight: '10px',
              }}
            >
              <label htmlFor={''} className={` input-label`}>
                Value
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={change.archiveValue?.amntLow || ''}
                  afterText={
                    formatForMatchingUnits(
                      change.currentValue?.amntLow,
                      change.currentValue?.amntHigh
                    ) || ''
                  }
                  onlyPrevious={isBefore}
                  onlyCurrent={!isBefore}
                />
              </div>
            </div>
          }
        </div>
      );
    });
  };

  const findKey = (changes: any[], key: string) => {
    return changes.find((change) => change.key === key);
  };

  return (
    <tbody className="comparison-rows">
      {findKey(changes, 'name') && (
        <HighlightChangesRow
          keys={['name']}
          titles={['Name']}
          changes={changes}
        />
      )}

      {findKey(changes, 'options') && (
        <tr>
          <td>
            <label htmlFor="Name" className="ketamine-general-label">
              Vital Options: ({vitalOptions.beforeOptions.length} items)
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                fontWeight: '500',
                fontSize: '15px',
                borderRadius: '8px',
                // paddingRight: '10px',
                backgroundColor: 'white',
              }}
            >
              {formatOptions(
                findBeforeVitalValue(changes, 'options') || [],
                true
              )}
            </div>
          </td>
          <td>
            <label htmlFor="Name" className="ketamine-general-label">
              Vital Options: ({vitalOptions.currentOptions.length} items)
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                fontWeight: '500',
                fontSize: '15px',
                borderRadius: '8px',
                backgroundColor: 'white',
              }}
            >
              {formatOptions(
                findCurrentVitalValue(changes, 'options') || [],
                false
              )}
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default VitalComparisonView;
