import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';

import { Button } from 'react-bootstrap';
import './Review.scss';

import {
  FaChevronRight,
  FaCircleCheck,
  FaCircleXmark,
  FaRectangleXmark,
  FaTrash,
  FaUser,
  FaUserCheck,
  FaUserClock,
  FaUserLock,
} from 'react-icons/fa6';
import {
  DatabaseResponse,
  loadDatabase,
  Response,
  ResponseType,
} from '../../../data/AmplifyDB';

import { commonStyle, getStatusStyles } from './styles';
import { User } from '../../../models';
import ReviewStatusButton from './ReviewStatusButton';
import { GitCommitIcon } from '@primer/styled-octicons';
import { FaCalendarAlt, FaCheckCircle, FaUserTimes } from 'react-icons/fa';

import { FaReply } from 'react-icons/fa';
import ReviewalItem, {
  cloneReviewalItem,
} from '../../../data/model/ReviewalItem';
import { MdCreateNewFolder } from 'react-icons/md';
import {
  createAcknowledgeItem,
  createReviewalProcess,
  createUserCommentItem,
  deleteAcknowledgeItem,
  deleteReviewalProcess,
  getFullReviewal,
} from '../../../data/functions/ReviewalDB';
import AcknowledgeItem from '../../../data/model/AcknowledgeItem';
import UserCommentItem from '../../../data/model/UserCommentItem';
import ReviewUserSideout from './ReviewUserSideout';
import { HiPencil } from 'react-icons/hi';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { ACKStatus, LogEvent, Reviewal } from '../../../API';
import { globals, handleCopy, toTitleCase } from '../../_global/common/Utils';
import DraftChangeItem, {
  DraftChangeType,
} from '../../../data/model/DraftChangeItem';
import { TbDatabaseSearch, TbTrash } from 'react-icons/tb';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import {
  closeDraftChanges,
  Draft,
  publishDraftUpdates,
} from '../../../data/AmplifyVersion';
import DraftGroupItem from '../../../data/model/DraftGroupItem';
import { InputText } from 'primereact/inputtext';
import { IoCheckbox } from 'react-icons/io5';
import { BsQuestionSquareFill } from 'react-icons/bs';
import { handleGetDepartment } from '../../../store/actions';
import { InputTextarea } from 'primereact/inputtextarea';
import ReviewalSubscriber, {
  ReviewalError,
} from '../../../data/subscribers/ReviewalSubscriber';
import CategoryItem from '../../../data/model/CategoryItem';

const ReviewDetails = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((reviewal: any) => reviewal.protocol.departmentItem)
  );
  const department = useMemo(() => {
    return database.department;
  }, [database]);
  const user: User = useSelector((state: any) => state?.user);
  const { data, handleStatusStyle } = location.state;

  const [reviewal, setReviewal] = useState<ReviewalItem>(data);
  const [homeButtonClicked, setHomeButtonClicked] = useState(false);
  const [changeButtonCliked, setChangeButtonCliked] = useState(false);
  const [sortedItems, setSortedItems] =
    useState<(UserCommentItem | LogEvent | AcknowledgeItem)[]>();
  const [ownerName, setOwnerName] = useState<User>();
  const [selectedTab, setSelectedTab] = useState<string | null>('Details');
  const [approvedCount, setApprovedCount] = useState<number>(0);
  const [isEditable, setIsEditable] = useState(false);
  const [textModal, setTextModal] = useState(false);
  const [title, setTitle] = useState(reviewal.title);
  const [isModalVisisble, setIsModalVisible] = useState(false);
  const [isFullReviewComplete, setIsFullReviewComplete] = useState(
    reviewal.isFullyLoaded
  );
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [comment, setComment] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [reviewDescription, setReviewDescription] = useState(
    reviewal.description ? reviewal.description : ''
  );
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  const [modalState, setModalState] = useState({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    isRedBtn: false,
    handleSubmit: () => {},
    handleClose: undefined as any,
  });

  const isClosedState = useMemo(() => {
    return (
      reviewal.state === ACKStatus.CLOSED ||
      reviewal.state === ACKStatus.PUBLISHED ||
      reviewal.state === ACKStatus.REJECTED ||
      reviewal.state === ACKStatus.APPROVED
    );
  }, [reviewal.state]);

  const handleFullReloadReviewal = (
    reviewal: ReviewalItem,
    reloadEverything = false
  ) => {
    getFullReviewal(
      database,
      reviewal,
      user,
      dispatch,
      reloadEverything,
      reloadEverything
    )
      .then((response) => {
        if (response) {
          setIsFullReviewComplete(true);
          setReviewal(response);
          if (globals.debug) console.log('Full reviewal loaded', response);
        }
      })
      .catch((error) => {
        console.error('Error getting full reviewal', error);
      });
  };

  /* Reload the reviewal item everytime this UI renders AND ONLY WHEN THE UI RENDERS */
  useEffect(() => {
    handleFullReloadReviewal(reviewal, true);
  }, []);

  useEffect(() => {
    let approvedCount = reviewal.acknowledgements.filter(
      (ack: AcknowledgeItem) => ack.state === ACKStatus.APPROVED
    ).length;
    setApprovedCount(approvedCount);
  }, [reviewal]);

  //  get list of all users name
  useEffect(() => {
    const allUsers = database.users;
    const allUserNames = allUsers.map(
      (user) => user.firstName + ' ' + user.lastName
    );
  }, [database]);

  useEffect(() => {
    const owner = database.users.find((user) => user.id === reviewal.user.id);
    setOwnerName(owner); // Default to 'Unknown' if no owner found
  }, [reviewal.user.id]);

  const userReviewACK = useMemo(() => {
    return reviewal.acknowledgements.find(
      (ack: AcknowledgeItem) => ack.user.id === user.id
    );
  }, [reviewal, user]);

  useEffect(() => {
    if (reviewal?.comments && reviewal?.events) {
      let combinedArray: (UserCommentItem | LogEvent | AcknowledgeItem)[] = [
        ...reviewal.comments,
        ...reviewal.events,
        // ...reviewal.acknowledgements,
      ];
      for (let i = 0; i < reviewal.draftGroups.length; i++) {
        const draftGroup = reviewal.draftGroups[i];
        for (let j = 0; j < draftGroup.draftChanges.length; j++) {
          const draftChange = draftGroup.draftChanges[j];
          combinedArray = [
            ...combinedArray,
            ...draftChange.comments,
            ...draftChange.allAcknowledgements.filter(
              (ack: AcknowledgeItem) => ack.state === ACKStatus.REJECTED
            ),
          ];
        }
      }
      combinedArray.sort((a, b) => {
        return (
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
      });
      setSortedItems(combinedArray);
    }
  }, [
    isFullReviewComplete,
    reviewal.comments,
    reviewal.events,
    reviewal.draftGroups,
    reviewal,
  ]);

  useEffect(() => {
    if (homeButtonClicked) {
      // stay on the samepage
      navigate('/reviewDetails', {
        state: {
          data: reviewal,
        },
      });
    }
  }, [homeButtonClicked]);

  useEffect(() => {
    if (changeButtonCliked) {
      navigate('/reviewChanges', {
        state: {
          data: reviewal,
        },
      });
    }
  }, [changeButtonCliked]);

  const handleBack = () => {
    navigate('/review', { state: reviewal });
  };

  function isUserComments(item: any): item is UserCommentItem {
    return (item as UserCommentItem).message !== undefined;
  }
  function isAcknowledgeItem(item: any): item is AcknowledgeItem {
    return (item as AcknowledgeItem).state !== undefined;
  }
  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
    if (tabName === 'Details') {
      setHomeButtonClicked(true);
      setChangeButtonCliked(false);
    } else {
      setChangeButtonCliked(true);
      setHomeButtonClicked(false);
    }
  };

  const handleTitleChange = (event: any) => {
    setTitle(event.target.value);
  };

  const handleUpdateReviewalStatus = async (
    reviewal: ReviewalItem,
    status: ACKStatus,
    title: string,
    description: string
  ) => {
    try {
      /* This will update the current reviewal status */
      const response: Response = await createReviewalProcess(
        database,
        {
          previousItem: reviewal,
          title: reviewal.title,
          description: reviewal.description,
          reviewers: reviewal.reviewers,
          user: reviewal.user,
          status: status,
          events: [
            ...reviewal.events,
            {
              title: title,
              description: description,
              timestamp: new Date().toISOString(),
              color: '#00534C',
            },
          ],
        },
        false
      );
      if (response.type === ResponseType.Success) {
        let resp = response.data as ReviewalItem;
        let updatedReviewal = cloneReviewalItem(reviewal, database);
        updatedReviewal.state = status;
        updatedReviewal.events = resp.events;
        updatedReviewal.any_model = resp.any_model;
        updatedReviewal.model = resp.model;
        setReviewal(updatedReviewal);
      } else console.error('Error updating reviewal status', response.data);
    } catch (error) {
      console.error('Error updating reviewal status', error);
    }
  };

  const handleReSubmitReviewal = async () => {
    /* Remove all the declined acknowledgements */
    let promises: any[] = [];
    let allDraftChanges: DraftChangeItem[] = [];
    for (let group of reviewal.draftGroups) {
      allDraftChanges = [...allDraftChanges, ...group.draftChanges];
    }
    // let acks: AcknowledgeItem[] = [
    //   ...reviewal.acknowledgements.filter(
    //     (ack: AcknowledgeItem) => ack.state === ACKStatus.REJECTED
    //   ),
    //   ...allDraftChanges.map((change: DraftChangeItem) => {
    //     return change.acknowledgements.filter(
    //       (ack: AcknowledgeItem) => ack.state === ACKStatus.REJECTED
    //     );
    //   }),
    // ].flat();

    let modifyACKs = allDraftChanges
      .map((change: DraftChangeItem) => {
        return change.acknowledgements.filter(
          (ack: AcknowledgeItem) => ack.state === ACKStatus.REJECTED
        );
      })
      .flat();

    let deleteACKs = reviewal.acknowledgements.filter(
      (ack: AcknowledgeItem) => ack.state === ACKStatus.REJECTED
    );

    console.log('MODIFYING ACKNOWLEDGEMENTS', modifyACKs);
    console.log('DELETING ACKNOWLEDGEMENTS', deleteACKs);

    modifyACKs.forEach((ack: AcknowledgeItem) => {
      promises.push(
        createAcknowledgeItem({
          previousItem: ack,
          reviewal: reviewal,
          state: ack.state,
          isArchived: true,
          user: user,
          owner: ack.owner,
        })
      );
    });

    deleteACKs.forEach((ack: AcknowledgeItem) => {
      promises.push(deleteAcknowledgeItem(ack));
    });

    let results = await Promise.all(promises);
    console.log('ACKNOWLEDGEMENTS RESULTS', results);
    //Now remove the declined acknowledgements from the reviewal & draft changes
    let updatedReviewal = cloneReviewalItem(reviewal, database);
    updatedReviewal.acknowledgements = updatedReviewal.acknowledgements.filter(
      (ack: AcknowledgeItem) => ack.state !== ACKStatus.REJECTED
    );
    updatedReviewal.draftGroups.forEach((group: DraftGroupItem) => {
      group.draftChanges.forEach((change: DraftChangeItem) => {
        let newACKs = change.allAcknowledgements.map((ack: AcknowledgeItem) => {
          if (ack.state === ACKStatus.REJECTED && !ack.isArchived)
            ack.isArchived = true;
          return ack;
        });
        change.allAcknowledgements = newACKs;
        change.acknowledgements = change.allAcknowledgements.filter(
          (ack: AcknowledgeItem) => !ack.isArchived
        );
        if (change.reviewACK && change.reviewACK.state === ACKStatus.REJECTED)
          change.reviewACK = null;
      });
    });
    updatedReviewal.any_model = reviewal.any_model;
    handleUpdateReviewalStatus(
      updatedReviewal,
      ACKStatus.PENDING,
      'Review has been resubmitted',
      'The review has been resubmitted by ' +
        user.firstName +
        ' ' +
        user.lastName +
        ' for approval.'
    );
  };

  /**
   * TODO Update UI globally on the reviewal item
   * @returns Remove the acknowledgement from the draft change item
   */
  const handleRemoveAcknowledgement = async (ack: AcknowledgeItem) => {
    // setAcceptedDrafts((prev) => new Set(prev.add(draftId)));
    if (ack == null) return;
    try {
      const response: Response = await deleteAcknowledgeItem(ack);
      if (response.type === ResponseType.Success) {
        if (globals.debug) console.log('Review ACK deleted successfully');
        return ack;
      } else if (globals.debug)
        console.log('Review ACK deletion failed', response.data);
    } catch (error) {
      if (globals.debug) console.log('error', error);
    }
  };

  const handleUpdateReviewalDescription = async (description: string) => {
    try {
      /* This will update the current reviewal status */
      const response: Response = await createReviewalProcess(
        database,
        {
          previousItem: reviewal,
          title: reviewal.title,
          description: description,
          reviewers: reviewal.reviewers,
          user: reviewal.user,
          status: reviewal.state,
          events: reviewal.events,
        },
        true
      );
      if (response.type === ResponseType.Success) {
        let resp = response.data as ReviewalItem;
        let updatedReviewal = cloneReviewalItem(reviewal, database);
        updatedReviewal.description = description;
        updatedReviewal.any_model = resp.any_model;
        updatedReviewal.model = resp.model;
        setReviewal(updatedReviewal);
      } else console.error('Error updating reviewal status', response.data);
    } catch (error) {
      console.error('Error updating reviewal status', error);
    }
  };

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const handlePublishReviewal = async (button: string) => {
    try {
      let drafts: Draft[] = [];
      reviewal.draftGroups.forEach((group) => {
        group.draftChanges.forEach((change) => {
          if (change.changeItem == null) return;
          drafts.push({
            draftChangeItem: change,
            changeType: change.changeType,
            model: change.changeItem,
            title: change.changeItem.name,
          });
        });
      });

      /* Check to add the index changes */
      let indexChanges = drafts.filter((draft) => {
        return draft.changeType === DraftChangeType.PROTOCOL_INDEX;
      });
      if (indexChanges.length > 0) {
        // for (let draft of indexChanges) {
        //   /* To do to publish the protocols */
        //   const folder = draft.model as CategoryItem;
        //   const draftProtocols = folder.protocols.filter((protocol) => {
        //   });
        //   for (let protocol of protocols) {
        //     let protocolItem = protocol.activeItem;
        //     if (protocolItem) {
        //       drafts.push({
        // }
      }

      let promises = [
        createReviewalProcess(
          database,
          {
            previousItem: reviewal,
            title: reviewal.title,
            description: reviewal.description,
            reviewers: reviewal.reviewers,
            user: reviewal.user,
            endedAt: new Date(),
            status:
              button === 'Close Review'
                ? ACKStatus.CLOSED
                : ACKStatus.PUBLISHED,
            events: [
              ...reviewal.events,
              {
                title:
                  button === 'Close Review'
                    ? 'Review has been closed'
                    : 'Review Changes have been published',
                description:
                  button === 'Close Review'
                    ? 'The review has been closed by ' +
                      user.firstName +
                      ' ' +
                      user.lastName +
                      ' without publishing the changes.'
                    : 'The changes have been published by ' +
                      user.firstName +
                      ' ' +
                      user.lastName +
                      (button === 'Publish Now' ? ' without approval.' : '.'),
                timestamp: new Date().toISOString(),
                color: '#00534C',
              },
            ],
          },
          false
        ),
      ];
      if (button !== 'Close Review')
        promises.push(publishDraftUpdates(department, drafts));
      else promises.push(closeDraftChanges(drafts));
      /* TODO NEED TO UPDATE THE DRAFT CHANGE ITEMS IDS TO POINT AT THE RIGHT STUFF */

      let allResponses = await Promise.all(promises);
      if (globals.debug) console.log('ALL RESPONSES', allResponses);
      let response = allResponses[0];
      let publishResponse = allResponses.length > 1 ? allResponses[1] : null;
      if (
        response.type === ResponseType.Success &&
        (publishResponse == null ||
          publishResponse.type === ResponseType.Success)
      ) {
        let resp = response.data as ReviewalItem;
        handleFullReloadReviewal(resp, true);

        if (publishResponse) reloadDatabase();
      }
    } catch (error) {
      console.error('Error publishing reviewal', error);
    }
  };

  const handleDeleteReviewal = async () => {
    const response: Response = await deleteReviewalProcess(reviewal);
    if (response.type === ResponseType.Success) {
      const deletedReviewal = response.data as ReviewalItem;
      if (globals.debug)
        console.log('Successfully deleted reviewal', deletedReviewal);
      navigate('/review');
    } else {
      console.error('Error deleting reviewal', response.data);
    }
  };
  const handleUpdateReviewer = async (reviewers: User[]) => {
    try {
      let new_events = [
        ...reviewal.events,
        {
          title: 'Reviewers have been updated',
          description:
            user.firstName +
            ' ' +
            user.lastName +
            ' has updated the reviewers to ' +
            reviewers
              .map((reviewer) => reviewer.firstName + ' ' + reviewer.lastName)
              .join(', '),
          timestamp: new Date().toISOString(),
          color: '#00534C',
        } as LogEvent,
      ];
      createReviewalProcess(
        database,
        {
          previousItem: reviewal,
          title: reviewal.title,
          description: reviewal.description,
          reviewers: reviewers,
          user: reviewal.user,
          status: reviewal.state,
          events: new_events,
        },
        false
      )
        .then((response) => {
          if (response.type === ResponseType.Success) {
            let resp = response.data as ReviewalItem;
            let updatedReviewal = cloneReviewalItem(reviewal, database);
            updatedReviewal.reviewers = reviewers;
            updatedReviewal.events = new_events;
            updatedReviewal.any_model = resp.any_model;
            updatedReviewal.model = resp.model;
            setReviewal(updatedReviewal);
          }
        })
        .finally(() => {
          setIsModalVisible(false);
        });
    } catch (error) {
      console.error('Error updating reviewers', error);
    }
  };

  const handleRemoveEvents = async () => {
    try {
      createReviewalProcess(
        database,
        {
          previousItem: reviewal,
          title: reviewal.title,
          description: reviewal.description,
          reviewers: reviewal.reviewers,
          user: reviewal.user,
          status: reviewal.state,
          events: [
            {
              title:
                'Review Created by ' + user.firstName + ' ' + user.lastName,
              description:
                'Review was created by ' +
                user.firstName +
                ' ' +
                user.lastName +
                ' on ' +
                new Date().toLocaleDateString(),
              timestamp: reviewal.createdAt.toISOString(),
              color: '#00534C',
            },
          ],
        },
        false
      )
        .then((response) => {
          if (response.type === ResponseType.Success) {
            let resp = response.data as ReviewalItem;
            let updatedReviewal = cloneReviewalItem(reviewal, database);
            updatedReviewal.events = [];
            updatedReviewal.any_model = resp.any_model;
            updatedReviewal.model = resp.model;
            setReviewal(updatedReviewal);
          }
        })
        .finally(() => {
          setIsModalVisible(false);
        });
    } catch (error) {
      console.error('Error updating reviewers', error);
    }
  };

  const description = useMemo(() => {
    switch (reviewal.state) {
      case ACKStatus.DRAFT:
        return 'This review is in draft mode and is only visible to you as of now. Submit the reviewal to start the review process';
      case ACKStatus.PENDING:
        if (user.id === reviewal.user.id)
          return (
            'The review is in progress. There is ' +
            reviewal.reviewers.length +
            ' reviewer' +
            (reviewal.reviewers.length > 1 ? 's' : '') +
            ' reviewing the changes. You cannot modify this review until it is rejected or approved.'
          );
        else {
          let reviewACK = reviewal.acknowledgements.find(
            (ack: AcknowledgeItem) => ack.user.id === user.id
          );
          return reviewACK
            ? 'You have reviewed the changes and ' +
                toTitleCase(reviewACK.state) +
                ' the changes. Waiting for other reviewers to complete the review.'
            : reviewal.user.firstName +
                ' ' +
                reviewal.user.lastName +
                ' has requested for you to review the changes. Please fo through the changes and provide your feedback.';
        }
      case ACKStatus.APPROVED:
        return 'This review has been approved and is ready to publish';
      case ACKStatus.REJECTED:
        return 'This review has been rejected, please look in the comments for more information about the rejection. When you are ready Re-Sumbit the review for approval';
      case ACKStatus.PUBLISHED:
        return (
          'These changes were pubished at ' + reviewal.endedAt?.toLocaleString()
        );
      case ACKStatus.CLOSED:
        return (
          'These changes were closed at ' + reviewal.endedAt?.toLocaleString()
        );
      default:
        return '';
    }
  }, [reviewal, user]);

  const RenderDescriptionMessage = ({ message }: { message: string }) => {
    const messages =
      message === ''
        ? [
            'Review created by ' +
              reviewal.user.firstName +
              ' ' +
              reviewal.user.lastName +
              ' at ' +
              reviewal.createdAt.toLocaleString(),
          ]
        : message.split('\n');

    let numberPattern = /[0-9]+\.\s[A-Za-z0-9]*/g;

    return (
      <div
        style={
          {
            // position: '',
          }
        }
      >
        {/* <h4 style={{ fontSize: '16px', fontWeight: 'bold' }}>
          Review Description:
        </h4> */}
        {/* Create the hover button for hte description in the top right corner*/}
        {/* <div> */}

        {/* </div> */}

        {messages.map((msg: string, index: number) => {
          return msg[0] === '*' ? (
            // <li
            //   style={{
            //     //Add a bullet point to the list
            //     listStyleType: 'disc',
            //   }}
            // >
            <li
              style={{
                fontSize: '16px',
                color: 'black',
                margin: 0,
                marginLeft: '30px',
                padding: 0,
                fontWeight: '500',
                listStyleType: 'disc',
              }}
              key={index}
            >
              {msg.substring(1)}
            </li>
          ) : numberPattern.test(msg) ? (
            <li
              style={{
                fontSize: '16px',
                color: 'black',
                margin: 0,
                marginLeft: '30px',
                padding: 0,
                fontWeight: '500',
              }}
              key={index}
            >
              {msg}
            </li>
          ) : (
            // </li>
            <p
              style={{
                fontSize: '16px',
                color: 'black',
                margin: 0,
                marginLeft: '10px',
                padding: 0,
                fontWeight: '500',
              }}
              key={index}
            >
              {msg}
            </p>
          );
        })}
      </div>
    );
  };

  //  redirect the user to reviewChanges page with the correesponding draftChange item and draftGroup active on the page
  const handleUserCommentClick = (item: UserCommentItem) => {
    const draftGroup = reviewal.draftGroups.find((group: DraftGroupItem) =>
      group.draftChanges.find((change: DraftChangeItem) =>
        change.comments.find((comment: UserCommentItem) => comment === item)
      )
    );
    const draftChange = draftGroup?.draftChanges.find(
      (change: DraftChangeItem) =>
        change.comments.find((comment: UserCommentItem) => comment === item)
    );
    navigate('/reviewChanges', {
      state: {
        data: reviewal,
        activeDraftChange: draftChange,
        activeDraftGroup: draftGroup,
      },
    });
  };

  //  redirect the user to reviewChanges page with the correesponding draftChange item and draftGroup active on the page
  const handleAcknowledgeItemClick = (item: AcknowledgeItem) => {
    const allDraftChanges = reviewal.draftGroups.flatMap(
      (group: DraftGroupItem) => group.draftChanges
    );
    const draftGroup = reviewal.draftGroups.find((group: DraftGroupItem) =>
      group.draftChanges.find((change: DraftChangeItem) =>
        change.allAcknowledgements.find((ack: AcknowledgeItem) => ack === item)
      )
    );
    const draftChange = allDraftChanges.find((change: DraftChangeItem) =>
      change.allAcknowledgements.find((ack: AcknowledgeItem) => ack === item)
    );
    if (draftGroup && draftChange) {
      navigate('/reviewChanges', {
        state: {
          data: reviewal,
          activeDraftChange: draftChange,
          activeDraftGroup: draftGroup,
        },
      });
    } else {
      console.error('No draft group or draft change found');
    }
  };

  const UserIcon = ({ ack }: { ack: AcknowledgeItem | undefined }) => {
    let ackState =
      reviewal.state !== ACKStatus.PENDING ? reviewal.state : ack?.state;
    return ackState === ACKStatus.CLOSED ? (
      <FaUserLock
        style={{
          color: getStatusStyles(reviewal.state).color,
          marginRight: '10px',
          height: '2rem',
          width: '2rem',
        }}
      />
    ) : ackState === ACKStatus.REJECTED ? (
      <FaUserTimes
        style={{
          color: getStatusStyles(ACKStatus.REJECTED).color,
          marginRight: '10px',
          height: '2rem',
          width: '2rem',
        }}
      />
    ) : ackState == null || ackState === ACKStatus.DRAFT ? (
      <FaUserClock
        style={{
          color: getStatusStyles(
            reviewal.state === ACKStatus.PENDING && user.id !== reviewal.user.id
              ? ack
                ? ack.state
                : ACKStatus.REJECTED
              : reviewal.state
          ).color,
          marginRight: '10px',
          height: '2rem',
          width: '2rem',
        }}
      />
    ) : (
      <FaUserCheck
        style={{
          color: getStatusStyles(ACKStatus.APPROVED).color,
          marginRight: '10px',
          height: '2rem',
          width: '2rem',
        }}
      />
    );
  };

  const statusTitle = useMemo(() => {
    if (reviewal.state === ACKStatus.PUBLISHED)
      return 'Review was Successfully Published';
    if (reviewal.state === ACKStatus.CLOSED) return 'Review has been Closed';
    if (reviewal.state === ACKStatus.REJECTED) return 'Review Rejected';
    if (reviewal.state === ACKStatus.APPROVED) return 'Review Approved';
    if (reviewal.state === ACKStatus.DRAFT) return 'Review is Ready';
    else {
      if (reviewal.user.id === user.id) return 'Review in Progress';
    }
    return 'Review Required';
  }, [reviewal, userReviewACK, user]);

  const statusDescription = useMemo(() => {
    if (reviewal.state === ACKStatus.PUBLISHED)
      return (
        reviewal.user.firstName +
        ' ' +
        reviewal.user.lastName +
        ' successfully published the changes to OneDose.'
      );
    if (reviewal.state === ACKStatus.CLOSED)
      return (
        'The review has been closed and can be re-opened by ' +
        reviewal.user.firstName +
        ' ' +
        reviewal.user.lastName +
        ' if needed.'
      );
    if (reviewal.state === ACKStatus.REJECTED)
      return 'The review has been rejected and needs to be resubmitted for approval. Review the comments for more information.';
    if (reviewal.state === ACKStatus.APPROVED)
      return (
        'All reviewers have approved the changes and the changes can be published' +
        (user.id !== reviewal.user.id
          ? ' by ' +
            reviewal.user.firstName +
            ' ' +
            reviewal.user.lastName +
            '.'
          : '.')
      );
    if (reviewal.state === ACKStatus.DRAFT)
      return 'The review is in draft mode and is only visible to you as of now. Submit the reviewal to start the review process';
    else {
      if (userReviewACK)
        return (
          'You have reviewed the changes and ' +
          toTitleCase(userReviewACK.state) +
          ' the changes. Waiting for other reviewers to complete the review.'
        );
      else if (user.id !== reviewal.user.id)
        return (
          reviewal.user.firstName +
          ' ' +
          reviewal.user.lastName +
          ' has requested for you to review the changes. Go through the changes and provide your feedback.'
        );
    }
    return 'The review is in progress and is waiting for the reviewers to complete their reviews.';
  }, [reviewal, userReviewACK, user]);

  const handleComment = async (comment: string, prev?: UserCommentItem) => {
    try {
      const response: Response = await createUserCommentItem({
        previousItem: prev,
        owner: reviewal,
        user: user,
        message: comment,
        relatedItems: [],
      });
      if (response.type === ResponseType.Success) {
        if (globals.debug) console.log('Comment created successfully');

        const newComment = response.data as UserCommentItem;
        reviewal.comments.push(newComment);
        let newReviewal = cloneReviewalItem(reviewal, database);
        setReviewal(newReviewal);
      } else if (globals.debug) {
        console.log('Comment creation failed', response.data);
      }
    } catch (error) {
      if (globals.debug) console.log('error', error);
    }
  };

  const handleReviewalChange = (reviewal: ReviewalItem, isDeleted: boolean) => {
    if (isDeleted) handleReviewalDeleted(reviewal);
    else setReviewal(reviewal);
  };

  const handleReviewalDeleted = (parmReviewal: ReviewalItem) => {
    if (reviewal.uid === parmReviewal.uid) {
      setModalState({
        isVisible: true,
        title: 'This reviewal has been deleted',
        description: 'The review ' + reviewal.title + ' has been deleted',
        primaryBtnName: '',
        secondaryBtnName: 'Okay',
        isRedBtn: false,
        handleClose: () => {
          navigate('/review');
        },
        handleSubmit: () => {
          navigate('/review');
        },
      });
    }
  };

  const handleReviewalError = (
    error: ReviewalError,
    parmReviewal: ReviewalItem
  ) => {
    if (error === ReviewalError.MOVED_TO_DRAFT) {
      setModalState({
        isVisible: true,
        title: 'This reviewal has been moved to DRAFT mode',
        description:
          'The review ' +
          reviewal.title +
          ' has been moved to draft mode by ' +
          reviewal.user.firstName +
          ' ' +
          reviewal.user.lastName +
          '. It can no longer be worked on until it has been re-submitted for review.',
        primaryBtnName: '',
        secondaryBtnName: 'Okay',
        isRedBtn: false,
        handleClose: () => navigate('/review'),
        handleSubmit: () => navigate('/review'),
      });
    }
  };

  function handleUpdateLogic(state: 'reopen' | 'resubmit' | 'submit') {
    if (state === 'reopen') {
      setModalState({
        isVisible: true,
        title: 'Reopen Review?',
        description:
          'This will reopen the review and notify all the reviewers that they can start reviewing the changes.',
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Reopen',
        isRedBtn: false,
        handleClose: undefined,
        handleSubmit: () => {
          setModalState({ ...modalState, isVisible: false });
          handleUpdateReviewalStatus(
            reviewal,
            ACKStatus.PENDING,
            'Review Reopened',
            'The review has been reopened by ' +
              reviewal.user.firstName +
              ' ' +
              reviewal.user.lastName +
              '.'
          );
        },
      });
    } else if (state === 'resubmit') {
      setModalState({
        isVisible: true,
        title: 'Re-Submit for Review Again?',
        description:
          'This will re-submit the review for approval. Every reviewer will be notified to review the changes again and all reviews that are declined will be moved to in progress again.',
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Re-Submit',
        isRedBtn: false,
        handleClose: undefined,
        handleSubmit: () => {
          setModalState({ ...modalState, isVisible: false });
          handleReSubmitReviewal();
        },
      });
    } else if (state === 'submit') {
      setModalState({
        isVisible: true,
        title: 'Submit Changes for Review?',
        description:
          'This will notify all the reviewers that they can start reviewing the changes.',
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Submit',
        isRedBtn: false,
        handleClose: undefined,
        handleSubmit: () => {
          setModalState({ ...modalState, isVisible: false });
          handleUpdateReviewalStatus(
            reviewal,
            ACKStatus.PENDING,
            'Changes have been submitted for review',
            user.firstName +
              ' ' +
              user.lastName +
              ' has submitted the changes for review.'
          );
        },
      });
    }
  }

  return (
    <div
      className="screen-container review-details"
      style={{
        height: '100%',
        padding: '0 0rem',
      }}
    >
      <ReviewUserSideout
        isVisible={isModalVisisble}
        handleClose={() => {
          setIsModalVisible(false);
        }}
        handleUpdateReviewer={handleUpdateReviewer}
        type={'review'}
        users={reviewal.reviewers}
      />
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={
          modalState.handleClose
            ? modalState.handleClose
            : () => {
                setModalState({ ...modalState, isVisible: false });
              }
        }
        handleSubmit={modalState.handleSubmit}
        primaryBtnName={
          modalState.primaryBtnName === ''
            ? undefined
            : modalState.primaryBtnName
        }
        isSingleBtn={modalState.primaryBtnName === ''}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.description}
        secondaryDescription={''}
        isDeleteBtn={modalState.isRedBtn === true ? true : false}
      />

      <ProtocolHeader
        isBackButton={true}
        page={'Review Home Page'}
        handleCancel={handleBack}
        name={
          <>
            {isEditable ? (
              // <input
              //   type="text"
              //   value={title}
              //   onChange={handleTitleChange}
              //   onBlur={() => setEditMode('')}
              //   style={{ marginRight: '10px', fontSize: '16px' }}
              //   autoFocus
              // />
              <InputText
                value={title}
                onChange={handleTitleChange}
                placeholder="Add Review Title..."
                className="title-input-text-box"
                style={{
                  fontWeight: 'bold',
                  width: `calc(1rem*${title.length})`,
                  minWidth: '350px',
                }}
              />
            ) : (
              <div style={{ marginRight: '10px' }}>{title}</div>
            )}
            <div
              className="lightText"
              style={{
                ...commonStyle,
                marginTop: '5px',
                border: `2px solid ${getStatusStyles(reviewal.state).borderColor}`, // Conditional border color
                backgroundColor: `${getStatusStyles(reviewal.state).backgroundColor}`, // Conditional background color
                color: `${getStatusStyles(reviewal.state).color}`, // Conditional text color
              }}
            >
              {`${toTitleCase(ACKStatus[reviewal.state])}`}
            </div>
            {/* {isEditable && (
              <span
                className="iconButton"
                onClick={() => setEditMode('header')}
              >
                <HiPencil data-testid="isDotButton" className="icon" />
              </span>
            )} */}
          </>
        }
        description={description}
        rightSideBtn={'edit'}
        isDeleteButton={
          (isEditable && reviewal.state === ACKStatus.DRAFT) ||
          user.type === 'ADMIN'
        }
        handleDelete={() => {
          setModalState({
            isVisible: true,
            title: 'Delete Reviewal?',
            description:
              'This will permanently delete the reviewal and all of its contents. This action is irreversible.',
            primaryBtnName: 'Cancel',
            secondaryBtnName: 'Delete',
            isRedBtn: true,
            handleSubmit: () => {
              handleDeleteReviewal();
              setModalState({ ...modalState, isVisible: false });
            },
            handleClose: undefined,
          });
        }}
        isSaveButton={isEditable}
        isEditButton={
          !isClosedState &&
          (user.id === reviewal.user.id || user.type === 'ADMIN') &&
          !isEditable
        }
        handleEdit={() => {
          setIsEditable(true);
        }}
        isCustomButton={
          user.id === reviewal.user.id &&
          (reviewal.state === ACKStatus.DRAFT ||
            reviewal.state === ACKStatus.REJECTED ||
            reviewal.state === ACKStatus.CLOSED)
        }
        customText={
          reviewal.state === ACKStatus.CLOSED
            ? 'Reopen'
            : reviewal.state === ACKStatus.REJECTED
              ? 'Re-Submit'
              : 'Submit'
        }
        handleCustom={() => {
          handleUpdateLogic(
            reviewal.state === ACKStatus.CLOSED
              ? 'reopen'
              : reviewal.state === ACKStatus.REJECTED
                ? 'resubmit'
                : 'submit'
          );
          // setModalState({
          //   isVisible: true,
          //   title:
          //     reviewal.state === ACKStatus.CLOSED
          //       ? 'Reopen Review?'
          //       : reviewal.state === ACKStatus.REJECTED
          //         ? 'Re-Submit Review?'
          //         : 'Submit Changes for Review?',
          //   description:
          //     reviewal.state === ACKStatus.CLOSED
          //       ? 'This will reopen the review and notify all the reviewers that they can start reviewing the changes.'
          //       : reviewal.state === ACKStatus.REJECTED
          //         ? 'This will re-submit the review for approval. Every reviewer will be notified to review the changes again and all reviews that are declined will be moved to in progress again.'
          //         : 'This will notify all the reviewers that they can start reviewing the changes.',
          //   primaryBtnName: 'Cancel',
          //   secondaryBtnName:
          //     reviewal.state === ACKStatus.CLOSED
          //       ? 'Reopen'
          //       : reviewal.state === ACKStatus.REJECTED
          //         ? 'Re-Submit'
          //         : 'Submit',
          //   isRedBtn: false,
          //   handleSubmit: () => {
          //     setModalState({ ...modalState, isVisible: false });
          //     if (modalState.secondaryBtnName === 'Re-Submit') {
          //       console.log('RE-SUBMITTING');
          //       handleReSubmitReviewal();
          //     } else {
          //       handleUpdateReviewalStatus(
          //         reviewal,
          //         ACKStatus.PENDING,
          //         'Changes have been submitted for review',
          //         user.firstName +
          //           ' ' +
          //           user.lastName +
          //           ' has submitted the changes for review.'
          //       );
          //     }
          //   },
          //   handleClose: undefined,
          // });
        }}
        isCustomActive={true}
        isSaveActive={true}
        handleCancelEdit={() => setIsEditable(false)}
        handleSave={() => setIsEditable(false)}
        type={'protocol'}
      />

      <ReviewalSubscriber
        reviewal={reviewal}
        onReviewalChange={handleReviewalChange}
        onListUpdated={() => {}}
        onError={handleReviewalError}
        onFullReloadReviewal={(reviewalItem: ReviewalItem) => {
          reviewalItem.isFullyLoaded = false; // Force the review to reload
          handleFullReloadReviewal(reviewalItem, true);
        }}
      />

      <div>
        <div>
          <div>
            <div
              style={{
                display: 'flex',
                justifyContent: ' space-between',
              }}
            >
              <div className="buttonContainer">
                <Button
                  className={`secondary-button-gray btn-rightMargin ${
                    selectedTab === 'Details'
                      ? 'calculation-active btn-active'
                      : 'calculation-inActive'
                  }`}
                  onClick={() => handleTabClick('Details')}
                >
                  Details
                </Button>

                <Button
                  className={`secondary-button-gray btn-rightMargin ${
                    selectedTab === 'Changes'
                      ? 'btn-active pdf-active'
                      : 'pdf-inActive'
                  }`}
                  disabled={!isFullReviewComplete}
                  onClick={() => handleTabClick('Changes')}
                >
                  Changes
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            gap: '2rem',
          }}
        >
          <div
            className="contentBorderLeft"
            style={{
              flex: 65,
              display: 'flex',
              marginTop: '5px',
              flexDirection: 'column',
              borderTop: '2px solid rgb(224, 224, 224)',
            }}
          >
            {/* <div
              style={{
                marginTop: '20px',
                borderTop: '2px solid rgb(224, 224, 224)',
              }}
            /> */}
            <div className="commentEventSection">
              {sortedItems &&
                isFullReviewComplete &&
                sortedItems.length > 0 &&
                sortedItems.map(
                  (
                    item: UserCommentItem | LogEvent | AcknowledgeItem,
                    index
                  ) => {
                    // const isAsscoiatedWithDraftChange =
                    const draftChange = reviewal.draftGroups
                      .flatMap((group: DraftGroupItem) => group.draftChanges)
                      .find((change: DraftChangeItem) =>
                        change.allAcknowledgements.find(
                          (ack: AcknowledgeItem) => ack === item
                        )
                      );
                    return (
                      <div key={index}>
                        {index === 0 ? (
                          <div className="itemContainer" key={index}>
                            <ul
                              className="itemList"
                              style={{
                                marginLeft: '-25px',
                                width: 'calc(100% + 30px)',
                              }}
                            >
                              <li
                                className="item"
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  // alignItems: 'center',
                                  cursor: 'pointer',
                                  padding: 0,
                                }}
                                onMouseEnter={() => {
                                  if (user.id === reviewal.user.id)
                                    setIsHovered(true);
                                }}
                                onMouseLeave={() => setIsHovered(false)}
                              >
                                <div
                                  style={{
                                    borderBottom: '1px solid #e0e0e0',
                                    flexDirection: 'row',
                                    display: 'flex',
                                    width: '100%',
                                    padding: '10px 10px 0px 10px',
                                  }}
                                >
                                  <UserIcon ack={userReviewACK} />
                                  <h4
                                    style={{
                                      fontSize: '16px',
                                      fontWeight: 'bold',
                                      color: getStatusStyles(
                                        reviewal.state === ACKStatus.PENDING &&
                                          user.id !== reviewal.user.id
                                          ? userReviewACK
                                            ? userReviewACK.state
                                            : ACKStatus.REJECTED
                                          : reviewal.state
                                      ).color,
                                    }}
                                  >
                                    {statusTitle}
                                    <p
                                      style={{
                                        fontSize: '14px',
                                        color: '#434343',
                                        margin: 0,
                                        padding: 0,
                                        fontWeight: '500',
                                      }}
                                    >
                                      {statusDescription}
                                    </p>
                                  </h4>
                                </div>
                                {reviewal.state === ACKStatus.REJECTED && (
                                  <div>
                                    {reviewal.acknowledgements.map((ack) => {
                                      if (ack.state !== ACKStatus.REJECTED)
                                        return null;
                                      return (
                                        <div
                                          style={{
                                            borderBottom: '1px solid #e0e0e0',
                                            flexDirection: 'row',
                                            display: 'flex',
                                            width: '100%',
                                            padding: '10px 10px 0px 10px',
                                          }}
                                        >
                                          <div
                                            className=""
                                            style={{
                                              position: 'relative',
                                              // display: 'inline-block',
                                              marginRight: '10px',
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: '30px',
                                                height: '30px',
                                                borderRadius: '50%',
                                                backgroundColor: '#00534C',
                                                color: 'white',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                                // position: 'relative',
                                              }}
                                            >
                                              {ack.user.firstName[0]}
                                              {ack.user.lastName[0]}
                                            </div>
                                            <div
                                              style={{
                                                position: 'absolute',
                                                top: 20,
                                                right: '0',
                                                width: '14px',
                                                height: '14px',
                                                borderRadius: '50%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: '#FFF',
                                              }}
                                            >
                                              <FaCircleXmark
                                                style={{
                                                  color: '#8c1010',
                                                  fontSize: '12px',
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <h4
                                            style={{
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                              flex: 1,
                                              color: getStatusStyles(
                                                ACKStatus.REJECTED
                                              ).color,
                                            }}
                                          >
                                            Review Rejected by{' '}
                                            {ack.user.firstName}{' '}
                                            {ack.user.lastName}
                                            <p
                                              style={{
                                                fontSize: '14px',
                                                color: '#434343',
                                                margin: 0,
                                                padding: 0,
                                                fontWeight: '500',
                                              }}
                                            >
                                              {ack.comment?.message}
                                            </p>
                                          </h4>
                                          <div
                                            style={{
                                              fontSize: '12px',
                                              color: '#434343',
                                            }}
                                          >
                                            {ack.timestamp.toLocaleTimeString() +
                                              ' ' +
                                              ack.timestamp.toLocaleDateString()}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                <div
                                  style={{
                                    // borderBottom: '1px solid #00534C',
                                    flexDirection: 'column',
                                    display: 'flex',
                                    width: '100%',
                                    padding: '10px',
                                    position: 'relative',
                                  }}
                                >
                                  <span
                                    style={{
                                      position: 'absolute',
                                      right: '2px',
                                      top: '2px',
                                      display:
                                        isHovered && !isEditable && !textModal
                                          ? 'block'
                                          : 'none',
                                    }}
                                    className="iconButton"
                                    onClick={() => setTextModal(true)}
                                  >
                                    <HiPencil
                                      data-testid="isDotButton"
                                      className="icon"
                                    />
                                  </span>
                                  {!isEditable && !textModal ? (
                                    <RenderDescriptionMessage
                                      message={reviewal.description || ''}
                                    />
                                  ) : (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        padding: '10px',
                                      }}
                                    >
                                      <InputTextarea
                                        value={reviewDescription}
                                        onChange={(e) => {
                                          setReviewDescription(e.target.value);
                                          setIsSaveClicked(false);
                                        }}
                                        placeholder="Add Review Description..."
                                        className="review-descripion-input-text-box"
                                        style={{
                                          flex: 1,
                                          // fontWeight: 'bold',
                                          // width: `calc(1rem*${title.length})`,
                                          // minWidth: '350px',
                                        }}
                                      />
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'flex-end',
                                          marginTop: '10px',
                                          gap: '10px',
                                        }}
                                      >
                                        {!isEditable && (
                                          <Button
                                            className="primary-button cancel"
                                            onClick={() => {
                                              setReviewDescription(
                                                reviewal.description
                                                  ? reviewal.description
                                                  : ''
                                              );
                                              setTextModal(false);
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                        )}
                                        <Button
                                          className={
                                            isSaveClicked
                                              ? 'secondary-button'
                                              : 'primary-button'
                                          }
                                          onClick={() => {
                                            handleUpdateReviewalDescription(
                                              reviewDescription
                                            );
                                            // setReviewDescription('');
                                            setIsSaveClicked(true);
                                            setTextModal(false);
                                          }}
                                          disabled={isSaveClicked}
                                        >
                                          {isSaveClicked ? 'Saved' : 'Save'}
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </li>
                            </ul>
                            <h4
                              style={{
                                fontSize: '14px',
                                fontWeight: 'bold',
                                color: '#434343',
                                marginTop: '10px',
                                marginLeft: '10px',
                              }}
                            >
                              Activity
                            </h4>
                          </div>
                        ) : (
                          <div className="itemContainer" key={index}>
                            <div className="commitIcon">
                              <GitCommitIcon
                                color="red.6"
                                mr={2}
                                size={'small'}
                                verticalAlign="unset"
                                className="commitIcon"
                              />
                            </div>
                            {isUserComments(item) ? (
                              <ul className="itemList">
                                <li
                                  className={`item ${item.owner.uid === reviewal.uid ? '' : 'userComment'}`}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                  onClick={() => {
                                    if (item.owner.uid !== reviewal.uid)
                                      handleUserCommentClick(item);
                                  }}
                                >
                                  <div
                                    style={{
                                      width: '30px',
                                      height: '30px',
                                      borderRadius: '50%',
                                      backgroundColor: '#00534C',
                                      color: 'white',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      fontSize: '11px',
                                      fontWeight: 'bold',
                                      marginRight: '10px',
                                    }}
                                  >
                                    {item.user.firstName[0]}
                                    {item.user.lastName[0]}
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      <div
                                        style={{ fontSize: '16px', flex: 1 }}
                                      >
                                        {item.message}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '12px',
                                          color: '#434343',
                                        }}
                                      >
                                        {item.timestamp.toLocaleTimeString() +
                                          ' ' +
                                          item.timestamp.toLocaleDateString()}
                                      </div>
                                    </div>
                                    {item.owner.uid !== reviewal.uid && (
                                      <div
                                        style={{
                                          fontSize: '12px',
                                          color: '#00534C',
                                        }}
                                      >
                                        {item.user.firstName +
                                          ' ' +
                                          item.user.lastName}{' '}
                                        commented on{' '}
                                        {toTitleCase(
                                          (item.owner as DraftChangeItem)
                                            .changeType
                                        )}{' '}
                                        {
                                          (item.owner as DraftChangeItem)
                                            .changeItem?.name
                                        }
                                      </div>
                                    )}
                                  </div>
                                </li>
                              </ul>
                            ) : isAcknowledgeItem(item) ? (
                              <ul className="itemList">
                                <li
                                  className="item userComment"
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                  onClick={() =>
                                    handleAcknowledgeItemClick(item)
                                  }
                                >
                                  <div
                                    className=""
                                    style={{
                                      position: 'relative',
                                      // display: 'inline-block',
                                      marginRight: '10px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '50%',
                                        backgroundColor: '#00534C',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        // position: 'relative',
                                      }}
                                    >
                                      {item.user.firstName[0]}
                                      {item.user.lastName[0]}
                                    </div>
                                    <div
                                      style={{
                                        position: 'absolute',
                                        top: 20,
                                        right: '0',
                                        width: '14px',
                                        height: '14px',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: '#FFF',
                                      }}
                                    >
                                      {item.state === ACKStatus.APPROVED ? (
                                        <FaCircleCheck
                                          style={{
                                            color: '#037F02',
                                            fontSize: '12px',
                                          }}
                                        />
                                      ) : (
                                        <FaCircleXmark
                                          style={{
                                            color: '#8c1010',
                                            fontSize: '12px',
                                          }}
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      width: '100%',
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                      }}
                                    >
                                      <div
                                        style={{ fontSize: '16px', flex: 1 }}
                                      >
                                        {item.user.firstName}{' '}
                                        {item.user.lastName} has{' '}
                                        {item.state.toLocaleLowerCase()}{' '}
                                        {
                                          (item.owner as DraftChangeItem)
                                            .changeItem?.name
                                        }{' '}
                                        change.
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '12px',
                                          color: '#434343',
                                        }}
                                      >
                                        {item.timestamp.toLocaleTimeString() +
                                          ' ' +
                                          item.timestamp.toLocaleDateString()}
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '100%',
                                      }}
                                    >
                                      <div
                                        style={{
                                          fontSize: '12px',
                                          color: '#586069',
                                          fontWeight: 'bold',
                                          flex: 1,
                                        }}
                                      >
                                        {item.comment?.message}
                                      </div>
                                      <FaChevronRight
                                        style={{
                                          color: '#586069',
                                          fontSize: '12px',
                                          marginLeft: '10px',
                                        }}
                                      />
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            ) : (
                              <ul className="itemList">
                                <li
                                  className="item"
                                  style={{ fontSize: '16px' }}
                                >
                                  {item.description}
                                </li>
                              </ul>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              {!isFullReviewComplete && (
                <div>
                  <ReactLoading
                    className="load"
                    type={props.type ? props.type : 'bubbles'}
                    height={60}
                    width={60}
                  />
                </div>
              )}
            </div>
            {sortedItems && sortedItems.length === 0 && (
              <div className="no-data-container" style={{ marginTop: '15vh' }}>
                <MdCreateNewFolder size={220} className="light-grey-icon" />
                <h4 className="light-grey-icon">
                  No comments or events created...
                </h4>
              </div>
            )}

            {/*  add a check that this is only viisble to the owner */}
            {user.id === reviewal.user.id &&
              isFullReviewComplete &&
              sortedItems &&
              sortedItems.length !== 0 &&
              (reviewal.state === ACKStatus.APPROVED ||
                reviewal.state === ACKStatus.REJECTED ||
                reviewal.state === ACKStatus.PENDING) && (
                <div
                  style={{
                    marginTop: '10px',
                    marginBottom: '2rem',
                  }}
                >
                  <ReviewStatusButton
                    state={reviewal}
                    approvedCount={approvedCount}
                    onClick={(type: 'publish' | 'override' | 'close') => {
                      setModalState({
                        isVisible: true,
                        title:
                          type === 'override'
                            ? 'Publish Changes Without Approval?'
                            : type === 'close'
                              ? 'Close Review?'
                              : 'Publish Changes?',
                        description:
                          type === 'override'
                            ? `This will bypass the approval process and publish the changes immediately. Then it will close the reviewal.`
                            : type === 'close'
                              ? `This will close the review process and will not publish the changes. The review will be able to be found in the closed section.`
                              : `This will publish the changes to OneDose and end this review process. Reload the application to view the changes.`,
                        primaryBtnName: 'Cancel',
                        secondaryBtnName:
                          type === 'override'
                            ? 'Publish Now'
                            : type === 'close'
                              ? 'Close Review'
                              : 'Publish',
                        isRedBtn: type !== 'publish',
                        handleSubmit: () => {
                          setModalState({ ...modalState, isVisible: false });
                          handlePublishReviewal(
                            type === 'override'
                              ? 'Publish Now'
                              : type === 'close'
                                ? 'Close Review'
                                : 'Publish'
                          );
                        },
                        handleClose: undefined,
                      });
                    }}
                  />
                </div>
              )}
            {(reviewal.state === ACKStatus.PENDING ||
              reviewal.state === ACKStatus.REJECTED ||
              reviewal.state === ACKStatus.APPROVED) && (
              <div
                className="commentWrapper"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // padding: '2px',
                  marginTop: '10px',
                }}
              >
                <div
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    backgroundColor: '#00534C',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    marginRight: '10px',
                  }}
                >
                  {user.firstName[0]}
                  {user.lastName[0]}
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid rgb(204, 204, 204)',
                    padding: '0.5rem 0.5rem',
                    borderRadius: '6px',
                    width: '100%',

                    justifyContent: 'center',
                  }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <input
                      // ref={inputRef}
                      type="text"
                      placeholder="Add a comment..."
                      style={{
                        width: '100%',
                        height: '100%',
                        fontSize: '14px',
                        border: 'none',
                        outline: 'none',
                        borderRadius: '0',
                      }}
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleComment(comment);
                          setComment('');
                        }
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '10px',
                    }}
                  >
                    <Button
                      className="primary-button btn-rightMargin btn btn-primary"
                      disabled={comment.length === 0}
                      style={{
                        marginRight: '0px',
                      }}
                      onClick={() => {
                        handleComment(comment);
                        setComment('');
                      }}
                    >
                      Comment
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            className="review-card"
            style={{
              border: '1px solid #e1e4e8',
              borderRadius: '6px',
              margin: '0px auto',
              overflow: 'hidden',
              fontSize: '14px',
              flex: 35,
              // width: '31%',
              height: '100%',
            }}
          >
            <div
              className="status-banner"
              style={{
                padding: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                backgroundColor: getStatusStyles(reviewal.state)
                  .backgroundColor,
                color: getStatusStyles(reviewal.state).color,
              }}
            >
              <span>{ACKStatus[reviewal.state]}</span>
            </div>
            <div
              className="review-content"
              style={{
                padding: '16px',
                backgroundColor: '#f6f8fa',
                flex: 1,
              }}
            >
              <div
                style={{
                  marginBottom: '12px',
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <strong
                  style={{
                    // display: 'block',
                    display: 'flex',
                    // marginTop: '6px',
                    // marginBottom: '12px',
                    fontSize: '16px',
                    marginRight: '8px',
                    textAlign: 'center',
                  }}
                >
                  Reviewers
                </strong>
                {isEditable && (
                  <span
                    className="iconButton"
                    onClick={() => setIsModalVisible(true)}
                  >
                    <HiPencil data-testid="isDotButton" className="icon" />
                  </span>
                )}
              </div>

              {reviewal.reviewers.length > 0 &&
                reviewal.reviewers.map((reviewer: User, index) => {
                  const user = database.users.find(
                    (user) => user.id === reviewer.id
                  );
                  const reviewerAck = reviewal.acknowledgements.find(
                    (ack: AcknowledgeItem) => ack.user.id === reviewer.id
                  );
                  const comment = reviewerAck?.comment?.message;
                  return (
                    <div
                      key={index}
                      className="reviewer"
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        marginBottom: '12px',
                      }}
                    >
                      <div
                        style={{
                          display: 'inline-block',
                          verticalAlign: 'top',
                        }}
                      >
                        {reviewal.state === ACKStatus.REJECTED &&
                        reviewerAck == null ? (
                          <BsQuestionSquareFill
                            style={{
                              marginRight: '10px',
                              display: 'inline-block',
                              width: '20px',
                              height: '20px',
                              verticalAlign: 'top',
                              color: 'grey',
                            }}
                          />
                        ) : reviewerAck == null ? (
                          <input
                            type="checkbox"
                            checked={false}
                            disabled
                            className="custom-checkbox"
                            style={{
                              marginRight: '10px',
                              display: 'inline-block',
                              width: '20px',
                              height: '20px',
                              verticalAlign: 'top',
                            }}
                          />
                        ) : reviewerAck?.state === ACKStatus.APPROVED ? (
                          <IoCheckbox
                            style={{
                              marginRight: '10px',
                              display: 'inline-block',
                              width: '20px',
                              height: '20px',
                              verticalAlign: 'top',
                              color: '#037F02',
                            }}
                          />
                        ) : (
                          <FaRectangleXmark
                            style={{
                              marginRight: '10px',
                              display: 'inline-block',
                              width: '20px',
                              height: '20px',
                              verticalAlign: 'top',
                              color: '#8c1010',
                            }}
                          />
                        )}
                      </div>
                      <div style={{ marginLeft: '8px', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <strong style={{ flex: 1 }}>
                            {user?.firstName + ' ' + user?.lastName}
                          </strong>
                          {reviewerAck != null && (
                            <span style={{ marginLeft: '8px' }}>
                              {reviewerAck.timestamp.toLocaleTimeString(
                                'en-US',
                                {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true,
                                }
                              ) +
                                ' ' +
                                reviewerAck.timestamp.toLocaleDateString()}
                            </span>
                          )}
                        </div>
                        {comment && (
                          <div>
                            <FaReply
                              style={{
                                marginLeft: '5px',
                                transform: 'rotate(180deg)',
                                color: '#586069',
                              }}
                            />

                            <span
                              style={{ fontStyle: 'italic', color: '#586069' }}
                            >
                              {'  ' + comment}
                            </span>
                          </div>
                        )}
                      </div>
                      <div></div>
                    </div>
                  );
                })}
            </div>
            <div
              className="metadata"
              style={{
                padding: '16px',
                borderTop: '1px solid #e1e4e8',
                backgroundColor: '#fff',
              }}
            >
              {reviewal.endedAt && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <FaCalendarAlt
                    style={{ marginRight: '8px', color: '#586069' }}
                  />

                  <div style={{ marginLeft: '8px' }}>
                    <strong>Completed At:</strong>{' '}
                    {reviewal.endedAt.toLocaleString()}
                  </div>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                <FaCalendarAlt
                  style={{ marginRight: '8px', color: '#586069' }}
                />

                <div style={{ marginLeft: '8px' }}>
                  <strong>Created At:</strong>{' '}
                  {reviewal.createdAt.toLocaleString()}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                <FaUser style={{ marginRight: '8px', color: '#586069' }} />

                <div style={{ marginLeft: '8px' }}>
                  <strong>Created By:</strong>{' '}
                  {reviewal.user.firstName + ' ' + reviewal.user.lastName}
                </div>
              </div>
              {user.type === 'ADMIN' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <TbDatabaseSearch
                    style={{ marginRight: '8px', color: '#586069' }}
                  />

                  <div style={{ marginLeft: '8px' }}>
                    <strong>ID:</strong> {reviewal.uid}
                    <span>
                      {isCopied && isCopied === reviewal.uid ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) =>
                            handleCopy(reviewal.uid, e, setIsCopied)
                          }
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === 'ADMIN' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <TbDatabaseSearch
                    style={{ marginRight: '8px', color: '#586069' }}
                  />

                  <div style={{ marginLeft: '8px' }}>
                    <strong>Department ID:</strong> {reviewal.departmentID}
                    <span>
                      {isCopied && isCopied === reviewal.departmentID ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) =>
                            handleCopy(reviewal.departmentID, e, setIsCopied)
                          }
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
              {user.type === 'ADMIN' && isEditable && (
                <div
                  style={{
                    marginBottom: '12px',
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <strong
                    style={{
                      // display: 'block',
                      display: 'flex',
                      // marginTop: '6px',
                      // marginBottom: '12px',
                      fontSize: '14px',
                      marginRight: '8px',
                      textAlign: 'center',
                    }}
                  >
                    Events:
                  </strong>

                  <span
                    className="iconButton"
                    onClick={() => handleRemoveEvents()}
                  >
                    <FaTrash
                      data-testid="isDotButton"
                      className="icon"
                      style={{ fontSize: '12px' }}
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewDetails;
