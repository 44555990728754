import {
  BatchQuery,
  DatabaseResponse,
  loadDatabase,
  Response,
  ResponseType,
} from './AmplifyDB';
import DepartmentItem from './model/DepartmentItem';
import {
  convertCategoryChangeToDraft,
  getCategoryDrafts,
  isCategryDraftCreated,
  publishCategory,
  removeCurrentCategoryDrafts,
} from './functions/CategoryDB';
import {
  convertProtocolChangeToDraft,
  getProtocolDrafts,
  isProtocolDraftCreated,
  publishProtocol,
  removeCurrentProtocolDrafts,
} from './functions/ProtocolDB';
import {
  convertEquipmentChangeToDraft,
  getEquipmentDrafts,
  isEquipmentDraftCreated,
  publishEquipment,
  removeCurrentEquipmentDrafts,
} from './functions/EquipmentDB';
import {
  convertElectricalChangeToDraft,
  convertElectricalDoseChangeToDraft,
  getElectricalDrafts,
  isElectricalDosesDraftCreated,
  isElectricalDraftCreated,
  publishElectrical,
  publishElectricalDoses,
  removeCurrentElectricalDrafts,
} from './functions/ElectricalDB';
import {
  convertMedicationChangeToDraft,
  convertMedicationDoseChangeToDraft,
  getMedicationDrafts,
  isMedicationDosesDraftCreated,
  isMedicationDraftCreated,
  publishMedication,
  publishMedicationDoses,
  removeCurrentMedicationDrafts,
} from './functions/MedicationDB';
import {
  convertVitalChangeToDraft,
  getVitalDrafts,
  isVitalDraftCreated,
  publishVital,
  removeCurrentVitalDrafts,
} from './functions/VitalDB';
import {
  convertInfusionChangeToDraft,
  convertInfusionDoseChangeToDraft,
  getInfusionDrafts,
  isInfusionDosesDraftCreated,
  isInfusionDraftCreated,
  publishInfusion,
  publishInfusionDoses,
  removeCurrentInfusionDrafts,
} from './functions/InfusionDB';
import {
  convertChecklistChangeToDraft,
  getCheckListDrafts,
  isCheckListDraftCreated,
  publishCheckList,
  removeCurrentCheckListDrafts,
} from './functions/CheckListDB';
import { globals } from '../ui/_global/common/Utils';
import DraftChangeItem, { DraftChangeType } from './model/DraftChangeItem';
import CategoryItem from './model/CategoryItem';
import ModelItem from './model/ModelItem';
import ProtocolItem from './model/ProtocolItem';
import MedicationItem from './model/MedicationItem';
import EquipmentItem from './model/EquipmentItem';
import ElectricalItem from './model/ElectricalItem';
import VitalItem from './model/VitalItem';
import ChecklistItem from '../ui/pages/protocol/details/ChecklistItem';
import FormItem from './model/FormItem';
import {
  DraftChangeJSON,
  getDraftChangesByChangeID,
  getDraftChangesByDepartment,
  updateDraftChangeItem,
} from './functions/ReviewalDB';
import MedicationSubItem from './model/MedicationSubItem';
import {
  auditDoseDatabaseToOldDBStyle,
  auditMedicationDosesToOldDBStyle,
} from './AmplifyActions';
import InfusionItem from './model/InfusionItem';
import InfusionSubItem from './model/InfusionSubItem';
import ElectricalSubItem from './model/ElectricalSubItem';
import ModelSubItem from './model/ModelSubItem';
import {
  convertCPRChangeToDraft,
  getCPRDrafts,
  isCPRDraftCreated,
  publishCPR,
  removeCurrentCPRDrafts,
} from './functions/CprDB';
import CPRItem from './model/CPRItem';
import { DraftChange } from '../API';

export type Draft = {
  model: ModelItem<any> | ModelSubItem<any>;
  title: string;
  message?: string;
  changeType: DraftChangeType;
  draftChangeItem?: DraftChangeItem;
};

/* ------------------ CHECKING DRAFT ------------------ */
export const isDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const concurrentQueries = [
      isCategryDraftCreated(department),
      isProtocolDraftCreated(department),
      isMedicationDraftCreated(department),
      isMedicationDosesDraftCreated(department),
      isInfusionDraftCreated(department),
      isInfusionDosesDraftCreated(department),
      isEquipmentDraftCreated(department),
      isElectricalDraftCreated(department),
      isElectricalDosesDraftCreated(department),
      isVitalDraftCreated(department),
      isCheckListDraftCreated(department),
      isCPRDraftCreated(department),
    ];

    const responses = await Promise.all(concurrentQueries);

    for (let i = 0; i < responses.length; i++) {
      let res = responses[i];
      if (res.type === ResponseType.Failure) {
        return {
          type: ResponseType.Failure,
          data: res.data,
        };
      }
      if (res.data > 0) {
        return {
          type: ResponseType.Success,
          data: true,
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: false,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* ------------------ DRAFT UPDATES ------------------ */

/**
 *
 * @param db The database response
 * @param retrieveAll True: Retrieve all drafts, False: Retrieve only the drafts that are not in a DraftChange
 * @returns Draft[] The list of drafts in the Draft Type
 */
export const getDraftUpdates = async (
  db: DatabaseResponse,
  retrieveAll: boolean = false,
  includeFolders: boolean = true
): Promise<Response> => {
  try {
    let updates: Draft[] = [];
    const concurrentQueries = [
      includeFolders
        ? getCategoryDrafts(db)
        : Promise.resolve({
            type: ResponseType.Success,
            data: [],
          }),
      getProtocolDrafts(db),
      getMedicationDrafts(db),
      getInfusionDrafts(db),
      getEquipmentDrafts(db),
      getElectricalDrafts(db),
      getVitalDrafts(db),
      getCheckListDrafts(db),
      getCPRDrafts(db),
    ];

    const results = await Promise.all(concurrentQueries);
    for (let res of results) {
      if (res.type === ResponseType.Success) {
        updates.push(...res.data);
      } else {
        if (globals.debug) console.log('Error getting drafts', res.data);
        return {
          type: ResponseType.Failure,
          data: res.data,
        };
      }
    }

    if (!retrieveAll) {
      const draftResp = await getDraftChangesByDepartment(db);
      if (draftResp.type === ResponseType.Success) {
        const draftChanges = draftResp.data;
        updates = updates.filter((draft: Draft) => {
          //Search if the changeID is in a DraftChangeItem
          let found = draftChanges.find(
            (dc: DraftChange) =>
              dc.changeType === draft.changeType &&
              dc.changeID === draft.model.uid
          );
          return found == null;
        });
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    console.log('ERROR GETTING DRAFT UPDATES', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

//  convert DraftChangeItem to Draft

export const convertDraftChangeItemToDraft = (
  draftChangeItems: DraftChangeItem[]
): Draft[] => {
  try {
    let updates: (Draft | null)[] = [];
    for (let draftChangeItem of draftChangeItems) {
      if (
        draftChangeItem.changeType === DraftChangeType.FOLDER ||
        draftChangeItem.changeType === DraftChangeType.PROTOCOL_INDEX
      ) {
        let catDraft = convertCategoryChangeToDraft(draftChangeItem);
        updates.push(catDraft);
      } else if (draftChangeItem.changeType === DraftChangeType.PROTOCOL) {
        let protDraft = convertProtocolChangeToDraft(draftChangeItem);
        updates.push(protDraft);
      } else if (draftChangeItem.changeType === DraftChangeType.MEDICATION) {
        let medDraft = convertMedicationChangeToDraft(draftChangeItem);
        updates.push(medDraft);
      } else if (
        draftChangeItem.changeType === DraftChangeType.MEDICATION_DOSE
      ) {
        let medDraft = convertMedicationDoseChangeToDraft(draftChangeItem);
        updates.push(medDraft);
      } else if (draftChangeItem.changeType === DraftChangeType.EQUIPMENT) {
        let equipDraft = convertEquipmentChangeToDraft(draftChangeItem);
        updates.push(equipDraft);
      } else if (draftChangeItem.changeType === DraftChangeType.ELECTRICAL) {
        let elecDraft = convertElectricalChangeToDraft(draftChangeItem);
        updates.push(elecDraft);
      } else if (
        draftChangeItem.changeType === DraftChangeType.ELECTRICAL_SHOCK
      ) {
        let elecDraft = convertElectricalDoseChangeToDraft(draftChangeItem);
        updates.push(elecDraft);
      } else if (draftChangeItem.changeType === DraftChangeType.VITAL) {
        let vitalDraft = convertVitalChangeToDraft(draftChangeItem);
        updates.push(vitalDraft);
      } else if (draftChangeItem.changeType === DraftChangeType.CHECKLIST) {
        let checkDraft = convertChecklistChangeToDraft(draftChangeItem);
        updates.push(checkDraft);
      } else if (draftChangeItem.changeType === DraftChangeType.INFUSION) {
        let infusDraft = convertInfusionChangeToDraft(draftChangeItem);
        updates.push(infusDraft);
      } else if (draftChangeItem.changeType === DraftChangeType.INFUSION_DOSE) {
        let infusDraft = convertInfusionDoseChangeToDraft(draftChangeItem);
        updates.push(infusDraft);
      } else if (draftChangeItem.changeType === DraftChangeType.CPR) {
        let cprDraft = convertCPRChangeToDraft(draftChangeItem);
        updates.push(cprDraft);
      }
    }
    return updates.filter((d) => d !== null) as Draft[];
  } catch (error) {
    return [];
  }
};

/* ------------------ PUBLISH DRAFT UPDATES ------------------ */

export const publishDraftUpdates = async (
  department: DepartmentItem,
  drafts: Draft[]
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let errors: any[] = [];
    let concurrentQueries: Promise<Response>[] = [];
    if (globals.debug) console.log('PUBLISHING DRAFTS', drafts);
    for (let draft of drafts) {
      let model = draft.model;
      if (model instanceof CategoryItem || model.TAG === 'CategoryItem')
        concurrentQueries.push(publishCategory(model as CategoryItem));
      else if (model instanceof ProtocolItem || model.TAG === 'ProtocolItem')
        concurrentQueries.push(
          publishProtocol(model as ProtocolItem, draft.draftChangeItem)
        );
      else if (
        model instanceof MedicationItem ||
        model.TAG === 'MedicationItem'
      )
        concurrentQueries.push(
          publishMedication(
            department,
            model as MedicationItem,
            draft.draftChangeItem
          )
        );
      else if (model instanceof InfusionItem || model.TAG === 'InfusionItem')
        concurrentQueries.push(
          publishInfusion(
            department,
            model as InfusionItem,
            draft.draftChangeItem
          )
        );
      else if (
        model instanceof MedicationSubItem ||
        model.TAG === 'MedicationSubItem'
      )
        concurrentQueries.push(
          publishMedicationDoses(
            model as MedicationSubItem,
            draft.draftChangeItem
          )
        );
      else if (
        model instanceof InfusionSubItem ||
        model.TAG === 'InfusionSubItem'
      )
        concurrentQueries.push(
          publishInfusionDoses(model as InfusionSubItem, draft.draftChangeItem)
        );
      else if (model instanceof EquipmentItem || model.TAG === 'EquipmentItem')
        concurrentQueries.push(
          publishEquipment(model as EquipmentItem, draft.draftChangeItem)
        );
      else if (
        model instanceof ElectricalItem ||
        model.TAG === 'ElectricalItem'
      )
        concurrentQueries.push(
          publishElectrical(model as ElectricalItem, draft.draftChangeItem)
        );
      else if (
        model instanceof ElectricalSubItem ||
        model.TAG === 'ElectricalSubItem'
      )
        concurrentQueries.push(
          publishElectricalDoses(
            model as ElectricalSubItem,
            draft.draftChangeItem
          )
        );
      else if (model instanceof VitalItem || model.TAG === 'VitalItem')
        concurrentQueries.push(
          publishVital(model as VitalItem, draft.draftChangeItem)
        );
      else if (model instanceof FormItem || model.TAG === 'FormItem')
        concurrentQueries.push(
          publishCheckList(model as FormItem, draft.draftChangeItem)
        );
      else if (model instanceof CPRItem || model.TAG === 'CPRItem')
        concurrentQueries.push(
          publishCPR(model as CPRItem, draft.draftChangeItem)
        );
    }

    const results = await Promise.all(concurrentQueries);
    if (globals.debug) console.log('PUBLISHING DRAFTS', results);
    for (let i = 0; i < results.length; i++) {
      let res = results[i];
      let draft = drafts[i];
      if (res.type === ResponseType.Success) updates.push(res.data);
      else {
        if (globals.debug) console.log('Error publishing drafts', res.data);
        errors.push({
          draft: draft,
          error: res.data,
        });
      }
    }

    if (globals.oldDBDoseStyle) {
      let medcationDoses = drafts.filter(
        (draft) => draft.model instanceof MedicationSubItem
      );
      let infusionDoses = drafts.filter(
        (draft) => draft.model instanceof InfusionSubItem
      );
      let electricalDoses = drafts.filter(
        (draft) => draft.model instanceof ElectricalSubItem
      );
      if (
        medcationDoses.length > 0 ||
        infusionDoses.length > 0 ||
        electricalDoses.length > 0
      ) {
        if (globals.debug)
          console.log(
            'CONVERTING DOSES OT OLD DB STYLE',
            medcationDoses,
            infusionDoses,
            electricalDoses
          );
        auditDoseDatabaseToOldDBStyle(
          medcationDoses.map((d) => d.model as MedicationSubItem),
          infusionDoses.map((d) => d.model as InfusionSubItem),
          electricalDoses.map((d) => d.model as ElectricalSubItem)
        )
          .then((res) => {
            if (res.type === ResponseType.Success) {
              if (globals.debug)
                console.log('DOSES CONVERTED TO OLD DB STYLE', res.data);
            } else {
              console.error('Error converting doses', res.data);
            }
          })
          .catch((error) => {
            console.error('Error converting doses', error);
          });
      }
    }

    if (errors.length > 0) {
      console.error('Errors publishing drafts', errors);
      // for (let error of errors) {
      //   console.error('Error publishing draft', error.draft, error.error);
      // }
    }

    return {
      type: ResponseType.Success,
      data: {
        updates: updates,
        errors: errors,
      },
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Close all the draft changes that are not in a DraftChangeItem
 * @param drafts The drafts to close
 * @returns The response of the batch query
 */
export const closeDraftChanges = async (drafts: Draft[]): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let promiseFunctions: (() => Promise<Response>)[] = [];
      for (let draft of drafts) {
        let draftChange: DraftChangeItem | undefined = draft.draftChangeItem;
        if (draftChange == null || draftChange.changeItem == null) continue;
        promiseFunctions.push(() =>
          updateDraftChangeItem({
            previousDraftChange: draftChange as DraftChangeItem,
            changeItem: (draftChange as DraftChangeItem).changeItem as
              | ModelItem<any>
              | ModelSubItem<any>,
            changeType: (draftChange as DraftChangeItem).changeType,
            previousItem:
              (draftChange as DraftChangeItem).previousItem || undefined,
            isClosed: true,
          })
        );
      }
      let results = await BatchQuery(promiseFunctions);
      for (let res of results) {
        if (res.type === ResponseType.Failure) {
          console.error('Error closing draft changes', res.data);
          // reject(res.data);
        }
      }
      resolve({
        type: ResponseType.Success,
        data: results,
      });
    } catch (error) {
      console.error('Error closing draft changes', error);
      reject(error);
    }
  });
};

export const checkActiveToArchiveDraftChange = async (
  activeID: string,
  archiveID: string
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let result = await getDraftChangesByChangeID(activeID);
      if (result.type === ResponseType.Failure) {
        console.error('Error getting draft changes', result.data);
        reject(result.data);
      } else {
        let drafts = result.data as DraftChange[];
        let promises: Promise<Response>[] = [];
        for (let i = 0; i < drafts.length; i++) {
          let dc = drafts[i];
          if (dc.isClosed) {
            let new_dc: DraftChangeJSON = {
              previousDraftChange: dc,
              changeItem: archiveID,
              changeType: dc.changeType,
            };
            promises.push(updateDraftChangeItem(new_dc));
          }
        }
        let results = await Promise.all(promises);
        for (let res of results) {
          if (res.type === ResponseType.Failure) {
            console.error('Error updating draft changes', res.data);
            reject(res.data);
          }
        }
        resolve({
          type: ResponseType.Success,
          data: results,
        });
      }
    } catch (error) {
      console.error('Error checking active to archive draft change', error);
      reject(error);
    }
  });
};

export const removeCurrentDraftUpdates = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];

    const concurrentQueries = [
      removeCurrentCategoryDrafts(db),
      removeCurrentProtocolDrafts(db),
      removeCurrentMedicationDrafts(db),
      removeCurrentInfusionDrafts(db),
      removeCurrentEquipmentDrafts(db),
      removeCurrentElectricalDrafts(db),
      removeCurrentCheckListDrafts(db),
      removeCurrentVitalDrafts(db),
      removeCurrentCPRDrafts(db),
    ];

    const [
      catResp,
      protResp,
      medResp,
      infusResp,
      equipResp,
      elecResp,
      checkResp,
      vitalResp,
      cprResp,
    ] = await Promise.all(concurrentQueries);

    if (catResp.type === ResponseType.Success) updates.push(...catResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current category drafts', catResp.data);
      return {
        type: ResponseType.Failure,
        data: catResp.data,
      };
    }

    if (protResp.type === ResponseType.Success) updates.push(...protResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current protocol drafts', protResp.data);
      return {
        type: ResponseType.Failure,
        data: protResp.data,
      };
    }

    if (medResp.type === ResponseType.Success) updates.push(...medResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current medication drafts', medResp.data);
      return {
        type: ResponseType.Failure,
        data: medResp.data,
      };
    }

    if (infusResp.type === ResponseType.Success)
      updates.push(...infusResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current infusion drafts', infusResp.data);
      return {
        type: ResponseType.Failure,
        data: infusResp.data,
      };
    }

    if (equipResp.type === ResponseType.Success)
      updates.push(...equipResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current equipment drafts', equipResp.data);
      return {
        type: ResponseType.Failure,
        data: equipResp.data,
      };
    }

    if (elecResp.type === ResponseType.Success) updates.push(...elecResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current electrical drafts', elecResp.data);
      return {
        type: ResponseType.Failure,
        data: elecResp.data,
      };
    }

    if (checkResp.type === ResponseType.Success)
      updates.push(...checkResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current checklist drafts', checkResp.data);
      return {
        type: ResponseType.Failure,
        data: checkResp.data,
      };
    }

    if (vitalResp.type === ResponseType.Success)
      updates.push(...vitalResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current vital drafts', vitalResp.data);
      return {
        type: ResponseType.Failure,
        data: vitalResp.data,
      };
    }

    if (cprResp.type === ResponseType.Success) updates.push(...cprResp.data);
    else {
      if (globals.debug)
        console.log('Error removing current cpr drafts', cprResp.data);
      return {
        type: ResponseType.Failure,
        data: cprResp.data,
      };
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
