import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  FaAmbulance,
  FaWeight,
  FaHistory,
  FaKey,
  FaUsers,
  FaInfoCircle,
} from 'react-icons/fa';
import { DatabaseResponse, ResponseType } from '../../../data/AmplifyDB';
import { toTitleCase } from '../../_global/common/Utils';
import {
  FaBoltLightning,
  FaFolderOpen,
  FaHeartPulse,
  FaSyringe,
  FaWeightHanging,
  FaBuilding,
  FaClock,
  FaUser,
} from 'react-icons/fa6';
import {
  IoNotifications,
  IoPeopleSharp,
  IoHeart,
  IoDocumentText,
  IoMedkit,
  IoWater,
  IoDocuments,
  IoPersonSharp,
} from 'react-icons/io5';
import { RiContactsBook2Fill } from 'react-icons/ri';
import ProtocolItem from '../../../data/model/ProtocolItem';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import ElectricalItem from '../../../data/model/ElectricalItem';
import MedicationItem from '../../../data/model/MedicationItem';
import InfusionItem from '../../../data/model/InfusionItem';
import FormItem from '../../../data/model/FormItem';
import VitalItem from '../../../data/model/VitalItem';
import KeychainItem from '../../../data/model/KeychainItem';
import { Ambulance, User, WeightObject } from '../../../models';
import ContactItem from '../../../data/model/ContactItem';
import { useDispatch } from 'react-redux';
import { handleDataTableRowSelection } from '../../../store/actions';
import CategoryItem from '../../../data/model/CategoryItem';
import MedicationSubItem from '../../../data/model/MedicationSubItem';
import InfusionSubItem from '../../../data/model/InfusionSubItem';
import { fetchUser } from '../../../data/functions/UserDB';
import { IconButton, Tooltip } from '@mui/material';
import ElectricalSubItem from '../../../data/model/ElectricalSubItem';
import ModelItem from '../../../data/model/ModelItem';
import ModelSubItem from '../../../data/model/ModelSubItem';

const KeyFormattedValues = {
  department: 'Department',
  cprModel: 'CPR Assist',
  users: 'User',
  categories: 'Folder',
  protocols: 'Protocol',
  ambulances: 'Ambulance',
  oneWeights: 'OneWeights',
  medications: 'Medication',
  medicationDoses: 'Medication Dose',
  infusions: 'Infusion',
  infusionDoses: 'Infusion Dose',
  equipment: 'Equipment',
  electrical: 'Electrical',
  electricalDoses: 'Electrical Dose',
  checklists: 'Checklist',
  vitals: 'Vital',
  logs: 'Log',
  notifications: 'Notification',
  contacts: 'Contact',
  weightObjects: 'Subtractable Weight',
  keychains: 'Keychain',
  groups: 'Group',
};

interface ActivityLogSidebarProps {
  show: boolean;
}

interface ActivityItem {
  parentKey: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  item: any;
  modifiedBy: string | null;
}

const iconMapping: { [key: string]: React.ComponentType } = {
  department: FaBuilding,
  cprModel: FaHeartPulse,
  users: IoPeopleSharp,
  categories: FaFolderOpen,
  protocols: IoDocuments,
  ambulances: FaAmbulance,
  oneWeights: FaWeight,
  medications: FaSyringe,
  medicationDoses: FaSyringe,
  infusions: IoWater,
  infusionDoses: IoWater,
  equipment: IoMedkit,
  electrical: FaBoltLightning,
  electricalDoses: FaBoltLightning,
  checklists: IoDocumentText,
  vitals: IoHeart,
  logs: FaHistory,
  notifications: IoNotifications,
  contacts: RiContactsBook2Fill,
  weightObjects: FaWeightHanging,
  keychains: FaKey,
  groups: FaUsers,
  // Fallback icon if no match is found
  default: FaHistory,
};

/* 11-07-24 Gagan: Created Activity Log Sidebar component */
const ActivityLogSidebar: React.FC<ActivityLogSidebarProps> = ({ show }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const isEditMode = useSelector((state: any) => state.isEditMode);
  const [activityList, setActivityList] = useState<ActivityItem[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<ActivityItem | null>(
    null
  );
  const user: User = useSelector((state: any) => state.user);
  const username = `${user.firstName} ${user.lastName}`;
  const [filterByCurrentUser, setFilterByCurrentUser] = useState(false);

  useEffect(() => {
    // create a function to fetch users
    const fetchUserName = async (
      item: ModelItem<any> | ModelSubItem<any>,
      _users: User[]
    ) => {
      /* Base Check for types that are NOT tracked by the system */
      if (item instanceof ContactItem) return null;

      let users = _users ?? [];
      let id = item.modifiedByID || item.createdByID;
      let userData = users.find((user) => user.id === id);
      if (userData == null) {
        const response = await fetchUser(id);
        if (response.type === ResponseType.Success && response.data)
          userData = response.data;
      }
      return userData && userData.firstName && userData.lastName
        ? `${userData.firstName} ${userData.lastName}`
        : 'Hinckley Medical';
    };

    const loadActivityList = async () => {
      if (database) {
        const processedList: ActivityItem[] = [];

        for (const [parentKey, value] of Object.entries(database)) {
          // Exclude items of type 'users'
          if (
            parentKey !== 'users' &&
            Array.isArray(value) &&
            value.length > 0
          ) {
            for (const item of value) {
              const modifiedBy = await fetchUserName(
                item as any,
                database.users
              );

              processedList.push({
                item,
                parentKey,
                createdAt:
                  'createdAt' in item ? item.createdAt?.toString() || '' : '',
                updatedAt:
                  'updatedAt' in item ? item.updatedAt?.toString() || '' : '',
                name: 'name' in item ? item.name : '',
                modifiedBy: modifiedBy,
              });
            }
          } else if (
            typeof value === 'object' &&
            value !== null &&
            !Array.isArray(value) &&
            Object.keys(value).length > 0
          ) {
            const modifiedBy = await fetchUserName(
              value as any,
              database.users
            );

            processedList.push({
              item: value,
              parentKey,
              createdAt:
                'createdAt' in value ? value.createdAt?.toString() || '' : '',
              updatedAt:
                'updatedAt' in value ? value.updatedAt?.toString() || '' : '',
              name: 'name' in value ? value.name : '',
              modifiedBy: modifiedBy,
            });
          }
        }

        const sorted = processedList.sort((a, b) => {
          const timeA = a.updatedAt || a.createdAt;
          const timeB = b.updatedAt || b.createdAt;

          if (!timeA && !timeB) return 0;
          if (!timeA) return 1;
          if (!timeB) return -1;

          return new Date(timeB).getTime() - new Date(timeA).getTime();
        });

        setActivityList(sorted);
      }
    };

    loadActivityList();
  }, [database]);

  const handleSelectActivity = (activity: ActivityItem) => {
    setSelectedActivity(activity);
    let state;
    switch (activity.parentKey) {
      case 'department':
        navigate('/settings');
        break;
      case 'cprModel':
        navigate('/database/cpr-assist');
        break;
      case 'users':
        // This data is currently not read from the json
        break;
      case 'categories':
        const category = activity.item as CategoryItem;
        // Dispatch selection before navigation
        dispatch<any>(
          handleDataTableRowSelection({
            selectedRowData: [category],
          })
        );
        // Navigate with state after small delay
        setTimeout(() => {
          navigate('/protocol/edit', {
            state: {
              initialSelectedItem: category,
            },
          });
        }, 0);
        break;
      case 'protocols':
        const protocol = activity.item as ProtocolItem;
        state = {
          selectedProtocol: protocol,
          editMode: false,
          backPage: 'list-protocols',
          search: { searchQuery: '', filters: [], categoriesFilter: [] },
        };
        const formattedString = protocol.nickname.replace(/[\s/]/g, '-');
        navigate(`/${formattedString}/protocol-detail`, { state });
        break;
      case 'ambulances':
        const amb = activity.item as Ambulance;
        state = {
          selectedProtocol: null,
          value: amb,
          subValue: null,
          type: 'Ambulance',
          editType: 'edit',
          editMode: false,
          page: 'listAmbulancePage',
        };
        navigate(`/database/edit/ambulance`, { state });
        break;
      case 'oneWeights':
        // No Data
        break;
      case 'medications':
        const medication = activity.item as MedicationItem;
        state = {
          selectedProtocol: null,
          value: medication,
          subValue: null,
          type: 'Medication',
          editType: 'edit',
          editMode: false,
          page: 'listMedicationsPage',
        };
        navigate(`/protocol/edit/edit-protocol`, { state });
        break;
      case 'medicationDoses':
        const medDose = activity.item as MedicationSubItem;
        const medProtocol = medDose.parentProtocol as ProtocolItem;
        const isCPRMedication = medDose.parentCPR != null;
        if (isCPRMedication) {
          // Navigate to the CPR Page in edit mode
          state = {
            selectedDose: medDose,
          };
          navigate(`/database/cpr-assist`, { state });
        } else {
          state = {
            selectedProtocol: medProtocol,
            editMode: false,
            backPage: 'list-protocols',
            search: { searchQuery: '', filters: [], categoriesFilter: [] },
            selectedDose: medDose,
          };
          const medFormattedString = medProtocol.nickname.replace(
            /[\s/]/g,
            '-'
          );
          navigate(`/${medFormattedString}/protocol-detail`, { state });
        }
        break;
      case 'infusions':
        const infusion = activity.item as InfusionItem;
        state = {
          selectedProtocol: null,
          value: infusion,
          subValue: null,
          type: 'Infusion',
          editType: 'edit',
          editMode: isEditMode,
          page: 'listInfusionsPage',
        };
        navigate(`/protocol/edit/edit-protocol`, { state });
        break;
      case 'infusionDoses':
        const infDose = activity.item as InfusionSubItem;
        const infProtocol = infDose.parentProtocol as ProtocolItem;
        state = {
          selectedProtocol: infProtocol,
          editMode: false,
          backPage: 'list-protocols',
          search: { searchQuery: '', filters: [], categoriesFilter: [] },
          selectedDose: infDose,
        };
        const infFormattedString = infProtocol.nickname.replace(/[\s/]/g, '-');
        navigate(`/${infFormattedString}/protocol-detail`, { state });
        break;
      case 'equipment':
        const equi = activity.item as ElectricalItem;
        state = {
          selectedProtocol: null,
          value: equi,
          subValue: null,
          type: 'Equipment',
          editType: 'edit',
          editMode: false,
          page: 'listEquipmentPage',
        };
        navigate(`/protocol/edit/edit-protocol`, { state });
        break;
      case 'electrical':
        const elec = activity.item as ElectricalItem;
        state = {
          selectedProtocol: null,
          value: elec,
          subValue: null,
          type: 'Electrical',
          editType: 'edit',
          editMode: false,
          page: 'listElectricalPage',
        };
        navigate(`/protocol/edit/edit-protocol`, { state });
        break;
      case 'electricalDoses':
        const elecDose = activity.item as ElectricalSubItem;
        const elecProtocol = elecDose.parentProtocol as ProtocolItem;
        const isCPRElectrical = elecDose.parentCPR != null;
        if (isCPRElectrical) {
          // Navigate to the CPR Page in edit mode
          state = {
            selectedDose: elecDose,
          };
          navigate(`/database/cpr-assist`, { state });
        } else {
          state = {
            selectedProtocol: elecProtocol,
            editMode: false,
            backPage: 'list-protocols',
            search: { searchQuery: '', filters: [], categoriesFilter: [] },
            selectedDose: elecDose,
          };
          const elecFormattedString = elecProtocol.nickname.replace(
            /[\s/]/g,
            '-'
          );
          navigate(`/${elecFormattedString}/protocol-detail`, { state });
        }
        break;
      case 'checklists':
        const check = activity.item as FormItem;
        state = {
          selectedProtocol: null,
          value: check,
          subValue: null,
          type: 'Checklist',
          editType: 'edit',
          editMode: false,
          page: 'listChecklistPage',
        };
        navigate(`/protocol/edit/edit-protocol`, { state });
        break;
      case 'vitals':
        const vit = activity.item as VitalItem;
        state = {
          selectedProtocol: null,
          value: vit,
          subValue: null,
          type: 'Vital',
          editType: 'edit',
          editMode: false,
          page: 'listVitalPage',
        };
        navigate(`/protocol/edit/edit-protocol`, { state });
        break;
      case 'logs':
        // No Data
        break;
      case 'notifications':
        // No Data
        break;
      case 'contacts':
        const contact = activity.item as ContactItem;
        state = {
          selectedProtocol: null,
          value: contact,
          subValue: null,
          type: 'Contact',
          editType: 'edit',
          editMode: false,
          page: 'listContactPage',
        };
        navigate(`/database/edit/contact`, { state });
        break;
      case 'weightObjects':
        const wtobj = activity.item as WeightObject;
        state = {
          selectedProtocol: null,
          value: wtobj,
          subValue: null,
          type: 'WeightObject',
          editType: 'edit',
          editMode: false,
          page: 'listWeightObjectPage',
        };
        navigate(`/database/edit/subtractable-weights`, { state });
        break;
      case 'keychains':
        const obj = activity.item as KeychainItem;
        state = {
          selectedProtocol: null,
          value: obj,
          subValue: null,
          type: 'KeychainItem',
          editType: 'edit',
          editMode: false,
          page: 'listKeychainsPage',
        };
        navigate(`/database/edit/keychains`, { state });
        break;
      case 'groups':
        // No Data
        break;
    }
  };

  const formatDate = (date: string) => {
    if (!date) return '';
    try {
      return new Date(date).toLocaleString('en-US', {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    } catch (error) {
      return date;
    }
  };

  return (
    <div className={`activity-log-sidebar ${show ? 'active' : ''}`}>
      <div
        className="greeting"
        style={{
          borderBottom: '1px solid #e0e0e0',
          paddingBottom: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 5px',
          }}
        >
          <Tooltip
            title={`View the history of all changes made to ${database?.department.name}.`}
            placement="right"
            arrow
          >
            <span
              style={{
                fontSize: '28px',
                fontWeight: '400',
                // lineHeight: '35px',
              }}
            >
              <FaHistory style={{ marginRight: '10px' }} size={26} />
              Activity Log
              <span>
                <FaInfoCircle
                  size={18}
                  color="#636363"
                  style={{ marginLeft: '10px', marginTop: '6px' }}
                />
              </span>
            </span>
          </Tooltip>
          <Tooltip
            title={
              filterByCurrentUser
                ? 'Show all activities'
                : 'Show my activities only'
            }
          >
            <IconButton
              onClick={(e) => setFilterByCurrentUser(!filterByCurrentUser)}
              sx={{
                backgroundColor: filterByCurrentUser ? '#003935' : '#e0e0e0',
                color: filterByCurrentUser ? '#fff' : '#616161',
                '&:hover': {
                  backgroundColor: filterByCurrentUser ? '#003935' : '#e0eadd',
                },
                width: 35,
                height: 35,
                padding: '6px',
              }}
            >
              {filterByCurrentUser ? <IoPersonSharp /> : <IoPeopleSharp />}
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="activity-log-content">
        <ul className="sidebar-ul">
          <ViewportList
            items={activityList.filter(
              (activity) =>
                !filterByCurrentUser || activity.modifiedBy === username
            )}
          >
            {(activity: ActivityItem, index) => {
              // Select the icon based on the parentKey
              const Icon =
                iconMapping[activity.parentKey] || iconMapping.default;
              const timestamp = formatDate(
                activity.updatedAt || activity.createdAt
              );

              return (
                <li key={index}>
                  <div
                    style={
                      selectedActivity === activity
                        ? { backgroundColor: '#e0eadd', borderRadius: 10 }
                        : {}
                    }
                    onClick={() => handleSelectActivity(activity)}
                  >
                    <a
                      href="#"
                      onClick={(e) => e.preventDefault()}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <div
                        className="titleItemContainer"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {React.createElement(Icon as any, {
                            className: 'icon',
                          })}
                          <span
                            className="titleItemText"
                            style={{
                              fontWeight: '500',
                              fontSize: '14px',
                              color: 'black',
                            }}
                          >
                            {toTitleCase(
                              KeyFormattedValues[
                                activity.parentKey as keyof typeof KeyFormattedValues
                              ]
                            )}
                          </span>
                        </div>
                        <span
                          style={{
                            fontSize: '12px',
                            color: 'black',
                            // background: '#E3E3E3',
                            padding: '2px 5px',
                            borderRadius: '5px',
                          }}
                        >
                          {timestamp}
                          {/* <FaClock
                            style={{
                              marginRight: '5px',
                              color: '#616161',
                              marginTop: '-2px',
                            }}
                          /> */}
                          {/* <span style={{ marginTop: '4px' }}>{timestamp}</span> */}
                        </span>
                      </div>
                      {activity.name && (
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: 'black',
                            marginTop: '5px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {activity.name}
                        </div>
                      )}
                      {activity.modifiedBy && (
                        <div
                          style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: 'black',
                            marginTop: '5px',
                          }}
                        >
                          <span
                            style={{
                              background: '#E3E3E3',
                              padding: '4px 5px',
                              borderRadius: '5px',
                            }}
                          >
                            <FaUser
                              style={{
                                marginRight: '5px',
                                color: 'black',
                                marginTop: '-3px',
                              }}
                            />
                            {activity.modifiedBy}
                          </span>
                        </div>
                      )}
                    </a>
                  </div>
                </li>
              );
            }}
          </ViewportList>
        </ul>
      </div>
    </div>
  );
};

export default ActivityLogSidebar;
