import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import FormItem from '../../../../../data/model/FormItem';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { copyChecklistsFromDeptoDep } from '../../../../../data/AmplifyActions';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { getHashedPin } from '../../../../_global/common/Encrypt';
import { IoLockClosed } from 'react-icons/io5';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { DraftChangeType } from '../../../../../data/model/DraftChangeItem';
import {
  deleteCategory,
  getCategoryByID,
} from '../../../../../data/functions/CategoryDB';
import { DatabaseResponse } from '../../../../../data/AmplifyDB';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import CopyToClipboard from '../../../../components/CopyToClipboard';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import {
  deleteProtocol,
  getProtocolByID,
} from '../../../../../data/functions/ProtocolDB';
import { toTitleCase } from '../../../../_global/common/Utils';
import CategoryItem from '../../../../../data/model/CategoryItem';
import { User } from '../../../../../models';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  deleteMedication,
  deleteMedicationDoseItem,
  getMedicationByID,
  getMedicationDoseByID,
} from '../../../../../data/functions/MedicationDB';
import {
  deleteInfusion,
  deleteInfusionDoseItem,
  getInfusionByID,
  getInfusionDoseByID,
} from '../../../../../data/functions/InfusionDB';
import {
  deleteElectrical,
  deleteElectricalDoseItem,
  getElectricalByID,
  getElectricalDoseByID,
} from '../../../../../data/functions/ElectricalDB';
import {
  deleteEquipment,
  getEquipmentByID,
} from '../../../../../data/functions/EquipmentDB';
import {
  deleteVital,
  getVitalByID,
} from '../../../../../data/functions/VitalDB';
import {
  deleteCheckList,
  getChecklistByID,
} from '../../../../../data/functions/CheckListDB';
import {
  deleteWorkbook,
  getWorkbookByID,
} from '../../../../../data/functions/WorkbookDB';
import { deleteCPR, getCPRByID } from '../../../../../data/functions/CprDB';
import MedicationItem from '../../../../../data/model/MedicationItem';
import MedicationSubItem from '../../../../../data/model/MedicationSubItem';
import InfusionSubItem from '../../../../../data/model/InfusionSubItem';
import InfusionItem from '../../../../../data/model/InfusionItem';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import ElectricalSubItem from '../../../../../data/model/ElectricalSubItem';
import EquipmentItem from '../../../../../data/model/EquipmentItem';
import CPRItem from '../../../../../data/model/CPRItem';
import WorkbookItem from '../../../../../data/model/WorkbookItem';
import { getUserByID } from '../../../../../data/functions/UserDB';

const AVAILABLE_TYPES = [
  { label: 'Department', value: 'Department' },
  { label: 'User', value: 'User' },
  { label: 'Category', value: 'Category' },
  { label: 'Protocol', value: 'Protocol' },
  { label: 'Medication', value: 'Medication' },
  { label: 'Medication Dose', value: 'MedicationDose' },
  { label: 'Infusion', value: 'Drip' },
  { label: 'Infusion Dose', value: 'InfusionDose' },
  { label: 'Electrical', value: 'ElectricalShock' },
  { label: 'Electrical Dose', value: 'ElectricalDose' },
  { label: 'Equipment', value: 'Equipment' },
  { label: 'Vital', value: 'Vitals' },
  { label: 'Form', value: 'Form' },
  { label: 'Workbook', value: 'Workbook' },
  { label: 'CPRAssist', value: 'CPRAssist' },
  { label: 'Ambulance', value: 'Ambulance' },
  { label: 'InputForm', value: 'InputForm' },
  { label: 'Notification', value: 'Notification' },
  { label: 'Concentration', value: 'Concentration' },
  { label: 'WeightObject', value: 'WeightObject' },
  { label: 'MedicShift', value: 'MedicShift' },
  { label: 'PatientInteraction', value: 'PatientInteraction' },
  { label: 'Invite', value: 'Invite' },
  { label: 'Keychain', value: 'Keychain' },
  { label: 'Group', value: 'Group' },
  { label: 'Notify', value: 'Notify' },
  { label: 'NotifyACK', value: 'NotifyACK' },
  { label: 'DeveloperNotification', value: 'DeveloperNotification' },
  { label: 'OneWeight', value: 'OneWeight' },
  { label: 'Log', value: 'Log' },
  { label: 'Reviewal', value: 'Reviewal' },
  { label: 'DraftGroup', value: 'DraftGroup' },
  { label: 'DraftChange', value: 'DraftChange' },
  { label: 'Acknowledge', value: 'Acknowledge' },
  { label: 'UserComment', value: 'UserComment' },
];

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const DeleteModelPage = (props: any) => {
  const navigate = useNavigate();
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const user: User = useSelector((state: any) => state.user);
  const department: DepartmentItem = database.department;
  const [info, setInfo] = useState<any>({
    id: '',
    item: null,
    type: 'Protocol',
    isSoftDelete: false,
  });

  const [confirm, setConfirm] = useState<any>({
    title: '',
    description: '',
    isVisible: false,
    isSingleBtn: false,
    secondaryBtnName: '',
    primaryDescription: '',
    isDeleteBtn: false,
    primaryBtnName: '',
  });
  const handleBack = () => {
    navigate(`/actions/database-actions`);
  };

  const isIDValid = useMemo(() => {
    //Check if it is a valid UUIDv4
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89ab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(
      info.id
    );
  }, [info]);

  const handleFind = async () => {
    let item: any = null;
    switch (info.type) {
      case 'Category':
        item = await getCategoryByID(database, info.id);
        break;
      case 'Protocol':
        item = await getProtocolByID(database, info.id);
        break;
      case 'Medication':
        item = await getMedicationByID(database, info.id);
        break;
      case 'MedicationDose':
        item = await getMedicationDoseByID(database, info.id);
        break;
      case 'Infusion':
        item = await getInfusionByID(database, info.id);
        break;
      case 'InfusionDose':
        item = await getInfusionDoseByID(database, info.id);
        break;
      case 'Electrical':
        item = await getElectricalByID(database, info.id);
        break;
      case 'ElectricalDose':
        item = await getElectricalDoseByID(database, info.id);
        break;
      case 'Equipment':
        item = await getEquipmentByID(database, info.id);
        break;
      case 'Vitals':
        item = await getVitalByID(database, info.id);
        break;
      case 'Form':
        item = await getChecklistByID(database, info.id);
        break;
      case 'Workbook':
        item = await getWorkbookByID(database, info.id);
        break;
      case 'CPRAssist':
        item = await getCPRByID(database, info.id);
        break;
      case 'User':
        item = await getUserByID(info.id);
        break;
      default:
        window.alert(
          'CONTACT COLTON: Find Model is not implemented for this type: ' +
            info.type
        );
        return;
    }
    if (item) {
      setInfo({ ...info, item: item });
    } else {
      setConfirm({
        title: 'Error: Item Not Found',
        secondaryDescription: 'ID: ' + info.id + '\nType: ' + info.type,
        isVisible: true,
        isSingleBtn: true,
        secondaryBtnName: 'Okay',
        primaryDescription: 'The item you are trying to delete was not found.',
        primaryBtnName: 'Okay',
        isDeleteBtn: false,
        handleSubmit: () => {
          setConfirm({ ...confirm, isVisible: false });
          setInfo({ ...info, item: null });
        },
        handleClose: () => {
          setConfirm({ ...confirm, isVisible: false });
          setInfo({ ...info, item: null });
        },
      });
    }
  };

  const handleDelete = async (isConfirmed: boolean) => {
    console.log('delete');
    if (isConfirmed) {
      let item: any = null;
      switch (info.type) {
        case 'Category':
          item = await deleteCategory(
            department,
            info.item as CategoryItem,
            user,
            info.isSoftDelete,
            false
          );
          break;
        case 'Protocol':
          item = await deleteProtocol(
            department,
            info.item as ProtocolItem,
            user,
            info.isSoftDelete,
            false,
            false
          );
          break;
        case 'Medication':
          item = await deleteMedication(
            info.item as MedicationItem,
            info.isSoftDelete
          );
          break;
        case 'MedicationDose':
          item = await deleteMedicationDoseItem(
            info.item as MedicationSubItem,
            info.isSoftDelete
          );
          break;
        case 'Infusion':
          item = await deleteInfusion(
            info.item as InfusionItem,
            info.isSoftDelete
          );
          break;
        case 'InfusionDose':
          item = await deleteInfusionDoseItem(
            info.item as InfusionSubItem,
            info.isSoftDelete
          );
          break;
        case 'Electrical':
          item = await deleteElectrical(
            info.item as ElectricalItem,
            info.isSoftDelete
          );
          break;
        case 'ElectricalDose':
          item = await deleteElectricalDoseItem(
            info.item as ElectricalSubItem,
            info.isSoftDelete
          );
          break;
        case 'Equipment':
          item = await deleteEquipment(
            info.item as EquipmentItem,
            info.isSoftDelete
          );
          break;
        case 'Vitals':
          item = await deleteVital(info.item as VitalItem, info.isSoftDelete);
          break;
        case 'Form':
          item = await deleteCheckList(
            info.item as FormItem,
            info.isSoftDelete
          );
          break;
        case 'Workbook':
          item = await deleteWorkbook(
            info.item as WorkbookItem,
            info.isSoftDelete
          );
          break;
        case 'CPRAssist':
          item = await deleteCPR(info.item as CPRItem, info.isSoftDelete);
          break;
        default:
          window.alert(
            'CONTACT COLTON: Delete Model is not implemented for this type: ' +
              info.type
          );
          return;
      }
      if (item != null) {
        setConfirm({
          title: info.type + ' has been deleted',
          primaryDescription:
            'Deleted the ' +
            info.type +
            ' item with the ID: ' +
            info.id +
            ' and the name: ' +
            item.name,
          secondaryDescription: 'ID: ' + info.id + '\nType: ' + info.type,
          isVisible: true,
          isSingleBtn: true,
          secondaryBtnName: 'Okay',
          primaryBtnName: 'Okay',
          isDeleteBtn: false,
          handleSubmit: () => {
            setConfirm({ ...confirm, isVisible: false });
            setInfo({ ...info, id: '', item: null });
          },
          handleClose: () => {
            setConfirm({ ...confirm, isVisible: false });
            setInfo({ ...info, id: '', item: null });
          },
        });
      } else {
        setConfirm({
          title: 'Error: Item Not Deleted',
          secondaryDescription: 'ID: ' + info.id + '\nType: ' + info.type,
          isVisible: true,
          isSingleBtn: true,
          secondaryBtnName: 'Okay',
          primaryDescription: 'The item was not deleted.',
          primaryBtnName: 'Okay',
          isDeleteBtn: false,
          handleSubmit: () => {
            setConfirm({ ...confirm, isVisible: false });
            setInfo({ ...info, item: null });
          },
          handleClose: () => {
            setConfirm({ ...confirm, isVisible: false });
            setInfo({ ...info, item: null });
          },
        });
      }
    } else {
      setConfirm({
        title: 'Are you sure you want to delete this item?',
        secondaryDescription: 'ID: ' + info.id + '\nType: ' + info.type,
        isVisible: true,
        isSingleBtn: false,
        isDeleteBtn: true,
        primaryBtnName: 'Cancel',
        secondaryBtnName: 'Delete',
        primaryDescription:
          'Deleting this item will remove it from the database.',
        handleSubmit: () => {
          handleDelete(true);
        },
        handleClose: () => {
          setConfirm({ ...confirm, isVisible: false });
        },
      });
    }
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Actions'}
        name={'Delete Modal Page'}
        description={
          'Delete any model in the database by entering the id and selecting the type'
        }
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCancelButton={false}
        isDeleteButton={info.item != null}
        isDeleteDisabled={false}
        handleDelete={() => {
          handleDelete(false);
        }}
        isSaveButton={false}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <ConfirmModal
        isVisible={confirm.isVisible}
        title={confirm.title}
        handleClose={confirm.handleClose}
        handleSubmit={confirm.handleSubmit}
        isSingleBtn={confirm.isSingleBtn}
        secondaryBtnName={confirm.secondaryBtnName}
        primaryDescription={confirm.primaryDescription}
        isDeleteBtn={confirm.isDeleteBtn}
        secondaryDescription={confirm.secondaryDescription}
        primaryBtnName={confirm.primaryBtnName}
      />
      <Row>
        <Col sm={6}>
          {/* Left column */}

          <label className="ketamine-general-label" style={{}}>
            {info.type} ID: <span className="required-field">*</span>
            {isIDValid && (
              <CopyToClipboard text={info.id} style={{ marginLeft: '10px' }} />
            )}
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Enter in the id of the model you want to delete.
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="pin"
              data-testid="action"
              name="pin"
              value={info.id}
              onChange={(e: any) => {
                setInfo({ ...info, id: e.target.value, item: null });
              }}
            />
            <div className="input-border"></div>
          </div>
          <div
            style={{
              display: 'flex',
              marginTop: '20px',
              alignItems: 'center',
              // justifyContent: 'center',
              gap: '24px',
            }}
          >
            <Dropdown
              value={info.type}
              options={AVAILABLE_TYPES}
              onChange={(value: any) => {
                setInfo({ ...info, type: value, item: null });
              }}
              style={{
                marginTop: '-6px',
                padding: 0,
              }}
              buttonColor={'#E0EADD'}
              textColor={'#00534C'}
            />
            <Button
              disabled={!isIDValid}
              className="primary-button"
              onClick={handleFind}
            >
              <span>
                <FaMagnifyingGlass className="icon-normal" size="1rem" />
              </span>{' '}
              Find
            </Button>
          </div>
        </Col>
        <Col sm={6}>
          {info.item && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <label
                className="ketamine-general-label"
                style={
                  {
                    // margin: 0,
                  }
                }
              >
                Found {toTitleCase(info.type)}
                {info.item.name ? ' ' + info.item.name : ''}
                <span className="required-field">*</span>
              </label>
              <span
                style={{
                  fontWeight: '600',
                  fontSize: '14px',
                  color: '#616161',
                  marginLeft: '10px',
                  margin: 0,
                  padding: 0,
                }}
              >
                ID: {info.id}
                <CopyToClipboard
                  text={info.id}
                  style={{ marginLeft: '10px' }}
                />
              </span>

              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                {info.item.model
                  ? JSON.stringify(info.item.model)
                  : JSON.stringify(info.item)}
              </p>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default DeleteModelPage;
