import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { useDispatch } from 'react-redux';

import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import SearchBar from '../../components/Search/SearchBar';
import './Review.scss';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../data/AmplifyDB';

import { IoArrowBack, IoEllipsisVertical, IoSave } from 'react-icons/io5';
import { commonStyle, getStatusStyles } from './styles';
import { LogEvent, Medication, User } from '../../../models';
import {} from '@primer/styled-octicons';
import ReviewalItem, {
  cloneReviewalItem,
} from '../../../data/model/ReviewalItem';
import DraftGroupItem from '../../../data/model/DraftGroupItem';
import AcknowledgeItem from '../../../data/model/AcknowledgeItem';
import DraftChangeItem, {
  DraftChangeType,
  cloneDraftChangeItem,
} from '../../../data/model/DraftChangeItem';
import FolderComparison from './reviewComparsion/FolderComparison';
import ProtocolComparison from './reviewComparsion/ProtocolComparison';
import ProtocolPDFComparison from './reviewComparsion/ProtocolPDFComparison';
import MedicationComparison from './reviewComparsion/MedicationComparison';
import InfusionComparison from './reviewComparsion/InfusionComparison';
import ElectricalComparison from './reviewComparsion/ElectricalComparison';
import EquipmentComparison from './reviewComparsion/EquipmentComparison';
import VitalComparison from './reviewComparsion/VitalComparison';
import OtherComparison from './reviewComparsion/OtherComparison';
import { globals, handleCopy, toTitleCase } from '../../_global/common/Utils';
import ReviewDraftGroup from './ReviewDraftGroup';
import {
  FaCircleXmark,
  FaListUl,
  FaReply,
  FaTicketSimple,
} from 'react-icons/fa6';
import { MdCreateNewFolder } from 'react-icons/md';
import TextConfirmModal from './TextConfirmModal';
import {
  faFileCircleCheck,
  faFileCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ViewportList } from 'react-viewport-list';
import {
  createAcknowledgeItem,
  createReviewalProcess,
  createUserCommentItem,
  deleteAcknowledgeItem,
  findUserComments,
  getFullReviewal,
} from '../../../data/functions/ReviewalDB';
import {
  BiChevronLeft,
  BiChevronRight,
  BiCopy,
  BiSolidCopy,
} from 'react-icons/bi';
import { ACKStatus, UserComment } from '../../../API';
import IconDropdown from '../../components/Dropdown/IconDropdown';
import UserCommentItem from '../../../data/model/UserCommentItem';
import { FaCheckCircle, FaPencilAlt } from 'react-icons/fa';
import { set } from 'lodash';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import MedicationDoseComparison from './reviewComparsion/MedicationDoseComparison';
import InfusionDoseComparison from './reviewComparsion/InfusionDoseComparison';
import ElectricalShockComparison from './reviewComparsion/ElectricalShockComparison';
import DoseIndexComparison from './reviewComparsion/DoseIndexComparsion';
import ProtocolItem from '../../../data/model/ProtocolItem';
import ProtocolIndexComparison from './reviewComparsion/ProtocolIndexComparison';
import CategoryItem from '../../../data/model/CategoryItem';
import DepartmentItem from '../../../data/model/DepartmentItem';
import MedicationSubItem from '../../../data/model/MedicationSubItem';
import CopyToClipboard from '../../components/CopyToClipboard';
import ChecklistComparison from './reviewComparsion/ChecklistComparison';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import MultiSelectDropdown from '../../components/MultiSelectDropdown/MultiSelectDropdown';
import InfusionSubItem from '../../../data/model/InfusionSubItem';
import { UserType } from '../../../models';
import ReviewalSubscriber, {
  ReviewalError,
} from '../../../data/subscribers/ReviewalSubscriber';
import ElectricalSubItem from '../../../data/model/ElectricalSubItem';

const DECLINE_DRAFT_ITEM = 0;
const DECLINE_REVIEW = 1;
const ADD_COMMENT = 2;
const EDIT_COMMENT = 3;
const ACCEPT_REVIEW = 4;
const ACCEPT_REVIEW_FINAL = 5;

const FILTER_TYPES = [
  'Checklist',
  'Electrical',
  'Equipment',
  'Infusion',
  'Medication',
  'Protocol',
  'Vital',
];

const ReviewChanges = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department: DepartmentItem = useMemo(
    () => database.department,
    [database.department]
  );
  const { data, handleStatusStyle, activeDraftChange, activeDraftGroup } =
    location.state;

  const [reviewal, setReviewal] = useState<ReviewalItem>(data);
  const [sortedItems, setSortedItems] = useState<(UserComment | LogEvent)[]>(
    []
  );
  const [filter, setFilter] = useState<any>({
    type: [],
  });
  const [viewMode, setViewMode] = useState<string | null>('tickets');
  const [expanded, setExpanded] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState<string | null>('Changes');

  const [draftApprovedCount, setDraftApprovedCount] = useState<number>(0);
  const [totalDraftCount, setTotalDraftCount] = useState<number>(0);

  const [textInputModal, setTextInputModal] = useState<number>(-1);

  const [searchQuery, setSearchQuery] = useState('');
  const [currentDraft, setCurrentDraft] = useState<DraftChangeItem>(
    activeDraftChange
      ? activeDraftChange
      : reviewal.draftGroups[0]?.draftChanges[0]
  );
  const [commentText, setCommentText] = useState('');
  const inputRef = useRef(null);
  const [isCopied, setIsCopied] = useState<string>('');
  const user: User = useSelector((state: any) => state.user);
  const DISABLED_MODE = useMemo(
    () =>
      reviewal.state === ACKStatus.CLOSED ||
      reviewal.state === ACKStatus.PUBLISHED,
    [reviewal]
  );

  const isClosedState = useMemo(
    () =>
      reviewal.state === ACKStatus.REJECTED ||
      reviewal.state === ACKStatus.CLOSED ||
      reviewal.state === ACKStatus.PUBLISHED ||
      reviewal.state === ACKStatus.APPROVED,
    [reviewal]
  );

  const currentDraftGroup = useMemo(() => {
    return currentDraft.draftGroup;
  }, [currentDraft]);

  const [modalState, setModalState] = useState({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    isRedBtn: false,
    handleClose: () => {},
    handleSubmit: () => {},
  });

  const nextLogic = useMemo(() => {
    if (currentDraft == null) return { isFirst: false, isLast: false };
    let isFirst =
      currentDraft.uid === reviewal.draftGroups[0].draftChanges[0].uid;
    let isLast =
      currentDraft.uid ===
      reviewal.draftGroups[reviewal.draftGroups.length - 1].draftChanges[
        reviewal.draftGroups[reviewal.draftGroups.length - 1].draftChanges
          .length - 1
      ].uid;
    return {
      isFirst: isFirst,
      isLast: isLast,
    };
  }, [reviewal, currentDraft]);

  const handleFullReloadReviewal = (
    reviewal: ReviewalItem,
    reloadEverything: boolean = false
  ) => {
    console.log('Reloading full reviewal', reviewal);
    getFullReviewal(
      database,
      reviewal,
      user,
      dispatch,
      reloadEverything,
      reloadEverything
    )
      .then((response) => {
        if (response) {
          setReviewal(response);
          if (globals.debug) console.log('Full reviewal loaded', response);
          /* Update DraftChange */
          if (currentDraft) {
            let draftChange = response.draftGroups
              .find((dg) => dg.uid === currentDraft.draftGroup?.uid)
              ?.draftChanges.find((dc) => dc.uid === currentDraft.uid);
            if (draftChange) {
              setCurrentDraft(draftChange);
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error getting full reviewal', error);
      });
  };

  /* Reload the reviewal item everytime this UI renders AND ONLY WHEN THE UI RENDERS */
  useEffect(() => {
    console.log('RELOADING REVIEWAL');
    handleFullReloadReviewal(reviewal, true);
  }, []);

  // useEffect to count total draft changes and approved changes in draft changes draft groups
  useEffect(() => {
    calculateTotalApproved();
  }, [reviewal, reviewal.isFullyLoaded, currentDraft]);

  /* When the currentDraft changes check to see if hte ACK has a UserComment associated to it */
  useEffect(() => {
    // let dc = currentDraft
    for (let ack of currentDraft.acknowledgements) {
      findUserComments(database, ack).then((response) => {
        if (
          response.type === ResponseType.Success &&
          ack.owner.uid === currentDraft.uid
        ) {
          let comment =
            (response.data as UserCommentItem[]).length > 0
              ? (response.data as UserCommentItem[])[0]
              : null;
          if (
            (comment && ack.comment == null) ||
            (comment == null && ack.comment != null)
          ) {
            let cloneDraftItem = cloneDraftChangeItem(
              currentDraft,
              database,
              user
            );
            setCurrentDraft(cloneDraftItem);
          }
        } else {
          console.log('No comment found for ACK or not ID pair', ack);
        }
      });
    }
  }, [currentDraft, database]);

  /**
   * Make sure the correct draft group is expanded
   */
  useEffect(() => {
    if (reviewal.draftGroups.length > 0) {
      let dg = currentDraftGroup ? currentDraftGroup : reviewal.draftGroups[0];
      setExpanded([...expanded, dg.uid]);
    }
  }, [reviewal.draftGroups]);

  /* Key press event listener */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Example: Detect "Ctrl + '>'" or "Ctrl + '.'" key press
      if (event.ctrlKey && event.key === '.') {
        event.preventDefault();
        getNextDraftChangeItem('right');
      } else if (event.ctrlKey && event.key === ',') {
        event.preventDefault();
        getNextDraftChangeItem('left');
      } else if (event.ctrlKey && event.shiftKey && event.key === 'E') {
        event.preventDefault();
        //Save the current Draft ot JSON
        try {
          const draftJSON = JSON.stringify(currentDraft);
          console.log('Draft JSON', draftJSON);
          //Save the JSON to the clipboard
          navigator.clipboard.writeText(draftJSON);
          console.log('Draft JSON saved to clipboard');
        } catch (error) {
          console.error('Error saving draft JSON to clipboard', error);
        }
      } else if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        event.preventDefault();
        console.log('Current Draft', currentDraft);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [currentDraft, textInputModal, user, reviewal]);

  const handleBack = () => {
    navigate('/review', {
      state: {
        data: reviewal,
      },
    });
  };

  const filteredDrafts = useMemo(() => {
    return reviewal.draftGroups.flatMap((group: DraftGroupItem) =>
      group.draftChanges.filter((draft: DraftChangeItem) =>
        draft.changeType.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [searchQuery, reviewal.draftGroups]);

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
    navigate(tabName === 'Details' ? '/reviewDetails' : '/reviewChanges', {
      state: {
        data: reviewal,
      },
    });
  };

  const calculateTotalApproved = () => {
    let draftApprovedCount = reviewal.draftGroups.reduce((acc, draftGroup) => {
      return (
        acc +
        draftGroup.draftChanges.filter((draft) => draft.reviewACK != null)
          .length
      );
    }, 0);
    let totalDraftCount = reviewal.draftGroups.reduce((acc, draftGroup) => {
      return acc + draftGroup.draftChanges.length;
    }, 0);
    setTotalDraftCount(totalDraftCount);
    setDraftApprovedCount(draftApprovedCount);
  };

  /**
   * Acknowledge the draft change item
   * @param dc The draft change item to be acknowledged
   * @param state The state of the acknowledgement (Approved or Rejected)
   * @param comment The comment (string) to be added to the acknowledgement
   */
  const handleAcknowledgment = async (
    dc: DraftChangeItem | ReviewalItem,
    state: ACKStatus,
    previousACK?: AcknowledgeItem,
    comment?: string
  ): Promise<Response> => {
    // setAcceptedDrafts((prev) => new Set(prev.add(draftId)));
    console.log('inside handle Ack', dc);

    try {
      const response: Response = await createAcknowledgeItem({
        previousItem: previousACK,
        user: user,
        owner: dc,
        state: state,
        reviewal: reviewal,
      });
      if (response.type === ResponseType.Success) {
        if (globals.debug)
          console.log('Review ACK created successfully', response.data);
        let new_ack: AcknowledgeItem = response.data as AcknowledgeItem;
        // const newDraft = cloneDraftChangeItem(dc, database, user);
        // setCurrentDraft(newDraft);

        calculateTotalApproved();

        if (comment) {
          const commentResponse = await createUserCommentItem({
            previousItem: previousACK?.comment,
            owner: new_ack,
            user: user,
            message: comment,
            relatedItems: [],
          });
          if (commentResponse.type === ResponseType.Success) {
            if (globals.debug)
              console.log('Comment for ACK created successfully');
            new_ack.comment = commentResponse.data as UserCommentItem;
            // dc.reviewACK.comment = commentResponse.data as UserCommentItem;
            // const newDraft = cloneDraftChangeItem(dc, database, user);
            // setCurrentDraft(newDraft);
          } else {
            if (globals.debug)
              console.log('Comment ACK creation failed', commentResponse.data);
            return {
              type: ResponseType.Failure,
              data: commentResponse.data,
            };
          }
        }
        return {
          type: ResponseType.Success,
          data: new_ack,
        };
      } else {
        if (globals.debug)
          console.log('Review ACK creation failed', response.data);
        return {
          type: ResponseType.Failure,
          data: response.data,
        };
      }
    } catch (error) {
      if (globals.debug) console.log('error', error);
      return {
        type: ResponseType.Failure,
        data: error,
      };
    }
  };

  /**
   * TODO Update UI globally on the reviewal item
   * @returns Remove the acknowledgement from the draft change item
   */
  const handleRemoveAcknowledgement = async (ack: AcknowledgeItem) => {
    // setAcceptedDrafts((prev) => new Set(prev.add(draftId)));
    if (ack == null) return;
    try {
      const response: Response = await deleteAcknowledgeItem(ack);
      if (response.type === ResponseType.Success) {
        if (globals.debug) console.log('Review ACK deleted successfully');
        return ack;
      } else if (globals.debug)
        console.log('Review ACK deletion failed', response.data);
    } catch (error) {
      if (globals.debug) console.log('error', error);
    }
  };

  function isDraftChangeItem(item: any): item is DraftChangeItem {
    return (item as DraftChangeItem).draftGroup !== undefined;
  }

  function isAcknowledgeItem(item: any): item is AcknowledgeItem {
    return (item as AcknowledgeItem).state !== undefined;
  }

  /**
   * Create/Update a comment for the owner (DraftChangeItem, or AcknowledgeItem)
   * @param owner The owner (DraftChangeItem, or AcknowledgeItem) of the comment
   * @param comment The comment to be added
   * @param prev The previous comment to be modified (if any)
   * @returns The comment item created
   */
  const handleComment = async (
    owner: DraftChangeItem | AcknowledgeItem,
    comment: string,
    prev?: UserCommentItem
  ) => {
    try {
      const response: Response = await createUserCommentItem({
        previousItem: prev,
        owner: owner,
        user: user,
        message: comment,
        relatedItems: [],
      });
      if (response.type === ResponseType.Success) {
        const newComment = response.data as UserCommentItem;
        if (globals.debug)
          console.log(
            'Successfully ' +
              (prev ? 'updated' : 'added') +
              ' comment to DraftChangeItem',
            owner
          );
        if (isDraftChangeItem(owner)) {
          owner.comments.push(response.data as UserCommentItem);

          const newDraft = cloneDraftChangeItem(owner, database, user);
          setCurrentDraft(newDraft);
        } else if (isAcknowledgeItem(owner)) {
          owner.comment = response.data as UserCommentItem;
          const newDraft = cloneDraftChangeItem(currentDraft, database, user);
          newDraft.reviewACK = owner;
          setCurrentDraft(newDraft);
        }
      } else if (globals.debug)
        console.log('Comment creation failed', response.data);
    } catch (error) {
      if (globals.debug) console.log('error', error);
    }
  };

  const handleUpdateReviewalStatus = async (
    status: ACKStatus
  ): Promise<Response> => {
    try {
      let event = {
        title:
          status === ACKStatus.PENDING
            ? 'Review Changes have been Reopened'
            : status === ACKStatus.APPROVED
              ? 'Review Changes have been Approved'
              : 'Review Changes have been Declined',
        description: ACKStatus.REJECTED
          ? user.firstName +
            ' ' +
            user.lastName +
            (status === ACKStatus.REJECTED
              ? ' has moved the review to the rejected state.'
              : status === ACKStatus.APPROVED
                ? ' has moved the review to the approved state.'
                : ' has reopened the review to in progress.')
          : '',
        timestamp: new Date().toISOString(),
        color: '#00534C',
      };
      /* This will update the current reviewal status */
      const response: Response = await createReviewalProcess(
        database,
        {
          previousItem: reviewal,
          title: reviewal.title,
          description: reviewal.description,
          reviewers: reviewal.reviewers,
          user: reviewal.user,
          status: status,
          events: [...reviewal.events, event],
        },
        false
      );
      if (response.type === ResponseType.Success) {
        let resp = response.data as ReviewalItem;
        let updatedReviewal = cloneReviewalItem(reviewal, database);
        updatedReviewal.state = status;
        updatedReviewal.events = resp.events;
        updatedReviewal.any_model = resp.any_model;
        updatedReviewal.model = resp.model;
        setReviewal(updatedReviewal);
        handleFullReloadReviewal(updatedReviewal, true);
        return {
          type: ResponseType.Success,
          data: updatedReviewal,
        };
      } else {
        console.error('Error updating reviewal status', response.data);
        return response;
      }
    } catch (error) {
      console.error('Error updating reviewal status', error);
      return {
        type: ResponseType.Failure,
        data: error,
      };
    }
  };

  const getNextDraftChangeItem = (direction: 'left' | 'right') => {
    let currentGroupIndex = reviewal.draftGroups.findIndex(
      (group) => group.uid === currentDraft.draftGroup?.uid
    );
    let currentDraftIndex =
      currentDraft.draftGroup?.draftChanges.findIndex(
        (draft) => draft.uid === currentDraft.uid
      ) || 0;
    let nextDraft: DraftChangeItem;
    if (direction === 'left') {
      if (currentGroupIndex === 0) {
        if (currentDraftIndex === 0) return null;
        nextDraft =
          reviewal.draftGroups[currentGroupIndex].draftChanges[
            currentDraftIndex - 1
          ];
      } else {
        if (currentDraftIndex === 0) {
          nextDraft =
            reviewal.draftGroups[currentGroupIndex - 1].draftChanges[
              reviewal.draftGroups[currentGroupIndex - 1].draftChanges.length -
                1
            ];
          //Make sure the folder is expanded
          if (
            !expanded.includes(reviewal.draftGroups[currentGroupIndex - 1].uid)
          )
            setExpanded([
              ...expanded,
              reviewal.draftGroups[currentGroupIndex - 1].uid,
            ]);
        } else
          nextDraft =
            reviewal.draftGroups[currentGroupIndex].draftChanges[
              currentDraftIndex - 1
            ];
      }
    } else {
      if (
        currentGroupIndex === reviewal.draftGroups.length - 1 &&
        currentDraftIndex ===
          reviewal.draftGroups[currentGroupIndex].draftChanges.length - 1
      )
        return null;
      if (
        currentDraftIndex ===
        reviewal.draftGroups[currentGroupIndex].draftChanges.length - 1
      ) {
        nextDraft = reviewal.draftGroups[currentGroupIndex + 1].draftChanges[0];
        //Make sure the folder is expanded
        if (!expanded.includes(reviewal.draftGroups[currentGroupIndex + 1].uid))
          setExpanded([
            ...expanded,
            reviewal.draftGroups[currentGroupIndex + 1].uid,
          ]);
      } else
        nextDraft =
          reviewal.draftGroups[currentGroupIndex].draftChanges[
            currentDraftIndex + 1
          ];
    }
    if (nextDraft) setCurrentDraft(nextDraft);
  };

  const declineDraftItem = (comment: string) => {
    let promises: Promise<Response>[] = [];
    promises.push(
      handleAcknowledgment(
        currentDraft,
        ACKStatus.REJECTED,
        currentDraft.reviewACK || undefined,
        comment
      ).then((ackResp: Response) => {
        if (ackResp.type === ResponseType.Success) {
          currentDraft.reviewACK = ackResp.data as AcknowledgeItem;
          let newDraft = cloneDraftChangeItem(currentDraft, database, user);
          setCurrentDraft(newDraft);
        }
        return ackResp;
      })
    );
    let findReviewalACK = reviewal.acknowledgements.find(
      (ack) => ack.owner.uid === reviewal.uid && ack.user.id === user.id
    );
    if (findReviewalACK == null) {
      promises.push(
        handleAcknowledgment(
          reviewal,
          ACKStatus.REJECTED,
          undefined,
          comment
        ).then((ackResp: Response) => {
          if (ackResp.type === ResponseType.Success) {
            reviewal.acknowledgements.push(ackResp.data as AcknowledgeItem);
            handleUpdateReviewalStatus(ACKStatus.REJECTED).then((response) => {
              if (globals.debug)
                console.log('Reviewal status updated', response);
            });
          }
          return ackResp;
        })
      );
    }
    Promise.all(promises).then((responses) => {
      console.log('responses', responses);
    });
  };

  const handleEdit = () => {
    navigate('/reviewEdit', {
      state: {
        data: reviewal,
      },
    });
  };

  const handleRemoveReview = () => {
    setModalState({ ...modalState, isVisible: false });
    let ack = reviewal.acknowledgements.find((ack) => ack.user.id === user.id);
    if (!ack) return;
    handleRemoveAcknowledgement(ack).then((response) => {
      if (response) {
        reviewal.acknowledgements = reviewal.acknowledgements.filter(
          (ack) => ack.user.id !== user.id
        );
        handleUpdateReviewalStatus(ACKStatus.PENDING).then((response) => {
          if (response.type === ResponseType.Success) {
            let newReviewal = cloneReviewalItem(reviewal, database);
            newReviewal.state = ACKStatus.PENDING;
            setReviewal(newReviewal);
          }
        });
      }
    });
  };

  const removeReviewCheck = () => {
    let ack = reviewal.acknowledgements.find((ack) => ack.user.id === user.id);
    if (!ack) return;
    if (
      ack.state === ACKStatus.REJECTED &&
      reviewal.acknowledgements.filter(
        (ack) => ack.state === ACKStatus.REJECTED
      ).length === 1
    ) {
      /* Prompt the user this will make hte review go back to pending */
      setModalState({
        isVisible: true,
        title: 'Remove Review Warning',
        description:
          'Removing this review will make the review go back to pending. Are you sure you want to remove this review?',
        secondaryBtnName: 'Remove Review',
        primaryBtnName: 'Cancel',
        isRedBtn: true,
        handleClose: () => setModalState({ ...modalState, isVisible: false }),
        handleSubmit: () => handleRemoveReview(),
      });
    } else {
      handleRemoveAcknowledgement(ack).then((response) => {
        if (response) {
          reviewal.acknowledgements = reviewal.acknowledgements.filter(
            (ack) => ack.user.id !== user.id
          );
          let newReviewal = cloneReviewalItem(reviewal, database);
          setReviewal(newReviewal);
        }
      });
    }
  };

  const handleReviewalChange = (reviewal: ReviewalItem, isDeleted: boolean) => {
    if (isDeleted) handleReviewalDeleted(reviewal);
    else setReviewal(reviewal);
  };

  const handleReviewalDeleted = (parmReviewal: ReviewalItem) => {
    if (reviewal.uid === parmReviewal.uid) {
      setModalState({
        isVisible: true,
        title: 'This reviewal has been deleted',
        description: 'The review ' + reviewal.title + ' has been deleted',
        primaryBtnName: '',
        secondaryBtnName: 'Okay',
        isRedBtn: false,
        handleClose: () => {
          navigate('/review');
        },
        handleSubmit: () => {
          navigate('/review');
        },
      });
    }
  };

  /* useMeme Hooks for string outputs and rendering UI */

  const userReviewACK = useMemo(() => {
    return reviewal.acknowledgements.find(
      (ack: AcknowledgeItem) => ack.user.id === user.id
    );
  }, [reviewal, user]);

  const reviewButtonStatus = useMemo(() => {
    if (
      reviewal.acknowledgements.find(
        (ack) => ack.user.id === user.id && ack.state === ACKStatus.APPROVED
      )
    )
      return 2;
    else if (draftApprovedCount === totalDraftCount && totalDraftCount > 0)
      return 1;
    else return 0;
  }, [user, reviewal, draftApprovedCount, totalDraftCount]);

  const description = useMemo(() => {
    let userACK = reviewal.acknowledgements.find(
      (ack) => ack.user.id === user.id
    );
    if (reviewal.state === ACKStatus.CLOSED) return 'Review is closed';
    else if (reviewal.state === ACKStatus.PUBLISHED)
      return (
        'Review has been published at ' +
        reviewal.endedAt?.toLocaleDateString() +
        ' ' +
        reviewal.endedAt?.toLocaleTimeString()
      );
    else if (reviewal.state === ACKStatus.APPROVED)
      return 'This review has been approved and is ready to publish';
    else if (userACK) {
      return `You have ${userACK?.state === ACKStatus.APPROVED ? 'approved' : 'declined'} this review. ${userACK.state === ACKStatus.APPROVED ? 'You can still change your review.' : 'You can continue to review the other changes.'}`;
    } else if (user.id === reviewal.user.id && currentDraft != null)
      return `Change ${toTitleCase(currentDraft.changeType)} ${currentDraft.changeItem?.name} acknowledgements: ${currentDraft.acknowledgements.length}/${reviewal.reviewers.length}`;
    else if (draftApprovedCount === totalDraftCount && totalDraftCount > 0)
      return `You have reviewed all changes`;
    else return `${draftApprovedCount}/${totalDraftCount} changes approved`;
  }, [user, reviewal, currentDraft, draftApprovedCount, totalDraftCount]);

  const menuItems = useMemo(() => {
    if (currentDraft?.reviewACK) {
      let arr = [];
      if (currentDraft.reviewACK.comment)
        arr.push({ value: 'Edit Comment', label: 'Edit Comment' });
      else arr.push({ value: 'Add Comment', label: 'Add Comment' });

      if (currentDraft.reviewACK.state === ACKStatus.APPROVED) {
        return [...arr, { value: 'Remove Approval', label: 'Remove Approval' }];
      } else if (currentDraft.reviewACK.state === ACKStatus.REJECTED)
        return [...arr, { value: 'Remove Decline', label: 'Remove Decline' }];
    }
    return [];
  }, [currentDraft]);

  const ChangesHeader = ({ description }: { description: string }) => {
    const [desc, setDesc] = useState<string>(description);
    useEffect(() => {
      setDesc(description);
    }, [description]);
    return (
      <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
        <div
          style={{
            margin: '0px 10px',
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            // // justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0px 0px',
              flex: 1,
              overflowX: 'auto',
            }}
          >
            <div className={`titleStyling`}>
              <div
                className=""
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <h6 className={`backTextLight hoverText`} onClick={handleBack}>
                  <span>
                    <IoArrowBack
                      data-testid="isBackBttn"
                      size="1.15rem"
                      style={{ marginRight: '5px' }}
                    />
                  </span>
                  Review Home Page
                </h6>
              </div>
              <div className="titleItemContainer">
                <div className="titleTextPH" style={{ marginRight: '10px' }}>
                  {reviewal.title}
                </div>
                <div
                  className="lightText"
                  style={{
                    ...commonStyle,
                    marginTop: '5px',
                    border: `2px solid ${getStatusStyles(reviewal.state).borderColor}`, // Conditional border color
                    backgroundColor: `${getStatusStyles(reviewal.state).backgroundColor}`, // Conditional background color
                    color: `${getStatusStyles(reviewal.state).color}`, // Conditional text color
                  }}
                >
                  {`${toTitleCase(ACKStatus[reviewal.state])}`}
                </div>
              </div>
              <h6
                className={`headerTextLight`}
                style={{
                  fontWeight: '400',
                  color: '#616161',
                  marginRight: '2rem',
                }}
              >
                {desc}
              </h6>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'flex-end',
              paddingRight: 0,
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                paddingRight: 0,
              }}
            >
              {user.id !== reviewal.user.id &&
                (reviewal.state === ACKStatus.PENDING ||
                  reviewal.state === ACKStatus.REJECTED) && (
                  <>
                    {userReviewACK ? (
                      <>
                        <div
                          className="doseReviewButtonContainer"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              color:
                                userReviewACK.state === ACKStatus.APPROVED
                                  ? '#037F02'
                                  : '#880808',
                              fontWeight: 'bold',
                              fontSize: '16px',
                            }}
                          >
                            {userReviewACK.state === ACKStatus.APPROVED
                              ? 'Approved'
                              : 'Declined'}
                          </span>
                          <FontAwesomeIcon
                            icon={
                              userReviewACK.state === ACKStatus.APPROVED
                                ? faFileCircleCheck
                                : faFileCircleXmark
                            }
                            style={{
                              color:
                                userReviewACK.state === ACKStatus.APPROVED
                                  ? '#037F02'
                                  : '#880808',
                              fontSize: '24px',
                            }}
                          />
                          <IconDropdown
                            value={''}
                            style={{ marginTop: '-8px' }}
                            options={[
                              {
                                value: 'Remove Review',
                                label: 'Remove Review',
                              },
                            ]}
                            onChange={(value) => {
                              if (value === 'Remove Review') {
                                removeReviewCheck();
                              }
                            }}
                            icon={<IoEllipsisVertical />}
                            buttonColor="#E0EADD"
                            textColor="#00534C"
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {/* <Button
                          data-testid="isSaveCancelButton"
                          className="primary-button btn-rightMargin decline fullScreenBtn"
                          onClick={() => setTextInputModal(DECLINE_REVIEW)}
                          disabled={reviewButtonStatus !== 1}
                        >
                          Decline Changes
                        </Button> */}
                        <Button
                          data-testid="isSaveButton"
                          className={` ${
                            // rightSideBtn === "save"
                            'primary-button btn-rightMargin accept dynamicWidth'
                            // : "secondary-button-small-border btn-rightMargin"
                          }`}
                          onClick={() => {
                            /* Check if all reviewers have approved after this */
                            let isFinal = false;
                            if (
                              reviewal.acknowledgements.length ===
                              reviewal.reviewers.length - 1
                            ) {
                              /* Now search and make sure all are Approval and this user review is the only one left */
                              isFinal = reviewal.acknowledgements.every(
                                (ack) => {
                                  if (ack.user.id === user.id) return true;
                                  return ack.state === ACKStatus.APPROVED;
                                }
                              );
                            }
                            setTextInputModal(
                              isFinal ? ACCEPT_REVIEW_FINAL : ACCEPT_REVIEW
                            );
                          }}
                          disabled={reviewButtonStatus !== 1}
                        >
                          Accept Review Changes
                        </Button>
                      </>
                    )}
                  </>
                )}
              {!DISABLED_MODE &&
                !isClosedState &&
                reviewal.user.id === user.id && (
                  <Button
                    data-testid="isEditButton"
                    className={` ${'primary-button btn-rightMargin'}`}
                    onClick={handleEdit}
                  >
                    <span>
                      <FaPencilAlt className="icon-size" />
                    </span>{' '}
                    Edit
                  </Button>
                )}
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  };

  const getComparisonComponent = () => {
    switch (currentDraft?.changeType) {
      case DraftChangeType.FOLDER:
        return (
          <FolderComparison
            currentDraft={currentDraft}
            changeItem={currentDraft?.changeItem}
            previousItem={currentDraft?.previousItem}
          />
        );
      case DraftChangeType.PROTOCOL:
        return (
          <ProtocolComparison
            currentDraft={currentDraft}
            reviewal={reviewal}
            department={department}
          />
        );

      case DraftChangeType.PROTOCOL_INDEX:
        return (
          <ProtocolIndexComparison
            changeType={currentDraft.changeType}
            currentDraft={currentDraft}
          />
        );

      case DraftChangeType.PROTOCOL_PDF:
        return (
          <ProtocolPDFComparison
            currentDraft={currentDraft}
            changeItem={currentDraft?.changeItem}
            previousItem={currentDraft?.previousItem}
          />
        );
      case DraftChangeType.MEDICATION:
        return (
          <MedicationComparison
            currentDraft={currentDraft}
            reviewal={reviewal}
          />
        );
      case DraftChangeType.MEDICATION_DOSE:
        return (
          <MedicationDoseComparison
            currentDraft={currentDraft}
            changeItem={currentDraft?.changeItem}
            previousItem={currentDraft?.previousItem}
            department={department}
            reviewal={reviewal}
            user={user}
          />
        );
      // case DraftChangeType.MEDICATION_INDEX:
      // case DraftChangeType.INFUSION_INDEX:
      // case DraftChangeType.ELECTRICAL_INDEX:
      //   return (
      //     <DoseIndexComparison
      //       changeType={currentDraft.changeType}
      //       currentDraft={currentDraft}
      //       department={department}
      //       changeItem={
      //         currentDraft.changeType === 'MEDICATION_INDEX'
      //           ? (currentDraft?.changeItem as ProtocolItem).medications
      //           : currentDraft.changeType === 'INFUSION_INDEX'
      //             ? (currentDraft?.changeItem as ProtocolItem).infusions
      //             : currentDraft.changeType === 'ELECTRICAL_INDEX'
      //               ? (currentDraft?.changeItem as ProtocolItem).electrical
      //               : []
      //       }
      //       previousItem={
      //         currentDraft.changeType === 'MEDICATION_INDEX'
      //           ? (currentDraft?.previousItem as ProtocolItem).medications
      //           : currentDraft.changeType === 'INFUSION_INDEX'
      //             ? (currentDraft?.previousItem as ProtocolItem).infusions
      //             : currentDraft.changeType === 'ELECTRICAL_INDEX'
      //               ? (currentDraft?.previousItem as ProtocolItem).electrical
      //               : []
      //       }
      //     />
      //   );

      case DraftChangeType.INFUSION:
        return (
          <InfusionComparison currentDraft={currentDraft} reviewal={reviewal} />
        );
      case DraftChangeType.INFUSION_DOSE:
        return (
          <InfusionDoseComparison
            currentDraft={currentDraft}
            changeItem={currentDraft?.changeItem}
            previousItem={currentDraft?.previousItem}
            department={department}
          />
        );
      case DraftChangeType.ELECTRICAL:
        return (
          <ElectricalComparison
            currentDraft={currentDraft}
            reviewal={reviewal}
          />
        );

      case DraftChangeType.ELECTRICAL_SHOCK:
        return (
          <ElectricalShockComparison
            currentDraft={currentDraft}
            reviewal={reviewal}
            department={department}
          />
        );
      case DraftChangeType.EQUIPMENT:
        return (
          <EquipmentComparison
            currentDraft={currentDraft}
            reviewal={reviewal}
          />
        );
      case DraftChangeType.VITAL:
        return (
          <VitalComparison currentDraft={currentDraft} reviewal={reviewal} />
        );
      case DraftChangeType.CHECKLIST:
        return (
          <ChecklistComparison
            currentDraft={currentDraft}
            reviewal={reviewal}
          />
        );

      default:
        return (
          <OtherComparison currentDraft={currentDraft} reviewal={reviewal} />
        );
    }
  };

  /* 8-1-24 Hazlett: Updated this to include the comment in the reviewACK */
  const ChangeDescription = ({
    comment,
    id,
  }: {
    comment?: UserCommentItem | null;
    id?: string;
  }) => {
    const [userComment, setComment] = useState<
      UserCommentItem | null | undefined
    >(comment ? comment : null);

    useEffect(() => {
      setComment(comment);
    }, [comment]);

    if (!userComment) {
      return null;
    }

    return (
      <div
        style={{
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}
        >
          <div
            style={{
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              backgroundColor: '#00534C',
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '11px',
              fontWeight: 'bold',
              marginRight: '10px',
            }}
          >
            {userComment?.user.firstName[0]}
            {userComment?.user.lastName[0]}
          </div>
          <div>
            {userComment?.user.firstName} {userComment?.user.lastName}
          </div>
        </div>
        <div
          className="userCommentSection"
          style={{ marginTop: '5px', fontSize: '16px' }}
        >
          {userComment?.message && (
            <div>
              <FaReply
                style={{
                  marginLeft: '5px',
                  transform: 'rotate(180deg)',
                  color: '#586069',
                  fontSize: '14px',
                }}
              />
              <span
                style={{
                  fontStyle: 'italic',
                  color: '#586069',
                  fontSize: '14px',
                }}
              >
                {'  ' + userComment?.message}
              </span>
            </div>
          )}
          {id && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span
                style={{
                  fontStyle: 'italic',
                  color: '#586069',
                  fontSize: '14px',
                }}
              >
                Comment ID: {userComment?.uid}
                <span>
                  {isCopied && isCopied === userComment?.uid ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(userComment?.uid, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </span>
              <span
                style={{
                  fontStyle: 'italic',
                  color: '#586069',
                  fontSize: '14px',
                }}
              >
                ReviewID: {id}
                <span>
                  {isCopied && isCopied === id ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) => handleCopy(id, e, setIsCopied)}
                    />
                  )}
                </span>
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getChangeDescription = (change: DraftChangeItem) => {
    if (change.changeType === DraftChangeType.MEDICATION_DOSE) {
      const medicationName = (change.changeItem as MedicationSubItem).parent
        .name;
      const protocolName = (change.changeItem as MedicationSubItem).parentCPR
        ? 'CPR Assist'
        : (change.changeItem as MedicationSubItem).parentProtocol?.name || '';
      return (
        <span style={{ fontSize: '15px', fontWeight: '500', color: '#616161' }}>
          {medicationName} dose in {protocolName}.
        </span>
      );
    } else if (change.changeType === DraftChangeType.INFUSION_DOSE) {
      const infusionName = (change.changeItem as InfusionSubItem).parent.name;
      const protocolName =
        (change.changeItem as InfusionSubItem).parentProtocol?.name || '';
      return (
        <span style={{ fontSize: '15px', fontWeight: '500', color: '#616161' }}>
          {infusionName} dose in {protocolName}.
        </span>
      );
    } else if (change.changeType === DraftChangeType.ELECTRICAL_SHOCK) {
      const shockName = (change.changeItem as ElectricalSubItem).parent.name;
      const protocolName =
        (change.changeItem as ElectricalSubItem).parentProtocol?.name || '';
      return (
        <span style={{ fontSize: '15px', fontWeight: '500', color: '#616161' }}>
          {shockName} shock in {protocolName}.
        </span>
      );
    }
    return <></>;
  };

  return (
    <div
      className="screen-container"
      style={{
        height: '100%',
        padding: '0 0rem',
      }}
    >
      <TextConfirmModal
        isVisible={textInputModal !== -1}
        title={
          textInputModal === ADD_COMMENT
            ? 'Add Comment'
            : textInputModal === EDIT_COMMENT
              ? 'Edit Comment'
              : textInputModal === DECLINE_DRAFT_ITEM
                ? 'Decline Change?'
                : textInputModal === DECLINE_REVIEW
                  ? 'Decline Review?'
                  : 'Accept Review?'
        }
        handleClose={() => {
          setTextInputModal(-1);
        }}
        required={textInputModal < ACCEPT_REVIEW}
        handleSubmit={(comment: string) => {
          if (
            textInputModal === ADD_COMMENT ||
            textInputModal === EDIT_COMMENT
          ) {
            if (currentDraft.reviewACK) {
              handleComment(
                currentDraft.reviewACK,
                comment,
                currentDraft.reviewACK.comment as UserCommentItem
              );
            }
          } else if (textInputModal === DECLINE_DRAFT_ITEM) {
            declineDraftItem(comment);
          } else if (textInputModal === DECLINE_REVIEW) {
            handleAcknowledgment(
              reviewal,
              ACKStatus.REJECTED,
              undefined,
              comment
            ).then((response) => {
              if (response.type === ResponseType.Success) {
                reviewal.acknowledgements.push(
                  response.data as AcknowledgeItem
                );

                let newReviewal = cloneReviewalItem(reviewal, database);
                setReviewal(newReviewal);
              }
            });
          } else if (
            textInputModal === ACCEPT_REVIEW ||
            textInputModal === ACCEPT_REVIEW_FINAL
          ) {
            handleAcknowledgment(
              reviewal,
              ACKStatus.APPROVED,
              undefined,
              comment
            ).then((response) => {
              if (response.type === ResponseType.Success) {
                reviewal.acknowledgements.push(
                  response.data as AcknowledgeItem
                );
                if (textInputModal === ACCEPT_REVIEW_FINAL) {
                  handleUpdateReviewalStatus(ACKStatus.APPROVED).then(() => {
                    if (globals.debug)
                      console.log('Reviewal Updated to APPROVED');
                  });
                } else {
                  let newReviewal = cloneReviewalItem(reviewal, database);
                  setReviewal(newReviewal);
                }
              }
            });
          }
          setTextInputModal(-1);
        }}
        isDeleteBtn={false}
        primaryBtnName="Cancel"
        secondaryBtnName={textInputModal > 1 ? 'Submit' : 'Decline'}
        secondaryBtnColor={textInputModal > 1 ? undefined : '#880808'}
        primaryDescription={
          textInputModal === ADD_COMMENT
            ? 'Add a comment to the acknowledgement'
            : textInputModal === EDIT_COMMENT
              ? 'Edit the comment'
              : textInputModal === DECLINE_DRAFT_ITEM
                ? `Declining this change will move the reviewal to the 'Rejected' state. Once rejected, the review owner ${reviewal.user.firstName} ${reviewal.user.lastName} will be notified and will need to re-submit the review to continue.`
                : textInputModal === DECLINE_REVIEW
                  ? `Declining the reviewal to the 'Rejected' state. Once rejected, the review owner ${reviewal.user.firstName} ${reviewal.user.lastName} will be notified and will need to re-submit the review to continue.`
                  : `Accepting this review will move your review to the "Approved" state. ${
                      textInputModal === ACCEPT_REVIEW_FINAL
                        ? 'All reviews have been approved and the review will be moved to the approved state. ' +
                          reviewal.user.firstName +
                          ' ' +
                          reviewal.user.lastName +
                          ' will be notified and can publish the changes.'
                        : 'Once approved, the review owner ' +
                          reviewal.user.firstName +
                          ' ' +
                          reviewal.user.lastName +
                          ' will be notified and can publish the changes.'
                    }`
        }
        inputType="text"
        prefilledInput={
          textInputModal === EDIT_COMMENT
            ? currentDraft.reviewACK?.comment?.message
            : ''
        }
      />

      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={
          modalState.handleClose
            ? modalState.handleClose
            : () => {
                setModalState({ ...modalState, isVisible: false });
              }
        }
        handleSubmit={
          modalState.handleSubmit
            ? modalState.handleSubmit
            : () => {
                setModalState({ ...modalState, isVisible: false });
              }
        }
        primaryBtnName={
          modalState.primaryBtnName === ''
            ? undefined
            : modalState.primaryBtnName
        }
        isSingleBtn={modalState.primaryBtnName === '' ? true : false}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.description}
        secondaryDescription={''}
        isDeleteBtn={modalState.isRedBtn === true ? true : false}
      />

      <ChangesHeader description={description} />

      <ReviewalSubscriber
        reviewal={reviewal}
        draftGroup={currentDraft.draftGroup}
        draftChange={currentDraft}
        onReviewalChange={handleReviewalChange}
        onDraftChangeChange={(
          reviewal: ReviewalItem,
          draftChange: DraftChangeItem,
          isDeleted: boolean
        ) => {
          if (currentDraft.uid === draftChange.uid) {
            if (isDeleted)
              setCurrentDraft(reviewal.draftGroups[0].draftChanges[0]);
            else setCurrentDraft(draftChange);
          }
        }}
        onError={(error: ReviewalError, reviewal: ReviewalItem) => {
          console.log('error', error, reviewal);
        }}
        onFullReloadReviewal={(reviewalItem: ReviewalItem) => {
          reviewalItem.isFullyLoaded = false; // Force the review to reload
          handleFullReloadReviewal(reviewalItem, true);
        }}
      />

      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className=""
            style={{ display: 'flex', flexDirection: 'row', flex: 1 }}
          >
            <Button
              className={`secondary-button-gray btn-rightMargin ${
                selectedTab === 'Details'
                  ? 'calculation-active btn-active'
                  : 'calculation-inActive'
              }`}
              onClick={() => handleTabClick('Details')}
            >
              Details
            </Button>

            <Button
              className={`secondary-button-gray btn-rightMargin ${
                selectedTab === 'Changes'
                  ? 'btn-active pdf-active'
                  : 'pdf-inActive'
              }`}
              onClick={() => handleTabClick('Changes')}
            >
              Changes
            </Button>
            <div
              style={{
                display: 'flex',
                // justifyContent: 'flex-end',
                // alignItems: 'center',
                // margin: '0px 10px',
              }}
            >
              {/* <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  newAlignment: string | null
                ) => setViewMode(newAlignment)}
                aria-label="owner"
                sx={{
                  padding: '0px',
                  marginLeft: '1rem',
                }}
              >
                <Tooltip title={'Ticket View Mode'} placement="bottom" arrow>
                  <ToggleButton
                    value="tickets"
                    aria-label="tickets"
                    autoCapitalize="none"
                    sx={{
                      padding: '8px', // Adjust padding as needed for the "All" button
                      minWidth: 'auto', // Ensures the width is only as wide as the content
                    }}
                    onChange={() => {
                      setViewMode('tickets');
                    }}
                  >
                    <FaTicketSimple
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title={'List View Mode'} placement="bottom" arrow>
                  <ToggleButton
                    value={'list'}
                    aria-label={'list'}
                    autoCapitalize="none"
                    sx={{
                      padding: '8px', // Adjust padding as needed for the "All" button
                      minWidth: 'auto', // Ensures the width is only as wide as the content
                    }}
                    onChange={() => {
                      setViewMode('list');
                    }}
                  >
                    <FaListUl
                      style={{
                        fontSize: '16px',
                      }}
                    />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup> */}
            </div>
          </div>
          <div
            style={{
              marginRight: '2rem',
            }}
          >
            <MultiSelectDropdown<string>
              title={'Types'}
              options={FILTER_TYPES}
              selectedItems={filter.type}
              labelField={(option: string) => option}
              keyField={(option: string) => option}
              onSelected={(selected: string[]) => {
                setFilter({
                  ...filter,
                  type: selected,
                });
              }}
              buttonColor="#e3e3e3"
              textColor="#000"
              buttonStyle={{
                padding: '4px 8px',
              }}
            />
          </div>
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <span
              className={`iconButton ${nextLogic.isFirst ? 'disabled' : ''}`}
            >
              <BiChevronLeft
                data-testid="left-arrow-icon"
                className={`icon ${nextLogic.isFirst ? 'disabled' : ''}`}
                onClick={
                  nextLogic.isFirst
                    ? () => {}
                    : () => getNextDraftChangeItem('left')
                }
              />
            </span>
            <span
              className={`iconButton ${nextLogic.isLast ? 'disabled' : ''}`}
            >
              <BiChevronRight
                data-testid="right-arrow-icon"
                className={`icon ${nextLogic.isLast ? 'disabled' : ''}`}
                onClick={
                  nextLogic.isLast
                    ? () => {}
                    : () => getNextDraftChangeItem('right')
                }
              />
            </span>
          </div>
        </div>
      </div>

      {reviewal.draftGroups.length > 0 ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            gap: '10px',
          }}
        >
          <div
            className="contentBorderLeft"
            style={{
              flex: 1,
              padding: '0 10px',
              display: 'flex',
              marginTop: '5px',
              flexDirection: 'column',
              border: '1px solid rgb(224, 224, 224)',
              // maxHeight: 'calc(100vh - 327px)',
              minHeight: '77.5vh',

              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              height: '100%',
              position: 'sticky',
              top: '20vh',
              zIndex: '1',
            }}
          >
            <SearchBar
              containerStyle={{
                width: '100%',
                marginTop: '15px',
                backgroundColor: 'white',
              }}
              value={searchQuery}
              onChange={(searchTerm: string) => {
                setSearchQuery(searchTerm);
              }}
              onSubmit={(searchTerm: string) => {}}
              placeholder={'Search Reviews...'}
            />

            <div
              className="jira-tickets-Wrapper"
              style={{
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 280px)',
                background: viewMode === 'list' ? 'white' : 'transparent',
                borderRadius: viewMode === 'list' ? '8px' : '0px',
                border:
                  viewMode === 'list' ? '1px solid rgb(224, 224, 224)' : 'none',
              }}
            >
              <ViewportList items={reviewal.draftGroups}>
                {(draftGroup: DraftGroupItem, groupIndex: number) => (
                  <ReviewDraftGroup
                    viewMode={viewMode === 'list' ? 'list' : 'tickets'}
                    reviewal={reviewal}
                    isUserOwner={reviewal.user.id === user.id}
                    key={groupIndex}
                    draftGroup={draftGroup}
                    currentDraft={currentDraft}
                    handleClick={(draft: DraftChangeItem) =>
                      setCurrentDraft(draft)
                    }
                    expanded={expanded}
                    setExpanded={setExpanded}
                    searchQuery={searchQuery}
                    filter={filter}
                    filteredDrafts={filteredDrafts}
                    singleDraft={reviewal.draftGroups.length === 1}
                  />
                )}
              </ViewportList>
            </div>
          </div>
          <div
            className="contentBorderRightWrapper"
            style={{
              flex: 5,
              display: 'flex',
              flexDirection: 'column',
              fontSize: '20px',
              height: '100%',
            }}
          >
            <div
              className="contentBorderRight"
              style={{
                // flex: '0 0 76.5%',
                display: 'flex',
                marginTop: '5px',
                flexDirection: 'column',
                border: '1px solid rgb(224, 224, 224)',
                overflow: 'hidden',
                fontSize: '20px',
                height: '100%',
                borderRadius: '8px',
              }}
            >
              <div className="doseReview">
                <div className="doseName">
                  {currentDraft?.reviewACK && (
                    <span
                      style={{
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                        backgroundColor: '#00534C',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '11px',
                        fontWeight: 'bold',
                      }}
                    >
                      {currentDraft.reviewACK?.user.firstName[0]}
                      {currentDraft.reviewACK?.user.lastName[0]}
                    </span>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                      className="doseNameText"
                      style={{ fontSize: '16px', marginLeft: '0px' }}
                    >
                      {toTitleCase(currentDraft?.changeType || '')}

                      {user.type === UserType.ADMIN && (
                        <span
                          style={{
                            fontSize: '12px',
                            fontWeight: '600',
                            color: '#616161',
                            marginLeft: '5px',
                          }}
                        >
                          ID: {currentDraft?.uid}
                          <CopyToClipboard text={currentDraft?.uid} />
                        </span>
                      )}
                    </span>
                    {getChangeDescription(currentDraft)}
                  </div>
                </div>
                {reviewal.state !== ACKStatus.DRAFT &&
                  reviewButtonStatus !== 2 &&
                  user.id !== reviewal.user.id && (
                    <>
                      {currentDraft?.reviewACK == null ? (
                        <div className="doseReviewButtonContainer">
                          <Button
                            className="red-background-button btn-rightMargin  btn btn-primary"
                            onClick={() =>
                              setTextInputModal(DECLINE_DRAFT_ITEM)
                            }
                            disabled={DISABLED_MODE}
                          >
                            Decline
                          </Button>
                          <Button
                            className="primary-button btn-rightMargin btn btn-primary"
                            onClick={() =>
                              handleAcknowledgment(
                                currentDraft,
                                ACKStatus.APPROVED,
                                currentDraft.reviewACK || undefined
                              ).then((response) => {
                                if (response.type === ResponseType.Success) {
                                  currentDraft.reviewACK =
                                    response.data as AcknowledgeItem;
                                  let newDraft = cloneDraftChangeItem(
                                    currentDraft,
                                    database,
                                    user
                                  );
                                  console.log('ACCEEPTED Draft', newDraft);
                                  // newDraft.reviewACK =
                                  //   response.data as AcknowledgeItem;
                                  setCurrentDraft(newDraft);
                                  if (globals.debug)
                                    console.log('ACCEEPTED Draft', newDraft);
                                  getNextDraftChangeItem('right');
                                }
                              })
                            }
                            disabled={DISABLED_MODE}
                          >
                            Accept
                          </Button>
                        </div>
                      ) : currentDraft.reviewACK.state ===
                        ACKStatus.APPROVED ? (
                        <div
                          className="doseReviewButtonContainer"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              color: '#037F02',
                              fontWeight: 'bold',
                              fontSize: '16px',
                            }}
                          >
                            Accepted
                          </span>
                          <FontAwesomeIcon
                            icon={faFileCircleCheck}
                            style={{
                              color: '#037F02',
                              fontSize: '24px',
                            }}
                          />
                          <IconDropdown
                            value={''}
                            style={{ marginTop: '-5px' }}
                            options={menuItems}
                            onChange={(value) => {
                              if (
                                value === 'Remove Approval' &&
                                currentDraft.reviewACK
                              )
                                handleRemoveAcknowledgement(
                                  currentDraft.reviewACK
                                ).then((response) => {
                                  if (response) {
                                    currentDraft.reviewACK = undefined;
                                    let newDraft = cloneDraftChangeItem(
                                      currentDraft,
                                      database,
                                      user
                                    );
                                    console.log('REMOVED Draft', newDraft);
                                    setCurrentDraft(newDraft);
                                  }
                                });
                              else if (value === 'Add Comment') {
                                // setIsCommentModal(true);
                                setTextInputModal(ADD_COMMENT);
                              } else if (
                                value === 'Edit Comment' &&
                                currentDraft &&
                                currentDraft.reviewACK &&
                                currentDraft.reviewACK.comment
                              ) {
                                // setIsCommentModal(true);
                                setTextInputModal(EDIT_COMMENT);
                              } else if (value === 'Change Review') {
                                // setIsWarningModal(true);
                                setTextInputModal(DECLINE_REVIEW);
                              }
                            }}
                            icon={<IoEllipsisVertical />}
                            buttonColor="#E0EADD"
                            textColor="#00534C"
                          />
                        </div>
                      ) : (
                        <div
                          className="doseReviewButtonContainer"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span
                            style={{
                              color: '#880808',
                              fontWeight: 'bold',
                              fontSize: '16px',
                            }}
                          >
                            Declined
                          </span>
                          <FontAwesomeIcon
                            icon={faFileCircleXmark}
                            style={{
                              color: '#880808',
                              fontSize: '24px',
                            }}
                          />
                          <IconDropdown
                            value={''}
                            options={menuItems}
                            style={{ marginTop: '-5px' }}
                            onChange={(value) => {
                              if (
                                value === 'Remove Decline' &&
                                currentDraft.reviewACK
                              )
                                handleRemoveAcknowledgement(
                                  currentDraft.reviewACK
                                ).then((response) => {
                                  if (response) {
                                    currentDraft.reviewACK = undefined;
                                    let newDraft = cloneDraftChangeItem(
                                      currentDraft,
                                      database,
                                      user
                                    );
                                    setCurrentDraft(newDraft);
                                  }
                                });
                              else if (value === 'Add Comment') {
                                // setIsCommentModal(true);
                                setTextInputModal(ADD_COMMENT);
                              } else if (
                                value === 'Edit Comment' &&
                                currentDraft &&
                                currentDraft.reviewACK &&
                                currentDraft.reviewACK.comment
                              ) {
                                // setIsCommentModal(true);
                                setTextInputModal(EDIT_COMMENT);
                              } else if (value === 'Change Review') {
                                handleAcknowledgment(
                                  currentDraft,
                                  ACKStatus.APPROVED
                                );
                              }
                            }}
                            icon={<IoEllipsisVertical />}
                            buttonColor="#E0EADD"
                            textColor="#00534C"
                          />
                        </div>
                      )}
                    </>
                  )}
              </div>

              {currentDraft?.changeType === 'PROTOCOL_PDF' ? (
                <>
                  <ChangeDescription
                    comment={
                      currentDraft?.comments.length > 0
                        ? currentDraft?.comments[0]
                        : null
                    }
                  />
                  {getComparisonComponent()}
                </>
              ) : (
                <>
                  <ChangeDescription
                    comment={currentDraft.reviewACK?.comment}
                    // id={currentDraft.reviewACK?.uid}
                  />
                  {getComparisonComponent()}
                </>
              )}
            </div>

            {/* Comment Section */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    fontSize: '18px',
                    paddingLeft: '10px',
                    display: 'flex',
                    // alignItems: 'center', // Ensure vertical alignment
                    paddingTop: '20px',
                    // flex: 1,
                    fontWeight: 'bold',
                  }}
                >
                  Comments
                  <span
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      backgroundColor: 'rgb(248, 249, 250)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '12px',
                      fontWeight: 'bold',
                      marginLeft: '10px',
                      color: '#555',
                    }}
                  >
                    {currentDraft.comments.length}
                  </span>
                </div>
                {!DISABLED_MODE && reviewal.state !== ACKStatus.DRAFT && (
                  <div
                    className="commentWrapper"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '2px',
                    }}
                  >
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        backgroundColor: '#00534C',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        marginRight: '10px',
                      }}
                    >
                      {user.firstName[0]}
                      {user.lastName[0]}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid rgb(204, 204, 204)',
                        padding: '0.5rem 0.5rem',
                        borderRadius: '6px',
                        width: '100%',
                        marginTop: '10px',

                        justifyContent: 'center',
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <input
                          ref={inputRef}
                          type="text"
                          placeholder="Add a comment..."
                          style={{
                            width: '100%',
                            height: '100%',
                            fontSize: '14px',
                            border: 'none',
                            outline: 'none',
                            borderRadius: '0',
                          }}
                          value={commentText}
                          onChange={(e) => setCommentText(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleComment(currentDraft, commentText);
                              setCommentText('');
                            }
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: '10px',
                        }}
                      >
                        <Button
                          className="primary-button btn-rightMargin btn btn-primary"
                          disabled={commentText.length === 0 || DISABLED_MODE}
                          style={{
                            marginRight: '0px',
                          }}
                          onClick={() => {
                            handleComment(currentDraft, commentText);
                            setCommentText('');
                          }}
                        >
                          Comment
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {currentDraft.comments.length > 0 && (
                  <div
                    className="comment-section"
                    style={{
                      border: '1px solid #d1d5da',
                      borderRadius: '5px',
                      padding: '10px 10px 5px 10px',
                      marginTop: '10px',
                      marginLeft: DISABLED_MODE ? '0' : '40px',
                    }}
                  >
                    <ViewportList items={currentDraft.comments}>
                      {(comment: UserCommentItem, groupIndex: number) => (
                        <div
                          key={groupIndex}
                          className="comment"
                          style={{ position: 'relative', marginBottom: '8px' }}
                        >
                          {groupIndex < currentDraft.comments.length - 1 && (
                            <div
                              className="vertical-line"
                              style={{
                                position: 'absolute',
                                left: '14px', // Adjust as needed
                                top: '30px', // Align with the center of the circle
                                height: '50px', // Adjust height as needed
                                width: '1.25px',
                                backgroundColor: 'rgb(204, 204, 204)',
                              }}
                            />
                          )}
                          <div
                            className="comment-header"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                                backgroundColor: '#00534C',
                                color: 'white',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '12px',
                                fontWeight: 'bold',
                                marginRight: '10px',
                              }}
                            >
                              {comment.user.firstName[0]}
                              {comment.user.lastName[0]}
                            </div>
                            <div style={{ display: 'flex' }}>
                              <span
                                className="comment-owner"
                                style={{ fontSize: '14px', fontWeight: 'bold' }}
                              >
                                {comment.user?.firstName}{' '}
                                {comment.user?.lastName}
                              </span>
                              <span
                                className="comment-timestamp"
                                style={{
                                  fontSize: '12px',
                                  color: '#555',
                                  paddingLeft: '10px',
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                {new Date(comment.timestamp).toLocaleString(
                                  'en-US',
                                  {
                                    month: 'short',
                                    day: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                          <div
                            className="comment-body"
                            style={{
                              fontSize: '14px',
                              marginLeft: '40px',
                              color: '#555',
                            }}
                          >
                            {comment.message}
                          </div>
                        </div>
                      )}
                    </ViewportList>
                  </div>
                )}
                {DISABLED_MODE && currentDraft.comments.length === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      // justifyContent: 'center',
                      height: '100%',
                      fontSize: '16px',
                      color: '#555',
                      marginLeft: '20px',
                    }}
                  >
                    No comments...
                  </div>
                )}
              </div>
              {user.id === reviewal.user.id && (
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      fontSize: '18px',
                      paddingLeft: '10px',
                      display: 'flex',
                      // alignItems: 'center', // Ensure vertical alignment
                      paddingTop: '20px',
                      // flex: 1,
                      fontWeight: 'bold',
                    }}
                  >
                    Reviews
                    <span
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                        backgroundColor: 'rgb(248, 249, 250)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        marginLeft: '10px',
                        color: '#555',
                      }}
                    >
                      {currentDraft.acknowledgements.length}
                    </span>
                  </div>

                  {currentDraft.acknowledgements.length > 0 && (
                    <div
                      className="comment-section"
                      style={{
                        border: '1px solid #d1d5da',
                        borderRadius: '5px',
                        padding: '10px 10px 5px 10px',
                        marginTop: '10px',
                        // marginLeft: '40px',
                      }}
                    >
                      <ViewportList items={currentDraft.acknowledgements}>
                        {(acknowledge: AcknowledgeItem, groupIndex: number) => (
                          <div
                            key={groupIndex}
                            className="comment"
                            style={{
                              position: 'relative',
                              marginBottom: '8px',
                            }}
                          >
                            {groupIndex <
                              currentDraft.acknowledgements.length - 1 && (
                              <div
                                className="vertical-line"
                                style={{
                                  position: 'absolute',
                                  left: '14px', // Adjust as needed
                                  top: '30px', // Align with the center of the circle
                                  height: '50px', // Adjust height as needed
                                  width: '1.25px',
                                  backgroundColor: 'rgb(204, 204, 204)',
                                }}
                              />
                            )}
                            <div
                              className="comment-header"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                className=""
                                style={{
                                  position: 'relative',
                                  // display: 'inline-block',
                                  marginRight: '10px',
                                }}
                              >
                                <div
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '50%',
                                    backgroundColor: '#00534C',
                                    color: 'white',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    // position: 'relative',
                                  }}
                                >
                                  {acknowledge.user.firstName[0]}
                                  {acknowledge.user.lastName[0]}
                                </div>
                                <div
                                  style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    right: '0',
                                    width: '14px',
                                    height: '14px',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#FFF',
                                  }}
                                >
                                  {acknowledge.state === ACKStatus.APPROVED ? (
                                    <FaCheckCircle
                                      style={{
                                        color: '#037F02',
                                        fontSize: '12px',
                                      }}
                                    />
                                  ) : (
                                    <FaCircleXmark
                                      style={{
                                        color: '#8c1010',
                                        fontSize: '12px',
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              {/* <div
                            style={{
                              display: 'flex',
                              width: '30px',
                              height: '30px',
                              borderRadius: '50%',
                              backgroundColor: '#00534C',
                              color: 'white',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              marginRight: '10px',
                            }}
                          >
                            {acknowledge.user.firstName[0]}
                            {acknowledge.user.lastName[0]}
                          </div> */}
                              <div style={{ display: 'flex' }}>
                                <span
                                  className="comment-owner"
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {acknowledge.user.firstName}{' '}
                                  {acknowledge.user.lastName}
                                </span>
                                <span
                                  className="comment-timestamp"
                                  style={{
                                    fontSize: '12px',
                                    color: '#555',
                                    paddingLeft: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {new Date(
                                    acknowledge.timestamp
                                  ).toLocaleString('en-US', {
                                    month: 'short',
                                    day: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                  })}
                                </span>
                              </div>
                            </div>
                            <div
                              className="comment-body"
                              style={{
                                fontSize: '14px',
                                marginLeft: '40px',
                                color: '#555',
                              }}
                            >
                              {acknowledge.comment?.message}
                            </div>
                          </div>
                        )}
                      </ViewportList>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data-container">
          <MdCreateNewFolder size={220} className="light-grey-icon" />
          <h4 className="light-grey-icon">
            No draft changes available for review
          </h4>
        </div>
      )}
    </div>
  );
};

export default ReviewChanges;
