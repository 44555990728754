import { DataStore } from '@aws-amplify/datastore';
import {
  Category,
  Department,
  Drip,
  Equipment,
  Medication,
  Protocol,
  Vitals,
  Form,
  MedicationProtocol,
  User,
  Contact,
  InputForm,
  MedicationDose,
  ProgressStatus,
  MedicationRange,
  ElectricalShock,
  Workbook,
  WeightObject,
  InfusionDose,
  ElectricalDose,
  ElectricalShockOption,
  ElectricalShockRange,
  Concentration,
  CPRAssist,
  VitalOption,
  EquipmentOption,
} from '../models';
import CategoryItem from './model/CategoryItem';
import ProtocolItem from './model/ProtocolItem';
import DepartmentItem from './model/DepartmentItem';
import {
  executeQuery,
  executeSingleQuery,
  Response,
  ResponseType,
} from './AmplifyDB';
import {
  generateNewIDs,
  globals,
  removeTypename,
} from '../ui/_global/common/Utils';
import {
  checkMediationDoseEquals,
  fetchMedications,
} from './functions/MedicationDB';
import MedicationSubItem from './model/MedicationSubItem';
import { validatePointerID } from './functions/ProtocolDB';
import InfusionSubItem from './model/InfusionSubItem';
import ElectricalSubItem from './model/ElectricalSubItem';
import {
  categoriesByDepartmentID,
  concentrationsByDepartmentID,
  dripsByDepartmentID,
  electricalShocksByDepartmentID,
  equipmentByDepartmentID,
  formsByDepartmentID,
  getCategory,
  getContact,
  getProtocol,
  listCategories,
  listContacts,
  listDepartments,
  listInputForms,
  listNotifications,
  listProtocols,
  listUsers,
  medicationsByDepartmentID,
  protocolsByCategoryID,
  vitalsByDepartmentID,
} from '../graphql/queries';
import {
  CreateNotificationInput,
  CreateVitalsInput,
  EquipmentOptionInput,
  Notification,
  UpdateContactInput,
  UpdateNotificationInput,
  VitalOptionInput,
} from '../API';
import {
  createNotification,
  createVitals,
  updateContact,
  updateNotification,
} from '../graphql/mutations';
import { notificationsByDepartmentID } from './QueryTypes';
import MedicationItem from './model/MedicationItem';
import { fetchDrips } from './functions/InfusionDB';
import InfusionItem from './model/InfusionItem';
import NotificationItem from './model/NotificationItem';
import ContactItem from './model/ContactItem';

export const resetContactIndexes = async (
  department: DepartmentItem,
  contacts: ContactItem[],
  isResetToZero: boolean = true
): Promise<Response> => {
  try {
    let n = contacts.length;
    console.log('Resetting', n, 'contacts');
    for (let i = 0; i < n; i++) {
      const dbContact: Contact | undefined = await executeSingleQuery(
        getContact,
        {
          id: contacts[i].uid,
        }
      );
      if (dbContact == null) {
        console.error('Could not find contact', contacts[i].name);
        return {
          type: ResponseType.Failure,
          data: 'Contact not found',
        };
      }
      console.log(
        'Contact',
        dbContact.fullName,
        'Previous Index',
        dbContact.index,
        'New Index',
        isResetToZero ? 0 : i
      );
      let json = {
        id: dbContact.id,
        index: isResetToZero ? 0 : i,
        _version: (dbContact as any)._version,
      } as UpdateContactInput;
      let newContact = await executeSingleQuery(updateContact, {
        input: json,
      });

      console.log('New Contact', contacts[i].name, newContact);
      if (newContact === null) {
        console.error('resetContactIndexes: ', newContact);
        return {
          type: ResponseType.Failure,
          data: 'Failed to reset contact indexes',
        };
      }

      if (globals.debug)
        console.log(
          'Reset Contact Indexes: ',
          i,
          ' / ',
          n,
          ' -> ',
          department.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyVitalsFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    const vitals = await executeQuery(vitalsByDepartmentID, {
      departmentID: departmentFrom.id,
      filter: {
        and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
      },
    });

    let i = 0,
      n = vitals.length;
    for (const vital of vitals) {
      const newVital = new Vitals({
        title: vital.title,
        departmentID: departmentTo.id,
        options: generateNewIDs(removeTypename(vital.options)),
        status: vital.status,
        version: 'v1.0.0',
        createdBy: 'OneBot',
        index: vital.index,
      });
      // const newVital = {
      //   title: vital.title,
      //   departmentID: departmentTo.id,
      //   options: vital.options.map((option: VitalOption) => {
      //     return {
      //       amntLow: option.amntLow,
      //       amntHigh: option.amntHigh,
      //       rangeLow: option.rangeLow,
      //       rangeHigh: option.rangeHigh,
      //     } as VitalOptionInput;
      //   }),
      //   status: vital.status,
      //   version: 'v1.0.0',
      //   createdBy: 'OneBot',
      //   _version: 1,
      // } as CreateVitalsInput;
      let response = await DataStore.save(newVital);
      console.log('response: ', response);
      // let response = await executeSingleQuery(createVitals, {
      //   input: newVital,
      // });
      if (response === null) {
        console.error('copyVitalsFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Vitals: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyEquipmentFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    // const equipments = await DataStore.query(Equipment, (c) =>
    //   c.and((c) => [
    //     c.departmentID.eq(departmentFrom.id),
    //     c.status.eq('ACTIVE'),
    //   ])
    // );
    const equipments: Equipment[] = await executeQuery(
      equipmentByDepartmentID,
      {
        departmentID: departmentFrom.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );

    let i = 0,
      n = equipments.length;
    for (const equipment of equipments) {
      const newEquipment = new Equipment({
        name: equipment.name,
        departmentID: departmentTo.id,
        optionItems: generateNewIDs(removeTypename(equipment.optionItems)),
        note: equipment.note,
        warning: equipment.warning,
        title: equipment.title,
        instruction: equipment.instruction,
        version: 'v1.0.0',
        status: equipment.status,

        createdBy: 'OneBot',
      });
      let response = await DataStore.save(newEquipment);
      if (response === null) {
        console.error('copyEquipmentFromDeptoDep: ', response);
      }
      if (globals.debug)
        if (globals.debug)
          console.log(
            'Copied Equipment: ',
            i++,
            ' / ',
            n,
            ' -> ',
            departmentTo.name
          );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyElectricalFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    const electricals: ElectricalShock[] = await executeQuery(
      electricalShocksByDepartmentID,
      {
        departmentID: departmentFrom.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );
    let i = 0,
      n = electricals.length;
    for (const electrical of electricals) {
      const newElectrical = new ElectricalShock({
        departmentID: departmentTo.id,
        title: electrical.title,
        options: [],
        rxNormCode: electrical.rxNormCode,
        // snowmedCode: electrical.snowmedCode,
        warning: electrical.warning,
        instruction: electrical.instruction,
        note: electrical.note,
        rangeHigh: electrical.rangeHigh,
        rangeLow: electrical.rangeLow,
        createdBy: 'OneBot',
        metaData: removeTypename(electrical.metaData),
        status: electrical.status,
        version: 'v1.0.0',
      });
      let response = await DataStore.save(newElectrical);
      if (response === null) {
        console.error('copyElectricalFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Electrical: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyMedicationFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department,
  includeDoses: boolean
): Promise<Response> => {
  try {
    // const medications = await DataStore.query(Medication, (c) =>
    //   c.and((c) => [
    //     c.departmentID.eq(departmentFrom.id),
    //     c.status.eq('ACTIVE'),
    //   ])
    // );
    const medications: Medication[] = await executeQuery(
      medicationsByDepartmentID,
      {
        departmentID: departmentFrom.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );
    let i = 0,
      n = medications.length;
    for (const med of medications) {
      const newMed = new Medication({
        departmentID: departmentTo.id,
        name: med.name,
        contraindication: med.contraindication,
        note: med.note,
        warning: med.warning,
        route: med.route,
        concentration: removeTypename(med.concentration),
        medClass: med.medClass,
        action: med.action,
        indication: med.indication,
        interaction: med.interaction,
        onset: med.onset,
        duration: med.duration,
        protocolOptions: includeDoses
          ? removeTypename(med.protocolOptions)
          : [],
        status: med.status,
        version: 'v1.0.0',
        rangeLow: med.rangeLow,
        rangeHigh: med.rangeHigh,

        createdBy: 'OneBot',
      });
      let response = await DataStore.save(newMed);
      if (response === null) {
        console.error('copyMedicationFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Medication: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyInfusionsFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    // const infusions = await DataStore.query(Drip, (c) =>
    //   c.and((c) => [
    //     c.departmentID.eq(departmentFrom.id),
    //     c.status.eq('ACTIVE'),
    //   ])
    // );
    const infusions: Drip[] = await executeQuery(dripsByDepartmentID, {
      departmentID: departmentFrom.id,
      filter: {
        and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
      },
    });
    let i = 0,
      n = infusions.length;
    for (const drip of infusions) {
      const newDrip = new Drip({
        departmentID: departmentTo.id,
        name: drip.name,
        dripOptions: removeTypename(drip.dripOptions),
        contraindication: drip.contraindication,
        note: drip.note,
        warning: drip.warning,
        route: drip.route,
        concentration: removeTypename(drip.concentration),
        medClass: drip.medClass,
        action: drip.action,
        indication: drip.indication,
        interaction: drip.interaction,
        onset: drip.onset,
        duration: drip.duration,
        status: drip.status,
        version: 'v1.0.0',
        rangeLow: drip.rangeLow,
        rangeHigh: drip.rangeHigh,
        createdBy: 'OneBot',
      });
      let response = await DataStore.save(newDrip);
      if (response === null) {
        console.error('copyInfusionsFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Infusion: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyCategoriesAndProtocolFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    let newCategories: Category[] = [];
    // const categories = await DataStore.query(Category, (c) =>
    //   c.and((c) => [
    //     c.departmentID.eq(departmentFrom.id),
    //     c.status.eq('ACTIVE'),
    //   ])
    // );
    const categories: Category[] = await executeQuery(
      categoriesByDepartmentID,
      {
        departmentID: departmentFrom.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );
    let i = 0,
      n = categories.length;
    for (const cat of categories) {
      const newCat = new Category({
        departmentID: departmentTo.id,
        name: cat.name,
        index: cat.index,
        status: cat.status,
        version: 'v1.0.0',
        createdBy: 'OneBot',
        isPublic: cat.isPublic,
        isRestrictive: cat.isRestrictive,
      });

      let response = await DataStore.save(newCat);
      if (response === null) {
        console.error('copyCategoriesAndProtocolFromDeptoDep: ', response);
      }
      newCategories.push(newCat);
      if (globals.debug)
        console.log(
          'Copied Category: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }

    i = 0;
    n = categories.length;
    let n2 = 0;
    for (let i = 0; i < categories.length; i++) {
      let oldCat = categories[i];
      let newCat = newCategories[i];
      // const protocols = await DataStore.query(Protocol, (c) =>
      //   c.and((c) => [c.categoryID.eq(oldCat.id), c.status.eq('ACTIVE')])
      // );
      const protocols: Protocol[] = await executeQuery(protocolsByCategoryID, {
        categoryID: oldCat.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      });
      let j = 0,
        m = protocols.length;
      n2 += m;
      for (const prot of protocols) {
        const newProt = new Protocol({
          departmentID: departmentTo.id,
          categoryID: newCat.id,
          name: prot.name,
          nickname: prot.nickname,
          pairedDepIDs: prot.pairedDepIDs,
          index: prot.index,
          pairedProtocols: prot.pairedProtocols,
          medicationIDs: prot.medicationIDs,
          equipmentIDs: prot.equipmentIDs,
          electricalIDs: prot.electricalIDs,
          dripIDs: prot.dripIDs,
          formIDs: prot.formIDs,
          pdfID: prot.pdfID,
          rangeHigh: prot.rangeHigh,
          rangeLow: prot.rangeLow,
          status: prot.status,
          version: 'v1.0.0',
          createdBy: 'OneBot',
          isPublic: prot.isPublic,
          isRestrictive: prot.isRestrictive,
        });
        let response = await DataStore.save(newProt);
        if (response === null) {
          console.error('copyCategoriesAndProtocolFromDeptoDep: ', response);
        }
        if (globals.debug)
          console.log(
            'Copied Protocol: ',
            j++,
            ' / ',
            m,
            ' -> ',
            departmentTo.name
          );
      }
      if (globals.debug)
        console.log(
          'Copied Protocols from Category: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: [n, n2],
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyChecklistsFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    // const forms = await DataStore.query(Form, (c) =>
    //   c.and((c) => [
    //     c.departmentID.eq(departmentFrom.id),
    //     c.status.eq('ACTIVE'),
    //   ])
    // );
    const forms: Form[] = await executeQuery(formsByDepartmentID, {
      departmentID: departmentFrom.id,
      filter: {
        and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
      },
    });
    let i = 0,
      n = forms.length;

    for (const form of forms) {
      let cleanedItems = removeTypename(
        form.items.map((item) => {
          let cleanedOptions = removeTypename(item.options);
          return {
            ...item,
            options: cleanedOptions,
          };
        })
      );
      const newForm = new Form({
        departmentID: departmentTo.id,
        name: form.name,
        items: cleanedItems,
        status: form.status,
        version: 'v1.0.0',
        createdBy: 'OneBot',
      });
      let response = await DataStore.save(newForm);
      if (response === null) {
        console.error('copyChecklistsFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Checklist: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyConcentrationsToSubDepartment = async (
  fromDepartment: DepartmentItem,
  toDepartment: DepartmentItem
): Promise<Response> => {
  try {
    console.log(
      'COPYING CONCENTRATIONS: ',
      fromDepartment.name,
      toDepartment.name
    );
    // let newConcentrations = await DataStore.query(Concentration, (c) =>
    //   c.departmentID.eq(fromDepartment.id)
    // );
    // let currentConcentrations = await DataStore.query(Concentration, (c) =>
    //   c.departmentID.eq(toDepartment.id)
    // );
    let newConcentrations: Concentration[] = await executeQuery(
      concentrationsByDepartmentID,
      {
        departmentID: fromDepartment.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );
    console.log('New Concentrations: ', newConcentrations.length);
    let currentConcentrations: Concentration[] = await executeQuery(
      concentrationsByDepartmentID,
      {
        departmentID: toDepartment.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );
    console.log('Concentrations: ', newConcentrations.length);

    let promises: Promise<Response>[] = [];
    /* Remove any concentrations for medications that are not active */
    promises.push(fetchMedications(toDepartment, undefined, false));
    promises.push(fetchDrips(toDepartment, undefined, false));
    let [medResponse, dripResponse] = await Promise.all(promises);
    if (medResponse.type === ResponseType.Failure) {
      console.error('copyConcentrationsToSubDepartment: ', medResponse);
      return {
        type: ResponseType.Failure,
        data: medResponse,
      };
    }
    let allMeds: MedicationItem[] = medResponse.data;

    if (dripResponse.type === ResponseType.Failure) {
      console.error('copyConcentrationsToSubDepartment: ', dripResponse);
      return {
        type: ResponseType.Failure,
        data: dripResponse,
      };
    }
    let allDrips: InfusionItem[] = dripResponse.data;

    console.log('DEPARTMENT: ', toDepartment.name);
    console.log('All Medications: ', allMeds.length);
    console.log('All Drips: ', allDrips.length);
    console.log('Current Concentrations: ', currentConcentrations.length);
    console.log('New Concentrations: ', newConcentrations.length);

    /* Round 1 filtering out concentrations that don't have matching medication AND drip IDs */
    newConcentrations = newConcentrations.filter((concentration) => {
      let med = allMeds.find((med) => med.uid === concentration.medicationID);
      let drip = allDrips.find((drip) => drip.uid === concentration.dripID);

      if (med === null && drip === null) return false;
      return true;
    });

    console.log('Round 1 - New Concentrations: ', newConcentrations.length);

    /* Round 2 filtering out concentrations that are already in the current concentrations */
    newConcentrations = newConcentrations.filter(
      (concentration) =>
        currentConcentrations.findIndex(
          (c) =>
            c.medicationID === concentration.medicationID &&
            c.dripID === concentration.dripID &&
            c.firstAmnt === concentration.firstAmnt &&
            c.firstUnit === concentration.firstUnit &&
            c.secAmnt === concentration.secAmnt &&
            c.secUnit === concentration.secUnit &&
            c.color === concentration.color
        ) === -1
    );

    console.log('Round 2 - New Concentrations: ', newConcentrations.length);
    // return {
    //   type: ResponseType.Success,
    //   data: newConcentrations,
    // };
    let concentrations: Concentration[] = [];
    for (let i = 0; i < newConcentrations.length; i++) {
      let c = newConcentrations[i];
      let newConcentration = new Concentration({
        departmentID: toDepartment.id,
        medicationID: c.medicationID,
        dripID: c.dripID,
        firstAmnt: c.firstAmnt,
        firstUnit: c.firstUnit,
        secAmnt: c.secAmnt,
        secUnit: c.secUnit,
        color: c.color,
        status: 'ACTIVE',
      });
      let response = await DataStore.save(newConcentration);
      if (response === null) {
        console.error('copyConcentrationsToSubDepartment: ', response);
        return {
          type: ResponseType.Failure,
          data: response,
        };
      }
      concentrations.push(newConcentration);
    }

    console.log('Created Concentrations: ', concentrations.length);
    return {
      type: ResponseType.Success,
      data: concentrations,
    };
  } catch (error: any) {
    console.error('copyConcentrationsToSubDepartment: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const copyNotificationsFromDeptoDep = async (
  departmentFrom: Department,
  departmentTo: Department,
  pairedDepIDs: string[]
): Promise<Response> => {
  try {
    const notifications: Notification[] = await executeQuery(
      notificationsByDepartmentID,
      {
        departmentID: departmentFrom.id,
        filter: {
          and: [{ _deleted: { ne: true } }],
        },
      }
    );
    let ids: string[] = [...new Set([...pairedDepIDs, departmentTo.id])];

    let i = 0,
      n = notifications.length;
    for (const not of notifications) {
      const newNotification: CreateNotificationInput = {
        departmentID: departmentTo.id,
        type: not.type,
        title: not.title,
        message: not.message,
        timestamp: new Date().toISOString(),
        expirationTimestamp: not.expirationTimestamp,
        deadlineTimestamp: not.deadlineTimestamp,
        isReadIDs: [],
        isAckIDs: [],
        imageURLs: not.imageURLs,
        videoURLs: not.videoURLs,
        fileURLs: not.fileURLs,
        taggedProtocols: not.taggedProtocols,
        questions: removeTypename(not.questions),
        formQuestions: removeTypename(not.formQuestions),
        isPush: false,
        pairedDepIDs: ids,
        createdBy: 'OneBot',
      };
      // let response = await DataStore.save(newNotification);
      let response = await executeSingleQuery(createNotification, {
        input: newNotification,
      });
      if (response === null) {
        console.error('copyNotificationsFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Notifications: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Copy notifications to a department
 * @param notifications - The notifications to copy
 * @param departmentTo - The department to copy the notifications to
 * @param pairedDepIDs - The departments to pair the notifications with
 * @returns The number of notifications copied
 */
export const copyNotificationsToDepartment = async (
  notifications: NotificationItem[],
  departmentTo: Department,
  pairedDepIDs: string[]
): Promise<Response> => {
  try {
    let ids: string[] = [...new Set([...pairedDepIDs, departmentTo.id])];

    let i = 0,
      n = notifications.length;
    for (const notification of notifications) {
      const not = notification.dbNotification;
      const newNotification: CreateNotificationInput = {
        departmentID: departmentTo.id,
        type: not.type,
        title: not.title,
        message: not.message,
        timestamp: new Date().toISOString(),
        expirationTimestamp: not.expirationTimestamp,
        deadlineTimestamp: not.deadlineTimestamp,
        isReadIDs: [],
        isAckIDs: [],
        imageURLs: not.imageURLs,
        videoURLs: not.videoURLs,
        fileURLs: not.fileURLs,
        taggedProtocols: not.taggedProtocols,
        questions: removeTypename(not.questions),
        formQuestions: removeTypename(not.formQuestions),
        isPush: false,
        pairedDepIDs: ids,
        createdBy: 'OneBot',
      };
      // let response = await DataStore.save(newNotification);
      let response = await executeSingleQuery(createNotification, {
        input: newNotification,
      });
      if (response === null) {
        console.error('copyNotificationsFromDeptoDep: ', response);
      }
      if (globals.debug)
        console.log(
          'Copied Notifications: ',
          i++,
          ' / ',
          n,
          ' -> ',
          departmentTo.name
        );
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const replaceOldIDsToNew = async (
  protocols: ProtocolItem[],
  departmentFrom: Department,
  departmentTo: Department
): Promise<Response> => {
  try {
    // let newMeds = await DataStore.query(Medication, (c) =>
    //   c.departmentID.eq(departmentTo.id)
    // );
    let newMeds: Medication[] = await executeQuery(medicationsByDepartmentID, {
      departmentID: departmentTo.id,
      filter: {
        and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
      },
    });
    let newDrips: Drip[] = await executeQuery(dripsByDepartmentID, {
      departmentID: departmentTo.id,
      filter: {
        and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
      },
    });

    let oldMeds: Medication[] = await executeQuery(medicationsByDepartmentID, {
      departmentID: departmentFrom.id,
      filter: {
        and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
      },
    });

    let oldDrips: Drip[] = await executeQuery(dripsByDepartmentID, {
      departmentID: departmentFrom.id,
      filter: {
        and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
      },
    });
    // let newDrips = await DataStore.query(Drip, (c) =>
    //   c.departmentID.eq(departmentTo.id)
    // );

    // let oldMeds = await DataStore.query(Medication, (c) =>
    //   c.departmentID.eq(departmentFrom.id)
    // );
    // let oldDrips = await DataStore.query(Drip, (c) =>
    //   c.departmentID.eq(departmentFrom.id)
    // );

    let n = 0;
    for (let i = 0; i < oldMeds.length; i++) {
      let oldMed = oldMeds[i];
      let newMed = newMeds.find((med) => med.name === oldMed.name);
      if (newMed == null) continue;

      /* Search through all protocols and update the medication IDs */
      for (let j = 0; j < protocols.length; j++) {
        let protocol = protocols[j];
        let medIDs = protocol.getModel().medicationIDs;
        if (medIDs) {
          let index = medIDs.indexOf(oldMed.id);
          if (index !== -1) {
            let newMedIDs = [...medIDs];
            newMedIDs = newMedIDs.filter((id) => id !== oldMed.id);
            newMedIDs[index] = newMed.id;
            let model = protocol.getModel();
            let response = await DataStore.save(
              Protocol.copyOf(protocol.getModel(), (updated) => {
                updated.medicationIDs = newMedIDs;
              })
            );
            if (globals.debug)
              console.log('replaced medID: ', oldMed.name, ' -> ', newMed.name);
            if (response === null) {
              console.error('replaceOldIDsToNew: ', response);
            }
            n++;
          }
        }
      }
    }

    for (let i = 0; i < oldDrips.length; i++) {
      let oldDrip = oldDrips[i];
      let newDrip = newDrips.find((drip) => drip.name === oldDrip.name);
      if (newDrip == null) continue;

      /* Search through all protocols and update the medication IDs */
      for (let j = 0; j < protocols.length; j++) {
        let protocol = protocols[j];
        let dripIDs = protocol.getModel().dripIDs;
        if (dripIDs) {
          let index = dripIDs.indexOf(oldDrip.id);
          if (index !== -1) {
            let newDripIDs = [...dripIDs];
            newDripIDs = newDripIDs.filter((id) => id !== oldDrip.id);
            newDripIDs[index] = newDrip.id;
            let model = protocol.getModel();
            let response = await DataStore.save(
              Protocol.copyOf(protocol.getModel(), (updated) => {
                updated.dripIDs = newDripIDs;
              })
            );
            if (globals.debug)
              console.log(
                'replaced dripID: ',
                oldDrip.name,
                ' -> ',
                newDrip.name
              );
            if (response === null) {
              console.error('replaceOldIDsToNew: ', response);
            }
            n++;
          }
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const replaceOldIdToNewProtocol = async (
  newProtocol: ProtocolItem,
  oldProtocol: ProtocolItem,
  department: Department
): Promise<Response> => {
  try {
    let count = 0;
    /* Load all the medications and drips*/
    // const medications = await DataStore.query(Medication, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const drips = await DataStore.query(Drip, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    const medications: Medication[] = await executeQuery(
      medicationsByDepartmentID,
      {
        departmentID: department.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );
    const drips: Drip[] = await executeQuery(dripsByDepartmentID, {
      departmentID: department.id,
      filter: {
        and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
      },
    });

    /* Search for the medication and drips in the old protocol ID in the MedicationProtocol and MedicationRange tables */
    for (let i = 0; i < medications.length; i++) {
      const med = medications[i];
      let medProts: MedicationProtocol[] = [];
      let isChanged = false;
      for (let i = 0; i < med.protocolOptions.length; i++) {
        const medProt = med.protocolOptions[i];
        if (medProt.protocolID === oldProtocol.uid) {
          /* Create a new MedicationProtocol with the new protocol ID */
          const newMedProt = new MedicationProtocol({
            protocolID: newProtocol.uid,
            options: medProt.options,
          });
          count++;
          isChanged = true;
          medProts.push(newMedProt);
        } else medProts.push(medProt);
      }
      if (isChanged) {
        /* Save the new MedicationProtocol array to the Medication table */
        const newMed = Medication.copyOf(med, (updated) => {
          updated.protocolOptions = medProts;
        });
        let response = await DataStore.save(newMed);
        if (response === null) {
          console.error('replaceOldIdToNewProtocol: ', response);
        }
      }
    }

    /* Search for the medication and drips in the old protocol ID in the MedicationProtocol and MedicationRange tables */
    for (let i = 0; i < drips.length; i++) {
      const drip = drips[i];
      let dripProts: MedicationProtocol[] = [];
      let isChanged = false;
      for (let i = 0; i < drip.dripOptions.length; i++) {
        const dripProt = drip.dripOptions[i];
        if (!dripProt) continue;
        if (dripProt.protocolID === oldProtocol.uid) {
          /* Create a new MedicationProtocol with the new protocol ID */
          const newMedProt = new MedicationProtocol({
            protocolID: newProtocol.uid,
            options: dripProt.options,
          });
          count++;
          isChanged = true;
          dripProts.push(newMedProt);
        } else dripProts.push(dripProt);
      }
      if (isChanged) {
        /* Save the new MedicationProtocol array to the Medication table */
        const newDrip = Drip.copyOf(drip, (updated) => {
          updated.dripOptions = dripProts;
        });
        let response = await DataStore.save(newDrip);
        if (response === null) {
          console.error('replaceOldIdToNewProtocol: ', response);
        }
      }
    }

    /* Search through the departments medications and update the IDs */

    return {
      type: ResponseType.Success,
      data: count,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const setDepartmentProtocolOwners = async (
  department: DepartmentItem,
  ownerList: DepartmentItem[],
  protocolList: ProtocolItem[]
): Promise<Response> => {
  try {
    const ownerIDs = ownerList.map((owner: DepartmentItem) => owner.id);
    let i = 0,
      n = protocolList.length;
    for (const protocol of protocolList) {
      // const dbProt = await DataStore.query(Protocol, protocol.uid);
      const dbProt = await executeSingleQuery(getProtocol, {
        id: protocol.uid,
      });
      if (dbProt == null) {
        console.error('setDepartmentProtocolOwners: ', dbProt);
        return {
          type: ResponseType.Failure,
          data: dbProt,
        };
      }

      const newProtocol = Protocol.copyOf(dbProt, (updated) => {
        updated.pairedDepIDs = ownerIDs;
      });

      let response = await DataStore.save(newProtocol);

      if (response === null) {
        console.error('setDepartmentProtocolOwners: ', response);
        return {
          type: ResponseType.Failure,
          data: response,
        };
      }

      if (globals.debug)
        console.log(
          'Successfuly set owners for protocol: ',
          i++,
          ' / ',
          n,
          ' -> ',
          protocol.name
        );
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    console.error('setDepartmentProtocolOwners: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const setDepartmentCategoryOwners = async (
  department: DepartmentItem,
  ownerList: DepartmentItem[],
  categoryList: CategoryItem[]
): Promise<Response> => {
  try {
    const ownerIDs = ownerList.map((owner: DepartmentItem) => owner.id);
    let i = 0,
      n = categoryList.length;
    for (const category of categoryList) {
      // const dbCat = await DataStore.query(Category, category.uid);
      const dbCat = await executeSingleQuery(getCategory, {
        id: category.uid,
      });
      if (dbCat == null) {
        console.error('setDepartmentCategoryOwners: ', dbCat);
        return {
          type: ResponseType.Failure,
          data: dbCat,
        };
      }

      const newCategory = Category.copyOf(dbCat, (updated) => {
        updated.pairedDepIDs = ownerIDs;
      });

      let response = await DataStore.save(newCategory);

      if (response === null) {
        console.error('setDepartmentCategoryOwners: ', response);
        return {
          type: ResponseType.Failure,
          data: response,
        };
      }

      if (globals.debug)
        console.log(
          'Successfuly set owners for category: ',
          i++,
          ' / ',
          n,
          ' -> ',
          category.name
        );
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    console.error('setDepartmentCategoryOwners: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteDepartmentIDFromAllReferences = async (
  id: string
): Promise<Response> => {
  try {
    let n = 0;
    /* Load all the models that have the pairedDepIDs */
    // const departments = await DataStore.query(Department, (c) =>
    //   c.subDepIDs.contains(id)
    // );
    // const users = await DataStore.query(User, (c) =>
    //   c.pairedDepIDs.contains(id)
    // );

    // const categories = await DataStore.query(Category, (c) =>
    //   c.pairedDepIDs.contains(id)
    // );
    // const protocols = await DataStore.query(Protocol, (c) =>
    //   c.pairedDepIDs.contains(id)
    // );
    // const contacts = await DataStore.query(Contact, (c) =>
    //   c.pairedDepIDs.contains(id)
    // );
    // const inputForms = await DataStore.query(InputForm, (c) =>
    //   c.pairedDepIDs.contains(id)
    // );
    const departments: Department[] = await executeQuery(listDepartments, {
      filter: {
        subDepIDs: { contains: id },
      },
    });
    const users: User[] = await executeQuery(listUsers, {
      filter: {
        pairedDepIDs: { contains: id },
      },
    });
    const notifications: Notification[] = await executeQuery(
      listNotifications,
      {
        filter: {
          pairedDepIDs: { contains: id },
        },
      }
    );
    const categories: Category[] = await executeQuery(listCategories, {
      filter: {
        pairedDepIDs: { contains: id },
      },
    });
    const protocols: Protocol[] = await executeQuery(listProtocols, {
      filter: {
        pairedDepIDs: { contains: id },
      },
    });
    const contacts: Contact[] = await executeQuery(listContacts, {
      filter: {
        pairedDepIDs: { contains: id },
      },
    });
    const inputForms: InputForm[] = await executeQuery(listInputForms, {
      filter: {
        pairedDepIDs: { contains: id },
      },
    });

    /* Search through the departments and update the IDs */
    for (let i = 0; i < departments.length; i++) {
      const dep = departments[i];
      let depIDs: string[] = [];
      let isChanged = false;
      if (dep.subDepIDs == null) continue;
      for (let i = 0; i < dep.subDepIDs.length; i++) {
        const depID = dep.subDepIDs[i];
        if (depID === id) {
          n++;
          isChanged = true;
        } else depIDs.push(depID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the Department table */
        const newDep = Department.copyOf(dep, (updated) => {
          updated.subDepIDs = depIDs;
        });
        let response = await DataStore.save(newDep);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in Department table: ',
              newDep.name,
              '->',
              n
            );
        }
      }
    }

    /* Search through the users and update the IDs */
    // for (let i = 0; i < users.length; i++) {
    //   const user = users[i];
    //   let userIDs: string[] = [];
    //   let isChanged = false;
    //   if (user.pairedDepIDs == null) continue;
    //   for (let i = 0; i < user.pairedDepIDs.length; i++) {
    //     const userID = user.pairedDepIDs[i];
    //     if (userID === id) {
    //       n++;
    //       isChanged = true;
    //     } else userIDs.push(userID);
    //   }
    //   if (isChanged) {
    //     /* Save the new pairedDepIDs array to the User table */
    //     const newUser = User.copyOf(user, (updated) => {
    //       updated.pairedDepIDs = userIDs;
    //     });
    //     let response = await DataStore.save(newUser);
    //     if (response === null) {
    //       console.error('deleteDepartmentIDFromAllReferences: ', response);
    //     } else {
    //       if (globals.debug)
    //         console.log(
    //           'Changed item in User table: ',
    //           newUser.firstName,
    //           newUser.lastName,
    //           '->',
    //           n
    //         );
    //     }
    //   }
    // }

    /* Search through the notifications and update the IDs */
    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i];
      let notificationIDs: string[] = [];
      let isChanged = false;
      if (notification.pairedDepIDs == null) continue;
      for (let i = 0; i < notification.pairedDepIDs.length; i++) {
        const notificationID = notification.pairedDepIDs[i];
        if (notificationID === id) {
          n++;
          isChanged = true;
        } else notificationIDs.push(notificationID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the Notification table */
        // const newNotification = Notification.copyOf(notification, (updated) => {
        //   updated.pairedDepIDs = notificationIDs;
        // });
        // let response = await DataStore.save(newNotification);
        const response = await executeSingleQuery(updateNotification, {
          id: notification.id,
          _version: notification._version,
          pairedDepIDs: notificationIDs,
        });
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in Notification table: ',
              notification.title,
              '->',
              n
            );
        }
      }
    }

    /* Search through the contacts and update the IDs */
    for (let i = 0; i < contacts.length; i++) {
      const contact = contacts[i];
      let contactIDs: string[] = [];
      let isChanged = false;
      if (contact.pairedDepIDs == null) continue;
      for (let i = 0; i < contact.pairedDepIDs.length; i++) {
        const contactID = contact.pairedDepIDs[i];
        if (contactID === id) {
          n++;
          isChanged = true;
        } else contactIDs.push(contactID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the Contact table */
        const newContact = Contact.copyOf(contact, (updated) => {
          updated.pairedDepIDs = contactIDs;
        });
        let response = await DataStore.save(newContact);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in Contact table: ',
              newContact.fullName,
              '->',
              n
            );
        }
      }
    }

    /* Search through the inputForms and update the IDs */
    for (let i = 0; i < inputForms.length; i++) {
      const inputForm = inputForms[i];
      let inputFormIDs: string[] = [];
      let isChanged = false;
      if (inputForm.pairedDepIDs == null) continue;
      for (let i = 0; i < inputForm.pairedDepIDs.length; i++) {
        const inputFormID = inputForm.pairedDepIDs[i];
        if (inputFormID === id) {
          n++;
          isChanged = true;
        } else inputFormIDs.push(inputFormID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the InputForm table */
        const newInputForm = InputForm.copyOf(inputForm, (updated) => {
          updated.pairedDepIDs = inputFormIDs;
        });
        let response = await DataStore.save(newInputForm);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in InputForm table: ',
              newInputForm.name,
              '->',
              n
            );
        }
      }
    }

    /* Search through the categories and update the IDs */
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      let categoryIDs: string[] = [];
      let isChanged = false;
      if (category.pairedDepIDs == null) continue;
      for (let i = 0; i < category.pairedDepIDs.length; i++) {
        const categoryID = category.pairedDepIDs[i];
        if (categoryID === id) {
          n++;
          isChanged = true;
        } else categoryIDs.push(categoryID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the Category table */
        const newCategory = Category.copyOf(category, (updated) => {
          updated.pairedDepIDs = categoryIDs;
        });
        let response = await DataStore.save(newCategory);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in Category table: ',
              newCategory.name,
              '->',
              n
            );
        }
      }
    }

    /* Search through the protocols and update the IDs */
    for (let i = 0; i < protocols.length; i++) {
      const protocol = protocols[i];
      let protocolIDs: string[] = [];
      let isChanged = false;
      if (protocol.pairedDepIDs == null) continue;
      for (let i = 0; i < protocol.pairedDepIDs.length; i++) {
        const protocolID = protocol.pairedDepIDs[i];
        if (protocolID === id) {
          n++;
          isChanged = true;
        } else protocolIDs.push(protocolID);
      }
      if (isChanged) {
        /* Save the new pairedDepIDs array to the Protocol table */
        const newProtocol = Protocol.copyOf(protocol, (updated) => {
          updated.pairedDepIDs = protocolIDs;
        });
        let response = await DataStore.save(newProtocol);
        if (response === null) {
          console.error('deleteDepartmentIDFromAllReferences: ', response);
        } else {
          if (globals.debug)
            console.log(
              'Changed item in Protocol table: ',
              newProtocol.name,
              '->',
              n
            );
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const deleteIDFromAllReferences = async (
  department: DepartmentItem,
  id: string
): Promise<Response> => {
  try {
    let n = 0;
    /* Load all the medications and drips*/
    // const medications = await DataStore.query(Medication, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const drips = await DataStore.query(Drip, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const equipments = await DataStore.query(Equipment, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const electricals = await DataStore.query(Electrical, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const forms = await DataStore.query(Form, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const categories = await DataStore.query(Category, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    const medications: Medication[] = await executeQuery(
      medicationsByDepartmentID,
      {
        departmentID: department.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );
    const drips: Drip[] = await executeQuery(dripsByDepartmentID, {
      departmentID: department.id,
      filter: {
        and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
      },
    });
    const equipments: Equipment[] = await executeQuery(
      equipmentByDepartmentID,
      {
        departmentID: department.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );
    const electricals: ElectricalShock[] = await executeQuery(
      electricalShocksByDepartmentID,
      {
        departmentID: department.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );
    const forms: Form[] = await executeQuery(formsByDepartmentID, {
      departmentID: department.id,
      filter: {
        and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
      },
    });
    const categories: Category[] = await executeQuery(
      categoriesByDepartmentID,
      {
        departmentID: department.id,
        filter: {
          and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
        },
      }
    );

    let protocols: Protocol[] = [];
    for (const category of categories) {
      // const catProtocols = await DataStore.query(Protocol, (c) =>
      //   c.categoryID.eq(category.id)
      // );
      const catProtocols: Protocol[] = await executeQuery(
        protocolsByCategoryID,
        {
          categoryID: category.id,
          filter: {
            and: [{ status: { eq: 'ACTIVE' } }, { _deleted: { ne: true } }],
          },
        }
      );
      protocols = [...protocols, ...catProtocols];
    }

    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      // let categoryProtIDs: string[] = [];
      let pairedDepIDs: string[] = [];
      // let subCategoryIDs: string[] = [];
      let isChanged = false;

      // if(category.pairedProtocols == null) continue;
      // for(let i = 0; i < category.pairedProtocols.length; i++) {
      //     const categoryProtID = category.pairedProtocols[i];
      //     if(categoryProtID === id) {
      //         n++;
      //         isChanged = true;
      //     }
      //     else
      //         categoryProtIDs.push(categoryProtID);
      // }

      if (category.pairedDepIDs == null) continue;
      for (let i = 0; i < category.pairedDepIDs.length; i++) {
        const pairedDepID = category.pairedDepIDs[i];
        if (pairedDepID === id) {
          n++;
          isChanged = true;
        } else pairedDepIDs.push(pairedDepID);
      }

      // if(category.subCategories == null) continue;
      // for(let i = 0; i < category.subCategories.length; i++) {
      //     const subCategoryID = category.subCategories[i];
      //     if(subCategoryID == null) continue;
      //     if(subCategoryID === id) {
      //         n++;
      //         isChanged = true;
      //     }
      //     else
      //         subCategoryIDs.push(subCategoryID);
      // }

      if (isChanged) {
        /* Save the new MedicationProtocol array to the Medication table */
        const newCategory = Category.copyOf(category, (updated) => {
          // updated.pairedProtocols = categoryProtIDs;
          updated.pairedDepIDs = pairedDepIDs;
          // updated.subCategories = subCategoryIDs;
        });
        let response = await DataStore.save(newCategory);
        if (response === null) {
          console.error('deleteIDFromAllReferences: ', response);
        }
      }
    }

    /* Search for the medication and drips in the old protocol ID in the MedicationProtocol and MedicationRange tables */
    for (let i = 0; i < medications.length; i++) {
      const med = medications[i];
      let medProts: MedicationProtocol[] = [];
      let isChanged = false;
      for (let i = 0; i < med.protocolOptions.length; i++) {
        const medProt: MedicationProtocol = med.protocolOptions[i];
        if (medProt.protocolID === id) {
          /* Delete the MedicationProtocol */
          n++;
          isChanged = true;
        } else medProts.push(medProt);
      }
      if (isChanged) {
        /* Save the new MedicationProtocol array to the Medication table */
        const newMed = Medication.copyOf(med, (updated) => {
          updated.protocolOptions = medProts;
        });
        let response = await DataStore.save(newMed);
        if (response === null) {
          console.error('deleteIDFromAllReferences: ', response);
        }
      }
    }

    for (let i = 0; i < drips.length; i++) {
      const drip = drips[i];
      let dripProts: MedicationProtocol[] = [];
      let isChanged = false;
      for (let i = 0; i < drip.dripOptions.length; i++) {
        const dripProt = drip.dripOptions[i];
        if (!dripProt) continue;
        if (dripProt.protocolID === id) {
          n++;
          isChanged = true;
        } else dripProts.push(dripProt);
      }
      if (isChanged) {
        /* Save the new MedicationProtocol array to the Medication table */
        const newDrip = Drip.copyOf(drip, (updated) => {
          updated.dripOptions = dripProts;
        });
        let response = await DataStore.save(newDrip);
        if (response === null) {
          console.error('deleteIDFromAllReferences: ', response);
        }
      }
    }

    // for(let i = 0; i < protocols.length; i++) {
    //     const protocol = protocols[i];
    //     let protocolProtIDs: string[] = [];
    //     let isChanged = false;

    //     /* Check for */
    //     for(let i = 0; i < protocol.pairedProtocols.length; i++) {
    //         const protocolProtID = protocol.pairedProtocols[i];
    //         if(protocolProtID === id) {
    //             n++;
    //             isChanged = true;
    //             protocolProtIDs.push("");
    //         }
    //         else
    //             protocolProtIDs.push(protocolProtID);
    //     }
    //     if(isChanged) {
    //         /* Save the new MedicationProtocol array to the Medication table */
    //         const newProtocol = Protocol.copyOf(protocol, updated => {
    //             updated.pairedProtocols = protocolProtIDs;
    //         });
    //         let response = await DataStore.save(newProtocol);
    //         if(response === null) {
    //             console.error("deleteIDFromAllReferences: ", response);
    //         }
    //     }
    // }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertToNewDatabaseLogic = async (
  department: DepartmentItem,
  allSubDeps: DepartmentItem[]
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let promises = [
        convertAllMedicationRangestoMedicationDoses(department),
        convertAllMedicationRangestoInfusionDoses(department),
        convertAllElectricalShockRangestoElectricalDoses(department),
        convertAllMedicationConcentrationsToConcentrationTables(department),
        addDepartmentIDToAllReferences(department, department.id),
        addDepartmentIDToUsersDepAdminPrivleges(department, department.id),
      ];

      let values = await Promise.all(promises);
      console.log('convertToNewDatabaseLogic: ', values);

      promises = [];
      if (allSubDeps && allSubDeps.length > 0) {
        for (let i = 0; i < allSubDeps.length; i++) {
          const subDep = allSubDeps[i];
          promises.push(
            addDepartmentIDToUsersDepAdminPrivleges(subDep, subDep.id)
          );
          promises.push(copyConcentrationsToSubDepartment(department, subDep));
        }

        let subValues = await Promise.all(promises);
        values = [...values, ...subValues];
      }

      resolve({
        type: ResponseType.Success,
        data: values,
      });
    } catch (error) {
      console.error('convertToNewDatabaseLogic: ', error);
      reject(error);
    }
  });
};

export const convertAllMedicationRangestoMedicationDoses = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    // First load all the medications from the department DRAFT, ACTIVE, and ARCHIVED
    let allMedicationDoses: MedicationDose[] = [];
    let allMeds = await DataStore.query(Medication, (c) =>
      c.and((c) => [c.departmentID.eq(department.id), c.status.eq('ACTIVE')])
    );
    let currentMedicationDoses = await DataStore.query(MedicationDose, (c) =>
      c.departmentID.eq(department.id)
    );
    console.log('All Meds: ', allMeds);
    console.log('Current Med Doses: ', currentMedicationDoses);
    for (let i = 0; i < allMeds.length; i++) {
      let med = allMeds[i];
      console.log('Updating medication', med.name, med.protocolOptions.length);
      if (med.protocolOptions.length === 0) continue;
      let doses: MedicationDose[] = [];
      let newProtocolOptions: MedicationProtocol[] = [];
      for (let j = 0; j < med.protocolOptions.length; j++) {
        let medProt = med.protocolOptions[j];

        let prot = await DataStore.query(Protocol, medProt.protocolID);
        if (prot == null) {
          console.error(
            'PROTOCOL/CPR NOT FOUND IN DATABASE: ',
            medProt.protocolID,
            '... Skipping'
          );
          continue;
        }

        console.log('Med Prot: ', medProt);
        let newMedProt = new MedicationProtocol({
          protocolID: medProt.protocolID,
          options: medProt.options,
        });
        for (let k = 0; k < medProt.options.length; k++) {
          let medRange = medProt.options[k];
          console.log('Med Range: ', medRange);
          let newMedDose = new MedicationDose({
            departmentID: department.id,
            medicationID: med.id,
            protocolID: medProt.protocolID,
            rangeLow: medRange.rangeLow,
            rangeHigh: medRange.rangeHigh,
            createdBy: 'OneBot',
            basis: medRange.basis,
            route: medRange.route,
            nemsisRoutes: medRange.nemsisRoutes,
            title: medRange.title,
            warning: medRange.warning,
            instruction: medRange.instruction,
            note: medRange.note,
            maxDose: medRange.maxDose,
            minDose: medRange.minDose,
            maxTotalDose: medRange.maxTotalDose,
            calcMax: medRange.calcMax,
            calcMin: medRange.calcMin,
            index: medRange.index,
            ageLow: medRange.ageLow,
            ageHigh: medRange.ageHigh,
            ageGroup: medRange.ageGroup,
            repeatTime: medRange.repeatTime,

            activeID: null,
            status: ProgressStatus.ACTIVE,
            version: 'v1.0.0',
            createdAt: new Date().toISOString(),
          });

          let currentDose = currentMedicationDoses.find(
            (dose) =>
              dose.medicationID === med.id &&
              dose.protocolID === medProt.protocolID &&
              dose.rangeLow === medRange.rangeLow &&
              dose.rangeHigh === medRange.rangeHigh &&
              dose.basis === medRange.basis &&
              dose.route.map((r) => r.toLocaleLowerCase()).join('') ===
                medRange.route.map((r) => r.toLocaleLowerCase()).join('')
          );
          if (currentDose == null) {
            console.log('Creating New Dose: ', newMedDose);
            let response = await DataStore.save(newMedDose);
            //We need to update the medicationDoseID in the medication table
            if (response === null) {
              console.error(
                'convertAllMedicationRangestoMedicationDoses: ',
                response
              );
            }
            doses.push(newMedDose);

            //Update the medicationDoseID in the medication table
            let newMedRange = new MedicationRange({
              doseID: newMedDose.id,

              rangeLow: medRange.rangeLow,
              rangeHigh: medRange.rangeHigh,
              basis: medRange.basis,
              route: medRange.route,
              nemsisRoutes: medRange.nemsisRoutes,
              title: medRange.title,
              warning: medRange.warning,
              instruction: medRange.instruction,
              note: medRange.note,
              maxDose: medRange.maxDose,
              minDose: medRange.minDose,
              maxTotalDose: medRange.maxTotalDose,
              calcMax: medRange.calcMax,
              calcMin: medRange.calcMin,
              index: medRange.index,
              ageLow: medRange.ageLow,
              ageHigh: medRange.ageHigh,
              ageGroup: medRange.ageGroup,
              repeatTime: medRange.repeatTime,
            });

            console.log('New Med Range: ', newMedRange);

            //replace the old medicationRange with the new one
            newMedProt = new MedicationProtocol({
              protocolID: newMedProt.protocolID,
              options: [
                ...newMedProt.options.slice(0, k),
                newMedRange,
                ...newMedProt.options.slice(k + 1),
              ],
            });

            console.log('New Med Prot: ', newMedProt);
          } else {
            console.log('FOUND DUPLICATE DOSE: ', currentDose);
          }
        }

        newProtocolOptions.push(newMedProt);
      }

      //Update the medication with the new protocol options
      let newMed = Medication.copyOf(med, (updated) => {
        updated.protocolOptions = newProtocolOptions;
      });
      let response = await DataStore.save(newMed);
      console.log('Updated Medication: ', newMed);
      if (response === null) {
        console.error(
          'convertAllMedicationRangestoMedicationDoses: ',
          response
        );
      }

      allMedicationDoses = [...allMedicationDoses, ...doses];
      // if (allMedicationDoses.length > 0) {
      //   break;
      // }
    }

    console.log('Created Medication Doses: ', allMedicationDoses);

    return {
      type: ResponseType.Success,
      data: allMedicationDoses,
    };
  } catch (error: any) {
    console.error('convertAllMedicationRangestoMedicationDoses: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Convert all the medication (infusion) ranges to infusion doses
 * @param department The department to convert the medication (infusion) ranges to infusion doses
 * @returns
 */
export const convertAllMedicationRangestoInfusionDoses = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    // First load all the medications from the department DRAFT, ACTIVE, and ARCHIVED
    let allInfusionDoses: InfusionDose[] = [];
    let allInfus = await DataStore.query(Drip, (c) =>
      c.and((c) => [c.departmentID.eq(department.id), c.status.eq('ACTIVE')])
    );
    let currentMedicationDoses = await DataStore.query(InfusionDose, (c) =>
      c.departmentID.eq(department.id)
    );
    console.log('All Meds: ', allInfus);
    console.log('Current Med Doses: ', currentMedicationDoses);
    for (let i = 0; i < allInfus.length; i++) {
      let infus = allInfus[i];
      console.log('Updating infusion', infus.name, infus.dripOptions.length);
      if (infus.dripOptions.length === 0) continue;
      let doses: InfusionDose[] = [];
      let newProtocolOptions: MedicationProtocol[] = [];
      for (let j = 0; j < infus.dripOptions.length; j++) {
        let medProt = infus.dripOptions[j];

        let prot = await DataStore.query(Protocol, medProt.protocolID);
        if (prot == null) {
          console.error(
            'PROTOCOL NOT FOUND IN DATABASE: ',
            medProt.protocolID,
            '... Skipping'
          );
          continue;
        }

        console.log('Med Prot: ', medProt);
        let newMedProt = new MedicationProtocol({
          protocolID: medProt.protocolID,
          options: medProt.options,
        });
        for (let k = 0; k < medProt.options.length; k++) {
          let medRange = medProt.options[k];
          console.log('Med Range: ', medRange);
          let newInfusDose = new InfusionDose({
            departmentID: department.id,
            dripID: infus.id,
            protocolID: medProt.protocolID,
            rangeLow: medRange.rangeLow,
            rangeHigh: medRange.rangeHigh,
            createdBy: 'OneBot',
            basis: medRange.basis,
            route: medRange.route,
            nemsisRoutes: medRange.nemsisRoutes,
            title: medRange.title,
            warning: medRange.warning,
            instruction: medRange.instruction,
            note: medRange.note,
            maxDose: medRange.maxDose,
            minDose: medRange.minDose,
            maxTotalDose: medRange.maxTotalDose,
            calcMax: medRange.calcMax,
            calcMin: medRange.calcMin,
            index: medRange.index,
            ageLow: medRange.ageLow,
            ageHigh: medRange.ageHigh,
            ageGroup: medRange.ageGroup,
            repeatTime: medRange.repeatTime,

            activeID: null,
            status: ProgressStatus.ACTIVE,
            version: 'v1.0.0',
            createdAt: new Date().toISOString(),
          });

          let currentDose = currentMedicationDoses.find(
            (dose) =>
              dose.dripID === infus.id &&
              dose.protocolID === medProt.protocolID &&
              dose.rangeLow === medRange.rangeLow &&
              dose.rangeHigh === medRange.rangeHigh &&
              dose.basis === medRange.basis &&
              dose.route.map((r) => r.toLocaleLowerCase()).join('') ===
                medRange.route.map((r) => r.toLocaleLowerCase()).join('')
          );
          if (currentDose == null) {
            console.log('Creating New Dose: ', newInfusDose);
            let response = await DataStore.save(newInfusDose);
            //We need to update the medicationDoseID in the medication table
            if (response === null) {
              console.error(
                'convertAllMedicationRangestoMedicationDoses: ',
                response
              );
            }
            doses.push(newInfusDose);

            //Update the medicationDoseID in the medication table
            let newMedRange = new MedicationRange({
              doseID: newInfusDose.id,

              rangeLow: medRange.rangeLow,
              rangeHigh: medRange.rangeHigh,
              basis: medRange.basis,
              route: medRange.route,
              nemsisRoutes: medRange.nemsisRoutes,
              title: medRange.title,
              warning: medRange.warning,
              instruction: medRange.instruction,
              note: medRange.note,
              maxDose: medRange.maxDose,
              minDose: medRange.minDose,
              maxTotalDose: medRange.maxTotalDose,
              calcMax: medRange.calcMax,
              calcMin: medRange.calcMin,
              index: medRange.index,
              ageLow: medRange.ageLow,
              ageHigh: medRange.ageHigh,
              ageGroup: medRange.ageGroup,
              repeatTime: medRange.repeatTime,
            });

            console.log('New Med Range: ', newMedRange);

            //replace the old medicationRange with the new one
            newMedProt = new MedicationProtocol({
              protocolID: newMedProt.protocolID,
              options: [
                ...newMedProt.options.slice(0, k),
                newMedRange,
                ...newMedProt.options.slice(k + 1),
              ],
            });

            console.log('New Med Prot: ', newMedProt);
          } else {
            console.log('FOUND DUPLICATE DOSE: ', currentDose);
          }
        }

        newProtocolOptions.push(newMedProt);
      }

      //Update the medication with the new protocol options
      let newMed = Drip.copyOf(infus, (updated) => {
        updated.dripOptions = newProtocolOptions;
      });
      let response = await DataStore.save(newMed);
      console.log('Updated Infusion: ', newMed);
      if (response === null) {
        console.error(
          'convertAllMedicationRangestoMedicationDoses: ',
          response
        );
      }

      allInfusionDoses = [...allInfusionDoses, ...doses];
      // if (allInfusionDoses.length > 0) {
      //   break;
      // }
    }

    console.log('Created Infusion Doses: ', allInfusionDoses);

    return {
      type: ResponseType.Success,
      data: allInfusionDoses,
    };
  } catch (error: any) {
    console.error('convertAllMedicationRangestoMedicationDoses: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Convert all the medication (infusion) ranges to infusion doses
 * @param department The department to convert the medication (infusion) ranges to infusion doses
 * @returns
 */
export const convertAllElectricalShockRangestoElectricalDoses = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    // First load all the medications from the department DRAFT, ACTIVE, and ARCHIVED
    let allElectricalDoses: ElectricalDose[] = [];
    let allElecs = await DataStore.query(ElectricalShock, (c) =>
      c.and((c) => [c.departmentID.eq(department.id), c.status.eq('ACTIVE')])
    );
    let currentMedicationDoses = await DataStore.query(ElectricalDose, (c) =>
      c.departmentID.eq(department.id)
    );
    console.log('All Meds: ', allElecs);
    console.log('Current Electrical Doses: ', currentMedicationDoses);
    for (let i = 0; i < allElecs.length; i++) {
      let elec = allElecs[i];
      console.log('Updating electrical', elec.title, elec.options.length);
      if (elec.options.length === 0) continue;
      let doses: ElectricalDose[] = [];
      let newShockOptions: ElectricalShockOption[] = [];
      for (let j = 0; j < elec.options.length; j++) {
        let shcokOption = elec.options[j];

        let prot = await DataStore.query(Protocol, shcokOption.protocolID);
        if (prot == null) {
          console.error(
            'PROTOCOL NOT FOUND IN DATABASE: ',
            shcokOption.protocolID,
            '... Skipping'
          );
          continue;
        }

        console.log('Med Prot: ', shcokOption);
        let newShockOption = new ElectricalShockOption({
          protocolID: shcokOption.protocolID,
          ranges: shcokOption.ranges,
        });
        for (let k = 0; k < newShockOption.ranges.length; k++) {
          let elecRange = newShockOption.ranges[k];
          let newShockDose = new ElectricalDose({
            departmentID: department.id,
            electricalID: elec.id,
            protocolID: shcokOption.protocolID,
            rangeLow: elecRange.rangeLow,
            rangeHigh: elecRange.rangeHigh,
            createdBy: 'OneBot',
            basis: elecRange.basis,
            title: elecRange.title,
            warning: elecRange.warning,
            instruction: elecRange.instruction,
            note: elecRange.note,
            maxDose: elecRange.fixedMax,
            minDose: undefined,
            maxTotalDose: undefined,
            calcMax: elecRange.calcMax,
            calcMin: undefined,
            index: elecRange.index,
            ageLow: elecRange.ageLow,
            ageHigh: elecRange.ageHigh,
            ageGroup: elecRange.ageGroup,
            repeatTime: undefined,

            activeID: null,
            status: ProgressStatus.ACTIVE,
            version: 'v1.0.0',
            createdAt: new Date().toISOString(),
          });

          let currentDose = currentMedicationDoses.find(
            (dose) =>
              dose.electricalID === elec.id &&
              dose.protocolID === shcokOption.protocolID &&
              dose.rangeLow === elecRange.rangeLow &&
              dose.rangeHigh === elecRange.rangeHigh &&
              dose.basis === elecRange.basis &&
              dose.title === elecRange.title
          );
          if (currentDose == null) {
            console.log('Creating New Dose: ', newShockDose);
            let response = await DataStore.save(newShockDose);
            //We need to update the medicationDoseID in the medication table
            if (response === null) {
              console.error(
                'convertAllMedicationRangestoMedicationDoses: ',
                response
              );
            }
            doses.push(newShockDose);

            //Update the medicationDoseID in the medication table
            let newElecRange = new ElectricalShockRange({
              doseID: newShockDose.id,

              rangeLow: elecRange.rangeLow,
              rangeHigh: elecRange.rangeHigh,
              basis: elecRange.basis,
              title: elecRange.title,
              warning: elecRange.warning,
              instruction: elecRange.instruction,
              note: elecRange.note,
              fixedMax: elecRange.fixedMax,
              calcMax: elecRange.calcMax,
              index: elecRange.index,
              ageLow: elecRange.ageLow,
              ageHigh: elecRange.ageHigh,
              ageGroup: elecRange.ageGroup,
            });

            console.log('New Elec Range: ', newElecRange);

            //replace the old medicationRange with the new one
            newShockOption = new ElectricalShockOption({
              protocolID: shcokOption.protocolID,
              ranges: [
                ...shcokOption.ranges.slice(0, k),
                newElecRange,
                ...shcokOption.ranges.slice(k + 1),
              ],
            });

            console.log('New Shock Option: ', newShockOption);
          } else {
            console.log('FOUND DUPLICATE DOSE: ', currentDose);
          }
        }

        newShockOptions.push(newShockOption);
      }

      //Update the medication with the new protocol options
      let newMed = ElectricalShock.copyOf(elec, (updated) => {
        updated.options = newShockOptions;
      });
      let response = await DataStore.save(newMed);
      console.log('Updated Electrical: ', newMed);
      if (response === null) {
        console.error(
          'convertAllMedicationRangestoMedicationDoses: ',
          response
        );
      }

      allElectricalDoses = [...allElectricalDoses, ...doses];
      // if (allElectricalDoses.length > 0) {
      //   break;
      // }
    }

    console.log('Created Electrical Doses: ', allElectricalDoses);

    return {
      type: ResponseType.Success,
      data: allElectricalDoses,
    };
  } catch (error: any) {
    console.error('convertAllMedicationRangestoMedicationDoses: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertAllMedicationConcentrationsToConcentrationTables = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let allMeds = await DataStore.query(Medication, (c) =>
      c.departmentID.eq(department.id)
    );
    let allInfusions = await DataStore.query(Drip, (c) =>
      c.departmentID.eq(department.id)
    );
    let allItems = [...allMeds, ...allInfusions];
    let allConcentrations: Concentration[] = [];
    let currentConcentrations = await DataStore.query(Concentration, (c) =>
      c.departmentID.eq(department.id)
    );

    for (let i = 0; i < allItems.length; i++) {
      let med = allItems[i];
      if (med.concentration == null) continue;
      console.log(
        'Updating ' + (med instanceof Medication ? 'Medication' : 'Drip'),
        med.name,
        med.concentration.length
      );
      if (med.concentration.length === 0) continue;

      let concentrations: Concentration[] = [];
      for (let j = 0; j < med.concentration.length; j++) {
        let concen = med.concentration[j];
        let found = currentConcentrations.find(
          (c) =>
            (med.id === c.medicationID || med.id === c.dripID) &&
            c.firstAmnt === concen.firstAmnt &&
            c.firstUnit === concen.firstUnit &&
            c.secAmnt === concen.secAmnt &&
            c.secUnit === concen.secUnit &&
            c.color === concen.color
        );
        if (found) {
          console.log('FOUND DUPLICATE CONCENTRATION: ', found);
          continue;
        } else {
          let newConcentration = new Concentration({
            departmentID: department.id,
            medicationID: med instanceof Medication ? med.id : undefined,
            dripID: med instanceof Drip ? med.id : undefined,
            firstAmnt: concen.firstAmnt,
            firstUnit: concen.firstUnit,
            secAmnt: concen.secAmnt,
            secUnit: concen.secUnit,
            color: concen.color,
            status: med.status,
          });
          let response = await DataStore.save(newConcentration);
          if (response === null) {
            console.error(
              'convertAllMedicationConcentrationsToConcentrationTables: ',
              response
            );
          }
          concentrations.push(newConcentration);
        }
      }

      allConcentrations = [...allConcentrations, ...concentrations];
      // if (allConcentrations.length > 0) {
      //   break;
      // }
    }

    console.log('Created Concentrations: ', allConcentrations);
    return {
      type: ResponseType.Success,
      data: allConcentrations,
    };
  } catch (error: any) {
    console.error(
      'convertAllMedicationConcentrationsToConcentrationTables: ',
      error
    );
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const auditIsRestrictiveStatus = async (
  department: DepartmentItem,
  subDeps: DepartmentItem[]
): Promise<Response> => {
  try {
    let allCats = await DataStore.query(Category, (c) =>
      c.departmentID.eq(department.id)
    );
    let allProtocols = await DataStore.query(Protocol, (c) =>
      c.departmentID.eq(department.id)
    );
    if (subDeps == null || subDeps.length === 0)
      return {
        type: ResponseType.Success,
        data: 0,
      };

    let n = 0;
    for (let i = 0; i < allCats.length; i++) {
      let cat = allCats[i];
      if (cat.isRestrictive == null || cat.isRestrictive === false) {
        // console.log('Category is Inclusive: ', cat);
        if (
          cat.pairedDepIDs != null &&
          !(
            compareToStringArray(
              cat.pairedDepIDs,
              subDeps.map((dep) => dep.id)
            ) ||
            compareToStringArray(cat.pairedDepIDs, [
              ...subDeps.map((dep) => dep.id),
              department.id,
            ])
          )
        ) {
          console.log('UPDATING CATEGORY TO RESTRICTIVE: ', cat);
          //Update to isRestrictive
          let newCat = Category.copyOf(cat, (updated) => {
            updated.isRestrictive = true;
          });
          DataStore.save(newCat);
          n++;
        }
      } else {
        //Make sure that the item should be restrictive
        if (cat.pairedDepIDs == null) {
          console.error('Protocol pairedDepIDs is null: ', cat);
          continue;
        }
        if (
          compareToStringArray(
            cat.pairedDepIDs,
            subDeps.map((dep) => dep.id)
          ) ||
          compareToStringArray(cat.pairedDepIDs, [
            ...subDeps.map((dep) => dep.id),
            department.id,
          ])
        ) {
          console.error('Category is not restrictive: ', cat);
          let newProt = Category.copyOf(cat, (updated) => {
            updated.isRestrictive = false;
          });
          DataStore.save(newProt);
          n++;
        }
      }
    }

    for (let i = 0; i < allProtocols.length; i++) {
      let prot = allProtocols[i];
      let cat = allCats.find((c) => c.id === prot.categoryID);
      let draftCat = allCats.find(
        (c) => c.activeID === prot.categoryID && c.status === 'DRAFT'
      );
      if (draftCat) cat = draftCat;
      if (cat == null) {
        console.error('Category not found for protocol: ', prot);
        continue;
      }
      let deps = !cat.isRestrictive
        ? subDeps
        : subDeps.filter((dep) => cat?.pairedDepIDs?.includes(dep.id) ?? false);
      if (prot.isRestrictive == null || prot.isRestrictive === false) {
        // console.log('Protocol is Inclusive: ', cat);
        if (
          prot.pairedDepIDs != null &&
          !(
            compareToStringArray(
              prot.pairedDepIDs,
              deps.map((dep) => dep.id)
            ) ||
            compareToStringArray(prot.pairedDepIDs, [
              ...deps.map((dep) => dep.id),
              department.id,
            ])
          )
        ) {
          console.log('UPDATING PROTOCOL TO RESTRICTIVE: ', prot);
          //Update to isRestrictive
          let newProt = Protocol.copyOf(prot, (updated) => {
            updated.isRestrictive = true;
          });
          DataStore.save(newProt);
          n++;
        }
      } else {
        console.log('Paired Deps: ', prot.pairedDepIDs);
        console.log(
          'Sub Deps: ',
          deps.map((dep) => dep.id)
        );
        //Make sure that the item should be restrictive
        if (prot.pairedDepIDs == null) {
          console.error('Protocol pairedDepIDs is null: ', prot);
          continue;
        }
        if (
          compareToStringArray(
            prot.pairedDepIDs,
            deps.map((dep) => dep.id)
          ) ||
          compareToStringArray(prot.pairedDepIDs, [
            ...deps.map((dep) => dep.id),
            department.id,
          ])
        ) {
          console.error('Protocol is not restrictive: ', prot);
          let newProt = Protocol.copyOf(prot, (updated) => {
            updated.isRestrictive = false;
          });
          DataStore.save(newProt);
          n++;
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error: any) {
    console.error(
      'convertAllMedicationConcentrationsToConcentrationTables: ',
      error
    );
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const compareToStringArray = (arr1: string[], arr2: string[]): boolean => {
  //Check if the arrays are the same length and all the elements are the same
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const auditDoseDatabaseToOldDBStyle = async (
  meds: MedicationSubItem[],
  infusions: InfusionSubItem[],
  electricals: ElectricalSubItem[]
): Promise<Response> => {
  try {
    let promises = [
      auditMedicationDosesToOldDBStyle(meds),
      auditInfusionDosesToOldDBStyle(infusions),
      auditElectricalDosesToOldDBStyle(electricals),
      auditProtocolPairings(meds, infusions, electricals),
    ];
    let results = await Promise.all(promises);

    let n = 0;
    for (let i = 0; i < results.length; i++) {
      n += results[i].data;
    }
    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const auditMedicationDosesToOldDBStyle = async (
  doses: MedicationSubItem[]
): Promise<Response> => {
  try {
    if (doses.length === 0)
      return {
        type: ResponseType.Success,
        data: 0,
      };
    let groupedMeds: { [key: string]: MedicationSubItem[] } = {};
    // let groupedProtocols: { [key: string]: string[] } = {};
    let n = 0;
    for (let i = 0; i < doses.length; i++) {
      const dose = doses[i];
      let medID = dose.parent.activeID ?? dose.parent.uid;
      if (medID == null) continue;
      if (groupedMeds[medID] == null) {
        groupedMeds[medID] = [];
      }
      groupedMeds[medID].push(dose);

      // let protocolID = dose.parentProtocol.activeID ?? dose.parentProtocol.uid;
      // if (protocolID == null) continue;
      // if (groupedProtocols[protocolID] == null) {
      //   groupedProtocols[protocolID] = [];
      // }
      // if (!groupedProtocols[protocolID].includes(medID)) {
      //   groupedProtocols[protocolID].push(medID);
      // }
    }

    // console.log('Grouped Protocols: ', groupedProtocols);
    console.log('Grouped Meds: ', groupedMeds);

    /* Go through every medication and group by parent medication - Then check if the MedicationDose is Created/Updated/Deleted */
    for (let medID in groupedMeds) {
      let medDoses = groupedMeds[medID];
      let dbMed = await DataStore.query(Medication, medID);
      if (
        dbMed == null ||
        (dbMed.status !== 'ACTIVE' && dbMed.status != null)
      ) {
        console.error(
          'ERRROR medication is null or not in an ACTIVE status: ',
          dbMed
        );
        continue;
      }
      console.log('Updating medication: ', dbMed.name);

      let options: MedicationProtocol[] = [...dbMed.protocolOptions];
      console.log('Before Options: ', options);
      for (let i = 0; i < medDoses.length; i++) {
        let dose = medDoses[i];
        let protocol = dose.parentProtocol;
        let protocolID =
          protocol.status === ProgressStatus.ACTIVE || protocol.activeID == null
            ? protocol.uid
            : protocol.activeID;

        if (!protocolID) {
          console.error('Failed to find protocol ID');
          continue;
        }
        let dbDose = dose.model;
        let doseID = dbDose.activeID ?? dbDose.id;

        let newMedRange = dose.status.includes('DELETE')
          ? null
          : new MedicationRange({
              doseID: doseID,
              rangeLow: dbDose.rangeLow,
              rangeHigh: dbDose.rangeHigh,
              basis: dbDose.basis,
              route: dbDose.route,
              nemsisRoutes: dbDose.nemsisRoutes,
              title: dbDose.title,
              warning: dbDose.warning,
              instruction: dbDose.instruction,
              note: dbDose.note,
              maxDose: dbDose.maxDose,
              minDose: dbDose.minDose,
              maxTotalDose: dbDose.maxTotalDose,
              calcMax: dbDose.calcMax,
              calcMin: dbDose.calcMin,
              index: dbDose.index,
              ageLow: dbDose.ageLow,
              ageHigh: dbDose.ageHigh,
              ageGroup: dbDose.ageGroup,
              repeatTime: dbDose.repeatTime,
            });

        console.log('New Med Range: ', newMedRange);

        //Check if the MedicationProtocol exists
        let medProtocol: MedicationProtocol | undefined = options.find(
          (option) => option.protocolID === protocolID
        );

        /* If the MedicationProtocol does not exist, create a new one */
        if (medProtocol == null) {
          if (newMedRange == null) continue; //This means it was never paired or already deleted
          console.log(
            'Created New MedProtocol for ',
            protocolID,
            ' in ',
            dbMed.name
          );
          medProtocol = new MedicationProtocol({
            protocolID: protocolID,
            options: [newMedRange],
          });
          options.push(medProtocol);
        } else {
          /* Otherwise check if the MedicationRange exists */
          let medRange: MedicationRange | undefined = medProtocol.options.find(
            (range) => range.doseID === doseID
          );
          if (medRange == null) {
            console.log(
              'MedRange does not exist for ',
              doseID + ' in ',
              dbMed.name,
              medProtocol,
              medRange,
              newMedRange
            );
            if (newMedRange) {
              let ranges = [...medProtocol.options, newMedRange];
              let newMedProtocol = new MedicationProtocol({
                protocolID: medProtocol.protocolID,
                options: ranges,
              });
              let newOptions = options.map((option) =>
                option.protocolID === protocolID ? newMedProtocol : option
              );
              options = newOptions;
            }
          } else {
            if (newMedRange == null)
              console.log('Deleted MedRange for ', doseID, ' in ', dbMed.name);
            else
              console.log('Updated MedRange for ', doseID, ' in ', dbMed.name);
            let newProtocolOptions = newMedRange
              ? [
                  ...medProtocol.options.slice(0, i),
                  newMedRange,
                  ...medProtocol.options.slice(i + 1),
                ]
              : medProtocol.options.filter((range) => range.doseID !== doseID);
            if (newProtocolOptions.length === 0) {
              /* Delete the MedicationProtocol */
              options = options.filter(
                (option) => option.protocolID !== protocolID
              );
            } else {
              /* Update the MedicationRange to the new one */
              let newMedProtocol = new MedicationProtocol({
                protocolID: medProtocol.protocolID,
                options: newProtocolOptions,
              });
              let newOptions = options.map((option) =>
                option.protocolID === protocolID ? newMedProtocol : option
              );
              options = newOptions;
            }
          }
        }
      }

      console.log('After Options: ', options);

      /* Update the medication with the new protocol options */
      let newMed = Medication.copyOf(dbMed, (updated) => {
        updated.protocolOptions = options;
      });
      let response = await DataStore.save(newMed);
      if (response === null) {
        console.error('auditMedicationDosesToOldDBStyle: ', response);
        continue;
      }
      n++;
    }
    /* Validate the protocols point to the medication --TODO need to update this for if there is an ACTIVE ID */
    // let resp = await auditProtocolPairings(groupedProtocols);
    // n += resp.data as number;

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error: any) {
    console.error('auditMedicationDosesToOldDBStyle: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const auditInfusionDosesToOldDBStyle = async (
  doses: InfusionSubItem[]
): Promise<Response> => {
  try {
    if (doses.length === 0)
      return {
        type: ResponseType.Success,
        data: 0,
      };
    let groupdedInfus: { [key: string]: InfusionSubItem[] } = {};
    // let groupedProtocols: { [key: string]: string[] } = {};
    let n = 0;
    for (let i = 0; i < doses.length; i++) {
      const dose = doses[i];
      let infusID = dose.parent.activeID ?? dose.parent.uid;
      if (infusID == null) continue;
      if (groupdedInfus[infusID] == null) {
        groupdedInfus[infusID] = [];
      }
      groupdedInfus[infusID].push(dose);
    }

    /* Go through every medication and group by parent medication - Then check if the MedicationDose is Created/Updated/Deleted */
    for (let infusID in groupdedInfus) {
      let medDoses = groupdedInfus[infusID];
      let dbMed = await DataStore.query(Drip, infusID);
      if (
        dbMed == null ||
        (dbMed.status !== 'ACTIVE' && dbMed.status != null)
      ) {
        console.error(
          'ERRROR medication is null or not in an ACTIVE status: ',
          dbMed
        );
        continue;
      }

      let options: MedicationProtocol[] = [...dbMed.dripOptions];
      for (let i = 0; i < medDoses.length; i++) {
        let dose = medDoses[i];
        let protocol = dose.parentProtocol;
        let protocolID =
          protocol.status === ProgressStatus.ACTIVE || protocol.activeID == null
            ? protocol.uid
            : protocol.activeID;

        if (!protocolID) {
          console.error('Failed to find protocol ID');
          continue;
        }
        let dbDose = dose.model;
        let doseID = dbDose.activeID ?? dbDose.id;

        let newMedRange = dose.status.includes('DELETE')
          ? null
          : new MedicationRange({
              doseID: doseID,
              rangeLow: dbDose.rangeLow,
              rangeHigh: dbDose.rangeHigh,
              basis: dbDose.basis,
              route: dbDose.route,
              nemsisRoutes: dbDose.nemsisRoutes,
              title: dbDose.title,
              warning: dbDose.warning,
              instruction: dbDose.instruction,
              note: dbDose.note,
              maxDose: dbDose.maxDose,
              minDose: dbDose.minDose,
              maxTotalDose: dbDose.maxTotalDose,
              calcMax: dbDose.calcMax,
              calcMin: dbDose.calcMin,
              index: dbDose.index,
              ageLow: dbDose.ageLow,
              ageHigh: dbDose.ageHigh,
              ageGroup: dbDose.ageGroup,
              repeatTime: dbDose.repeatTime,
            });

        //Check if the MedicationProtocol exists
        let medProtocol: MedicationProtocol | undefined = options.find(
          (option) => option.protocolID === protocolID
        );

        /* If the MedicationProtocol does not exist, create a new one */
        if (medProtocol == null) {
          if (newMedRange == null) continue; //This means it was never paired or already deleted
          medProtocol = new MedicationProtocol({
            protocolID: protocolID,
            options: [newMedRange],
          });
          options.push(medProtocol);
        } else {
          /* Otherwise check if the MedicationRange exists */
          let medRange: MedicationRange | undefined = medProtocol.options.find(
            (range) => range.doseID === doseID
          );
          if (medRange == null) {
            if (newMedRange) {
              let ranges = [...medProtocol.options, newMedRange];
              let newMedProtocol = new MedicationProtocol({
                protocolID: medProtocol.protocolID,
                options: ranges,
              });
              let newOptions = options.map((option) =>
                option.protocolID === protocolID ? newMedProtocol : option
              );
              options = newOptions;
            }
          } else {
            let newProtocolOptions = newMedRange
              ? [
                  ...medProtocol.options.slice(0, i),
                  newMedRange,
                  ...medProtocol.options.slice(i + 1),
                ]
              : medProtocol.options.filter((range) => range.doseID !== doseID);
            if (newProtocolOptions.length === 0) {
              /* Delete the MedicationProtocol */
              options = options.filter(
                (option) => option.protocolID !== protocolID
              );
            } else {
              /* Update the MedicationRange to the new one */
              let newMedProtocol = new MedicationProtocol({
                protocolID: medProtocol.protocolID,
                options: newProtocolOptions,
              });
              let newOptions = options.map((option) =>
                option.protocolID === protocolID ? newMedProtocol : option
              );
              options = newOptions;
            }
          }
        }
      }

      /* Update the medication with the new protocol options */
      let newMed = Drip.copyOf(dbMed, (updated) => {
        updated.dripOptions = options;
      });
      let response = await DataStore.save(newMed);
      if (response === null) {
        console.error('auditMedicationDosesToOldDBStyle: ', response);
        continue;
      }
      n++;
    }

    // let resp = await auditProtocolPairings(groupedProtocols);
    // n += resp.data as number;

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error: any) {
    console.error('auditMedicationDosesToOldDBStyle: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const auditElectricalDosesToOldDBStyle = async (
  doses: ElectricalSubItem[]
): Promise<Response> => {
  try {
    if (doses.length === 0)
      return {
        type: ResponseType.Success,
        data: 0,
      };
    let groupedElec: { [key: string]: ElectricalSubItem[] } = {};
    // let groupedProtocols: { [key: string]: string[] } = {};
    let n = 0;
    for (let i = 0; i < doses.length; i++) {
      const dose = doses[i];
      let elecID = dose.parent.activeID ?? dose.parent.uid;
      if (elecID == null) continue;
      if (groupedElec[elecID] == null) {
        groupedElec[elecID] = [];
      }
      groupedElec[elecID].push(dose);
    }

    /* Go through every medication and group by parent medication - Then check if the MedicationDose is Created/Updated/Deleted */
    for (let elecID in groupedElec) {
      let elecDoses = groupedElec[elecID];
      let dbMed = await DataStore.query(ElectricalShock, elecID);
      if (
        dbMed == null ||
        (dbMed.status !== 'ACTIVE' && dbMed.status != null)
      ) {
        console.error(
          'ERRROR medication is null or not in an ACTIVE status: ',
          dbMed
        );
        continue;
      }

      let options: ElectricalShockOption[] = [...dbMed.options];
      for (let i = 0; i < elecDoses.length; i++) {
        let dose = elecDoses[i];
        let protocol = dose.parentProtocol;
        let protocolID =
          protocol.status === ProgressStatus.ACTIVE || protocol.activeID == null
            ? protocol.uid
            : protocol.activeID;

        if (!protocolID) {
          console.error('Failed to find protocol ID');
          continue;
        }
        let dbDose = dose.model;
        let doseID = dbDose.activeID ?? dbDose.id;

        let newElecRange = dose.status.includes('DELETE')
          ? null
          : new ElectricalShockRange({
              doseID: doseID,
              rangeLow: dbDose.rangeLow,
              rangeHigh: dbDose.rangeHigh
                ? Number(dbDose.rangeHigh)
                : globals.MAX_VALUE,
              basis: dbDose.basis,
              title: dbDose.title ?? '',
              warning: dbDose.warning,
              instruction: dbDose.instruction,
              note: dbDose.note,
              fixedMax: dbDose.maxDose,
              calcMax: dbDose.calcMax,
              index: dbDose.index,
              ageLow: dbDose.ageLow,
              ageHigh: dbDose.ageHigh,
              ageGroup: dbDose.ageGroup,
            });

        //Check if the MedicationProtocol exists
        let elecOption: ElectricalShockOption | undefined = options.find(
          (option) => option.protocolID === protocolID
        );

        /* If the MedicationProtocol does not exist, create a new one */
        if (elecOption == null) {
          if (newElecRange == null) continue; //This means it was never paired or already deleted
          elecOption = new ElectricalShockOption({
            protocolID: protocolID,
            ranges: [newElecRange],
          });
          options.push(elecOption);
        } else {
          /* Otherwise check if the MedicationRange exists */
          let elecRange: ElectricalShockRange | undefined =
            elecOption.ranges.find((range) => range.doseID === doseID);
          if (elecRange == null) {
            if (newElecRange) {
              let ranges = [...elecOption.ranges, newElecRange];
              let newElecOption = new ElectricalShockOption({
                protocolID: elecOption.protocolID,
                ranges: ranges,
              });
              let newOptions = options.map((option) =>
                option.protocolID === protocolID ? newElecOption : option
              );
              options = newOptions;
            }
          } else {
            let newProtocolOptions = newElecRange
              ? [
                  ...elecOption.ranges.slice(0, i),
                  newElecRange,
                  ...elecOption.ranges.slice(i + 1),
                ]
              : elecOption.ranges.filter((range) => range.doseID !== doseID);
            if (newProtocolOptions.length === 0) {
              /* Delete the MedicationProtocol */
              options = options.filter(
                (option) => option.protocolID !== protocolID
              );
            } else {
              /* Update the MedicationRange to the new one */
              let newElecOption = new ElectricalShockOption({
                protocolID: elecOption.protocolID,
                ranges: newProtocolOptions,
              });
              let newOptions = options.map((option) =>
                option.protocolID === protocolID ? newElecOption : option
              );
              options = newOptions;
            }
          }
        }
      }

      /* Update the medication with the new protocol options */
      let newMed = ElectricalShock.copyOf(dbMed, (updated) => {
        updated.options = options;
      });
      let response = await DataStore.save(newMed);
      if (response === null) {
        console.error('auditMedicationDosesToOldDBStyle: ', response);
        continue;
      }
      n++;
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error: any) {
    console.error('auditMedicationDosesToOldDBStyle: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const auditProtocolPairings = async (
  meds: MedicationSubItem[],
  infusions: InfusionSubItem[],
  electricals: ElectricalSubItem[]
): Promise<Response> => {
  try {
    let n = 0;
    /* Validate the protocols point to the medication --TODO need to update this for if there is an ACTIVE ID */
    let groupedProtocols: {
      [key: string]: {
        medications: string[];
        infusions: string[];
        electricals: string[];
      };
    } = {};
    for (let i = 0; i < meds.length; i++) {
      const med = meds[i];
      if (med.cprAssistID) continue;
      let protocolID = med.parentProtocol.activeID ?? med.parentProtocol.uid;
      if (protocolID == null) continue;
      if (groupedProtocols[protocolID] == null) {
        groupedProtocols[protocolID] = {
          medications: [],
          infusions: [],
          electricals: [],
        };
      }
      groupedProtocols[protocolID].medications.push(
        med.parent.activeID ?? med.parent.uid
      );
    }

    for (let i = 0; i < infusions.length; i++) {
      const inf = infusions[i];
      let protocolID = inf.parentProtocol.activeID ?? inf.parentProtocol.uid;
      if (protocolID == null) continue;
      if (groupedProtocols[protocolID] == null) {
        groupedProtocols[protocolID] = {
          medications: [],
          infusions: [],
          electricals: [],
        };
      }
      groupedProtocols[protocolID].infusions.push(
        inf.parent.activeID ?? inf.parent.uid
      );
    }

    for (let i = 0; i < electricals.length; i++) {
      const elec = electricals[i];
      if (elec.cprAssistID) continue;
      let protocolID = elec.parentProtocol.activeID ?? elec.parentProtocol.uid;
      if (protocolID == null) continue;
      if (groupedProtocols[protocolID] == null) {
        groupedProtocols[protocolID] = {
          medications: [],
          infusions: [],
          electricals: [],
        };
      }
      groupedProtocols[protocolID].electricals.push(
        elec.parent.activeID ?? elec.parent.uid
      );
    }

    for (let protocolID in groupedProtocols) {
      let protocol = await DataStore.query(Protocol, protocolID);
      if (protocol == null) {
        console.error('Protocol is null: ', protocolID);
        continue;
      }
      let medIDs = [...new Set(protocol.medicationIDs ?? [])];
      let infIDs = [...new Set(protocol.dripIDs ?? [])];
      let elecIDs = [...new Set(protocol.electricalIDs ?? [])];
      //Check if the content is the same as the protocol
      let newMedIDs = [...new Set(groupedProtocols[protocolID].medications)];
      let newInfIDs = [...new Set(groupedProtocols[protocolID].infusions)];
      let newElecIDs = [...new Set(groupedProtocols[protocolID].electricals)];

      let changed = false;
      if (medIDs.length !== newMedIDs.length) {
        changed = true;
      } else {
        for (let i = 0; i < medIDs.length; i++) {
          if (!newMedIDs.includes(medIDs[i])) {
            changed = true;
            break;
          }
        }
      }

      if (infIDs.length !== newInfIDs.length) {
        changed = true;
      } else {
        for (let i = 0; i < infIDs.length; i++) {
          if (!newInfIDs.includes(infIDs[i])) {
            changed = true;
            break;
          }
        }
      }

      if (elecIDs.length !== newElecIDs.length) {
        changed = true;
      } else {
        for (let i = 0; i < elecIDs.length; i++) {
          if (!newElecIDs.includes(elecIDs[i])) {
            changed = true;
            break;
          }
        }
      }

      if (changed) {
        let newProtocol = Protocol.copyOf(protocol, (updated) => {
          updated.medicationIDs = [...new Set([...medIDs, ...newMedIDs])];
          updated.dripIDs = [...new Set([...infIDs, ...newInfIDs])];
          updated.electricalIDs = [...new Set([...elecIDs, ...newElecIDs])];
        });
        let response = await DataStore.save(newProtocol);
        if (response === null) {
          console.error('auditProtocolPairings: ', response);
          continue;
        }
        n++;
      }
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error: any) {
    console.error('auditProtocolPairings: ', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const addDepartmentIDToAllReferences = async (
  department: DepartmentItem,
  id: string
): Promise<Response> => {
  try {
    let n = 0;
    /* Load all the medications and drips*/
    let promises = [
      // DataStore.query(User, (c) => c.departmentID.eq(department.id)),
      DataStore.query(Category, (c) => c.departmentID.eq(department.id)),
      DataStore.query(Protocol, (c) => c.departmentID.eq(department.id)),
      DataStore.query(Workbook, (c) => c.departmentID.eq(department.id)),
      DataStore.query(WeightObject, (c) => c.departmentID.eq(department.id)),
      DataStore.query(Contact, (c) => c.departmentID.eq(department.id)),
      DataStore.query(InputForm, (c) => c.departmentID.eq(department.id)),
    ];
    let [
      users,
      categories,
      protocols,
      workbooks,
      weightObjects,
      contacts,
      inputForms,
    ] = await Promise.all(promises);

    // let usrs: User[] = users as User[];
    // for (let i = 0; i < usrs.length; i++) {
    //   const user = usrs[i];
    //   let pairedDepIDs: string[] = [
    //     ...new Set([...(user.pairedDepIDs ? [...user.pairedDepIDs] : []), id]),
    //   ];
    //   const newUser = User.copyOf(user, (updated) => {
    //     updated.pairedDepIDs = pairedDepIDs;
    //   });
    //   DataStore.save(newUser)
    //     .then((response) => {
    //       if (response === null)
    //         console.error('addDepartmentIDToAllReferences: ', response);
    //     })
    //     .catch((error) =>
    //       console.error('addDepartmentIDToAllReferences: ', error)
    //     );
    //   n++;
    // }

    let modifyPromises: any[] = [];

    const batchProcessPromises = async (promises: any[]) => {
      if (promises.length > globals.QUERY_BATCH_SIZE) {
        let resp = await Promise.all(promises);
        promises = promises.slice(globals.QUERY_BATCH_SIZE);
        return Promise.resolve(resp);
      }
      return Promise.resolve(null);
    };

    let cats: Category[] = categories as Category[];
    for (let i = 0; i < cats.length; i++) {
      const category = cats[i];
      let pairedDepIDs: string[] = [
        ...new Set([
          ...(category.pairedDepIDs ? [...category.pairedDepIDs] : []),
          id,
        ]),
      ];
      const newCategory = Category.copyOf(category, (updated) => {
        updated.pairedDepIDs = pairedDepIDs;
      });
      modifyPromises.push(DataStore.save(newCategory));
      let resp = await batchProcessPromises(modifyPromises);
      if (resp != null) modifyPromises = [];
      n++;
    }

    let prots: Protocol[] = protocols as Protocol[];
    for (let i = 0; i < prots.length; i++) {
      const protocol = prots[i];
      let pairedDepIDs: string[] = [
        ...new Set([
          ...(protocol.pairedDepIDs ? [...protocol.pairedDepIDs] : []),
          id,
        ]),
      ];
      const newProtocol = Protocol.copyOf(protocol, (updated) => {
        updated.pairedDepIDs = pairedDepIDs;
      });
      modifyPromises.push(DataStore.save(newProtocol));
      let resp = await batchProcessPromises(modifyPromises);
      if (resp != null) modifyPromises = [];
      n++;
    }

    let wbs: Workbook[] = workbooks as Workbook[];
    for (let i = 0; i < wbs.length; i++) {
      const workbook = wbs[i];
      let pairedDepIDs: string[] = [
        ...new Set([
          ...(workbook.pairedDepIDs ? [...workbook.pairedDepIDs] : []),
          id,
        ]),
      ];
      const newWorkbook = Workbook.copyOf(workbook, (updated) => {
        updated.pairedDepIDs = pairedDepIDs;
      });
      modifyPromises.push(DataStore.save(newWorkbook));
      let resp = await batchProcessPromises(modifyPromises);
      if (resp != null) modifyPromises = [];
      n++;
    }

    let wos: WeightObject[] = weightObjects as WeightObject[];
    for (let i = 0; i < wos.length; i++) {
      const weightObject = wos[i];
      let pairedDepIDs: string[] = [
        ...new Set([
          ...(weightObject.pairedDepIDs ? [...weightObject.pairedDepIDs] : []),
          id,
        ]),
      ];
      const newWeightObject = WeightObject.copyOf(weightObject, (updated) => {
        updated.pairedDepIDs = pairedDepIDs;
      });
      modifyPromises.push(DataStore.save(newWeightObject));
      let resp = await batchProcessPromises(modifyPromises);
      if (resp != null) modifyPromises = [];
      n++;
    }

    let conts: Contact[] = contacts as Contact[];
    for (let i = 0; i < conts.length; i++) {
      const contact = conts[i];
      let pairedDepIDs: string[] = [
        ...new Set([
          ...(contact.pairedDepIDs ? [...contact.pairedDepIDs] : []),
          id,
        ]),
      ];
      const newContact = Contact.copyOf(contact, (updated) => {
        updated.pairedDepIDs = pairedDepIDs;
      });
      modifyPromises.push(DataStore.save(newContact));
      let resp = await batchProcessPromises(modifyPromises);
      if (resp != null) modifyPromises = [];
      n++;
    }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const addDepartmentIDToUsersDepAdminPrivleges = async (
  department: DepartmentItem,
  id: string
): Promise<Response> => {
  try {
    let n = 0;
    /* Load all the medications and drips*/
    let promises = [
      DataStore.query(User, (u) =>
        u.and((u) => [
          u.departmentID.eq(department.id),
          u.or((u) => [u.type.eq('ADMIN'), u.type.eq('DEPT_ADMIN')]),
        ])
      ),
      DataStore.query(User, (u) =>
        u.and((u) => [u.departmentID.eq(department.id), u.status.ne('DELETED')])
      ),
    ];
    let [users, users2] = await Promise.all(promises);

    // const medications = await DataStore.query(Medication, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const drips = await DataStore.query(Drip, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const equipments = await DataStore.query(Equipment, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const electricals = await DataStore.query(Electrical, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const forms = await DataStore.query(Form, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const categories = await DataStore.query(Category, (c) =>
    //   c.departmentID.eq(department.id)
    // );
    // const protocols = await DataStore.query(Protocol, (c) =>
    //   c.departmentID.eq(department.id)
    // );

    // for (let i = 0; i < users.length; i++) {
    //   const user = users[i];
    //   let depAdminIDs: string[] = [
    //     ...new Set([...(user.depAdmins ? [...user.depAdmins] : []), id]),
    //   ];
    //   const newUser = User.copyOf(user, (updated) => {
    //     updated.depAdmins = depAdminIDs;
    //   });
    //   DataStore.save(newUser)
    //     .then((response) => {
    //       if (response === null)
    //         console.error('addDepartmentIDToAllReferences: ', response);
    //     })
    //     .catch((error) =>
    //       console.error('addDepartmentIDToAllReferences: ', error)
    //     );
    //   n++;
    // }

    return {
      type: ResponseType.Success,
      data: n,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
