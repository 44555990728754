import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  ChangeEvent,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DatabaseResponse, ResponseType } from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { getFormattedDate } from '../../../_global/common/Utils';
import { FaChevronRight } from 'react-icons/fa6';
import KeychainItem from '../../../../data/model/KeychainItem';
import {
  deleteKeychain,
  fetchKeychains,
} from '../../../../data/functions/KeychainDB';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import DataList, { Header } from './DataList';
import { User } from '../../../../models';
import HMCheckbox from '../../../components/general/HMCheckbox';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListKeychains = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const department = database.department;
  const [keychains, setKeychains] = useState<KeychainItem[]>(
    database.keychains
  );

  const scrollPosition = useRef(0);
  const [sort, setSort] = useState('name_asc');
  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );

  const [list, setList] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<KeychainItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const reloadDatabase = async () => {
    const response = await fetchKeychains(department);
    if (response.type === ResponseType.Success) {
      setKeychains(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          keychains: response.data,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
    setAllCheckedBtn(false);
  }, []);

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : keychains)];
    if (searchQuery !== '') {
      filteredList = filteredList.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: KeychainItem, b: KeychainItem) => {
      return a.name.localeCompare(b.name);
    });

    /* Sort the list based on the sort value */
    filteredList = filteredList.sort((a: KeychainItem, b: KeychainItem) => {
      if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort.includes('modified')) {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        if (sort === 'modified_desc') return bDate.getTime() - aDate.getTime();
        return aDate.getTime() - bDate.getTime();
      } else return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(keychains);
  }, [searchQuery, keychains, sort]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (obj: KeychainItem) => {
    const state = {
      selectedProtocol: null,
      value: obj,
      subValue: null,
      type: 'KeychainItem',
      editType: 'edit',
      editMode: false,
      page: 'listKeychainsPage',
    };
    navigate(`/database/edit/keychains`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'KeychainItem',
      editType: 'new',
      editMode: false,
      page: 'listKeychainsPage',
    };
    navigate(`/database/new/keychains`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  const handleSelectionChange = (keychain: KeychainItem) => {
    if (selectedItems.find((item) => item.uid === keychain.uid)) {
      setSelectedItems((prevItems: KeychainItem[]) => {
        const updatedItems = prevItems.filter(
          (item: KeychainItem) => item.uid !== keychain.uid
        );

        if (updatedItems.length === 0) {
          setAllCheckedBtn(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, keychain];
      let available = keychains.filter(
        (item) => department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setAllCheckedBtn(true);
      } else if (allCheckedBtn) {
        setAllCheckedBtn(false);
      }
    }
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];

      // Delete the medication
      let response = await deleteKeychain(currentMedication, database);
      if (response.type === ResponseType.Success) {
        setSnackbar({
          open: true,
          message: `Successfully deleted keychain: ${currentMedication.name}`,
          severity: 'success',
        });

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: KeychainItem[]) => {
          const updatedItems = prevItems.filter(
            (item: KeychainItem) => item.uid !== currentMedication.uid
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbar({
          open: true,
          message: 'Failed to delete keychain',
          severity: 'error',
        });
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to delete keychain',
        severity: 'error',
      });
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          let response = await deleteKeychain(parmMedication, database);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbar({
            open: true,
            message:
              `Successfully deleted ${successCount} keychain` +
              (successCount > 1 ? 's' : ''),
            severity: 'success',
          });
        }
        if (failureCount > 0) {
          setSnackbar({
            open: true,
            message:
              `Failed to delete ${failureCount} keychain` +
              (failureCount > 1 ? 's' : ''),
            severity: 'error',
          });
        }

        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to delete keychain',
          severity: 'error',
        });
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      severity: 'success',
    });
  };

  const headers: Header<KeychainItem>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.25,
        render: (item: KeychainItem) => {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              <HMCheckbox
                checked={selectedItems.some(
                  (selected) => selected.uid === item.uid
                )}
                onChange={() => handleSelectionChange(item)}
              />
            </div>
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: KeychainItem) =>
          getFormattedDate(
            item?.model?.updatedAt
              ? new Date(item.model.updatedAt)
              : new Date(),
            true
          ),
      },
      {
        key: 'modified_by',
        name: 'Modified By',
        sortable: true,
        flex: 1,
        render: (item: KeychainItem) => {
          const id = item.model?.modifiedBy || item.model?.createdBy;
          let username = 'Hinckley Medical';
          if (id) {
            const user = database.users.find((user) => user.id === id);
            if (user) {
              username = `${user.firstName} ${user.lastName}`;
            }
          }
          return username;
        },
      },
      // {
      //   key: 'type',
      //   name: 'Type',
      //   sortable: true,
      //   flex: 1,
      //   render: (item: KeychainItem) => {
      //     let type = 'None';
      //     if (department.keychainID === item.uid) type = 'Department';
      //     else {
      //       for (let i = 0; i < database.categories.length; i++) {
      //         if (database.categories[i].keychainID === item.uid) {
      //           type = 'Folder and Proocols';
      //           break;
      //         }
      //       }
      //       if (type === 'None') {
      //         for (let i = 0; i < database.protocols.length; i++) {
      //           if (database.protocols[i].keychainID === item.uid) {
      //             type = 'Folder and Protocols';
      //             break;
      //           }
      //         }
      //       }
      //     }
      //     return <span>{type}</span>;
      //   },
      // },
    ],

    [department, user, selectedItems, database.users]
  );

  const handleSortItem = (
    key: string,
    direction: string,
    a: KeychainItem,
    b: KeychainItem
  ) => {
    if (key === 'name') {
      return direction === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (key === 'modified_date') {
      const aDate = new Date(a.model?.updatedAt ?? new Date());
      const bDate = new Date(b.model?.updatedAt ?? new Date());
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'modified_by') {
      const aName = getModifiedByName(a);
      const bName = getModifiedByName(b);
      return direction === 'asc'
        ? aName.localeCompare(bName)
        : bName.localeCompare(aName);
    }
    return 0;
  };

  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedItems([
        ...keychains.filter(
          (item) =>
            item.status !== 'DEACTIVATED' && department.id === item.departmentID
        ),
      ]);
    } else {
      setSelectedItems([]);
    }
  };

  // Helper function for getting modified by name
  const getModifiedByName = (item: KeychainItem) => {
    const id = item.model?.modifiedBy || item.model?.createdBy;
    if (id) {
      const user = database.users.find((user) => user.id === id);
      if (user) {
        return `${user.firstName} ${user.lastName}`;
      }
    }
    return 'Hinckley Medical';
  };

  const getRowClassName = useCallback(
    (params: KeychainItem) => {
      const isSelected = selectedItems.some((item) => item.uid === params.id);
      if (isSelected) return 'selectedRow';
      // Default return for other cases
      return '';
    },
    [selectedItems]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Keychains?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} keychains?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Keychains: ' + list.length + ' items'
            : 'Keychains: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={true}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />

      <DataList<KeychainItem, never>
        items={list}
        headers={headers}
        onItemClick={handleItemClick}
        onSelectionChange={setSelectedItems}
        searchPlaceholder="Search Keychains..."
        sortItem={handleSortItem}
        getRowClassName={getRowClassName}
        handleSelectAll={handleSelectAll}
        selectedItems={selectedItems}
      />
    </div>
  );
};

export default ListKeychains;
