import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { DatabaseResponse, ResponseType } from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  findDepartmentOwner,
  getFormattedDate,
  globals,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import FormItem from '../../../../data/model/FormItem';
import { ProgressStatus, User } from '../../../../models';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import {
  deleteCheckList,
  fetchChecklists,
} from '../../../../data/functions/CheckListDB';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import HMCheckbox from '../../../components/general/HMCheckbox';
import HMSwitch from '../../../components/general/HMSwitch';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
import { handleToggleEnabled } from '../../../../data/functions/ModelDB';
import DataList, { Header } from './DataList';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListChecklists = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const department = database.department;
  const [checklists, setChecklists] = useState<FormItem[]>(database.checklists);
  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);
  const reducerState = useSelector((state: any) => state.department);
  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, reducerState, user);
  }, [department, reducerState, user]);
  const scrollPosition = useRef(0);
  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [filters, setFilters] = useState<any[]>(state?.search?.filters ?? []);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state?.search?.categoriesFilter ?? []
  );
  const [list, setList] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<FormItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const [mainCheckbox, setMainCheckbox] = useState(false);
  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    FormItem[]
  >([]);

  const [viewing, setViewing] = useState<string | null>('all');

  const allDepartmentOwners = useMemo(() => {
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < checklists.length; i++) {
      let owner = findDepartmentOwner(department, reducerState, checklists[i]);
      if (owner && !deps.find((dep) => dep.id === owner?.id)) deps.push(owner);
    }
    setViewing('all');
    setFilters([]);
    return deps.sort((a, b) => a.name.localeCompare(b.name));
  }, [checklists, department, reducerState]);

  const reloadDatabase = async () => {
    const response = await fetchChecklists(department);
    if (response.type === ResponseType.Success) {
      // const database = response.data;
      // setDatabase(database);
      setChecklists(response.data);
      handleFilterChange(response.data);
    }
  };

  useEffect(() => {
    reloadDatabase();
    setAllCheckedBtn(false);
  }, []);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : checklists)];
    if (searchQuery !== '' || filters.length > 0) {
      filteredList = filteredList.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
      if (filters.length > 0) {
        filteredList = filteredList.filter((item) => {
          //Check if the Filters (Department ID)
          return filters.some((filter) => {
            return (
              (item.status === ProgressStatus.DEACTIVATED &&
                item.overrideItem &&
                item.overrideItem.departmentID === filter.id) ||
              (item.status !== ProgressStatus.DEACTIVATED &&
                item.departmentID === filter.id)
            );
          });
        });
      }
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: FormItem, b: FormItem) => {
      return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(checklists);
  }, [searchQuery, filters, categoriesFilter, checklists]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (check: FormItem) => {
    const state = {
      selectedProtocol: null,
      value: check,
      subValue: null,
      type: 'Checklist',
      editType: 'edit',
      editMode: false,
      page: 'listChecklistPage',
    };
    navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'Checklist',
      editType: 'new',
      editMode: false,
      page: 'listChecklistPage',
    };
    navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  const handleToggleEnabledUI = async (item: FormItem) => {
    let find = itemDeactivationStatus.find(
      (newItem) => newItem.uid === item.uid
    );
    if (find)
      setItemDeactivationStatus(
        itemDeactivationStatus.filter((newItem) => newItem.uid !== item.uid)
      );
    else setItemDeactivationStatus([...itemDeactivationStatus, item]);
  };

  const headers: Header<FormItem>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.25,
        render: (item: FormItem) => {
          const isOwner = item.departmentID === department.id;
          const adminAccess = hasAdminUserAccess(
            department,
            reducerState,
            user
          );
          const isSwitchChecked = !itemDeactivationStatus.find(
            (newItem) => newItem.uid === item.uid
          )
            ? item.status !== ProgressStatus.DEACTIVATED
            : item.status === ProgressStatus.DEACTIVATED;

          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              {adminAccess && (
                <>
                  {isOwner && item.status !== ProgressStatus.DEACTIVATED ? (
                    <HMCheckbox
                      checked={selectedItems.some(
                        (selected) => selected.uid === item.uid
                      )}
                      onChange={() => handleSelectionChange(item)}
                    />
                  ) : (
                    <HMSwitch
                      checked={isSwitchChecked}
                      onChange={() => handleToggleEnabledUI(item)}
                    />
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: FormItem) => {
          const isOwner = item.departmentID === department.id;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(isOwner && item.status !== 'DEACTIVATED') && (
                <FaLock
                  className="table-icon"
                  style={{ marginRight: '8px' }}
                  color="#A3A3A3"
                />
              )}
              {item.name}
              {item.status !== 'ACTIVE' && (
                <Status status={item.status} style={{ marginLeft: '8px' }} />
              )}
            </div>
          );
        },
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: FormItem) =>
          getFormattedDate(
            item?.model?.updatedAt
              ? new Date(item.model.updatedAt)
              : new Date(),
            true
          ),
      },
      {
        key: 'modified_by',
        name: 'Modified By',
        sortable: true,
        flex: 1,
        render: (item: FormItem) => {
          const id = item.model?.modifiedBy || item.model?.createdBy;
          let username = 'Hinckley Medical';
          if (id) {
            const user = database.users.find((user) => user.id === id);
            if (user) {
              username = `${user.firstName} ${user.lastName}`;
            }
          }
          return username;
        },
      },
      {
        key: 'owner',
        name: 'Owner',
        sortable: true,
        flex: 1,
        render: (item: FormItem) => {
          const departmentOwner = findDepartmentOwner(
            department,
            reducerState,
            item
          );
          return departmentOwner ? (
            <OwnerImage
              owner={departmentOwner}
              size={32}
              style={{ padding: 0, margin: 0 }}
            />
          ) : null;
        },
      },
      {
        key: 'groups',
        name: 'Groups',
        sortable: true,
        flex: 1,
        render: (item: FormItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 1,
              marginLeft: '0.5rem',
            }}
          >
            <span>{item.items.length}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [
      department,
      reducerState,
      user,
      itemDeactivationStatus,
      selectedItems,
      database.users,
    ]
  );

  const handleSortItem = (
    key: string,
    direction: string,
    a: FormItem,
    b: FormItem
  ) => {
    if (key === 'name') {
      return direction === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (key === 'modified_date') {
      const aDate = new Date(a.model?.updatedAt ?? new Date());
      const bDate = new Date(b.model?.updatedAt ?? new Date());
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'groups') {
      return direction === 'asc'
        ? a.items.length - b.items.length
        : b.items.length - a.items.length;
    } else if (key === 'modified_by') {
      const aName = getModifiedByName(a);
      const bName = getModifiedByName(b);
      return direction === 'asc'
        ? aName.localeCompare(bName)
        : bName.localeCompare(aName);
    } else if (key === 'owner') {
      const aOwner =
        findDepartmentOwner(department, reducerState, a)?.name ?? '';
      const bOwner =
        findDepartmentOwner(department, reducerState, b)?.name ?? '';
      return direction === 'asc'
        ? aOwner.localeCompare(bOwner)
        : bOwner.localeCompare(aOwner);
    }
    return 0;
  };

  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedItems([
        ...checklists.filter(
          (item) =>
            item.status !== 'DEACTIVATED' && department.id === item.departmentID
        ),
      ]);
      setMainCheckbox(true);
    } else {
      setSelectedItems([]);
      setMainCheckbox(false);
    }
  };

  // Helper function for getting modified by name
  const getModifiedByName = (item: FormItem) => {
    const id = item.model?.modifiedBy || item.model?.createdBy;
    if (id) {
      const user = database.users.find((user) => user.id === id);
      if (user) {
        return `${user.firstName} ${user.lastName}`;
      }
    }
    return 'Hinckley Medical';
  };

  const handleSelectionChange = (checklist: FormItem) => {
    if (selectedItems.find((item) => item.uid === checklist.uid)) {
      setSelectedItems((prevItems: FormItem[]) => {
        const updatedItems = prevItems.filter(
          (item: FormItem) => item.uid !== checklist.uid
        );

        if (updatedItems.length === 0) {
          setMainCheckbox(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, checklist];
      let available = list.filter(
        (item) =>
          item.status !== 'DEACTIVATED' && department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setMainCheckbox(true);
      } else if (mainCheckbox) {
        setMainCheckbox(false);
      }
    }
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];
      const isDraft =
        currentMedication.status === ProgressStatus.DRAFT &&
        currentMedication.activeID == null;

      // Delete the medication
      let response = await deleteCheckList(currentMedication, !isDraft);
      if (response.type === ResponseType.Success) {
        setSnackbar({
          ...snackbar,
          open: true,
          message: `Successfully deleted ${currentMedication.name}`,
          severity: 'success',
        });

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: FormItem[]) => {
          const updatedItems = prevItems.filter(
            (item: FormItem) => item.uid !== currentMedication.uid
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Failed to delete checklist',
          severity: 'error',
        });
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbar({
        ...snackbar,
        open: true,
        message: 'Failed to delete checklist',
        severity: 'error',
      });
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          const isDraft =
            parmMedication.status === ProgressStatus.DRAFT &&
            parmMedication.activeID == null;
          let response = await deleteCheckList(parmMedication, !isDraft);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbar({
            ...snackbar,
            open: true,
            message: `Successfully deleted ${successCount} checklist${
              successCount === 1 ? '' : 's'
            }.`,
            severity: 'success',
          });
        }
        if (failureCount > 0) {
          setSnackbar({
            ...snackbar,
            open: true,
            message: `Failed to delete ${failureCount} checklist${
              failureCount === 1 ? '' : 's'
            }.`,
            severity: 'error',
          });
        }

        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Failed to delete checklist',
          severity: 'error',
        });
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const getRowClassName = useCallback(
    (params: FormItem) => {
      const rowItem = list.find((item) => item.uid === params.uid);
      const adminAccess = hasAdminUserAccess(
        department,
        reducerState,
        user,
        rowItem
      );
      // Apply background color when adminAccess is false or the item status is not DEACTIVATED.
      if (!(adminAccess && rowItem?.status !== 'DEACTIVATED')) {
        return 'inactive';
      }

      // If the row is selected, give it the selected class.
      const isSelected = selectedItems.some((item) => item.uid === params.id);
      if (isSelected) return 'selectedRow';

      // Default return for other cases
      return '';
    },
    [department, list, user, reducerState, selectedItems]
  );

  const handleSave = async () => {
    if (itemDeactivationStatus && itemDeactivationStatus.length > 0) {
      try {
        let promises: any[] = [];
        for (let i = 0; i < itemDeactivationStatus.length; i++) {
          promises.push(
            handleToggleEnabled(
              user,
              database.department,
              reducerState,
              itemDeactivationStatus[i]
            )
          );
        }
        let results = await Promise.all(promises);
        for (let i = 0; i < results.length; i++) {
          if (results[i].type === ResponseType.Success) {
            if (globals.debug) console.log('SAVED ITEM', results[i].data);
          } else {
            console.error('ERROR SAVING ITEM', results[i].data);
          }
        }

        let n = results.filter(
          (result) => result.type === ResponseType.Success
        ).length;
        setSnackbar({
          ...snackbar,
          open: true,
          message: `Successfully updated ${n} checklist${n === 1 ? '' : 's'}`,
          severity: 'success',
        });

        reloadDatabase().then(() => {
          setItemDeactivationStatus([]);
        });
      } catch (error) {
        console.error('ERROR SAVING DEACTIVATE STATE', error);
      }
    }
  };

  const customFilterComponent = useMemo(
    () =>
      allDepartmentOwners.length > 1 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            margin: '4px 0px',
          }}
        >
          <ToggleButtonGroup
            value={viewing}
            exclusive
            onChange={(event, newAlignment) => setViewing(newAlignment)}
            aria-label="owner"
            sx={{
              maxHeight: '50px',
            }}
          >
            <Tooltip title={'View All Checklists'} placement="bottom" arrow>
              <ToggleButton
                value="all"
                aria-label="all"
                autoCapitalize="none"
                sx={{
                  padding: '0px 8px', // Adjust padding as needed for the "All" button
                  minWidth: 'auto', // Ensures the width is only as wide as the content
                }}
                onChange={() => {
                  setViewing('all');
                  setFilters([]);
                }}
              >
                All
              </ToggleButton>
            </Tooltip>
            {allDepartmentOwners.map((owner) => (
              <ToggleButton
                key={owner.id}
                value={owner.id}
                onClick={() => {
                  setViewing(owner.id);
                  setFilters([owner]);
                }}
              >
                <Tooltip title={owner.name} placement="bottom" arrow>
                  <img
                    className="no-select owner-logo"
                    src={owner.logoVerifiedUrl ?? ''}
                    alt="Agency Logo"
                    style={{ width: 40, height: 40 }}
                  />
                </Tooltip>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      ) : null,
    [allDepartmentOwners, viewing]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Checklist?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} checklists?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}

      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Checklists: ' + list.length + ' items'
            : 'Checklists: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={adminLevel > 2 && adminAccess}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
        isSaveButton={itemDeactivationStatus.length > 0}
        isSaveActive={itemDeactivationStatus.length > 0}
        handleSave={() => handleSave()}
        handleCancelEdit={() => {
          setItemDeactivationStatus([]);
        }}
      />

      <DataList<FormItem, never>
        items={list}
        headers={headers}
        onItemClick={handleItemClick}
        onSelectionChange={setSelectedItems}
        searchPlaceholder="Search Checklists..."
        sortItem={handleSortItem}
        getRowClassName={getRowClassName}
        handleSelectAll={handleSelectAll}
        customFilterComponent={customFilterComponent}
        selectedItems={selectedItems}
      />
    </div>
  );
};

export default ListChecklists;
