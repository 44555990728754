import React from 'react';
import { Box, Tooltip } from '@mui/material';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <Box
      sx={{
        padding: 2,
        maxWidth: '1024px',
        margin: '0 auto',
        marginTop: '20px',
        fontSize: '1rem',
        gap: '10px',
      }}
    >
      <div
        style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '16px' }}
      >
        Privacy Policy of Hinckley Medical, Inc.
        <span
          style={{
            fontSize: '0.9rem',
            fontStyle: 'italic',
            marginLeft: '5px',
            fontWeight: 'normal',
            color: '#616161',
          }}
        >
          Revised January 30th, 2025
        </span>
      </div>

      <div
        style={{
          color: '#616161',
        }}
      >
        <div style={{ marginBottom: '16px' }}>
          For questions about the privacy policy information on this page,
          contact:
        </div>

        <div>
          <strong>Hinckley Medical, Inc.</strong>
          <br />
          8485 210th St W<br />
          Lakeville, MN 55044 USA
          <br />
          <a href="mailto:support@hinckleymed.com" style={{ color: '#2d7abf' }}>
            support@hinckleymed.com
          </a>
          <br />
          <a href="tel:(402) 207-0220" style={{ color: '#2d7abf' }}>
            (402) 207-0220
          </a>
        </div>

        {/* Website and Digital Activities - Marketing */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
            Privacy Policy for Website and Digital Activities
            <span
              style={{
                fontSize: '0.9rem',
                fontStyle: 'italic',
                fontWeight: 'normal',
                marginLeft: '8px',
              }}
            >
              Revised January 30, 2025
            </span>
          </span>

          <div className="privacy-policy-text">
            This privacy policy describes how Hinckley Medical, Inc. collects
            and processes personal information through its website and digital
            activities. By using our website, email services, and/or social
            media, you are consenting to the practices described herein and from
            related third-party service providers.
          </div>
          <div
            className="privacy-policy-text"
            style={{
              display: 'inline',
            }}
          >
            Hinckley Medical, Inc. hosts its website on the WordPress platform
            at{' '}
            <Tooltip title="https://hinckleymed.com" arrow placement="bottom">
              <a
                href="https://hinckleymed.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontWeight: 'normal', color: '#2d7abf' }}
              >
                https://hinckleymed.com
              </a>
            </Tooltip>
            {'. '}
            We utilize a number of plugins and services to provide Web visitors
            with a good experience:
          </div>
          <ul
            style={{
              paddingLeft: '0rem',
              marginBottom: '16px',
              listStyleType: 'none',
            }}
          >
            <li className="privacy-policy-list-item">
              <Tooltip
                title="https://analytics.google.com"
                arrow
                placement="right"
              >
                <a
                  href="https://analytics.google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#2d7abf', textDecoration: 'none' }}
                >
                  Google Analytics
                </a>
              </Tooltip>
            </li>
            <li className="privacy-policy-list-item">
              <Tooltip title="https://mailchimp.com" arrow placement="right">
                <a
                  href="https://mailchimp.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#2d7abf', textDecoration: 'none' }}
                >
                  MailChimp Email Services
                </a>
              </Tooltip>
            </li>
            <li className="privacy-policy-list-item">
              <Tooltip title="https://www.hubspot.com" arrow placement="right">
                <a
                  href="https://www.hubspot.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#2d7abf', textDecoration: 'none' }}
                >
                  HubSpot Marketing Services
                </a>
              </Tooltip>
            </li>
            <li className="privacy-policy-list-item">
              <Tooltip title="https://www.facebook.com" arrow placement="right">
                <a
                  href="https://www.facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#2d7abf', textDecoration: 'none' }}
                >
                  Facebook Social Media
                </a>
              </Tooltip>
            </li>
            <li className="privacy-policy-list-item">
              <Tooltip title="https://www.linkedin.com" arrow placement="right">
                <a
                  href="https://www.linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#2d7abf', textDecoration: 'none' }}
                >
                  LinkedIn Social Media
                </a>
              </Tooltip>
            </li>
            <li className="privacy-policy-list-item">
              <Tooltip title="https://www.youtube.com" arrow placement="right">
                <a
                  href="https://www.youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#2d7abf', textDecoration: 'none' }}
                >
                  YouTube Social Media
                </a>
              </Tooltip>
            </li>
          </ul>

          <div>
            Several of the above plugins and services compile information to
            help analyze how visitors use our website. Example information
            includes content viewed, time spent viewing, city location of
            visitors, and viewing device types. If you elect to receive email
            marketing messages, promotional emails will be processed through
            MailChimp and will offer unsubscribe options in email messages.
            Hinckley Medical, Inc. does not sell any personal data.
          </div>
        </div>

        {/* OneDose Application */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            paddingTop: '16px',
          }}
        >
          <div>
            <span
              style={{
                fontWeight: 'bold',
                marginRight: '5px',
                marginBottom: '16px',
              }}
            >
              Privacy Policy for OneDose Application
            </span>
            <span style={{ fontSize: '0.9rem', fontStyle: 'italic' }}>
              Revised January 30th, 2025
            </span>
          </div>
          <div className="privacy-policy-header">1. Introduction</div>
          <div className="privacy-policy-text">
            Welcome to the OneDose application (“we”, “our”, or “us”). We
            respect your privacy and are committed to protecting it through
            compliance with this policy. This policy describes the types of
            information we collect, how we use it, and your rights regarding
            that information.
          </div>

          <div className="privacy-policy-header">2. Information We Collect</div>
          <div className="privacy-policy-text">
            We collect the following types of information to improve the
            functionality and user experience of the OneDose application:
          </div>
          <li className="privacy-policy-list-item">
            OneDose Software Version Information: To ensure compatibility and
            provide the best experience, we access your device’s software
            version.
          </li>
          <li className="privacy-policy-list-item">
            Location Information (Android 11 and below): Required for enabling
            Bluetooth Low Energy (BLE) functionality to scan and connect to the
            OneWeight gurney scale.
          </li>
          <li className="privacy-policy-list-item">
            App Usage Data: We collect app interactions, such as pages viewed,
            buttons clicked, and error occurrences, to improve performance and
            user experience.
          </li>
          <li className="privacy-policy-list-item">
            Error and Crash Reports: To diagnose and fix issues, we may collect
            crash reports through third-party services like Firebase
            Crashlytics.
          </li>

          <div className="privacy-policy-header">
            3. How We Use Your Information
          </div>
          <div className="privacy-policy-text">
            Your data is used for the following purposes:
          </div>
          <li className="privacy-policy-list-item">
            Device Software Version Information: Lets us know what version of
            the app is being used. Useful for debugging and ensuring the app is
            working as expected. Your administrators can access this
            information.
          </li>
          <li className="privacy-policy-list-item">
            Location Information: Enables BLE functionality on Android 11 and
            lower to scan for and connect to the OneWeight scale.
          </li>
          <li className="privacy-policy-list-item">
            App Usage Data: Helps us analyze app interactions to improve
            functionality and user experience.
          </li>
          <li className="privacy-policy-list-item">
            Error and Crash Reports: Assists in identifying and resolving app
            issues efficiently.
          </li>

          <div className="privacy-policy-header">4. Data Retention</div>
          <div className="privacy-policy-text">
            App usage data and error logs are retained for 12 months to enhance
            the app experience, after which they are deleted or anonymized.
          </div>
          <div className="privacy-policy-text">
            Location data is temporarily accessed for BLE functionalities and
            not stored.
          </div>

          <div className="privacy-policy-header">
            5. Disclosure of Your Information
          </div>
          <div className="privacy-policy-text">
            We do not sell, rent, or trade your personal data. However, we may
            share anonymized usage analytics with trusted third-party services:
          </div>
          <li className="privacy-policy-list-item">
            <Tooltip
              title="https://aws.amazon.com/pinpoint/"
              arrow
              placement="right"
            >
              <a
                href="https://aws.amazon.com/pinpoint/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#2d7abf', textDecoration: 'none' }}
              >
                Amazon Web Services (AWS) Pinpoint
              </a>
            </Tooltip>
          </li>

          <div className="privacy-policy-header">6. User Rights & Control</div>
          <div className="privacy-policy-text">
            Under CCPA, users have the following rights:
          </div>
          <li className="privacy-policy-list-item">
            Access: Request a copy of your data.
          </li>
          <li className="privacy-policy-list-item">
            Correction: Request correction of inaccurate information.
          </li>
          <li className="privacy-policy-list-item">
            Deletion: Request deletion of your personal data.
          </li>
          <li className="privacy-policy-list-item">
            Opt-Out: Disable analytics tracking in app settings.
          </li>
          <li className="privacy-policy-list-item">
            Withdraw Consent: Users can revoke consent at any time through the
            privacy settings in the app.
          </li>

          <div className="privacy-policy-header">7. Data Security</div>
          <div className="privacy-policy-text">
            While no method of electronic transmission or data storage can
            guarantee absolute security, we employ industry-standard security
            protocols to safeguard transmitted information. All data is
            encrypted both in transit and at rest utilizing the Amazon Web
            Services (AWS) infrastructure.
          </div>

          <div className="privacy-policy-header">
            8. Changes to Our Privacy Policy
          </div>
          <div className="privacy-policy-text">
            We may update this policy from time to time. Updates will be posted
            on this page, and users are encouraged to review the policy
            periodically.
          </div>

          <div className="privacy-policy-header">9. Consent</div>
          <div className="privacy-policy-text">
            By creating a OneDose account, you explicitly consent to the
            collection and processing of data as described in this policy. Users
            may manage their privacy preferences within the app settings.
          </div>
        </div>
      </div>
    </Box>
  );
};

export default PrivacyPolicyPage;
