import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  findDepartmentOwner,
  getDepartmentsFromState,
  getFormattedDate,
  globals,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import { ProgressStatus, User } from '../../../../models';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../data/AmplifyVersion';
import DraftSaveModal from '../../../components/Modal/DraftSaveModal';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import CategoryItem from '../../../../data/model/CategoryItem';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { handleGetDepartment } from '../../../../store/actions';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
import HMSwitch from '../../../components/general/HMSwitch';
import { handleToggleEnabled } from '../../../../data/functions/ModelDB';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import DataList, { Header } from './DataList';
import HMCheckbox from '../../../components/general/HMCheckbox';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListProtocols = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = useMemo(() => {
    return database.department;
  }, [database.department]);

  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const [protocols, setProtocols] = useState<ProtocolItem[]>(
    database.protocols
  );
  const categories: CategoryItem[] = database.categories;
  const user: User = useSelector((state: any) => state?.user);
  const scrollPosition = useRef(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [filters, setFilters] = useState<any[]>(state?.search?.filters ?? []);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state?.search?.categoriesFilter ?? []
  );
  const [sort, setSort] = useState('name_asc');
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const filterOptions = [
    'Medications',
    'Infusions',
    'Equipment',
    'Electrical',
    'Paired Protocols',
    'Forms',
  ];

  const [list, setList] = useState<ProtocolItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<ProtocolItem[]>([]);
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);

  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    ProtocolItem[]
  >([]);
  const [viewing, setViewing] = useState<string | null>('all');

  const allDepartmentOwners = useMemo(() => {
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < protocols.length; i++) {
      let owner = findDepartmentOwner(department, reducerState, protocols[i]);
      if (owner && !deps.find((dep) => dep.id === owner?.id)) deps.push(owner);
    }
    setViewing('all');
    setFilters([]);
    return deps.sort((a, b) => a.name.localeCompare(b.name));
  }, [protocols, department, reducerState]);

  const reloadDatabase = async () => {
    const response = await loadDatabase(database, undefined, true);
    if (response.type === ResponseType.Success) {
      let protocols = response.data.protocols;
      setProtocols(protocols);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          protocols: protocols,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...pList];
    if (searchQuery !== '') {
      filteredList = protocols.filter((item) => {
        return (
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.nickname.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.uid.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item?.activeID?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item?.overrideID?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      });
    }
    if (filters.length > 0) {
      filteredList = filteredList.filter((item) => {
        //Check if the Filters (Department ID)
        return filters.some((filter) => {
          return (
            (item.status === ProgressStatus.DEACTIVATED &&
              item.overrideItem &&
              item.overrideItem.departmentID === filter.id) ||
            (item.status !== ProgressStatus.DEACTIVATED &&
              item.departmentID === filter.id)
          );
        });
      });
    }
    if (categoriesFilter.length > 0) {
      filteredList = filteredList.filter((item) => {
        return categoriesFilter.some(
          (category) => item.parent.uid === category.uid
        );
      });
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a, b) => {
      if (a.parent.index === b.parent.index) {
        if (a.index === b.index) return a.name.localeCompare(b.name);
        return a.index - b.index;
      }
      return a.parent.index - b.parent.index;
    });

    filteredList = filteredList.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort === 'modified_asc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return aDate.getTime() - bDate.getTime();
      } else if (sort === 'modified_desc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return bDate.getTime() - aDate.getTime();
      } else if (sort === 'file_size_asc') return a.getSum() - b.getSum();
      else if (sort === 'file_size_desc') return b.getSum() - a.getSum();
      else return a.name.localeCompare(b.name);
    });

    setList(filteredList);
  };

  const handleSave = async () => {
    if (itemDeactivationStatus && itemDeactivationStatus.length > 0) {
      try {
        let promises: any[] = [];
        for (let i = 0; i < itemDeactivationStatus.length; i++) {
          promises.push(
            handleToggleEnabled(
              user,
              database.department,
              reducerState,
              itemDeactivationStatus[i]
            )
          );
        }
        let results = await Promise.all(promises);
        console.log('RESULTS', results);
        for (let i = 0; i < results.length; i++) {
          if (results[i].type === ResponseType.Success) {
            if (globals.debug) console.log('SAVED ITEM', results[i].data);
          } else {
            console.error('ERROR SAVING ITEM', results[i].data);
          }
        }
        let n = results.filter(
          (result) => result.type === ResponseType.Success
        ).length;
        setSnackbar({
          ...snackbar,
          open: true,
          message:
            'Successfully updated ' + n + ' protocol' + (n === 1 ? '' : 's'),
          severity: 'success',
        });
        reloadDatabase().then(() => {
          setItemDeactivationStatus([]);
        });
      } catch (error) {
        console.error('ERROR SAVING DEACTIVATE STATE', error);
      }
    }
  };

  useEffect(() => {
    handleFilterChange(protocols);
  }, [searchQuery, filters, categoriesFilter, sort, protocols]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (protocol: ProtocolItem) => {
    const state = {
      selectedProtocol: protocol,
      editMode: false,
      backPage: 'list-protocols',
      search: { searchQuery, filters, categoriesFilter },
    };
    const formattedString = protocol.nickname.replace(/[\s\/]/g, '-');
    navigate(`/${formattedString}/protocol-detail`, { state });
  };

  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedItems([
        ...protocols.filter(
          (item) =>
            item.status !== 'DEACTIVATED' && department.id === item.departmentID
        ),
      ]);
    } else {
      setSelectedItems([]);
    }
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  /* 10-09-23 Arul: function for handling the draft confirm modal make new button functionality*/
  const handleMakeNew = async () => {
    navigate(`/protocol/edit`);
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = async () => {
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      else navigate(`/protocol/edit`);
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
  };

  const handleSortItem = (
    key: string,
    direction: string,
    a: ProtocolItem,
    b: ProtocolItem
  ) => {
    if (key === 'modified_date') {
      const aDate = a.updatedAt
        ? a.updatedAt
        : a.createdAt
          ? a.createdAt
          : new Date();
      const bDate = b.updatedAt
        ? b.updatedAt
        : b.createdAt
          ? b.createdAt
          : new Date();
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'file_size') {
      return direction === 'asc'
        ? a.getSum() - b.getSum()
        : b.getSum() - a.getSum();
    } else if (key === 'name') {
      return direction === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (key === 'modified_by') {
      let aUsername = a.modifiedBy
        ? a.modifiedBy.firstName + ' ' + a.modifiedBy.lastName
        : 'Hinckley Medical';
      let bUsername = b.modifiedBy
        ? b.modifiedBy.firstName + ' ' + b.modifiedBy.lastName
        : 'Hinckley Medical';
      return direction === 'asc'
        ? aUsername.localeCompare(bUsername)
        : bUsername.localeCompare(aUsername);
    } else if (key === 'owner') {
      let aOwnerName =
        findDepartmentOwner(department, reducerState, a)?.name ?? '';
      let bOwnerName =
        findDepartmentOwner(department, reducerState, b)?.name ?? '';
      return direction === 'asc'
        ? aOwnerName.localeCompare(bOwnerName)
        : bOwnerName.localeCompare(aOwnerName);
    }
    return a.name.localeCompare(b.name);
  };

  const handleSelectionChange = (protocol: ProtocolItem) => {
    if (selectedItems.find((item) => item.uid === protocol.uid)) {
      setSelectedItems((prevItems: ProtocolItem[]) => {
        const updatedItems = prevItems.filter(
          (item: ProtocolItem) => item.uid !== protocol.uid
        );

        if (updatedItems.length === 0) {
          setMainCheckbox(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, protocol];
      let available = protocols.filter(
        (item) =>
          item.status !== 'DEACTIVATED' && department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setMainCheckbox(true);
      } else if (mainCheckbox) {
        setMainCheckbox(false);
      }
    }
  };

  const headers: Header<ProtocolItem>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.25,
        render: (item: ProtocolItem) => {
          const isOwner = item.departmentID === department.id;
          const adminAccess = hasAdminUserAccess(
            department,
            reducerState,
            user
          );
          const isSwitchChecked = !itemDeactivationStatus.find(
            (newItem) => newItem.uid === item.uid
          )
            ? item.status !== ProgressStatus.DEACTIVATED
            : item.status === ProgressStatus.DEACTIVATED;

          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
              onClick={(e: any) => {
                e.stopPropagation();
                // handleItemClick(item);
              }}
            >
              {adminAccess && (
                <>
                  {isOwner && item.status !== ProgressStatus.DEACTIVATED ? (
                    <HMCheckbox
                      checked={selectedItems.some(
                        (selected) => selected.uid === item.uid
                      )}
                      onChange={() => handleSelectionChange(item)}
                    />
                  ) : (
                    <HMSwitch
                      checked={isSwitchChecked}
                      onChange={() => handleToggleEnabledUI(item)}
                    />
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item) => {
          const isOwner = item.departmentID === department.id;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(isOwner && item.status !== 'DEACTIVATED') && (
                <FaLock
                  className="table-icon"
                  style={{ marginRight: '8px' }}
                  color="#A3A3A3"
                />
              )}
              {item.name}
              {item.status !== 'ACTIVE' && (
                <Status status={item.status} style={{ marginLeft: '8px' }} />
              )}
            </div>
          );
        },
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item) =>
          getFormattedDate(
            item?.model?.updatedAt
              ? new Date(item.model.updatedAt)
              : new Date(),
            true
          ),
      },
      {
        key: 'modified_by',
        name: 'Modified By',
        sortable: true,
        flex: 1,
        render: (item) => {
          const user = item.modifiedBy;
          return user
            ? `${user.firstName} ${user.lastName}`
            : 'Hinckley Medical';
        },
      },
      {
        key: 'owner',
        name: 'Owner',
        sortable: true,
        flex: 1,
        render: (item) => {
          const departmentOwner = findDepartmentOwner(
            department,
            reducerState,
            item
          );
          return departmentOwner ? (
            <OwnerImage
              owner={departmentOwner}
              size={32}
              style={{ padding: 0, margin: 0 }}
            />
          ) : null;
        },
      },
      {
        key: 'file_size',
        name: 'File Size',
        sortable: true,
        flex: 1,
        render: (item) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 1,
              marginLeft: '0.5rem',
            }}
          >
            <span>{item.getSum()}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [
      protocols,
      department,
      reducerState,
      user,
      selectedItems,
      itemDeactivationStatus,
    ]
  );

  const customFilterComponent = useMemo(
    () =>
      allDepartmentOwners.length > 1 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            margin: '4px 0px',
          }}
        >
          <ToggleButtonGroup
            value={viewing}
            exclusive
            onChange={(event, newAlignment) => setViewing(newAlignment)}
            aria-label="owner"
            sx={{
              maxHeight: '50px',
            }}
          >
            <Tooltip title={'View All Medications'} placement="bottom" arrow>
              <ToggleButton
                value="all"
                aria-label="all"
                autoCapitalize="none"
                sx={{
                  padding: '0px 8px', // Adjust padding as needed for the "All" button
                  minWidth: 'auto', // Ensures the width is only as wide as the content
                }}
                onChange={() => {
                  setViewing('all');
                  setFilters([]);
                }}
              >
                All
              </ToggleButton>
            </Tooltip>
            {allDepartmentOwners.map((owner) => (
              <ToggleButton
                key={owner.id}
                value={owner.id}
                onClick={() => {
                  setViewing(owner.id);
                  setFilters([owner]);
                }}
              >
                <Tooltip title={owner.name} placement="bottom" arrow>
                  <img
                    className="no-select owner-logo"
                    src={owner.logoVerifiedUrl ?? ''}
                    alt="Agency Logo"
                    style={{ width: 40, height: 40 }}
                  />
                </Tooltip>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      ) : null,
    [allDepartmentOwners, viewing]
  );

  const handleToggleEnabledUI = async (item: ProtocolItem) => {
    let find = itemDeactivationStatus.find(
      (newItem) => newItem.uid === item.uid
    );
    if (find)
      setItemDeactivationStatus(
        itemDeactivationStatus.filter((newItem) => newItem.uid !== item.uid)
      );
    else setItemDeactivationStatus([...itemDeactivationStatus, item]);
  };

  const getRowClassName = useCallback(
    (params: ProtocolItem) => {
      const rowItem = list.find((item) => item.uid === params.uid);
      const adminAccess = hasAdminUserAccess(
        department,
        reducerState,
        user,
        rowItem
      );
      // Apply background color when adminAccess is false or the item status is not DEACTIVATED.
      if (!(adminAccess && rowItem?.status !== 'DEACTIVATED')) {
        return 'inactive';
      }

      // If the row is selected, give it the selected class.
      const isSelected = selectedItems.some((item) => item.uid === params.id);
      if (isSelected) return 'selectedRow';

      // Default return for other cases
      return '';
    },
    [department, list, user, reducerState, selectedItems]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => {
          setSnackbar({ ...snackbar, open: false });
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => {
            setSnackbar({ ...snackbar, open: false });
          }}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Medication?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={() => {
            // Implement the logic to delete selected items
          }}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} protocols?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}
      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            navigate(`/protocol/edit`);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Protocols: ' + list.length + ' items'
            : 'Protocols: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        isSaveButton={itemDeactivationStatus.length > 0}
        isSaveActive={itemDeactivationStatus.length > 0}
        page={department.name}
        rightSideBtn={'edit'}
        isEditIcon={true}
        handleSave={handleSave}
        handleCancelEdit={() => {
          console.log('Cancel Edit');
          setItemDeactivationStatus([]);
          setProtocols([...protocols]);
          handleFilterChange([...protocols]);
        }}
        handleEdit={handleCheckIsDraft}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />
      <DataList<ProtocolItem, CategoryItem>
        items={list}
        headers={headers}
        onItemClick={handleItemClick}
        onSelectionChange={setSelectedItems}
        searchPlaceholder="Search Protocols..."
        customFilterComponent={customFilterComponent}
        sortItem={handleSortItem}
        selectedItems={selectedItems}
        getRowClassName={getRowClassName}
        handleSelectAll={handleSelectAll}
        filterOptions={[
          {
            key: 'uid',
            title: 'Categories',
            options: database.categories,
            labelField: (option) => option.name,
            keyField: (option) => option.uid,
            filterFn: (protocol, selectedCategories) => {
              return selectedCategories.some(
                (category) => protocol.parent.uid === category.uid
              );
            },
          },
        ]}
      />
    </div>
  );
};

export default ListProtocols;
