import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import {
  AiOutlineClose,
  AiOutlineFileText,
  AiOutlineFullscreen,
  AiOutlinePlus,
  AiOutlineRight,
} from 'react-icons/ai';
import {
  BiChevronLeft,
  BiChevronRight,
  BiCopy,
  BiFullscreen,
  BiLock,
  BiSolidCopy,
} from 'react-icons/bi';
import { FaFileExcel } from 'react-icons/fa';

import { FaFolderOpen } from 'react-icons/fa';
import { FiUpload } from 'react-icons/fi';
import {
  IoArrowBack,
  IoDocumentText,
  IoEllipsisVertical,
  IoSave,
} from 'react-icons/io5';
import { toTitleCase } from '../../_global/common/Utils';
import { CgRename } from 'react-icons/cg';
import { useState } from 'react';
import { ProgressStatus } from '../../../API';
import Status from '../../components/ProgressStatus/ProgressStatus';
import { FaDownload, FaLock, FaTrash, FaUserCheck } from 'react-icons/fa6';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import DepartmentItem from '../../../data/model/DepartmentItem';
import OwnerImage from '../../components/OwnerImage/OwnerImage';
import { BsCloudFill, BsCloudSlashFill } from 'react-icons/bs';
import { MenuItem, Select, Tooltip } from '@mui/material';
import { RiPencilFill } from 'react-icons/ri';
import { IoIosAddCircle, IoMdAdd } from 'react-icons/io';
import {
  MdCancel,
  MdOutlineCancel,
  MdOutlineDriveFileMove,
} from 'react-icons/md';
import ButtonDropdown from '../../components/Dropdown/ButtonDropdown';
import DraftChangeItem from '../../../data/model/DraftChangeItem';
import { useNavigate } from 'react-router-dom';
import CopyToClipboard from '../../components/CopyToClipboard';
import { getFullReviewal } from '../../../data/functions/ReviewalDB';

interface ProtocolHeaderProps {
  name?: any;
  homeScreen?: boolean;
  type?: string;
  page?: string;
  descriptionTitle?: string;
  status?: ProgressStatus | keyof typeof ProgressStatus;
  bypassActiveStatusCheck?: boolean;
  description?: any;
  customDescription?: any;
  disableButton?: boolean;
  draftChangeLocked?: DraftChangeItem | null;
  handleDraftChangeLocked?: (draftChange: DraftChangeItem) => void;
  // isSubmenu?: boolean;
  isBackButton?: boolean;
  handleNew?: () => void;
  handleCancel?: () => void;
  handleSave?: () => void;
  handleReview?: () => void;
  itemOwner?: DepartmentItem;
  isVersionButton?: boolean;
  isAddButton?: boolean;
  isEditButton?: boolean;
  isEditIcon?: boolean;
  isSaveButton?: boolean;
  isCancelButton?: boolean;
  isDotButton?: boolean;
  isFullScreenButton?: boolean;
  isLockButton?: boolean;
  isUploadButton?: boolean;
  isRenameButton?: boolean;
  isMoveButton?: boolean;
  isDuplicateButton?: boolean;
  isFolderEditButton?: boolean;
  isSaveActive?: boolean;
  isCreateActive?: boolean;
  isSendActive?: boolean;
  isCreateButton?: boolean;
  isDeleteButton?: boolean;
  handleUnsubscribe?: () => void;
  isReviewButton?: boolean;
  isReviewActive?: boolean;
  isDownloadButton?: boolean;
  isNewButton?: boolean;
  isNewActive?: boolean;
  isfullScreenButton?: boolean;
  isSendButton?: boolean;
  isViewButton?: boolean;
  isBaseFolder?: boolean;
  isEnableButton?: boolean;
  isCustomButton?: boolean;
  isDeactivateButton?: boolean;
  isUnsubscribeIcon?: boolean;
  isUnsubscribeActive?: boolean;
  isUnsubscribeButton?: boolean;
  isReactivateButton?: boolean;
  customText?: string;
  isLogo?: boolean;
  handleEnable?: () => void;
  handleFullScreen?: () => void;
  handleLock?: () => void;
  handleViewFullScreen?: () => void;
  handleAdd?: () => void;
  handleEdit?: () => void;
  handleUpload?: () => void;
  handleVersion?: () => void;
  handleNewFolder?: () => void;
  handleNewProtocol?: () => void;
  handleCreate?: () => void;
  handleRename?: () => void;
  handleFolderEdit?: () => void;
  handleCancelEdit?: () => void;
  handleDelete?: () => void;
  handleCustom?: (text: string) => void;
  handleMove?: () => void;
  handleDuplicate?: () => void;
  handleDownload?: () => void;
  handleReactivate?: () => void;
  handleDeactivate?: () => void;
  newButtonRef?: any;
  protocolDetail?: any;
  rightSideBtn?: any;
  isDeleteDisabled?: boolean;
  isCustomActive?: boolean;
  isCopyDescription?: boolean;
  isArrowButtons?: boolean;
  isLeftArrowActive?: boolean;
  isRightArrowActive?: boolean;
  isDeactivateActive?: boolean;
  isReactivateActive?: boolean;
  handleArrow?: (isRight: boolean) => void;
  isExportButton?: boolean;
  isExportIcon?: boolean;
  exportActive?: boolean;
  handleExport?: () => void;
  isAddIcon?: boolean;
  handleReviewClicked?: (item: DraftChangeItem) => void;
}

/* 10-11-23 Arul: Created the Protocol Header Component globally*/
const ProtocolHeader: React.FC<ProtocolHeaderProps> = (props) => {
  const {
    name,
    page,
    descriptionTitle,
    description,
    customDescription,
    status,
    bypassActiveStatusCheck,
    homeScreen,
    isBackButton,
    isfullScreenButton,
    isDeactivateButton,
    isDeactivateActive,
    isUnsubscribeIcon,
    isUnsubscribeActive,
    isUnsubscribeButton,
    isLogo,
    handleViewFullScreen,
    rightSideBtn,
    protocolDetail,
    isVersionButton,
    isNewButton,
    isNewActive,
    isSendButton,
    isViewButton,
    isCreateActive,
    isCustomActive,
    isFolderEditButton,
    isRenameButton,
    isMoveButton,
    isDuplicateButton,
    isReactivateActive,
    isDownloadButton,
    handleMove,
    handleRename,
    handleFolderEdit,
    isAddButton,
    isAddIcon,
    isEditButton,
    isEditIcon,
    isUploadButton,
    isSaveButton,
    isCancelButton,
    disableButton,
    isSaveActive,
    isSendActive,
    isCreateButton,
    isDeleteButton,
    handleUnsubscribe,
    isReviewButton,
    isReviewActive,
    isBaseFolder,
    handleDelete,
    isFullScreenButton,
    isLockButton,
    isDotButton,
    isEnableButton,
    isCustomButton,
    itemOwner,
    customText,
    draftChangeLocked,
    handleDraftChangeLocked,
    handleCustom,
    handleEnable,
    handleCreate,
    handleNewFolder,
    handleNewProtocol,
    handleVersion,
    handleAdd,
    handleEdit,
    handleNew,
    handleFullScreen,
    handleLock,
    handleDuplicate,
    handleDownload,
    type,
    handleCancel,
    handleSave,
    handleCancelEdit,
    handleReview,
    handleUpload,
    isReactivateButton,
    handleDeactivate,
    handleReactivate,
    newButtonRef,
    isDeleteDisabled,
    isCopyDescription,
    isArrowButtons,
    isLeftArrowActive,
    isRightArrowActive,
    handleArrow,
    isExportButton,
    isExportIcon,
    exportActive,
    handleExport,
    handleReviewClicked,
  } = props;
  return (
    <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
      <div
        style={{ margin: '0px 10px', display: 'flex', flexDirection: 'row' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '10px 0px',
            flex: 1,
            overflowX: 'auto',
          }}
        >
          <div className={`titleStyling ${homeScreen ? 'colAlignCenter' : ''}`}>
            {type === 'protocol' && (
              <div
                className=""
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <h6
                  className={`backTextLight  ${
                    homeScreen ? 'titleText' : ''
                  } ${isBackButton ? 'hoverText' : ''}`}
                  onClick={handleCancel}
                >
                  {isBackButton && (
                    <span>
                      <IoArrowBack
                        data-testid="isBackBttn"
                        size="1.15rem"
                        onClick={handleCancel}
                        style={{ marginRight: '5px' }}
                      />
                    </span>
                  )}
                  {protocolDetail ? protocolDetail.name : page}
                </h6>
              </div>
            )}
            {name && (
              // <div className="titleItemContainer">
              //   <div className="titleItemText">
              //       <div
              //           className="hoverableText"
              //           onClick={(e) => {
              //               e.stopPropagation();
              //               handleTabClick(item, true);
              //           }}
              //       >
              //           {item?.name}
              //       </div>
              //   </div>
              //   {item.status !== "ACTIVE" &&
              //       <Status
              //           status={item.status}
              //       />
              //   }
              // </div>
              <div className="titleItemContainer">
                <div className="titleTextPH">{name}</div>
                {status && (bypassActiveStatusCheck || status !== 'ACTIVE') && (
                  <Status
                    status={status}
                    textStyle={{ fontSize: '16px' }}
                    style={{ marginLeft: '10px' }}
                  />
                )}
                {draftChangeLocked && (
                  <div
                    className="lock-icon"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip
                      title="This item is currently in review. It cannot be modified while it is in review."
                      placement="bottom"
                      arrow
                    >
                      <button
                        className="in-review-button"
                        onClick={() =>
                          // handleDraftChangeLocked &&
                          // handleDraftChangeLocked(draftChangeLocked)
                          handleReviewClicked &&
                          handleReviewClicked(draftChangeLocked)
                        }
                      >
                        <FaLock
                          data-testid="isLockButton"
                          style={{
                            // color: '#0d4d8e',
                            // fontSize: '1.0rem',
                            marginBottom: '2px',
                            marginRight: '5px',
                          }}
                        />
                        In Review
                      </button>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
            {description && (
              <h6
                className={`headerTextLight  ${homeScreen ? 'titleText' : ''}`}
                style={{
                  fontWeight: '400',
                  color: '#616161',
                  marginRight: '2rem',
                }}
              >
                {descriptionTitle}
                {description}
                {isCopyDescription && <CopyToClipboard text={description} />}
              </h6>
            )}
            {customDescription && <>{customDescription}</>}
          </div>
        </div>
        {type !== 'protocol' ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 0,
            }}
          >
            <div className="protocolHeaderButton">
              <>
                {isReactivateButton && (
                  <Tooltip title={'Reactivate'} placement="bottom" arrow>
                    <span
                      className={`iconButton ${isReactivateActive ? 'disabled' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReactivate && handleReactivate();
                      }}
                    >
                      <BsCloudFill
                        data-testid="isFullScreenButton"
                        className="icon"
                        style={{
                          padding: '6px',
                          color: isReactivateActive ? '#e0e0e0' : '#a94c4c',
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {isDeactivateButton && (
                  <Tooltip title={'Deactivate'} placement="bottom" arrow>
                    <span
                      className={`iconButton ${isDeactivateActive ? 'disabled' : ''}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeactivate && handleDeactivate();
                      }}
                    >
                      <BsCloudSlashFill
                        data-testid="isFullScreenButton"
                        className="icon"
                        style={{
                          padding: '6px',
                          color: isDeactivateActive ? '#e0e0e0' : '#a94c4c',
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
                {isUnsubscribeIcon && (
                  <Tooltip title={'Unsubscribe'} placement="bottom" arrow>
                    <span
                      className={`iconButton ${!isUnsubscribeActive ? 'disabled' : ''}`}
                      onClick={(e) => {
                        if (!isUnsubscribeActive) return;
                        e.stopPropagation();
                        handleUnsubscribe && handleUnsubscribe();
                      }}
                    >
                      <BsCloudSlashFill
                        data-testid="isFullScreenButton"
                        className="icon"
                        style={{
                          padding: '6px',
                          color: !isUnsubscribeActive ? '#e0e0e0' : '#a94c4c',
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
              </>
              {isDeleteButton && (
                <Tooltip title={'Delete'} placement="bottom" arrow>
                  <span
                    className={`iconButton ${isDeleteDisabled ? 'disabled' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete && handleDelete();
                    }}
                  >
                    <FaTrash
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: isDeleteDisabled ? '#e0e0e0' : '#a94c4c',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isUnsubscribeIcon && (
                <Tooltip title={'Unsubscribe'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUnsubscribe && handleUnsubscribe();
                    }}
                  >
                    <BsCloudSlashFill
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#a94c4c',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isDownloadButton && (
                <Tooltip
                  title={'Download Protocol PDF(s)'}
                  placement="bottom"
                  arrow
                >
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDownload && handleDownload();
                    }}
                  >
                    <FaDownload
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isMoveButton && (
                <Tooltip
                  title={'Move to different Folder'}
                  placement="bottom"
                  arrow
                >
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMove && handleMove();
                    }}
                  >
                    <MdOutlineDriveFileMove
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isRenameButton && (
                <Tooltip title={'Rename'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRename && handleRename();
                    }}
                  >
                    <CgRename
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isFolderEditButton && (
                <Tooltip title={'Edit'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFolderEdit && handleFolderEdit();
                    }}
                  >
                    <RiPencilFill
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}

              {isCancelButton && (
                <Tooltip title={'Cancel'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={() => {
                      handleCancel && handleCancel();
                    }}
                    style={{ marginRight: '10px' }}
                  >
                    <MdCancel
                      data-testid="isDotButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              <>
                {isNewButton && (
                  <ButtonDropdown
                    title="New"
                    titleIcon={<AiOutlinePlus />}
                    options={
                      isBaseFolder
                        ? [
                            {
                              value: '1',
                              label: 'Folder',
                              icon: <FaFolderOpen />,
                            },
                          ]
                        : [
                            {
                              value: '2',
                              label: 'Protocol',
                              icon: <IoDocumentText />,
                            },
                          ]
                    }
                    onChange={(option) => {
                      if (option.value === '1') {
                        handleNewFolder && handleNewFolder();
                      } else {
                        handleNewProtocol && handleNewProtocol();
                      }
                    }}
                    style={{
                      marginLeft: '10px',
                    }}
                  />
                )}
              </>
              {isSaveButton && (
                <Button
                  data-testid="isSaveButton"
                  className={` ${
                    // rightSideBtn === "save"
                    'primary-button btn-rightMargin'
                    // : "secondary-button-small-border btn-rightMargin"
                  }`}
                  onClick={handleSave}
                  disabled={!isSaveActive}
                >
                  <span>
                    <IoSave size="1rem" />
                  </span>{' '}
                  Save
                </Button>
              )}
              {isReviewButton && (
                <Button
                  className="primary-button-small"
                  disabled={disableButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReview && handleReview();
                  }}
                >
                  Review{' '}
                  <span>
                    <AiOutlineRight className="icon-normal" />
                  </span>
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 0,
            }}
          >
            <div className="headerButtonContainer">
              {isVersionButton && (
                <Button
                  data-testid="isVersionButton"
                  className="secondary-button-small-border btn-rightMargin"
                  onClick={handleVersion}
                >
                  Version(s)
                </Button>
              )}
              {isDeleteButton && (
                <Tooltip title={'Delete'} placement="bottom" arrow>
                  <span
                    className={`iconButton ${isDeleteDisabled ? 'disabled' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete && handleDelete();
                    }}
                    style={{
                      marginRight: '10px',
                    }}
                  >
                    <FaTrash
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: isDeleteDisabled ? '#e0e0e0' : '#8c1010',
                      }}
                    />
                  </span>
                </Tooltip>
              )}

              {isDownloadButton && (
                <Button
                  data-testid="isEditButton"
                  className={` ${
                    rightSideBtn === 'edit'
                      ? 'primary-button btn-rightMargin'
                      : 'secondary-button-small-border btn-rightMargin'
                  }`}
                  onClick={handleDownload}
                >
                  <span>
                    <FaDownload className="icon-size" />
                  </span>{' '}
                  Download
                </Button>
              )}
              {isAddButton && rightSideBtn !== 'add' && (
                <Button
                  data-testid="isAddButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleAdd}
                >
                  <span>
                    <AiOutlinePlus className="icon-normal" />
                  </span>{' '}
                  Add
                </Button>
              )}

              {isAddIcon && (
                <Tooltip title={'Add'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={() => {
                      handleAdd && handleAdd();
                    }}
                  >
                    <IoMdAdd
                      data-testid="isDotButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        // color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}

              {isSendButton && (
                <Button
                  data-testid="isSendButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleCreate}
                  disabled={!isSendActive}
                >
                  Send
                </Button>
              )}
              {isViewButton && (
                <Button
                  data-testid="isViewButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleCreate}
                >
                  {' '}
                  View
                </Button>
              )}

              {isUploadButton && (
                <Tooltip title={'Upload'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={() => {
                      handleUpload && handleUpload();
                    }}
                  >
                    <FiUpload
                      data-testid="isDotButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        // color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
                // <Button
                //   data-testid="isUploadButton"
                //   className="secondary-button-small-border btn-rightMargin"
                //   onClick={handleUpload}
                // >
                //   <span>
                //     <FiUpload className="icon-size" />
                //   </span>{' '}
                //   Upload
                // </Button>
              )}

              {isEnableButton && (
                <Button
                  data-testid="isEnableButton"
                  className={` ${
                    // rightSideBtn === "save"
                    'primary-button btn-rightMargin'
                    // : "secondary-button-small-border btn-rightMargin"
                  }`}
                  onClick={handleEnable}
                >
                  <span>
                    <FaUserCheck size="1rem" />
                  </span>{' '}
                  Enable
                </Button>
              )}

              {isfullScreenButton && (
                <Button
                  data-testid="isfullScreenButton"
                  className="fullScreenBtn primary-button btn-rightMargin"
                  onClick={handleViewFullScreen}
                >
                  <span>
                    <BiFullscreen className="view_pdf_icon" />
                  </span>
                  Full Screen
                </Button>
              )}
              {isEditIcon && (
                <Tooltip title={'Edit'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit && handleEdit();
                    }}
                  >
                    <RiPencilFill
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        // color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isEditButton && (
                <Button
                  data-testid="isReviewButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleEdit}
                  style={
                    {
                      // marginLeft: '10px',
                    }
                  }
                >
                  <RiPencilFill className="icon-normal" /> Edit
                </Button>
              )}
              {isFullScreenButton && (
                <Tooltip title={'Full Screen'} placement="bottom" arrow>
                  <span
                    className="iconButton"
                    onClick={() => {
                      handleFullScreen && handleFullScreen();
                    }}
                  >
                    <AiOutlineFullscreen
                      data-testid="isFullScreenButton"
                      className="icon"
                    />
                  </span>
                </Tooltip>
              )}
              {isArrowButtons && (
                <Tooltip title={'Previous'} placement="bottom" arrow>
                  <span
                    className={`iconButton ${isLeftArrowActive ? '' : 'disabled'}`}
                    onClick={() => {
                      if (isLeftArrowActive) handleArrow && handleArrow(false);
                    }}
                  >
                    <BiChevronLeft data-testid="isDotButton" className="icon" />
                  </span>
                </Tooltip>
              )}
              {isArrowButtons && (
                <Tooltip title={'Next'} placement="bottom" arrow>
                  <span
                    className={`iconButton ${isRightArrowActive ? '' : 'disabled'}`}
                    onClick={() => {
                      handleArrow && handleArrow(true);
                    }}
                  >
                    <BiChevronRight
                      data-testid="isDotButton"
                      className="icon"
                    />
                  </span>
                </Tooltip>
              )}

              {isAddButton && rightSideBtn === 'add' && (
                <Tooltip title={'Add'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={() => {
                      handleAdd && handleAdd();
                    }}
                  >
                    <IoMdAdd
                      data-testid="isDotButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        // color: '#616161',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isSaveButton && (
                <>
                  <Tooltip title={'Cancel'} placement="bottom" arrow>
                    <span
                      className={`iconButton`}
                      onClick={() => {
                        handleCancelEdit && handleCancelEdit();
                      }}
                      style={{ marginRight: '10px' }}
                    >
                      <MdCancel
                        data-testid="isDotButton"
                        className="icon"
                        style={{
                          padding: '6px',
                          // color: '#616161',
                        }}
                      />
                    </span>
                  </Tooltip>

                  <Button
                    data-testid="isSaveButton"
                    className={` ${
                      // rightSideBtn === "save"
                      'primary-button btn-rightMargin'
                      // : "secondary-button-small-border btn-rightMargin"
                    }`}
                    onClick={handleSave}
                    disabled={!isSaveActive}
                  >
                    <span>
                      <IoSave size="1rem" />
                    </span>{' '}
                    Save
                  </Button>
                </>
              )}
              <>
                {isReactivateButton && (
                  <Button
                    className="primary-button"
                    data-testid="deleteBtn"
                    onClick={() => handleReactivate && handleReactivate()}
                    disabled={!isReactivateActive}
                  >
                    <span>
                      <BsCloudFill className="icon-normal" />
                    </span>{' '}
                    Reactivate
                  </Button>
                )}
                {isDeactivateButton && (
                  <Button
                    className="red-background-button btn-rightMargin "
                    data-testid="deleteBtn"
                    onClick={() => handleDeactivate && handleDeactivate()}
                    disabled={!isDeactivateActive}
                  >
                    <span>
                      <BsCloudSlashFill className="icon-normal" />
                    </span>{' '}
                    Deactivate
                  </Button>
                )}
              </>
              {isUnsubscribeIcon && (
                <Tooltip title={'Unsubscribe'} placement="bottom" arrow>
                  <span
                    className={`iconButton ${!isUnsubscribeActive ? 'disabled' : ''}`}
                    onClick={(e) => {
                      if (!isUnsubscribeActive) return;
                      e.stopPropagation();
                      handleUnsubscribe && handleUnsubscribe();
                    }}
                  >
                    <BsCloudSlashFill
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                        color: !isUnsubscribeActive ? '#e0e0e0' : '#8c1010',
                      }}
                    />
                  </span>
                </Tooltip>
              )}
              {isUnsubscribeButton && (
                <Button
                  className="red-background-button btn-rightMargin "
                  data-testid="deleteBtn"
                  onClick={() => handleUnsubscribe && handleUnsubscribe()}
                  disabled={!isUnsubscribeActive}
                >
                  <span>
                    <BsCloudSlashFill className="icon-normal" />
                  </span>{' '}
                  Unsubscribe
                </Button>
              )}
              {isExportButton && (
                <Button
                  className="secondary-button-small-border btn-rightMargin"
                  data-testid="isExportButton"
                  onClick={() => handleExport && handleExport()}
                  disabled={!exportActive}
                >
                  <span style={{ marginRight: 8 }}>Export</span>
                  <span>
                    <FaFileExcel size="1rem" />
                  </span>
                </Button>
              )}
              {isExportIcon && (
                <Tooltip title={'Export Database'} placement="bottom" arrow>
                  <span
                    className={`iconButton`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleExport && handleExport();
                    }}
                  >
                    <FaFileExcel
                      data-testid="isFullScreenButton"
                      className="icon"
                      style={{
                        padding: '6px',
                      }}
                    />
                  </span>
                </Tooltip>
              )}

              {isCancelButton && (
                <Button
                  data-testid="isCancelButton"
                  className="secondary-button-small-border btn-rightMargin"
                  onClick={handleCancelEdit}
                >
                  <span>
                    <AiOutlineClose className="icon-normal" size="1rem" />
                  </span>{' '}
                  Cancel
                </Button>
              )}

              {isCustomButton && (
                <Button
                  data-testid="isReviewButton"
                  className="primary-button btn-rightMargin"
                  onClick={() => {
                    handleCustom && handleCustom(customText ? customText : '');
                  }}
                  disabled={!isCustomActive}
                >
                  {customText + ' '}
                  <span>
                    <AiOutlineRight className="icon-normal" />
                  </span>
                </Button>
              )}
              {isCreateButton && (
                <Button
                  data-testid="isCreateButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleCreate}
                  disabled={!isCreateActive}
                >
                  <span>
                    <AiOutlinePlus className="icon-normal" />
                  </span>{' '}
                  Create
                </Button>
              )}

              {isReviewButton && (
                <Button
                  data-testid="isReviewButton"
                  className="primary-button btn-rightMargin"
                  onClick={handleReview}
                  disabled={!isReviewActive}
                  style={
                    {
                      // marginLeft: '10px',
                    }
                  }
                >
                  Review{' '}
                  <span>
                    <AiOutlineRight className="icon-normal" />
                  </span>
                </Button>
              )}
              {isDotButton && (
                <span className="iconButton">
                  <IoEllipsisVertical
                    data-testid="isDotButton"
                    className="icon"
                  />
                </span>
              )}
              {itemOwner && (
                <Tooltip
                  title={itemOwner.name + ' owns this item.'}
                  placement="bottom"
                  arrow
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -14],
                          },
                        },
                      ],
                    },
                  }}
                >
                  <div className="protocol-header-logo-container">
                    <img
                      className="no-select owner-logo"
                      src={itemOwner.logoVerifiedUrl ?? ''}
                      alt="Agency Logo"
                    />
                    <div className="lock-icon">
                      <FaLock
                        data-testid="isLockButton"
                        style={{
                          color: isLockButton ? '#A3A3A3' : 'transparent',
                          fontSize: '1.25rem',
                        }}
                      />
                    </div>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </div>
      <hr />
    </div>
  );
};

export default ProtocolHeader;
