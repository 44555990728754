import React, { useMemo } from 'react';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import {
  getDepartmentsFromState,
  globals,
  toTitleCase,
} from '../../../_global/common/Utils';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import ProtocolComparisonView from '../comparisonView/ProtocolComparisonView';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { useSelector } from 'react-redux';
import ReviewalItem from '../../../../data/model/ReviewalItem';
import { useNavigate } from 'react-router-dom';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  reviewal: ReviewalItem;
  department: DepartmentItem;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

interface Change {
  key: string;
  currentValue: string;
  archiveValue: string;
}

export const compareProtocolItems = (
  currentItem: ProtocolItem,
  archiveItem: ProtocolItem,
  departments: DepartmentItem[]
): Change[] => {
  if (currentItem === null) return [];
  const changes: Change[] = [];

  const findAllDepartments = (
    item: ProtocolItem,
    departments: DepartmentItem[]
  ): string => {
    if (item == null) return '';
    if (item.isRestrictive) {
      if (item.pairedDepIDs == null) return '';
      let deps = item.pairedDepIDs.map((id) =>
        departments.find((dep) => dep.id === id)
      );
      return deps.map((dep) => dep?.id).join(', ');
    } else {
      const allDeps = departments.map((dep) => dep.id).join(', ');
      return allDeps;
    }
  };

  // Define the specific fields to check
  const fieldsToCompare: (keyof ProtocolItem)[] = [
    'name',
    'nickname',
    'rangeLow',
    'rangeHigh',
    'pairedDepIDs',
    'pairedProtocolIDs',
    'equipmentIDs',
    'checklistIDs',
    'pdfUrl',
  ];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  fieldsToCompare.forEach((key) => {
    let currentValue = currentItem[key];
    let archiveValue = archiveItem ? archiveItem[key] : '';

    if (key === 'rangeHigh') {
      currentValue =
        currentValue === globals.MAX_VALUE
          ? 'Max'
          : (currentValue as string) || '';
      archiveValue =
        archiveValue === globals.MAX_VALUE
          ? 'Max'
          : (archiveValue as string) || '';
    }

    if (key === 'rangeLow') {
      currentValue =
        currentValue === 0 ? 'Min' : (currentValue as string) || '';
      archiveValue =
        archiveValue === 0 ? 'Min' : (archiveValue as string) || '';
    }

    if (key === 'pairedDepIDs') {
      currentValue = findAllDepartments(currentItem, departments);
      archiveValue = findAllDepartments(archiveItem, departments);
    }

    if (key === 'pairedProtocolIDs') {
      //Compare the protocols by IDs
      let currentProtocols = currentItem.pairedProtocolIDs || [];
      let archiveProtocols = archiveItem?.pairedProtocolIDs || [];
      for (let i = 0; i < currentProtocols.length; i++) {
        if (!archiveProtocols.includes(currentProtocols[i])) {
          changes.push({
            key: 'pairedProtocolIDs',
            currentValue: currentProtocols.join(', '),
            archiveValue: archiveProtocols.join(', '),
          });
          break;
        }
      }
    } else if (key === 'equipmentIDs') {
      //Compare the equipment by IDs
      let currentEquipment = currentItem.equipmentIDs || [];
      let archiveEquipment = archiveItem?.equipmentIDs || [];
      for (let i = 0; i < currentEquipment.length; i++) {
        if (!archiveEquipment.includes(currentEquipment[i])) {
          changes.push({
            key: 'equipmentIDs',
            currentValue: currentEquipment.join(', '),
            archiveValue: archiveEquipment.join(', '),
          });
          break;
        }
      }
    } else if (compareValues(currentValue, archiveValue)) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  return changes;
};

const ProtocolComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  reviewal,
  department,
  onChangesCount, // Add the callback prop
}) => {
  const reducerState = useSelector((state: any) => state.department);
  const { allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);
  const navigate = useNavigate();
  const { changeItem, previousItem } = useMemo(() => {
    return {
      changeItem: currentDraft?.changeItem as ProtocolItem,
      previousItem: currentDraft?.previousItem as ProtocolItem | null,
    };
  }, [currentDraft]);
  const changes = useMemo(() => {
    const comparedChanges = compareProtocolItems(
      changeItem as ProtocolItem,
      previousItem as ProtocolItem,
      [department, ...allSubDeps]
    );
    if (onChangesCount) {
      onChangesCount(comparedChanges.length); // Call the callback with the changes count
    }
    return comparedChanges;
  }, [changeItem, previousItem, onChangesCount, allSubDeps]);

  const handleViewClick = (mode: 'before' | 'after') => {
    if (previousItem) {
      (previousItem as ProtocolItem).pairedDeps = (
        changeItem as ProtocolItem
      ).pairedDeps;
      (previousItem as ProtocolItem).medications = (
        changeItem as ProtocolItem
      ).medications;
      (previousItem as ProtocolItem).infusions = (
        changeItem as ProtocolItem
      ).infusions;
      (previousItem as ProtocolItem).equipment = (
        changeItem as ProtocolItem
      ).equipment;
      (previousItem as ProtocolItem).electrical = (
        changeItem as ProtocolItem
      ).electrical;
      (previousItem as ProtocolItem).forms = (changeItem as ProtocolItem).forms;
      (previousItem as ProtocolItem).pairedProtocols = (
        changeItem as ProtocolItem
      ).pairedProtocols;
      (previousItem as ProtocolItem).createdBy = (
        changeItem as ProtocolItem
      ).createdBy;
      (previousItem as ProtocolItem).modifiedBy = (
        changeItem as ProtocolItem
      ).modifiedBy;
      (previousItem as ProtocolItem).keychain = (
        changeItem as ProtocolItem
      ).keychain;
      (previousItem as ProtocolItem).sum = (changeItem as ProtocolItem).sum;
    }
    let protocol =
      mode === 'before' && previousItem ? previousItem : changeItem;

    const state = {
      selectedProtocol: protocol,
      editMode: false,
      reviewal: reviewal,
      currentDraft: currentDraft,
    };
    //Replace everything but [A-Za-z0-9] with '-'
    let cleanedPath = protocol.nickname.replace(/[^A-Za-z0-9]/g, '-');
    navigate(`/${cleanedPath}/protocol-detail`, { state });
  };

  return (
    <div>
      <div className="doseChangesWrapper">
        <table className="changesTable">
          {previousItem ? (
            <>
              <thead>
                <tr>
                  <th className="changeHeader beforeChange">
                    <div className="headerContent">
                      Before
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('before')}
                      >
                        View
                      </button>
                    </div>
                  </th>
                  <th className="changeHeader afterChange">
                    {' '}
                    <div className="headerContent">
                      After
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('after')}
                      >
                        View
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <ProtocolComparisonView
                focusItem={changeItem as ProtocolItem}
                department={department}
                changes={changes}
              />
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th className="changeHeader afterChange">
                    {' '}
                    <div className="headerContent">
                      New {toTitleCase(currentDraft.changeType)}
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('after')}
                      >
                        View
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <ProtocolComparisonView
                focusItem={changeItem as ProtocolItem}
                department={department}
                changes={changes}
              />
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default ProtocolComparison;
