import { useFormik } from 'formik';
import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import { handleGetDepartment } from '../../../../../store/actions';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import DraftSaveModal from '../../../../components/Modal/DraftSaveModal';
import ChecklistSideout from '../../../../components/SideOut/doseSideOut/ChecklistSideout';
import ProtocolHeader from '../../ProtocolHeader';
import {
  DatabaseResponse,
  ResponseType,
  Response,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import CheckListGroups from './CheckListGroups';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../../data/AmplifyVersion';
import { FormGroup, User } from '../../../../../models';
import {
  confirmAllItemsHaveID,
  findDepartmentOwner,
  generateID,
  getActiveID,
  getFormattedDate,
  globals,
  hasAdminUserAccess,
  isObjectEqual,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import {
  CheckListDB,
  createCheckList,
  deleteCheckList,
  fetchChecklists,
} from '../../../../../data/functions/CheckListDB';
import { FormOption, ProgressStatus } from '../../../../../API';
import FormItem from '../../../../../data/model/FormItem';
import { UserType } from '../../../../../models';
import {
  handleToggleEnabled,
  isModelItemLockedInReviewal,
} from '../../../../../data/functions/ModelDB';
import ReviewalItem from '../../../../../data/model/ReviewalItem';
import DraftChangeItem from '../../../../../data/model/DraftChangeItem';
import { handleLoadReviewAndNavigate } from '../../../../../data/functions/ReviewalDB';
import Loading from '../../../../components/Loading/Loading';

interface CheckListEditPageProps {
  stateData?: any;
  isEditPage?: any;
  page?: any;
  isFullPage?: boolean;
}

/* 10-17-23 Arul: Created checklist Protocol Edit Page component globally */
const CheckListEditPage: React.FC<CheckListEditPageProps> = ({
  stateData,
  isEditPage,
  isFullPage,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reducerState = useSelector((state: any) => state.department);

  const { reviewal, currentDraft } = stateData;

  const [parmChecklist, setParmChecklist] = useState<FormItem>(stateData.value);
  const [draftChangeInReview, setDraftChangeInReview] = useState<
    DraftChangeItem | null | undefined
  >(parmChecklist.draftChange);

  const [isEditMode, setIsEditMode] = useState(
    !draftChangeInReview && stateData.editMode == null
      ? isEditPage
      : stateData.editMode
  );

  const [editDose, setEditDose] = useState(false);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<FormGroup | null>(null);
  const [doseIndex, setDoseIndex] = useState<number>(1);
  const [isCancelWarningModal, setIsCancelWarningModal] = useState(false);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const department = database.department;
  const protocol: ProtocolItem = stateData.selectedProtocol;
  const departmentOwner = useMemo(() => {
    if (!department || !parmChecklist) return null;
    return findDepartmentOwner(department, reducerState, parmChecklist);
  }, [department, parmChecklist, reducerState]);

  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, reducerState, user);
  }, [department, reducerState, user]);
  const isOwner = useMemo(() => {
    return departmentOwner?.id === department.id;
  }, [departmentOwner, department]);
  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);
  const [isWarningModal, setIsWarningModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState<string | null>(null);

  const taggedProtocols = useMemo(() => {
    let taggedProtocols: ProtocolItem[] = [];
    const checklistID = getActiveID(parmChecklist);
    if (checklistID == null) return [];
    for (let i = 0; i < database.protocols.length; i++) {
      let protocol = database.protocols[i];
      if (
        protocol.checklistIDs &&
        protocol.checklistIDs.includes(checklistID)
      ) {
        taggedProtocols.push(protocol);
      }
    }
    return taggedProtocols;
  }, [parmChecklist, database.protocols]);

  const hasAdminAccess = useMemo(() => {
    return hasAdminUserAccess(
      database.department,
      reducerState,
      user,
      parmChecklist
    );
  }, [database.department, reducerState, user, parmChecklist]);

  /* 11-17-23 Arul: Function to handle Cancel button */
  const handleCancel = () => {
    if (formik.dirty && !isCancelWarningModal) {
      setIsCancelWarningModal(true);
    } else {
      formik.resetForm();
      setIsEditMode(false);
      setIsCancelWarningModal(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: parmChecklist ? parmChecklist.name : '',
      items: parmChecklist ? parmChecklist.items : [],
      departmentID: parmChecklist ? parmChecklist.departmentID : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      items: Yup.array(),
      departmentID: Yup.string(),
    }),
    onSubmit: async (values) => {
      if (formik.dirty && formik.isValid) {
        const newCheckList: CheckListDB = {
          name: values.name,
          items: values.items,
          departmentID: department.id,
          status: ProgressStatus.DRAFT,
          activeID:
            parmChecklist.status === ProgressStatus.ACTIVE
              ? parmChecklist.uid
              : parmChecklist.activeID,
          version:
            parmChecklist.status === ProgressStatus.ACTIVE
              ? upgradeVersion(
                  parmChecklist.version === null
                    ? 'v1.0.0'
                    : parmChecklist.version
                )
              : parmChecklist.version === null
                ? 'v1.0.0'
                : parmChecklist.version,
          modifiedBy: user.id,
          createdBy:
            parmChecklist.model && parmChecklist.model.createdBy
              ? parmChecklist.model.createdBy
              : '',
        };
        if (globals.debug) console.log('Creating checklist', parmChecklist);
        let results: Response = await createCheckList(
          newCheckList,
          parmChecklist
        );
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created checklist', results.data);
          let newCheckList: FormItem = results.data;
          updateChecklist(newCheckList);
        }
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    if (stateData.value) {
      setParmChecklist(stateData.value);
      formik.resetForm();
    }
  }, [stateData.value]);

  /**
   * Checks if the item is locked in an ACTIVE Reviewal Process.
   */
  useEffect(() => {
    if (parmChecklist.draftChange != null) {
      setDraftChangeInReview(parmChecklist.draftChange);
    } else {
      isModelItemLockedInReviewal(parmChecklist, database).then(
        (draftChange: DraftChangeItem | null) => {
          setDraftChangeInReview(draftChange);
        }
      );
    }
  }, [database, parmChecklist]);

  const updateChecklist = async (newChecklist: FormItem) => {
    reloadDatabase();
    formik.setValues({
      ...formik.values,
      name: newChecklist?.name ? newChecklist?.name : '',
      items: newChecklist?.items ? newChecklist?.items : [],
    });
  };

  const reloadDatabase = async (): Promise<boolean> => {
    const resp: Response = await fetchChecklists(
      database.department,
      true,
      true
    );
    if (resp.type === ResponseType.Success) {
      const _checklists: FormItem[] = resp.data;
      setDatabase({
        ...database,
        checklists: _checklists,
      });
      dispatch<any>(handleGetDepartment(database));

      if (globals.debug) console.log('Searching for updated checklist item');
      let updatedChecklist: FormItem | null = null;
      let checklistID = getActiveID(parmChecklist);
      for (let i = 0; i < _checklists.length; i++) {
        if (getActiveID(_checklists[i]) === checklistID) {
          updatedChecklist = _checklists[i];
          break;
        }
      }
      if (updatedChecklist) {
        setParmChecklist(updatedChecklist);
        formik.resetForm();
      } else if (globals.debug) console.log('Failed to find updated checklist');
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const handleBack = () => {
    if (formik.dirty && !isWarningModal) setIsWarningModal(true);
    else if (protocol) {
      navigate(`/${protocol.nickname}/protocol-detail`, {
        state: { selectedProtocol: protocol, editMode: true },
      });
    } else if (reviewal) {
      navigate('/reviewChanges', {
        state: {
          data: reviewal,
          activeDraftChange: currentDraft,
        },
      });
    } else {
      navigate(`/database/list-checklists`, {
        state: {
          department: department,
          data: database.checklists,
          database: database,
        },
      });
    }
  };

  // create new group and open sideout modal
  const onAddDoseClick = () => {
    if (isEditMode) {
      setSelectedGroup(null);
      setEditDose(false);
      setSidebarVisible(!sidebarVisible);
    }
  };

  //close sideout modal
  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedGroup(null);
  };

  // set edit protocol
  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
        setIsEditMode(true);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleUpdateCheckList = (data: any, prevGroup?: FormGroup) => {
    let newCheckList: FormGroup = {
      id: prevGroup ? prevGroup.id : generateID(),
      title: data.title,
      options: data.items.map((item: FormOption, index: number) => ({
        ...item,
        id: item.id == null ? generateID() : item.id,
        index: item.index == null ? index : item.index,
      })),
      isUrgent: data.isUrgent,
      isDouble: data.isDouble,
      index: prevGroup ? prevGroup.index : formik.values.items.length,
    };
    if (!prevGroup || formik.values.items.length === 0) {
      let newOptions = [...formik.values.items, newCheckList];
      formik.setFieldValue('items', newOptions);
    } else if (prevGroup) {
      let updatedItems: FormGroup[] = formik.values.items.filter(
        (item: any) => !isObjectEqual(prevGroup, item)
      );
      updatedItems.push(newCheckList);
      formik.setFieldValue('items', updatedItems);
    } else {
      console.error(
        'Unexpected case: prevGroup is not provided and also items is not empty.'
      );
    }
  };

  const handleCheckListGroupRemove = (dose: FormGroup) => {
    const updateItems = formik.values.items.filter(
      (item: any) => !isObjectEqual(dose, item)
    );
    // Set the filtered items back to the formik state
    formik.setFieldValue('items', updateItems);
  };

  const handleCheckIsDraft = useCallback(async (): Promise<boolean> => {
    if (isEditMode) return false;
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
      return isDraft;
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
    return false;
  }, [database.department, isEditMode]);

  const handleToggleState = async () => {
    try {
      if (globals.debug) console.log('Toggling enabled state', parmChecklist);
      let res: Response = await handleToggleEnabled(
        user,
        department,
        reducerState,
        parmChecklist
      );
      if (res.type === ResponseType.Success) {
        let updated: FormItem = res.data;
        if (globals.debug) console.log('Updated Checklist', updated);
        setParmChecklist(updated);
        reloadDatabase();
      } else {
        console.error('Failed to toggle enabled state', res.data);
      }
    } catch (error) {
      console.error('Error toggling enabled state', error);
    }

    setIsBlockModal(false);
  };

  // const handleVersion = () => {};

  const handleViewGroup = (item: FormGroup, index: number) => {
    if (isEditMode) {
      setSidebarVisible(true);
      setDoseIndex(index);
      setSelectedGroup(item);
      setEditDose(true);
    }
  };

  const handleDelete = () => {
    setIsDelete(true);
  };

  const handleConfirmDeleteItem = async () => {
    const isDraft =
      parmChecklist.status === ProgressStatus.DRAFT &&
      parmChecklist.activeID == null;
    let response = await deleteCheckList(parmChecklist, !isDraft);
    if (response.type === ResponseType.Success) {
      handleBack();
    } else {
      console.error('Failed to delete checklist', response.data);
    }
  };

  return (
    <div className="screen-container">
      {sidebarVisible && (
        <ChecklistSideout
          group={selectedGroup ? selectedGroup : undefined}
          visible={sidebarVisible}
          setVisible={handleCloseModal}
          editDose={editDose}
          doseIndex={doseIndex}
          onSubmit={handleUpdateCheckList}
          onRemove={handleCheckListGroupRemove}
        />
      )}
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleBack}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Checklist.  Click cancel to return to Checklist details.  To continue without saving changes, select Abandon Changes.`}
        />
      )}
      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            setIsEditMode(true);
            setIsOpen(false);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Delete Checklist?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteItem}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={`Are you sure you would like to delete ${parmChecklist.name}?`}
        />
      )}

      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}

      {isCancelWarningModal && (
        <ConfirmModal
          isVisible={isCancelWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsCancelWarningModal(false);
          }}
          handleSubmit={handleCancel}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this checklist.  Click cancel to return to checklist details.  To cancel without saving changes, select Abandon.`}
        />
      )}

      {isBlockModal && (
        <ConfirmModal
          isVisible={isBlockModal}
          title={`${
            parmChecklist.status === ProgressStatus.DEACTIVATED
              ? 'Reactivate'
              : 'Deactivate'
          } ${departmentOwner?.name} ${parmChecklist.name} Checklist?`}
          handleClose={() => {
            setIsBlockModal(false);
          }}
          handleSubmit={() => handleToggleState()}
          isDeleteBtn={parmChecklist.status !== ProgressStatus.DEACTIVATED}
          primaryBtnName="Cancel"
          secondaryBtnName={
            parmChecklist.status === ProgressStatus.DEACTIVATED
              ? 'Reactivate'
              : 'Deactivate'
          }
          primaryDescription={`This will ${parmChecklist.status === ProgressStatus.DEACTIVATED ? 'reactivate' : 'deactivate'} the ${departmentOwner?.name} ${parmChecklist?.name} Checklist for ${database.department?.name}. ${parmChecklist.status === ProgressStatus.DEACTIVATED ? '' : 'This can be reactiveated at any time.'}`}
        />
      )}

      <ProtocolHeader
        name={parmChecklist.name}
        page={
          reviewal
            ? (reviewal as ReviewalItem).name
            : protocol
              ? protocol.name
              : 'Checklists'
        }
        status={parmChecklist.status}
        protocolDetail={protocol}
        type={'protocol'}
        rightSideBtn={isEditMode ? 'save' : 'edit'}
        isSaveActive={formik.dirty && formik.isValid}
        // isVersionButton={true}
        isCopyDescription={user.type === UserType.ADMIN}
        descriptionTitle={user.type === UserType.ADMIN ? 'ID:' : ''}
        description={user.type === UserType.ADMIN ? parmChecklist.uid : ''}
        // isVersionButton={true}
        isDeleteButton={isEditMode}
        isEditButton={
          !draftChangeInReview && reviewal == null && !isEditMode && isOwner
        }
        isDotButton={true}
        isSaveButton={isEditMode}
        isBackButton={true}
        handleCancel={handleBack}
        handleCancelEdit={handleCancel}
        handleEdit={() => {
          handleCheckIsDraft().then((isDraft: boolean) => {
            if (!isDraft && !isEditMode) setIsEditMode(true);
          });
        }}
        handleDelete={handleDelete}
        handleSave={() => formik.submitForm()}
        isDeactivateButton={
          (adminLevel === 1 || adminLevel === 3) &&
          parmChecklist.status !== ProgressStatus.DEACTIVATED &&
          !isOwner
        }
        isDeactivateActive={true}
        isReactivateActive={true}
        isReactivateButton={parmChecklist.status === ProgressStatus.DEACTIVATED}
        handleDeactivate={() => {
          setIsBlockModal(true);
        }}
        handleReactivate={() => setIsBlockModal(true)}
        draftChangeLocked={draftChangeInReview}
        handleReviewClicked={() => {
          if (draftChangeInReview) {
            setLoading('Loading Review...');
            handleLoadReviewAndNavigate(
              database,
              draftChangeInReview,
              user,
              dispatch,
              navigate
            ).catch((error) => {
              console.error('Failed to load review and navigate', error);
              setLoading(null);
            });
          }
        }}
        // handleVersion={handleVersion}
      />

      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="form-header">
            Checklist
          </h5>
          <div className="input-container roundBorder">
            <div
              style={{ display: 'flex', marginTop: '5px' }}
              className="ketamine-general-label"
            >
              <div style={{ marginRight: '10px' }}>Modified By:</div>
              <div style={{ fontWeight: '500' }}>
                {parmChecklist.modifiedBy
                  ? parmChecklist.modifiedBy?.firstName +
                    ' ' +
                    parmChecklist.modifiedBy?.lastName
                  : 'Hinckley Medical'}
              </div>
            </div>
            {parmChecklist.model?.updatedAt && (
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Last Updated:</div>
                <div style={{ fontWeight: '500' }}>
                  {getFormattedDate(parmChecklist.model.updatedAt, true)}
                </div>
              </div>
            )}
            <div style={{ display: 'flex' }} className="ketamine-general-label">
              <div style={{ marginRight: '10px' }}>Version:</div>
              <div style={{ fontWeight: '500' }}>{parmChecklist.version}</div>
            </div>
          </div>
          <label htmlFor="Name" className="ketamine-general-label">
            Name<span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="Name"
              data-testid="name"
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              disabled={!isEditMode}
            />
            <div className="input-border"></div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="form-header-right">
            Checklist Groups
          </h5>
          {isEditMode && (
            <div style={{ marginTop: '5px' }}>
              <span className="ketamine-general-label">Option</span>
              <div
                onClick={onAddDoseClick}
                className={`contentBorder protocolCalculationPad contentHeading newProtocolBorder  ${
                  isEditMode && 'cursorPointer newRouteButton'
                }`}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <span
                  style={{
                    textDecoration: 'underLine',
                    textUnderlinePosition: 'under',
                  }}
                >
                  <HiPlus className="text-icon " /> Add Group
                </span>
              </div>
            </div>
          )}

          <ViewportList items={formik.values.items}>
            {(item: any, index: any) => (
              <div
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleViewGroup(item, index);
                }}
              >
                <CheckListGroups
                  data={item}
                  groupIndex={index}
                  isEdit={isEditMode}
                />
              </div>
            )}
          </ViewportList>
        </div>
      </div>
      {loading && <Loading type="bubbles" message={loading} />}
    </div>
  );
};

export default CheckListEditPage;
