/**
 * ReviewalSubscriber.tsx
 *
 * This component subscribes to the Reviewal model and updates the Redux store
 * when a new Reviewal is created, updated, or deleted.
 *
 *   Schemas to Subscribe to:
 *     - Reviewal
 *     - DraftGroup
 *     - DraftChange
 *     - Acknowledge
 *     - UserComment
 *
 * @author Colton Hazlett
 */
import { useMemo, useState } from 'react';
import { useEffect } from 'react';
import ReviewalItem, {
  cloneReviewalItem,
  updateReviewalItem,
} from '../model/ReviewalItem';
import { useDispatch, useSelector } from 'react-redux';
import DepartmentItem from '../model/DepartmentItem';
import { DatabaseResponse, ResponseType } from '../AmplifyDB';
import { API } from 'aws-amplify';
import {
  ACKStatus,
  OnCreateReviewalSubscription,
  OnCreateReviewalSubscriptionVariables,
  OnDeleteReviewalSubscription,
  OnDeleteReviewalSubscriptionVariables,
  OnUpdateReviewalSubscription,
  OnUpdateReviewalSubscriptionVariables,
  Reviewal,
  UserType,
  OnCreateUserCommentSubscriptionVariables,
  UserComment,
  OnCreateAcknowledgeSubscription,
  OnCreateAcknowledgeSubscriptionVariables,
  Acknowledge,
  OnDeleteAcknowledgeSubscriptionVariables,
  OnCreateUserCommentSubscription,
  OnDeleteAcknowledgeSubscription,
} from '../../API';
import { GraphQLSubscription } from '@aws-amplify/api';
import * as subscriptions from '../../../src/graphql/subscriptions';
import { User } from '../../models';
import { handleReviewalsCache } from '../../store/actions';
import DraftGroupItem from '../model/DraftGroupItem';
import DraftChangeItem, {
  cloneDraftChangeItem,
} from '../model/DraftChangeItem';
import UserCommentItem from '../model/UserCommentItem';
import AcknowledgeItem, {
  cloneAcknowledgeItem,
} from '../model/AcknowledgeItem';
import {
  findUserComments,
  updateDraftChangeItem,
} from '../functions/ReviewalDB';
import { globals } from '../../ui/_global/common/Utils';

export enum ReviewalError {
  MOVED_TO_DRAFT,
}

interface ReviewalSubscriberProps {
  reviewal?: ReviewalItem;
  draftGroup?: DraftGroupItem;
  draftChange?: DraftChangeItem;
  onFullReloadReviewal?: (reviewal: ReviewalItem) => void;
  onListUpdated?: (reviewal: ReviewalItem[]) => void;
  onReviewalChange: (reviewal: ReviewalItem, isDeleted: boolean) => void;
  onDraftGroupChange?: (
    reviewal: ReviewalItem,
    draftGroup: DraftGroupItem,
    isDeleted: boolean
  ) => void;
  onDraftChangeChange?: (
    reviewal: ReviewalItem,
    draftChange: DraftChangeItem,
    isDeleted: boolean
  ) => void;
  onUserCommentChange?: (
    userComment: UserCommentItem,
    isDeleted: boolean
  ) => void;
  onAcknowledgeChange?: (
    acknowledge: AcknowledgeItem,
    isDeleted: boolean
  ) => void;
  onError: (error: ReviewalError, reviewal: ReviewalItem) => void;
}

const ReviewalSubscriber = (props: ReviewalSubscriberProps) => {
  const {
    onReviewalChange,
    onListUpdated,
    onFullReloadReviewal,
    onDraftGroupChange,
    onDraftChangeChange,
    onUserCommentChange,
    onAcknowledgeChange,
    onError,
  } = props;
  const dispatch = useDispatch();
  const database: DatabaseResponse = useSelector(
    (reviewal: any) => reviewal.protocol.departmentItem
  );
  const department: DepartmentItem = useMemo(() => {
    return database.department;
  }, [database]);
  const user: User = useSelector((state: any) => state?.user);
  const reviewal = useMemo(() => props.reviewal, [props.reviewal]);
  const draftGroup = useMemo(() => props.draftGroup, [props.draftGroup]);
  const draftChange = useMemo(() => props.draftChange, [props.draftChange]);
  const reviewState = useSelector((state: any) => state.review);
  const [allReviewals, setAllReviewals] = useState<ReviewalItem[]>(
    reviewState.reviewals ? reviewState.reviewals : []
  );

  useEffect(() => {
    setAllReviewals(reviewState.reviewals);
  }, [reviewState.reviewals]);

  useEffect(() => {
    dispatch<any>(
      handleReviewalsCache({
        reviewals: allReviewals,
      })
    );
  }, [allReviewals, dispatch]);

  /* ----------- Reviewal Subscriber ----------- */
  useEffect(() => {
    /* Declare the subscriptions */
    let variables: any = {
      filter: { departmentID: { eq: department.getTopLevelDep().id } },
    };
    if (reviewal) {
      variables = {
        filter: { id: { eq: reviewal.uid } },
      };
    }

    const createSubscriber =
      reviewal != null
        ? undefined
        : API.graphql<GraphQLSubscription<OnCreateReviewalSubscription>>({
            query: subscriptions.onCreateReviewal,
            variables: variables as OnCreateReviewalSubscriptionVariables,
          }).subscribe({
            next: ({ provider, value }) => {
              if (value) {
                const reviewalItem: Reviewal | null | undefined = value.data
                  ?.onCreateReviewal as Reviewal | null | undefined;
                if (globals.debug)
                  console.log(
                    user.firstName + ' ' + user.lastName,
                    'Reviewal CREATED:',
                    reviewalItem
                  );
                if (reviewalItem && isUserTagged(reviewalItem, user)) {
                  // Add the new reviewal to the list
                  const newReviewal = new ReviewalItem(reviewalItem, database);
                  let reviewals = [...allReviewals, newReviewal].sort(
                    (a, b) => {
                      return (
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                      );
                    }
                  );
                  dispatch<any>(
                    handleReviewalsCache({
                      reviewals: reviewals,
                    })
                  );
                  // if (onListUpdated) onListUpdated(reviewals);
                }
              }
            },
            error: (error) => console.warn(error),
          });

    const updateSubscriber = API.graphql<
      GraphQLSubscription<OnUpdateReviewalSubscription>
    >({
      query: subscriptions.onUpdateReviewal,
      variables: variables as OnUpdateReviewalSubscriptionVariables,
    }).subscribe({
      next: ({ provider, value }) => {
        if (value) {
          const reviewalItem: Reviewal | null | undefined = value.data
            ?.onUpdateReviewal as Reviewal | null | undefined;
          if (reviewalItem != null) {
            if (globals.debug)
              console.log(
                user.firstName + ' ' + user.lastName,
                'Reviewal UPDATED:',
                reviewalItem
              );
            if (reviewal != null && reviewal.uid === reviewalItem.id) {
              if (
                reviewal.state !== reviewalItem.status &&
                onFullReloadReviewal
              )
                onFullReloadReviewal(reviewal);
              handleReviewalUpdated(reviewal, reviewalItem);
            } else if (isUserTagged(reviewalItem, user)) {
              // Add the new reviewal to the list
              const newReviewal = new ReviewalItem(reviewalItem, database);
              let reviewals = [...allReviewals, newReviewal].sort((a, b) => {
                return (
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
                );
              });
              dispatch<any>(
                handleReviewalsCache({
                  reviewals: reviewals,
                })
              );
              if (onListUpdated) onListUpdated(reviewals);
            }
          }
        }
      },
      error: (error) => console.warn(error),
    });

    const deleteSubscriber = API.graphql<
      GraphQLSubscription<OnDeleteReviewalSubscription>
    >({
      query: subscriptions.onDeleteReviewal,
      variables: variables as OnDeleteReviewalSubscriptionVariables,
    }).subscribe({
      next: ({ provider, value }) => {
        if (value) {
          const reviewalItem: Reviewal | null | undefined = value.data
            ?.onDeleteReviewal as Reviewal | null | undefined;
          if (reviewalItem) {
            if (globals.debug)
              console.log(
                user.firstName + ' ' + user.lastName,
                'Reviewal DELETED:',
                reviewalItem
              );
            // Remove the deleted reviewal from the list
            const filteredReviewals = allReviewals.filter(
              (r) => r.uid !== reviewalItem.id
            );
            dispatch<any>(
              handleReviewalsCache({
                reviewals: filteredReviewals,
              })
            );
            // if (onListUpdated) onListUpdated(filteredReviewals);
            onReviewalChange(new ReviewalItem(reviewalItem, database), true);
          }
        }
      },
      error: (error) => console.warn(error),
    });

    return () => {
      if (createSubscriber) createSubscriber.unsubscribe();
      if (updateSubscriber) updateSubscriber.unsubscribe();
      deleteSubscriber.unsubscribe();
    };
  }, [
    database,
    dispatch,
    reviewal,
    allReviewals,
    department,
    user,
    onListUpdated,
    onReviewalChange,
  ]);

  /* ----------- UserComment Subscriber ----------- */
  useEffect(() => {
    if (reviewal || (reviewal && draftGroup && draftChange)) {
      /* Declare the subscriptions */
      let variables: any = {
        filter: {
          ownerID: { eq: reviewal.uid },
        },
      };
      if (draftChange) {
        variables = {
          or: [
            { ownerID: { eq: draftChange.uid } },
            ...draftChange.acknowledgements.map((ack) => ({
              ownerID: { eq: ack.uid },
            })),
          ],
        };
      }

      const createSubscriber = API.graphql<
        GraphQLSubscription<OnCreateUserCommentSubscription>
      >({
        query: subscriptions.onCreateUserComment,
        variables: variables as OnCreateUserCommentSubscriptionVariables,
      }).subscribe({
        next: ({ provider, value }) => {
          if (value) {
            const userCommentItem: UserComment | null | undefined = value.data
              ?.onCreateUserComment as UserComment | null | undefined;
            if (userCommentItem) {
              if (globals.debug)
                console.log(
                  user.firstName + ' ' + user.lastName,
                  'UserComment CREATED:',
                  userCommentItem
                );
              const commentUser = database.users.find(
                (u) => u.id === userCommentItem.userID
              );
              if (!commentUser) {
                console.error('User not found comment', userCommentItem);
                return;
              }
              if (draftChange && userCommentItem.ownerID === draftChange.uid) {
                let newDraftChange = cloneDraftChangeItem(
                  draftChange,
                  database
                );
                newDraftChange.comments.push(
                  new UserCommentItem(
                    userCommentItem,
                    draftChange,
                    commentUser,
                    []
                  )
                );
                handleDraftChangeUpdated(reviewal, newDraftChange);
              } else if (reviewal && userCommentItem.ownerID === reviewal.uid) {
                let newReviewal = cloneReviewalItem(reviewal, database);
                newReviewal.comments.push(
                  new UserCommentItem(
                    userCommentItem,
                    reviewal,
                    commentUser,
                    []
                  )
                );
                onReviewalChange(newReviewal, false);
              } else if (
                draftChange &&
                draftChange.allAcknowledgements.length > 0
              ) {
                let findACK = draftChange.allAcknowledgements.find(
                  (ack) => ack.uid === userCommentItem.ownerID
                );
                if (findACK) {
                  let cloneACK = cloneAcknowledgeItem(findACK);
                  cloneACK.comment = new UserCommentItem(
                    userCommentItem,
                    cloneACK,
                    commentUser,
                    []
                  );
                  let newAcks = draftChange.allAcknowledgements.filter(
                    (ack) => ack.uid !== userCommentItem.ownerID
                  );
                  newAcks.push(cloneACK);
                  draftChange.allAcknowledgements = newAcks;
                  draftChange.acknowledgements = newAcks.filter(
                    (ack) => !ack.isArchived
                  );
                  handleDraftChangeUpdated(reviewal, draftChange);
                }
              }
            }
          }
        },
        error: (error) => console.warn(error),
      });
      return () => {
        createSubscriber.unsubscribe();
      };
    }
  }, [
    database,
    dispatch,
    reviewal,
    draftChange,
    user,
    onReviewalChange,
    onDraftChangeChange,
    draftGroup,
    onListUpdated,
  ]);

  /* ----------- Acknowledge Subscriber ----------- */
  useEffect(() => {
    if (reviewal || (reviewal && draftGroup && draftChange)) {
      /* Declare the subscriptions */
      let variables: any = {
        filter: {
          reviewalID: { eq: reviewal.uid },
        },
      };

      const createSubscriber = API.graphql<
        GraphQLSubscription<OnCreateAcknowledgeSubscription>
      >({
        query: subscriptions.onCreateAcknowledge,
        variables: variables as OnCreateAcknowledgeSubscriptionVariables,
      }).subscribe({
        next: ({ provider, value }) => {
          if (value) {
            const acknowledgeItem: Acknowledge | null | undefined = value.data
              ?.onCreateAcknowledge as Acknowledge | null | undefined;
            if (acknowledgeItem) {
              if (globals.debug)
                console.log(
                  user.firstName + ' ' + user.lastName,
                  'Acknowledge CREATED:',
                  acknowledgeItem
                );
              const acknowledgeUser = database.users.find(
                (u) => u.id === acknowledgeItem.userID
              );
              if (!acknowledgeUser) {
                console.error('User not found comment', acknowledgeItem);
                return;
              }
              if (acknowledgeItem.ownerID === reviewal.uid) {
                let cloneReviewal = cloneReviewalItem(reviewal, database);
                let newAcknowledge = new AcknowledgeItem(
                  acknowledgeItem,
                  database,
                  acknowledgeUser,
                  reviewal
                );
                cloneReviewal.acknowledgements.push(newAcknowledge);
                findUserComments(database, newAcknowledge).then((response) => {
                  if (response.type === ResponseType.Success) {
                    onReviewalChange(cloneReviewal, false);
                  }
                });
                if (onFullReloadReviewal) onFullReloadReviewal(cloneReviewal);
                // onReviewalChange(cloneReviewal, false);
              } else if (draftChange) {
                /* Check to see if it is any other draft change that belongs to the ReviewalItem */
                let draftChangeItem = findDraftChange(
                  reviewal,
                  acknowledgeItem.ownerID
                );
                if (draftChangeItem) {
                  let newDraftChange = cloneDraftChangeItem(
                    draftChangeItem,
                    database
                  );
                  let ack = new AcknowledgeItem(
                    acknowledgeItem,
                    database,
                    acknowledgeUser,
                    reviewal
                  );
                  if (user.id === acknowledgeUser.id)
                    newDraftChange.reviewACK = ack;
                  newDraftChange.acknowledgements.push(ack);
                  handleDraftChangeUpdated(reviewal, newDraftChange);
                }
              }
            }
          }
        },
        error: (error) => console.warn(error),
      });

      const deleteSubscriber = API.graphql<
        GraphQLSubscription<OnDeleteAcknowledgeSubscription>
      >({
        query: subscriptions.onDeleteAcknowledge,
        variables: variables as OnDeleteAcknowledgeSubscriptionVariables,
      }).subscribe({
        next: ({ provider, value }) => {
          if (value) {
            const acknowledgeItem: Acknowledge | null | undefined = value.data
              ?.onDeleteAcknowledge as Acknowledge | null | undefined;
            if (acknowledgeItem) {
              if (globals.debug)
                console.log(
                  user.firstName + ' ' + user.lastName,
                  'Acknowledge DELETED:',
                  acknowledgeItem
                );
              const acknowledgeUser = database.users.find(
                (u) => u.id === acknowledgeItem.userID
              );
              if (!acknowledgeUser) {
                console.error('User not found comment', acknowledgeItem);
                return;
              }
              if (acknowledgeItem.ownerID === reviewal.uid) {
                let cloneReviewal = cloneReviewalItem(reviewal, database);
                cloneReviewal.acknowledgements.filter(
                  (ack) => ack.uid !== acknowledgeItem.id
                );
                onReviewalChange(cloneReviewal, false);

                // onReviewalChange(cloneReviewal, false);
              } else if (draftChange) {
                /* Check to see if it is any other draft change that belongs to the ReviewalItem */
                let draftChangeItem = findDraftChange(
                  reviewal,
                  acknowledgeItem.ownerID
                );
                if (draftChangeItem) {
                  let newDraftChange = cloneDraftChangeItem(
                    draftChangeItem,
                    database
                  );
                  newDraftChange.acknowledgements =
                    newDraftChange.acknowledgements.filter(
                      (ack) => ack.uid !== acknowledgeItem.id
                    );
                  if (
                    newDraftChange.reviewACK &&
                    newDraftChange.reviewACK.uid === acknowledgeItem.id
                  )
                    newDraftChange.reviewACK = null;
                  handleDraftChangeUpdated(reviewal, newDraftChange);
                  if (globals.debug)
                    console.log(
                      'Removed ' +
                        acknowledgeUser.firstName +
                        ' ' +
                        acknowledgeUser.lastName +
                        ' ACK from ' +
                        newDraftChange.changeItem?.name ||
                        newDraftChange.changeType
                    );
                } else if (globals.debug) {
                  console.log('NO DRAFT CHANGE FOUND', acknowledgeItem);
                }
              }
            }
          }
        },
        error: (error) => console.warn(error),
      });

      return () => {
        createSubscriber.unsubscribe();
        // updateSubscriber.unsubscribe();
        deleteSubscriber.unsubscribe();
      };
    }
  }, [
    database,
    dispatch,
    reviewal,
    allReviewals,
    department,
    user,
    draftChange,
    draftGroup,
    onListUpdated,
    onReviewalChange,
    onDraftChangeChange,
  ]);

  const handleReviewalUpdated = (
    reviewal: ReviewalItem,
    parmReviewal: Reviewal
  ) => {
    if (
      parmReviewal.status === ACKStatus.DRAFT &&
      parmReviewal.userID !== user.id
    ) {
      onError(
        ReviewalError.MOVED_TO_DRAFT,
        new ReviewalItem(parmReviewal, database)
      );
    }
    let newReviewal = updateReviewalItem(reviewal, parmReviewal, database);
    onReviewalChange(newReviewal, false);
  };

  const handleDraftChangeUpdated = (
    reviewal: ReviewalItem,
    newDraftChange: DraftChangeItem
  ) => {
    if (onDraftChangeChange)
      onDraftChangeChange(reviewal, newDraftChange, false);
    let newReviewal = cloneReviewalItem(reviewal, database);
    newReviewal.updateDraftChange(newDraftChange, false);
    onReviewalChange(newReviewal, false);
  };

  const isUserTagged = (reviewal: Reviewal, user: User) => {
    return (
      user.type === UserType.ADMIN ||
      (reviewal.status === ACKStatus.DRAFT && reviewal.userID === user.id) ||
      reviewal.status !== ACKStatus.DRAFT
    );
  };

  function findDraftChange(reviewal: ReviewalItem, draftChangeID: string) {
    return reviewal.draftGroups
      .flatMap((dg) => dg.draftChanges)
      .find((dc) => dc.uid === draftChangeID);
  }

  return <div></div>;
};

export default ReviewalSubscriber;
