import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import {
  findBeforeValue,
  findCurrentValue,
} from '../reviewComparsion/MedicationComparison';
import HighlightChanges from '../HighlightChanges';
import { getDepartmentByID } from '../../../../data/functions/DepartmentDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import './Comparison.scss';
import { diffWords } from 'diff';
import HighlightChangesRow from '../HighlightChangesRow';
import FormItem from '../../../../data/model/FormItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import { useSelector } from 'react-redux';
import {
  getDepartmentsFromState,
  globals,
} from '../../../_global/common/Utils';
import { DatabaseResponse, fetchPDF } from '../../../../data/AmplifyDB';
import { getEquipmentByID } from '../../../../data/functions/EquipmentDB';
import { getChecklistByID } from '../../../../data/functions/CheckListDB';
import { getProtocolByID } from '../../../../data/functions/ProtocolDB';
import PDFScreen from '../../protocol/details/PdfScreen';
import { Tooltip } from '@mui/material';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { getHashedPin } from '../../../_global/common/Encrypt';
interface FolderComparisonProps {
  focusItem: ProtocolItem;
  department: DepartmentItem;
  changes: any[];
}

const PROTOCOL_DATA = [
  {
    keys: ['name'],
    titles: ['Name'],
  },
  {
    keys: ['nickname'],
    titles: ['Nickname'],
  },
  {
    keys: ['rangeLow', 'rangeHigh'],
    titles: ['Range Low (kg)', 'Range High (kg)'],
  },
];

const ProtocolComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  changes,
  department,
}) => {
  const reducerState = useSelector((state: any) => state.department);
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const allSubDeps = useMemo(() => {
    return getDepartmentsFromState(department, reducerState).allSubDeps;
  }, [department, reducerState]);

  const [deps, setDeps] = useState<any>({
    all: [] as DepartmentItem[],
    before: [] as DepartmentItem[],
    after: [] as DepartmentItem[],
    new: [] as DepartmentItem[],
    removed: [] as DepartmentItem[],
    same: [] as DepartmentItem[],
  });
  const [equipmentData, setEquipmentData] = useState<any>({
    all: [] as EquipmentItem[],
    before: [] as EquipmentItem[],
    after: [] as EquipmentItem[],
    new: [] as EquipmentItem[],
    removed: [] as EquipmentItem[],
    same: [] as EquipmentItem[],
  });
  const [checklistData, setChecklistData] = useState<any>({
    before: undefined as FormItem | undefined,
    after: undefined as FormItem | undefined,
  });
  const [pairedProtocolsData, setPairedProtocolsData] = useState<any>({
    all: [] as ProtocolItem[],
    before: [] as ProtocolItem[],
    after: [] as ProtocolItem[],
    new: [] as ProtocolItem[],
    removed: [] as ProtocolItem[],
    same: [] as ProtocolItem[],
  });

  const [filters, setFilters] = useState<any>({
    departments: [],
    equipment: [],
    checklist: [],
    pairedProtocols: [],
  });

  const [pdfData, setPdfData] = useState<any>(null);

  const handleGetDepartments = async (
    ids: string[]
  ): Promise<DepartmentItem[]> => {
    let promises: Promise<DepartmentItem | undefined>[] = [];
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i].trim();
      const department = allSubDeps.find((dep) => dep.id === id);
      if (department) promises.push(Promise.resolve(department));
      else promises.push(getDepartmentByID(id));
    }

    const departmentResults = await Promise.all(promises);
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < departmentResults.length; i++) {
      const department = departmentResults[i];
      if (department) deps.push(department);
    }
    return deps;
  };

  const handleGetEquipment = async (
    ids: string[]
  ): Promise<EquipmentItem[]> => {
    if (ids.length === 0) return [];
    let promises: Promise<EquipmentItem | null>[] = [];
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i].trim();
      promises.push(getEquipmentByID(database, id));
    }
    const equipmentResults = await Promise.all(promises);
    let equipments: EquipmentItem[] = [];
    for (let i = 0; i < equipmentResults.length; i++) {
      const equipment = equipmentResults[i];
      if (equipment) equipments.push(equipment);
    }
    return equipments;
  };

  const handleGetChecklist = async (id: string): Promise<FormItem | null> => {
    let checklist = await getChecklistByID(database, id);
    return checklist;
  };

  const handleGetPairedProtocols = async (
    ids: string[]
  ): Promise<ProtocolItem[]> => {
    try {
      if (ids.length === 0) return [];
      let promises: Promise<ProtocolItem | null>[] = [];
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i].trim();
        promises.push(getProtocolByID(database, id));
      }
      const protocolResults = await Promise.all(promises);
      console.log('Protocol Results', protocolResults);
      let protocols: ProtocolItem[] = [];
      for (let i = 0; i < protocolResults.length; i++) {
        const protocol = protocolResults[i];
        if (protocol) protocols.push(protocol);
      }
      return protocols;
    } catch (error) {
      console.error('Error getting paired protocols', error);
      return [];
    }
  };

  /* Get the departments from the changes */
  useEffect(() => {
    const getDepartments = async () => {
      // Fetch names for "before" value

      const beforeIdString = findBeforeValue(changes, 'pairedDepIDs');
      const afterIdString = findCurrentValue(changes, 'pairedDepIDs');
      const beforeIds = beforeIdString ? beforeIdString.split(',').sort() : [];
      const afterIds = afterIdString ? afterIdString.split(',').sort() : [];

      let beforePromise: any = null;
      let afterPromise: any = null;
      if (beforeIds) beforePromise = handleGetDepartments(beforeIds);
      if (afterIds) afterPromise = handleGetDepartments(afterIds);

      let [beforeDeps, afterDeps]: [DepartmentItem[], DepartmentItem[]] =
        await Promise.all([
          beforePromise ?? Promise.resolve([]),
          afterPromise ?? Promise.resolve([]),
        ]);

      /* Organize the departments, New Ones on the top, Removed Ones in second and the same ones last */
      const newDeps = afterDeps.filter(
        (dep) => !beforeDeps.find((bdep) => bdep.id === dep.id)
      );
      const removedDeps = beforeDeps.filter(
        (dep) => !afterDeps.find((bdep) => bdep.id === dep.id)
      );
      const sameDeps = beforeDeps.filter((dep) =>
        afterDeps.find((bdep) => bdep.id === dep.id)
      );
      const allDeps = [...sameDeps, ...removedDeps, ...newDeps];

      setDeps({
        all: allDeps,
        before: beforeDeps,
        after: afterDeps,
        new: newDeps,
        removed: removedDeps,
        same: sameDeps,
      });
    };

    getDepartments();
  }, [changes]);

  /* Get the equipment from the changes */
  useEffect(() => {
    const getEquipment = async () => {
      // Fetch names for "before" value
      const afterIds = focusItem.equipmentIDs || [];
      const beforeIds =
        (focusItem.activeItem as ProtocolItem)?.equipmentIDs || [];

      let beforePromise: any = null;
      let afterPromise: any = null;
      if (beforeIds) beforePromise = handleGetEquipment(beforeIds);
      if (afterIds) afterPromise = handleGetEquipment(afterIds);

      let [beforeEquipment, afterEquipment]: [
        EquipmentItem[],
        EquipmentItem[],
      ] = await Promise.all([
        beforePromise ?? Promise.resolve([]),
        afterPromise ?? Promise.resolve([]),
      ]);

      /* Organize the departments, New Ones on the top, Removed Ones in second and the same ones last */
      const newEquipment = afterEquipment.filter(
        (equipment) =>
          !beforeEquipment.find(
            (bequipment) => bequipment.uid === equipment.uid
          )
      );
      const removedEquipment = beforeEquipment.filter(
        (equipment) =>
          !afterEquipment.find((bequipment) => bequipment.uid === equipment.uid)
      );
      const sameEquipment = beforeEquipment.filter((equipment) =>
        afterEquipment.find((bequipment) => bequipment.uid === equipment.uid)
      );
      const allEquipment = [
        ...sameEquipment,
        ...removedEquipment,
        ...newEquipment,
      ];

      setEquipmentData({
        all: allEquipment,
        before: beforeEquipment,
        after: afterEquipment,
        new: newEquipment,
        removed: removedEquipment,
        same: sameEquipment,
      });
    };

    getEquipment();
  }, [changes, focusItem]);

  /* Get the checklist from the changes */
  useEffect(() => {
    const getChecklist = async () => {
      const beforeId = findBeforeValue(changes, 'checklistIDs');
      const afterId = findCurrentValue(changes, 'checklistIDs');
      let beforePromise: any = null;
      let afterPromise: any = null;
      if (beforeId) beforePromise = handleGetChecklist(beforeId);
      if (afterId) afterPromise = handleGetChecklist(afterId);
      let [beforeChecklist, afterChecklist]: [
        FormItem | undefined,
        FormItem | undefined,
      ] = await Promise.all([beforePromise, afterPromise]);

      setChecklistData({
        before: beforeChecklist,
        after: afterChecklist,
      });
    };

    getChecklist();
  }, [changes]);

  /* Get the Paired Protocols from the changes */
  useEffect(() => {
    const getPairedProtocols = async () => {
      // Fetch names for "before" value
      const afterIds = focusItem.pairedProtocolIDs || [];
      const beforeIds =
        (focusItem.activeItem as ProtocolItem)?.pairedProtocolIDs || [];

      console.log('Before IDs', beforeIds);
      console.log('After IDs', afterIds);

      let beforePromise: any = null;
      let afterPromise: any = null;
      if (beforeIds) beforePromise = handleGetPairedProtocols(beforeIds);
      if (afterIds) afterPromise = handleGetPairedProtocols(afterIds);

      let [beforeProtocols, afterProtocols]: [ProtocolItem[], ProtocolItem[]] =
        await Promise.all([
          beforePromise ?? Promise.resolve([]),
          afterPromise ?? Promise.resolve([]),
        ]);

      /* Organize the departments, New Ones on the top, Removed Ones in second and the same ones last */
      const newProtocols = afterProtocols.filter(
        (protocol) =>
          !beforeProtocols.find((bprotocol) => bprotocol.uid === protocol.uid)
      );
      const removedProtocols = beforeProtocols.filter(
        (protocol) =>
          !afterProtocols.find((bprotocol) => bprotocol.uid === protocol.uid)
      );
      const sameProtocols = beforeProtocols.filter((protocol) =>
        afterProtocols.find((bprotocol) => bprotocol.uid === protocol.uid)
      );
      const allProtocols = [
        ...sameProtocols,
        ...removedProtocols,
        ...newProtocols,
      ];

      setPairedProtocolsData({
        all: allProtocols,
        before: beforeProtocols,
        after: afterProtocols,
        new: newProtocols,
        removed: removedProtocols,
        same: sameProtocols,
      });
    };

    console.log('Proocols', database);

    getPairedProtocols();
  }, [changes]);

  useEffect(() => {
    const getPdfData = async () => {
      const beforePdfURL = findBeforeValue(changes, 'pdfUrl');
      const afterPdfURL = findCurrentValue(changes, 'pdfUrl');
      const [beforePDF, afterPDF] = await Promise.all([
        fetchPDF(beforePdfURL),
        fetchPDF(afterPdfURL),
      ]);
      setPdfData({
        before: beforePDF.data,
        after: afterPDF.data,
      });
    };
    if (
      findBeforeValue(changes, 'pdfUrl') ||
      findCurrentValue(changes, 'pdfUrl')
    )
      getPdfData();
    else setPdfData(null);
  }, [changes]);

  const handleFilter = (key: string, stat: string) => {
    setFilters((prev: any) => ({
      ...prev,
      [key]: prev[key].includes(stat)
        ? prev[key].filter((s: string) => s !== stat)
        : [...prev[key], stat],
    }));
  };

  /* Filter the departments based on the filters */
  const filteredDeps = useMemo(() => {
    let filteredByStat = filters.departments;
    if (filteredByStat.length === 0) return deps.all;
    else {
      let filteredDeps: DepartmentItem[] = [];
      if (filteredByStat.includes('same')) {
        filteredDeps = filteredDeps.concat(
          deps.all.filter((dep: DepartmentItem) =>
            deps.same.find((sdep: DepartmentItem) => sdep.id === dep.id)
          )
        );
      }
      if (filteredByStat.includes('new')) {
        filteredDeps = filteredDeps.concat(
          deps.all.filter((dep: DepartmentItem) =>
            deps.new.find((ndep: DepartmentItem) => ndep.id === dep.id)
          )
        );
      }
      if (filteredByStat.includes('removed')) {
        filteredDeps = filteredDeps.concat(
          deps.all.filter((dep: DepartmentItem) =>
            deps.removed.find((rdep: DepartmentItem) => rdep.id === dep.id)
          )
        );
      }
      return filteredDeps;
    }
  }, [filters.departments, deps]);

  const filteredEquipment = useMemo(() => {
    let filteredByStat = filters.equipment;
    if (filteredByStat.length === 0)
      return equipmentData.all as EquipmentItem[];
    else {
      let filteredEquipment: EquipmentItem[] = [];
      if (filteredByStat.includes('same')) {
        filteredEquipment = filteredEquipment.concat(
          equipmentData.all.filter((equip: EquipmentItem) => {
            return equipmentData.same.includes(equip);
          })
        );
      }
      if (filteredByStat.includes('new')) {
        filteredEquipment = filteredEquipment.concat(
          equipmentData.all.filter((equip: EquipmentItem) => {
            return equipmentData.new.includes(equip);
          })
        );
      }
      if (filteredByStat.includes('removed')) {
        filteredEquipment = filteredEquipment.concat(
          equipmentData.all.filter((equip: EquipmentItem) => {
            return equipmentData.removed.includes(equip);
          })
        );
      }
      return filteredEquipment;
    }
  }, [filters.equipment, equipmentData]);

  const filteredPairedProtocols = useMemo(() => {
    let filteredByStat = filters.pairedProtocols;
    if (filteredByStat.length === 0)
      return pairedProtocolsData.all as ProtocolItem[];
    else {
      let filteredPairedProtocols: ProtocolItem[] = [];
      if (filteredByStat.includes('same')) {
        filteredPairedProtocols = filteredPairedProtocols.concat(
          pairedProtocolsData.all.filter((protocol: ProtocolItem) => {
            return pairedProtocolsData.same.includes(protocol);
          })
        );
      }
      if (filteredByStat.includes('new')) {
        filteredPairedProtocols = filteredPairedProtocols.concat(
          pairedProtocolsData.all.filter((protocol: ProtocolItem) => {
            return pairedProtocolsData.new.includes(protocol);
          })
        );
      }
      if (filteredByStat.includes('removed')) {
        filteredPairedProtocols = filteredPairedProtocols.concat(
          pairedProtocolsData.all.filter((protocol: ProtocolItem) => {
            return pairedProtocolsData.removed.includes(protocol);
          })
        );
      }
      return filteredPairedProtocols;
    }
  }, [filters.pairedProtocols, pairedProtocolsData]);

  const isChange = (keys: string[]) => {
    return keys.some((key) => {
      return findBeforeValue(changes, key) || findCurrentValue(changes, key);
    });
  };

  const handleFullScreen = (pdf: string, name: string) => {
    if (pdf) {
      localStorage.setItem('pdfURL', pdf);
      localStorage.setItem('pdfName', name);
      let filterName = name.replace(/[^a-zA-Z0-9]/g, '_');
      const expiration = new Date().getTime() + 1000 * 60 * 60 * 6; //6 hours
      const signature = getHashedPin(
        filterName,
        pdf + expiration.toLocaleString(),
        'SHA512'
      );
      window.open(
        `${window.location.origin}/fullscreen-pdf/${filterName}?signature=${signature}&expiration=${expiration}
      `,
        '_blank'
      );
    }
  };

  return (
    <tbody className="comparison-rows">
      {PROTOCOL_DATA.map((item) => {
        return (
          isChange(item.keys) && (
            <HighlightChangesRow
              keys={item.keys}
              titles={item.titles}
              changes={changes}
              isNewItem={focusItem.activeItem == null}
            />
          )
        );
      })}

      {(findBeforeValue(changes, 'checklistIDs') ||
        findCurrentValue(changes, 'checklistIDs')) && (
        <tr>
          {focusItem.activeID != null && (
            <td>
              <div className="rows-container-item">
                <label htmlFor="Name" className="ketamine-general-label">
                  Checklist <span className="required-field">*</span>
                </label>
                <div
                  style={{
                    border: '1px solid #e0e0e0',
                    color: '#000',
                    fontWeight: '500',
                    fontSize: '15px',
                    padding: '4px',
                    borderRadius: '5px',
                    minHeight: '30px',
                    backgroundColor: 'white',
                  }}
                >
                  <HighlightChanges
                    beforeText={checklistData.before?.name || ''}
                    afterText={checklistData.after?.name || ''}
                    onlyPrevious={true}
                  />
                </div>
              </div>
            </td>
          )}
          <td>
            <div className="rows-container-item">
              <label htmlFor="Name" className="ketamine-general-label">
                Checklist <span className="required-field">*</span>
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={checklistData.before?.name || ''}
                  afterText={checklistData.after?.name || ''}
                  onlyCurrent={true}
                />
              </div>
            </div>
          </td>
        </tr>
      )}

      {(findBeforeValue(changes, 'equipmentIDs') ||
        findCurrentValue(changes, 'equipmentIDs')) && (
        <tr>
          {focusItem.activeID != null && (
            <td>
              <label htmlFor="Name" className="ketamine-general-label">
                Equipment <span className="required-field">*</span>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '10px',
                    gap: '10px',
                    marginTop: '0px',
                  }}
                >
                  {equipmentData.same?.length > 0 && (
                    <span
                      className={`transparent-stat-count same no-select ${
                        filters.equipment.includes('same') ? 'selected' : ''
                      }`}
                      onClick={() => handleFilter('equipment', 'same')}
                    >
                      {equipmentData.same?.length} same
                    </span>
                  )}
                  {equipmentData.new?.length > 0 && (
                    <span
                      className={`transparent-stat-count new no-select ${
                        filters.equipment.includes('new') ? 'selected' : ''
                      }`}
                      onClick={() => handleFilter('equipment', 'new')}
                    >
                      {equipmentData.new?.length} new
                    </span>
                  )}
                  {equipmentData.removed?.length > 0 && (
                    <span
                      className={`transparent-stat-count removed no-select ${
                        filters.equipment.includes('removed') ? 'selected' : ''
                      }`}
                      onClick={() => handleFilter('equipment', 'removed')}
                    >
                      {equipmentData.removed?.length} removed
                    </span>
                  )}
                </div>
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  maxHeight: '200px',
                  overflowY: 'auto',
                  backgroundColor: 'white',
                }}
              >
                {filteredEquipment.map((equip: EquipmentItem) => {
                  let isBefore = equipmentData.before.find(
                    (e: EquipmentItem) => e.uid === equip.uid
                  );
                  let isAfter = equipmentData.after.find(
                    (e: EquipmentItem) => e.uid === equip.uid
                  );
                  return (
                    <>
                      {!isBefore && isAfter ? (
                        <div
                          style={{
                            color: isBefore ? 'black' : 'transparent',
                          }}
                        >
                          {equip.name}
                        </div>
                      ) : (
                        <HighlightChanges
                          beforeText={isBefore ? equip.name : ''}
                          afterText={isAfter ? equip.name : ''}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </td>
          )}
          <td>
            <label htmlFor="Name" className="ketamine-general-label">
              Equipment <span className="required-field">*</span>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '10px',
                  gap: '10px',
                  marginTop: '0px',
                }}
              >
                {equipmentData.same?.length > 0 && (
                  <span
                    className={`stat-count same no-select ${
                      filters.equipment.includes('same') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('equipment', 'same')}
                  >
                    {equipmentData.same?.length} same
                  </span>
                )}
                {equipmentData.new?.length > 0 && (
                  <span
                    className={`stat-count new no-select ${
                      filters.equipment.includes('new') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('equipment', 'new')}
                  >
                    {equipmentData.new?.length} new
                  </span>
                )}
                {equipmentData.removed?.length > 0 && (
                  <span
                    className={`stat-count removed no-select ${
                      filters.equipment.includes('removed') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('equipment', 'removed')}
                  >
                    {equipmentData.removed?.length} removed
                  </span>
                )}
              </div>
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                maxHeight: '200px',
                overflowY: 'auto',
                backgroundColor: 'white',
              }}
            >
              {filteredEquipment.map((equip: EquipmentItem) => {
                let isBefore = equipmentData.before.find(
                  (e: EquipmentItem) => e.uid === equip.uid
                );
                let isAfter = equipmentData.after.find(
                  (e: EquipmentItem) => e.uid === equip.uid
                );
                return (
                  <>
                    {isBefore && !isAfter ? (
                      <div
                        style={{
                          color: isAfter ? 'black' : 'transparent',
                        }}
                      >
                        {equip.name}
                      </div>
                    ) : (
                      <HighlightChanges
                        beforeText={isBefore ? equip.name : ''}
                        afterText={isAfter ? equip.name : ''}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </td>
        </tr>
      )}

      {(findBeforeValue(changes, 'pairedProtocolIDs') ||
        findCurrentValue(changes, 'pairedProtocolIDs')) && (
        <tr>
          {focusItem.activeID != null && (
            <td>
              <label htmlFor="Name" className="ketamine-general-label">
                Paired Protocols <span className="required-field">*</span>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '10px',
                    gap: '10px',
                    marginTop: '0px',
                  }}
                >
                  {pairedProtocolsData.same?.length > 0 && (
                    <span
                      className={`transparent-stat-count same no-select ${
                        filters.pairedProtocols.includes('same')
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => handleFilter('pairedProtocols', 'same')}
                    >
                      {pairedProtocolsData.same?.length} same
                    </span>
                  )}
                  {pairedProtocolsData.new?.length > 0 && (
                    <span
                      className={`transparent-stat-count new no-select ${
                        filters.pairedProtocols.includes('new')
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => handleFilter('pairedProtocols', 'new')}
                    >
                      {pairedProtocolsData.new?.length} new
                    </span>
                  )}
                  {pairedProtocolsData.removed?.length > 0 && (
                    <span
                      className={`transparent-stat-count removed no-select ${
                        filters.pairedProtocols.includes('removed')
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => handleFilter('pairedProtocols', 'removed')}
                    >
                      {pairedProtocolsData.removed?.length} removed
                    </span>
                  )}
                </div>
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  maxHeight: '200px',
                  overflowY: 'auto',
                  backgroundColor: 'white',
                }}
              >
                {filteredPairedProtocols.map((protocol: ProtocolItem) => {
                  let isBefore = pairedProtocolsData.before.find(
                    (e: ProtocolItem) => e.uid === protocol.uid
                  );
                  let isAfter = pairedProtocolsData.after.find(
                    (e: ProtocolItem) => e.uid === protocol.uid
                  );
                  return (
                    <>
                      {!isBefore && isAfter ? (
                        <div
                          style={{
                            color: isBefore ? 'black' : 'transparent',
                          }}
                        >
                          {protocol.name}
                        </div>
                      ) : (
                        <HighlightChanges
                          beforeText={isBefore ? protocol.name : ''}
                          afterText={isAfter ? protocol.name : ''}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </td>
          )}
          <td>
            <label htmlFor="Name" className="ketamine-general-label">
              Paired Protocols <span className="required-field">*</span>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '10px',
                  gap: '10px',
                  marginTop: '0px',
                }}
              >
                {pairedProtocolsData.same?.length > 0 && (
                  <span
                    className={`stat-count same no-select ${
                      filters.pairedProtocols.includes('same') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('pairedProtocols', 'same')}
                  >
                    {pairedProtocolsData.same?.length} same
                  </span>
                )}
                {pairedProtocolsData.new?.length > 0 && (
                  <span
                    className={`stat-count new no-select ${
                      filters.pairedProtocols.includes('new') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('pairedProtocols', 'new')}
                  >
                    {pairedProtocolsData.new?.length} new
                  </span>
                )}
                {pairedProtocolsData.removed?.length > 0 && (
                  <span
                    className={`stat-count removed no-select ${
                      filters.pairedProtocols.includes('removed')
                        ? 'selected'
                        : ''
                    }`}
                    onClick={() => handleFilter('pairedProtocols', 'removed')}
                  >
                    {pairedProtocolsData.removed?.length} removed
                  </span>
                )}
              </div>
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                maxHeight: '200px',
                overflowY: 'auto',
                backgroundColor: 'white',
              }}
            >
              {filteredPairedProtocols.map((protocol: ProtocolItem) => {
                let isBefore = pairedProtocolsData.before.find(
                  (e: ProtocolItem) => e.uid === protocol.uid
                );
                let isAfter = pairedProtocolsData.after.find(
                  (e: ProtocolItem) => e.uid === protocol.uid
                );
                return (
                  <>
                    {isBefore && !isAfter ? (
                      <div
                        style={{
                          color: isAfter ? 'black' : 'transparent',
                        }}
                      >
                        {protocol.name}
                      </div>
                    ) : (
                      <HighlightChanges
                        beforeText={isBefore ? protocol.name : ''}
                        afterText={isAfter ? protocol.name : ''}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </td>
        </tr>
      )}

      {(findBeforeValue(changes, 'pairedDepIDs') ||
        findCurrentValue(changes, 'pairedDepIDs')) && (
        <tr>
          {focusItem.activeItem != null && (
            <td>
              <label htmlFor="Name" className="ketamine-general-label">
                Paired Departments <span className="required-field">*</span>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '10px',
                    gap: '10px',

                    marginTop: '0px',
                  }}
                >
                  {deps.same.length > 0 && (
                    <span
                      className={`transparent-stat-count same no-select ${
                        filters.departments.includes('same') ? 'selected' : ''
                      }`}
                      onClick={() => handleFilter('departments', 'same')}
                    >
                      {deps.same.length} same
                    </span>
                  )}
                  {deps.new.length > 0 && (
                    <span
                      className={`transparent-stat-count new no-select ${
                        filters.departments.includes('new') ? 'selected' : ''
                      }`}
                      onClick={() => handleFilter('departments', 'new')}
                    >
                      {deps.new.length} new
                    </span>
                  )}
                  {deps.removed.length > 0 && (
                    <span
                      className={`transparent-stat-count removed no-select ${
                        filters.departments.includes('removed')
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => handleFilter('departments', 'removed')}
                    >
                      {deps.removed.length} removed
                    </span>
                  )}
                </div>
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  maxHeight: '200px',
                  overflowY: 'auto',
                  backgroundColor: 'white',
                }}
              >
                {filteredDeps.map((item: DepartmentItem) => {
                  let isBefore = deps.before.find(
                    (dep: DepartmentItem) => dep.id === item.id
                  );
                  let isAfter = deps.after.find(
                    (dep: DepartmentItem) => dep.id === item.id
                  );
                  return (
                    <>
                      {!isBefore && isAfter ? (
                        <div
                          style={{
                            color: isBefore ? 'black' : 'transparent',
                          }}
                        >
                          {item.name}
                        </div>
                      ) : (
                        <HighlightChanges
                          beforeText={isBefore ? item.name : ''}
                          afterText={isAfter ? item.name : ''}
                        />
                      )}
                    </>
                  );
                })}
              </div>
            </td>
          )}
          <td>
            <label htmlFor="Name" className="ketamine-general-label">
              Paired Departments <span className="required-field">*</span>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '10px',
                  gap: '10px',
                  marginTop: '0px',
                }}
              >
                {deps.same.length > 0 && (
                  <span
                    className={`stat-count same no-select ${
                      filters.departments.includes('same') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('departments', 'same')}
                  >
                    {deps.same.length} same
                  </span>
                )}
                {deps.new.length > 0 && (
                  <span
                    className={`stat-count new no-select ${
                      filters.departments.includes('new') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('departments', 'new')}
                  >
                    {deps.new.length} new
                  </span>
                )}
                {deps.removed.length > 0 && (
                  <span
                    className={`stat-count removed no-select ${
                      filters.departments.includes('removed') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('departments', 'removed')}
                  >
                    {deps.removed.length} removed
                  </span>
                )}
              </div>
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                maxHeight: '200px',
                overflowY: 'auto',
                backgroundColor: 'white',
              }}
            >
              {filteredDeps.map((item: DepartmentItem) => {
                let isBefore = deps.before.find(
                  (dep: DepartmentItem) => dep.id === item.id
                );
                let isAfter = deps.after.find(
                  (dep: DepartmentItem) => dep.id === item.id
                );
                return (
                  <>
                    {isBefore && !isAfter ? (
                      <div
                        style={{
                          color: isAfter ? 'black' : 'transparent',
                        }}
                      >
                        {item.name}
                      </div>
                    ) : (
                      <HighlightChanges
                        beforeText={isBefore ? item.name : ''}
                        afterText={isAfter ? item.name : ''}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </td>
        </tr>
      )}

      {pdfData && (
        <tr>
          {focusItem.activeItem && (
            <td>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingBottom: '10px',
                }}
              >
                <label htmlFor="Name" className="ketamine-general-label">
                  PDF {(focusItem.activeItem as ProtocolItem)?.pdfVersion}
                </label>
                <Tooltip title={'Full Screen'} placement="bottom" arrow>
                  <span
                    className="iconButton"
                    onClick={() =>
                      handleFullScreen(
                        pdfData.before,
                        '(' +
                          (focusItem.activeItem as ProtocolItem)?.pdfVersion +
                          ') ' +
                          (focusItem.activeItem as ProtocolItem)?.name
                      )
                    }
                  >
                    <AiOutlineFullscreen
                      data-testid="isFullScreenButton"
                      className="icon"
                    />
                  </span>
                </Tooltip>
              </div>
              <PDFScreen pdf={pdfData.before} />
            </td>
          )}
          <td>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: '10px',
              }}
            >
              <label htmlFor="Name" className="ketamine-general-label">
                PDF {(focusItem as ProtocolItem)?.pdfVersion}
              </label>
              <Tooltip title={'Full Screen'} placement="bottom" arrow>
                <span
                  className="iconButton"
                  onClick={() =>
                    handleFullScreen(
                      pdfData.after,
                      '(' +
                        (focusItem as ProtocolItem)?.pdfVersion +
                        ') ' +
                        (focusItem as ProtocolItem)?.name
                    )
                  }
                >
                  <AiOutlineFullscreen className="icon" />
                </span>
              </Tooltip>
            </div>
            <PDFScreen pdf={pdfData.after} />
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default ProtocolComparisonView;
