import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import {
  BiCopy,
  BiPencil,
  BiRename,
  BiSolidCopy,
  BiTrash,
} from 'react-icons/bi';
import {
  getDepartmentsFromState,
  getFormattedDateTime,
  handleCopy,
  toTitleCase,
} from '../../../_global/common/Utils';
import { DatabaseResponse } from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { User, UserType } from '../../../../models';
import { Tooltip } from '@mui/material';
import { IoMdAdd } from 'react-icons/io';
import { FaTimesCircle } from 'react-icons/fa';
import { IoInformationCircle } from 'react-icons/io5';

interface DepartmentProps {
  user: User;
  formik: any;
  isEditMode: boolean;
}

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const EditProfilePage = (props: DepartmentProps) => {
  const { user, formik, isEditMode } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [pin, setPin] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(isEditMode);
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const dropRef = useRef(null);
  const [isSubDepChoiceOpen, setIsSubDepChoiceOpen] = useState(false);
  const divRef = useRef<HTMLUListElement>(null);

  const [isResetPinModal, setIsResetPinModal] = useState(false);
  const reducerState = useSelector((state: any) => state.department);
  const { allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(database.department, reducerState);
  }, [database.department, reducerState]);

  const parentAllSubDeps = useMemo(() => {
    return getDepartmentsFromState(
      database.department.getTopLevelDep(),
      reducerState
    ).allSubDeps;
  }, [database.department, reducerState]);

  const availableDeps = useMemo(() => {
    if (database.department.isTopEnabled || user.type === UserType.ADMIN)
      return [database.department, ...(allSubDeps || [])];
    return allSubDeps || [];
  }, [database.department, user, allSubDeps]);

  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>([]);
  const [depAdmins, setDepAdmins] = useState<DepartmentItem[]>([]);

  useEffect(() => {
    setIsEdit(isEditMode === true ? true : false);
  }, [isEditMode]);

  useEffect(() => {
    if (formik.values.pairedDepIDs && availableDeps) {
      let ids = formik.values.pairedDepIDs;
      let l = [...availableDeps];
      l = l.filter((dep) => {
        return ids.some((d: string) => d === dep.id);
      });
      setPairedDeps(l);
    }
    if (formik.values.depAdmins) {
      let ids = formik.values.depAdmins;
      let l = [...availableDeps];
      l = l.filter((dep) => {
        return ids.some((d: string) => d === dep.id);
      });
      setDepAdmins(l);
    }
  }, [formik.values.pairedDepIDs, availableDeps]);

  const handleBack = () => {
    navigate('/actions');
  };

  return (
    <div className="">
      {/* <ResetPinModal
        isVisible={isResetPinModal}
        department={parentDepartment}
        handleClose={() => {
          setIsResetPinModal(false);
        }}
        handleSubmit={(pin: string, salt: string, hash: string) => {
          formik.setFieldValue('pin', pin);
          formik.setFieldValue('saltedPin', salt);
          formik.setFieldValue('hashedPin', hash);
          setIsResetPinModal(false);
        }}
        value={formik.values.pin === '' ? undefined : formik.values.pin}
      /> */}
      <Row>
        <Col
          sm={8}
          style={{
            padding: '0 5rem',
            justifyContent: 'center',
            alignItems: 'center',

            // overflowX: 'hidden',
          }}
        >
          <label
            htmlFor="neonateCutoff"
            className="settings-general-label"
            style={{
              fontSize: '1.25rem',
              marginTop: '3rem',
              marginLeft: '0rem',
            }}
          >
            General
          </label>

          <Row>
            <Col sm={6}>
              <label
                htmlFor="firstName"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                }}
              >
                First Name
                <span className="required-field ">
                  *
                  <BiRename
                    className="refresh-icon"
                    onClick={() => {
                      formik.setFieldValue(
                        'firstName',
                        toTitleCase(formik.values.firstName)
                      );
                    }}
                    size="1.25rem"
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                  />
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="firstName"
                  name="firstName"
                  data-testid="firstName"
                  required={true}
                  value={formik.values.firstName + ''}
                  onChange={(e: any) => {
                    formik.setFieldValue('firstName', e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
            </Col>
            <Col sm={6}>
              <label
                htmlFor="lastName"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                }}
              >
                Last Name
                <span className="required-field ">
                  *
                  <BiRename
                    className="refresh-icon"
                    onClick={() => {
                      formik.setFieldValue(
                        'lastName',
                        toTitleCase(formik.values.lastName)
                      );
                    }}
                    size="1.25rem"
                    style={{ marginRight: '5px', cursor: 'pointer' }}
                  />
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="lastName"
                  name="lastName"
                  data-testid="lastName"
                  required={true}
                  value={formik.values.lastName + ''}
                  onChange={(e: any) => {
                    formik.setFieldValue('lastName', e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
            </Col>
          </Row>
          {user.type === 'ADMIN' && (
            <>
              <label
                htmlFor="departmentID"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                }}
              >
                Department ID
                <span>
                  {isCopied && isCopied === formik.values.departmentID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(formik.values.departmentID, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="departmentID"
                  name="departmentID"
                  data-testid="departmentID"
                  required={true}
                  value={formik.values.departmentID}
                  onChange={(e: any) => {
                    formik.setFieldValue('departmentID', e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
              <label
                htmlFor="departmentID"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                }}
              >
                Organization ID
                <span>
                  {isCopied && isCopied === formik.values.indexedParentDepID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(
                          formik.values.indexedParentDepID,
                          e,
                          setIsCopied
                        )
                      }
                    />
                  )}
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="indexedParentDepID"
                  name="indexedParentDepID"
                  data-testid="indexedParentDepID"
                  required={true}
                  value={formik.values.indexedParentDepID}
                  onChange={(e: any) => {
                    formik.setFieldValue('indexedParentDepID', e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
              <label
                htmlFor="cognitoID"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                }}
              >
                Username
                <span>
                  {isCopied && isCopied === formik.values.cognitoID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(formik.values.cognitoID, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="cognitoID"
                  name="cognitoID"
                  data-testid="cognitoID"
                  required={true}
                  value={formik.values.cognitoID}
                  onChange={(e: any) => {
                    formik.setFieldValue('cognitoID', e.target.value);
                  }}
                  disabled={false}
                />
                <div className="input-border"></div>
              </div>
              <label
                htmlFor="departmentID"
                className="settings-general-label"
                style={{
                  marginTop: '1rem',
                  marginLeft: '0px',
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>Subscribed Departments </div>
                <span style={{ display: 'flex', gap: '0rem' }}>
                  <Tooltip
                    title={isEdit ? 'Cancel' : 'Edit'}
                    placement="bottom"
                    arrow
                  >
                    <span
                      className={`iconButton`}
                      onClick={() => {
                        setIsEdit(!isEdit);
                      }}
                    >
                      {!isEdit ? (
                        <BiPencil
                          data-testid="isDotButton"
                          className="icon"
                          style={{
                            padding: '6px',
                            // color: '#616161',
                          }}
                        />
                      ) : (
                        <FaTimesCircle
                          data-testid="isDotButton"
                          className="icon"
                          style={{
                            padding: '6px',
                            // color: '#616161',
                          }}
                        />
                      )}
                    </span>
                  </Tooltip>
                  <Tooltip title={'Add'} placement="bottom" arrow>
                    <span
                      className={`iconButton`}
                      onClick={() => {
                        formik.setFieldValue('pairedDepIDs', [
                          ...formik.values.pairedDepIDs,
                          '',
                        ]);
                      }}
                    >
                      <IoMdAdd
                        data-testid="isDotButton"
                        className="icon"
                        style={{
                          padding: '6px',
                          // color: '#616161',
                        }}
                      />
                    </span>
                  </Tooltip>
                </span>
              </label>
              {formik.values.pairedDepIDs &&
                formik.values.pairedDepIDs.length > 0 &&
                formik.values.pairedDepIDs.map(
                  (depID: string, index: number) => {
                    return (
                      <div key={index} style={{ display: 'flex', gap: '1rem' }}>
                        {isEdit && (
                          <Tooltip
                            title={
                              [
                                ...(parentAllSubDeps || []),
                                database.department,
                              ]?.find((dep) => dep.id === depID)?.name ?? depID
                            }
                            placement="left"
                            arrow
                          >
                            <span className={`iconButton`}>
                              <IoInformationCircle
                                className="icon"
                                size="1.2rem"
                                style={{ padding: '6px' }}
                              />
                            </span>
                          </Tooltip>
                        )}
                        <div
                          className="input-container"
                          style={{
                            flex: 1,
                          }}
                        >
                          <InputText
                            type="text"
                            className="form-control-general"
                            required={true}
                            value={
                              !isEdit
                                ? ([
                                    ...(parentAllSubDeps || []),
                                    database.department,
                                  ]?.find((dep) => dep.id === depID)?.name ??
                                  depID)
                                : depID
                            }
                            onChange={(e: any) => {
                              formik.setFieldValue(
                                'pairedDepIDs',
                                formik.values.pairedDepIDs.map((id: string) =>
                                  id === depID ? e.target.value : id
                                )
                              );
                            }}
                            // disabled={true}
                          />
                          <div className="input-border"></div>
                        </div>
                        <Tooltip title={'Delete'} placement="bottom" arrow>
                          <span
                            className={`iconButton`}
                            onClick={() => {
                              formik.setFieldValue(
                                'pairedDepIDs',
                                formik.values.pairedDepIDs.filter(
                                  (id: string) => id !== depID
                                )
                              );
                            }}
                          >
                            <BiTrash
                              className="icon"
                              size="1.2rem"
                              style={{ padding: '6px' }}
                            />
                          </span>
                        </Tooltip>
                      </div>
                    );
                  }
                )}
            </>
          )}
        </Col>
        <Col sm={4}>
          <label
            htmlFor="s"
            className="settings-general-label"
            style={{
              marginLeft: '0rem',
              fontSize: '1.25rem',
              marginTop: '3rem',
            }}
          >
            User Information
          </label>
          <div
            className="input-container roundBorder"
            style={{
              margin: '0px 0rem',
              marginTop: '1rem',
              gap: '0.25rem',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
              }}
              className="settings-general-label"
            >
              <div style={{ marginRight: '10px' }}>Last Activity:</div>
              <div style={{ fontWeight: '500' }}>
                {getFormattedDateTime(user.lastActivity ?? '', false)}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
              }}
              className="settings-general-label"
            >
              <div style={{ marginRight: '10px' }}>OneDose Version:</div>
              <div style={{ fontWeight: '500' }}>
                {formik.values.oneDoseVersion}
              </div>
            </div>
            <div
              style={{ display: 'flex', marginTop: '5px' }}
              className="settings-general-label"
            >
              <div style={{ marginRight: '10px' }}>Username:</div>
              <div style={{ fontWeight: '500' }}>
                {formik.values.cognitoID}
                <span>
                  {isCopied && isCopied === formik.values.cognitoID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(formik.values.cognitoID, e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
              }}
              className="settings-general-label"
            >
              <div style={{ marginRight: '10px' }}>User Type:</div>
              <div style={{ fontWeight: '500' }}>
                {toTitleCase(formik.values.type)}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: '5px',
              }}
              className="settings-general-label"
            >
              <div style={{ marginRight: '10px' }}>Status</div>
              <div style={{ fontWeight: '500' }}>
                {toTitleCase(formik.values.status)}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EditProfilePage;
