import React, { useMemo, useState } from 'react';
import ModelItem from '../../../../data/model/ModelInterface';
import VitalItem, { parseOption } from '../../../../data/model/VitalItem';
import HighlightChanges from '../HighlightChanges';
import {
  globals,
  jsonToTitleCase,
  mapIndexToRange,
  toTitleCase,
} from '../../../_global/common/Utils';
import VitalFullView from '../fullView/VitalFullView';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import VitalComparisonView from '../comparisonView/VitalComparisonView';
import FullViewDialog from '../fullView/FullViewDialog';
import { formatForMatchingUnits } from '../../../../data/model/EquipmentItem';
import { VitalOption } from '../../../../models';
import ReviewalItem from '../../../../data/model/ReviewalItem';
import { useNavigate } from 'react-router-dom';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  reviewal: ReviewalItem;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

export const compareVitalItems = (
  currentItem: VitalItem,
  archiveItem: VitalItem
) => {
  const changes: any[] = [];

  // Define the specific fields to check
  const fieldsToCompare: (keyof VitalItem)[] = ['name'];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  fieldsToCompare.forEach((key) => {
    let currentValue = currentItem[key];
    let archiveValue = archiveItem ? archiveItem[key] : '';

    if (compareValues(currentValue, archiveValue)) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  // Compare options array
  const currentOptions = currentItem.options;
  const archiveOptions = archiveItem?.options || [];

  const optionsChanges = (
    currentOptions.length >= archiveOptions.length
      ? currentOptions
      : archiveOptions
  ).reduce((acc: any[], option, index) => {
    const currentOption =
      currentOptions.length >= archiveOptions.length
        ? option
        : currentOptions.find((opt) => option.id === opt.id);
    const archiveOption =
      archiveOptions.length > currentOptions.length
        ? option
        : archiveOptions.find((opt) => option.id === opt.id);

    // Define the specific fields to check in options
    const fieldsToCompareInOptions: (keyof VitalOption)[] = [
      'rangeLow',
      'rangeHigh',
      'amntLow',
    ];

    let optionChanged = false;
    const optionChanges: any = { index, currentValue: {}, archiveValue: {} };

    fieldsToCompareInOptions.forEach((key) => {
      let currentValue = currentOption ? currentOption[key] : '';
      let archiveValue = archiveOption ? archiveOption[key] : '';

      if (key === 'rangeHigh') {
        currentValue = currentOption
          ? mapIndexToRange(currentOption.rangeHigh)
          : '';
        archiveValue = archiveOption
          ? mapIndexToRange(archiveOption.rangeHigh)
          : '';
        optionChanges.currentValue[key] = currentValue;
        optionChanges.archiveValue[key] = archiveValue;
      }

      if (key === 'rangeLow') {
        currentValue = currentOption
          ? mapIndexToRange(currentOption.rangeLow)
          : '';
        archiveValue = archiveOption
          ? mapIndexToRange(archiveOption.rangeLow)
          : '';
        optionChanges.currentValue[key] = currentValue;
        optionChanges.archiveValue[key] = archiveValue;
      }

      if (key === 'amntLow') {
        currentValue = currentOption
          ? formatForMatchingUnits(
              currentOption.amntLow,
              currentOption.amntHigh
            )
          : '';
        archiveValue = archiveOption
          ? formatForMatchingUnits(
              archiveOption.amntLow,
              archiveOption.amntHigh
            )
          : '';
        optionChanges.currentValue[key] = currentValue;
        optionChanges.archiveValue[key] = archiveValue;
      }

      if (compareValues(currentValue, archiveValue)) {
        optionChanged = true;
        //   optionChanges.currentValue[key] = String(currentValue);
        //   optionChanges.archiveValue[key] = String(archiveValue) || '';
      }
    });

    // Only add to the changes if any field has changed
    if (optionChanged) {
      acc.push(optionChanges);
    } else {
      acc.push({
        index,
        currentValue: {
          amntLow: currentOption ? parseOption(currentOption) : '',
          rangeLow: currentOption
            ? mapIndexToRange(currentOption.rangeLow)
            : '',
          rangeHigh: currentOption
            ? mapIndexToRange(currentOption.rangeHigh)
            : '',
        },
        archiveValue: {
          amntLow: archiveOption ? parseOption(archiveOption) : '',
          rangeLow: archiveOption
            ? mapIndexToRange(archiveOption.rangeLow)
            : '',
          rangeHigh: archiveOption
            ? mapIndexToRange(archiveOption.rangeHigh)
            : '',
        },
      });
    }
    return acc;
  }, []);

  if (optionsChanges.length > 0) {
    changes.push({
      key: 'options',
      changes: optionsChanges,
    });
  }

  return changes;
};

const VitalComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  reviewal,
  onChangesCount,
}) => {
  const navigate = useNavigate();
  const { changeItem, previousItem } = useMemo(() => {
    return {
      changeItem: currentDraft?.changeItem as VitalItem,
      previousItem: currentDraft?.previousItem as VitalItem | null,
    };
  }, [currentDraft]);
  const changes = useMemo(() => {
    const comparedChanges = compareVitalItems(
      changeItem as VitalItem,
      previousItem as VitalItem
    );
    if (onChangesCount) {
      onChangesCount(comparedChanges.length);
    }
    return comparedChanges;
  }, [changeItem, previousItem, onChangesCount]);

  const [viewMode, setViewMode] = useState<'none' | 'before' | 'after'>('none');

  const handleViewClick = (mode: 'before' | 'after') => {
    let vit = mode === 'before' && previousItem ? previousItem : changeItem;

    const state = {
      selectedProtocol: null,
      value: vit,
      subValue: null,
      type: 'Vital',
      editType: 'edit',
      editMode: false,
      page: 'reviewPage',
      reviewal: reviewal,
      currentDraft: currentDraft,
    };
    //Replace everything but [A-Za-z0-9] with '-'
    let cleanedPath = vit.name.replace(/[^A-Za-z0-9]/g, '-');
    navigate(`/review/view/${cleanedPath}`, { state });
  };

  return (
    <div>
      {viewMode === 'none' ? (
        <div className="doseChangesWrapper">
          <table className="changesTable">
            {previousItem ? (
              <>
                <thead>
                  <tr>
                    <th className="changeHeader beforeChange">
                      <div className="headerContent">
                        Before
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('before')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                    <th className="changeHeader afterChange">
                      {' '}
                      <div className="headerContent">
                        After
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>

                <VitalComparisonView
                  focusItem={changeItem as VitalItem}
                  changes={changes}
                />
              </>
            ) : (
              <>
                <thead>
                  <tr>
                    <th className="changeHeader afterChange">
                      <div className="headerContent">
                        New {toTitleCase(currentDraft.changeType)}
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <VitalComparisonView
                  focusItem={changeItem as VitalItem}
                  changes={changes}
                />
              </>
            )}
          </table>
        </div>
      ) : (
        <>
          <FullViewDialog
            isVisible={true}
            handleClose={() => setViewMode('none')}
            title="View Vital"
          >
            <VitalFullView
              focusItem={
                viewMode === 'before'
                  ? (previousItem as VitalItem)
                  : (changeItem as VitalItem)
              }
              handleBack={() => setViewMode('none')}
              viewText={viewMode === 'before' ? 'Before' : 'After'}
            />
          </FullViewDialog>
        </>
      )}
    </div>
  );
};

export default VitalComparison;
