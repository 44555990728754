import React, { useMemo, useState } from 'react';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import HighlightChanges from '../HighlightChanges';
import {
  globals,
  jsonToTitleCase,
  toTitleCase,
} from '../../../_global/common/Utils';
import ElectricalFullView from '../fullView/ElectricalFullView';
import FullViewDialog from '../fullView/FullViewDialog';
import ComparisonView from '../comparisonView/ComparisonView';
import { useNavigate } from 'react-router-dom';
import ModelItem from '../../../../data/model/ModelItem';
import ReviewalItem from '../../../../data/model/ReviewalItem';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  reviewal: ReviewalItem;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

interface Change {
  key: string;
  currentValue: string;
  archiveValue: string;
}
export const compareElectricalItems = (
  currentItem: ElectricalItem,
  archiveItem: ElectricalItem
): Change[] => {
  const changes: Change[] = [];

  // Define the specific fields to check
  const fieldsToCompare: string[] = [
    'name',
    'rangeLow',
    'rangeHigh',
    'warning',
    'instruction',
    'note',
  ];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  fieldsToCompare.forEach((key) => {
    let currentValue;
    let archiveValue;
    // if (key === 'warning' || key === 'instruction' || key === 'note') {
    //   currentValue = currentItem?.model?.warning;
    //   archiveValue = archiveItem?.model ? archiveItem?.model?.warning : '';
    // } else {
    currentValue = currentItem[key as keyof ElectricalItem];
    archiveValue = archiveItem ? archiveItem[key as keyof ElectricalItem] : '';
    // }

    if (key === 'rangeHigh') {
      currentValue =
        currentValue === globals.MAX_VALUE
          ? 'Max'
          : (currentValue as string) || '';
      archiveValue =
        archiveValue === globals.MAX_VALUE
          ? 'Max'
          : (archiveValue as string) || '';
    }

    if (key === 'rangeLow') {
      currentValue =
        currentValue === 0 ? 'Min' : (currentValue as string) || '';
      archiveValue =
        archiveValue === 0 ? 'Min' : (archiveValue as string) || '';
    }

    if (compareValues(currentValue, archiveValue)) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  return changes;
};

const ELECTRICAL_DATA = [
  {
    keys: ['name'],
    titles: ['Name'],
  },
  {
    keys: ['rangeLow', 'rangeHigh'],
    titles: ['Range Low (kg)', 'Range High (kg)'],
  },
  {
    keys: ['warning'],
    titles: ['Warning'],
  },
  {
    keys: ['instruction'],
    titles: ['Instructions'],
  },
  {
    keys: ['note'],
    titles: ['Note'],
  },
];

const ElectricalComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  reviewal,
  onChangesCount, // Add the callback prop
}) => {
  const navigate = useNavigate();
  const { changeItem, previousItem } = useMemo(() => {
    return {
      changeItem: currentDraft?.changeItem as ElectricalItem,
      previousItem: currentDraft?.previousItem as ElectricalItem | null,
    };
  }, [currentDraft]);
  const changes = useMemo(() => {
    const comparedChanges = compareElectricalItems(
      changeItem as ElectricalItem,
      previousItem as ElectricalItem
    );
    if (onChangesCount) {
      onChangesCount(comparedChanges.length); // Call the callback with the changes count
    }
    return comparedChanges;
  }, [changeItem, previousItem, onChangesCount]);
  const [viewMode, setViewMode] = useState<'none' | 'before' | 'after'>('none');

  const handleViewClick = (mode: 'before' | 'after') => {
    if (previousItem) {
      (previousItem as ElectricalItem).subItems = (
        changeItem as ElectricalItem
      ).subItems;
    }
    let elec = mode === 'before' && previousItem ? previousItem : changeItem;

    const state = {
      selectedProtocol: null,
      value: elec,
      subValue: null,
      type: 'Electrical',
      editType: 'edit',
      editMode: false,
      page: 'reviewPage',
      reviewal: reviewal,
      currentDraft: currentDraft,
    };
    //Replace everything but [A-Za-z0-9] with '-'
    let cleanedPath = elec.name.replace(/[^A-Za-z0-9]/g, '-');
    navigate(`/review/view/${cleanedPath}`, { state });
  };

  return (
    <div>
      {viewMode === 'none' ? (
        <div className="doseChangesWrapper">
          <table className="changesTable">
            {previousItem != null ? (
              <>
                <thead>
                  <tr>
                    <th className="changeHeader beforeChange">
                      <div className="headerContent">
                        Before
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('before')}
                        >
                          View
                        </button>
                      </div>{' '}
                    </th>
                    <th className="changeHeader afterChange">
                      <div className="headerContent">
                        After
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <ComparisonView
                  focusItem={changeItem as ElectricalItem}
                  changes={changes}
                  data={ELECTRICAL_DATA}
                />
                {/* <tbody>
                  <tr>
                    <td>
                      <ElectricalComparisonView
                        focusItem={previousItem as ElectricalItem}
                        showHeader={false}
                        changes={changes}
                        keepBefore={true}
                      />
                    </td>
                    <td>
                      <ElectricalComparisonView
                        focusItem={changeItem as ElectricalItem}
                        changes={changes}
                        showHeader={false}
                      />
                    </td>
                  </tr>
                </tbody> */}
              </>
            ) : (
              <>
                <thead>
                  <tr>
                    <th className="changeHeader afterChange">
                      <div className="headerContent">
                        New {toTitleCase(currentDraft.changeType)}
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                {/* <tbody>
                  <tr>
                    <td> */}
                <ComparisonView
                  focusItem={changeItem as ElectricalItem}
                  changes={changes}
                  data={ELECTRICAL_DATA}
                  isNewItem={true}
                  // showHeader={false}
                />
                {/* </td>
                  </tr>
                </tbody> */}
              </>
            )}
          </table>
        </div>
      ) : (
        <>
          <FullViewDialog
            isVisible={true}
            handleClose={() => setViewMode('none')}
            title="View Electrical"
          >
            <ElectricalFullView
              focusItem={
                viewMode === 'before'
                  ? (previousItem as ElectricalItem)
                  : (changeItem as ElectricalItem)
              }
              handleBack={() => setViewMode('none')}
              viewText={viewMode === 'before' ? 'Before' : 'After'}
            />
          </FullViewDialog>
        </>
      )}
    </div>
  );
};

export default ElectricalComparison;
