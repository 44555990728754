import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { useDispatch } from 'react-redux';

import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { ViewportList } from 'react-viewport-list';
import './Review.scss';
import ReactLoading from 'react-loading';

import { FaArrowDown, FaCircleCheck } from 'react-icons/fa6';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../data/AmplifyDB';

import { getStatusStyles } from './styles';
import { User } from '../../../models';
import { FaTimes } from 'react-icons/fa';

import ReviewalItem from '../../../data/model/ReviewalItem';
import SearchableDropdown from '../../components/SearchableDropdown';
import {
  createReviewalProcess,
  DraftChangeJSON,
  DraftGroupJSON,
  ReviewalJSON,
} from '../../../data/functions/ReviewalDB';
import { Select, MenuItem } from '@mui/material';
import SearchBar from '../../components/Search/SearchBar';
import { Draft, getDraftUpdates } from '../../../data/AmplifyVersion';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { HiPencil } from 'react-icons/hi';
import { InputText } from 'primereact/inputtext';
import { LogEventInput, UserType } from '../../../API';
import Loading from '../../components/Loading/Loading';
import CategoryItem from '../../../data/model/CategoryItem';
import MedicationItem from '../../../data/model/MedicationItem';
import ProtocolItem from '../../../data/model/ProtocolItem';
import ModelItem from '../../../data/model/ModelItem';
import MedicationSubItem from '../../../data/model/MedicationSubItem';
import TextConfirmModal from './TextConfirmModal';
import { globals } from '../../_global/common/Utils';
import { DraftChangeType } from '../../../data/model/DraftChangeItem';

const ReviewCreate = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((reviewal: any) => reviewal.protocol.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const { data, handleStatusStyle } = location.state;

  const [reviewal, setReviewal] = useState<ReviewalItem>(data);

  const [searchQuery, setSearchQuery] = useState('');
  const [type, setType] = useState('');
  const [folder, setFolder] = useState('');
  const [modifiedBy, setModifiedBy] = useState('');
  const [changed, setChanged] = useState('');

  const [reviewers, setReviewers] = useState<User[]>([]);

  const [allUsers, setAllUsers] = useState<User[]>(
    database.users.filter((_user: User) => {
      // Only include ADMIN and DEPT_ADMIN users
      if (_user.type !== UserType.ADMIN && _user.type !== UserType.DEPT_ADMIN) {
        return false;
      }

      // Don't include other admins if current user is admin
      if (user.type === UserType.ADMIN && _user.type === UserType.ADMIN) {
        return false;
      }

      // For dept admins, check department access
      if (_user.type === UserType.DEPT_ADMIN && _user.pairedDepIDs) {
        return (
          _user.pairedDepIDs.includes(database.department.id) ||
          _user.departmentID === database.department.id
        );
      }

      return true;
    })
  );
  const [loading, setLoading] = useState<string | null>(null);
  const [allDrafts, setAllDrafts] = useState<Draft[]>([]);
  const [draftGroups, setDraftGroups] = useState<any>([
    {
      title: 'Review Group 1',
      description: '',
      drafts: [],
    },
  ]);
  const [curIndex, setCurIndex] = useState(0);

  const [editMode, setEditMode] = useState('');
  const [title, setTitle] = useState(
    'Review' + ' ' + new Date().toLocaleDateString()
  );
  const [titleError, setTitleError] = useState('');
  // const [reviewDescription, setReviewDescription] = useState('');

  const [selectAll, setSelectAll] = useState(false);
  const [selectedDrafts, setSelectedDrafts] = useState<Draft[]>([]);
  const draftGroup = useMemo(
    () => draftGroups[curIndex],
    [draftGroups, curIndex]
  );
  const [hoveredDraft, setHoveredDraft] = useState<string | null>(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [textInputModal, setTextInputModal] = useState<boolean>(false);
  const [loadingDrafts, setLoadingDrafts] = useState<boolean>(false);
  const typeMappings = {
    electrical: [
      DraftChangeType.ELECTRICAL,
      DraftChangeType.ELECTRICAL_SHOCK,
      DraftChangeType.ELECTRICAL_INDEX,
    ],
    medication: [
      DraftChangeType.MEDICATION,
      DraftChangeType.MEDICATION_DOSE,
      DraftChangeType.MEDICATION_INDEX,
    ],
    infusion: [
      DraftChangeType.INFUSION,
      DraftChangeType.INFUSION_DOSE,
      DraftChangeType.INFUSION_INDEX,
    ],
    protocol: [
      DraftChangeType.PROTOCOL,
      DraftChangeType.PROTOCOL_PDF,
      DraftChangeType.PROTOCOL_INDEX,
    ],
  };

  useEffect(() => {
    if (database) {
      //Filter out duplicates
      let users = database.users.filter((_user: User) => {
        // Only include ADMIN and DEPT_ADMIN users
        if (_user.type !== UserType.ADMIN && _user.type !== UserType.DEPT_ADMIN)
          return false;

        // Don't include other admins if current user is NOT SUPER ADMIN
        if (user.type !== UserType.ADMIN && _user.type === UserType.ADMIN)
          return false;

        // For dept admins, check department access
        if (_user.pairedDepIDs) {
          return (
            _user.pairedDepIDs.includes(database.department.id) ||
            _user.departmentID === database.department.id
          );
        } else if (_user.departmentID === database.department.id) return true;

        return false;
      });
      setAllUsers(users);
    }
  }, [database, user]);

  useEffect(() => {
    setLoadingDrafts(true);
  }, []);

  useEffect(() => {
    const fetchChanges = async () => {
      let resp: Response = await getDraftUpdates(database, false, false);
      if (resp.type === ResponseType.Success) setAllDrafts(resp.data);
      setLoadingDrafts(false);
    };
    fetchChanges();
  }, [database]);

  useEffect(() => {
    if (reviewal?.reviewers?.length > 0) {
      setReviewers(reviewal.reviewers);
    }
  }, [reviewal, reviewal.reviewers]);

  const handleAddReviwer = (reviewer: User) => {
    setReviewers([...reviewers, reviewer]);
  };

  const handleRemoveReviewer = (reviewer: User) => {
    const newOptions = reviewers.filter(
      (option: User) => option.id !== reviewer.id
    );
    setReviewers(newOptions);
  };

  const handleBack = () => {
    navigate('/review', { state: reviewal });
  };

  const handleSearchChange = (text: string) => {
    setSearchQuery(text);
  };

  const handleReset = () => {
    setSearchQuery('');
    setType('');
    setFolder('');
    setModifiedBy('');
    setChanged('');
  };

  const getCategoryList = (draft: any, folderId: string) => {
    if (draft.model instanceof MedicationItem) {
      const filteredMedications = draft.model.subItems.filter(
        (medication: MedicationSubItem) => {
          // Check if the medication's parent protocol's parent UID matches the folderId
          return medication?.parentProtocol?.parent?.uid === folderId;
        }
      );

      if (filteredMedications.length === 0) {
        return false;
      }

      // Optionally, log or process the filtered items
      return true;
    } else if (draft?.model instanceof ProtocolItem) {
      // Protocol Item
      if (draft?.model?.parent?.uid === folderId) {
        return draft?.model?.parent?.uid === folderId;
      }
    } else if (draft?.model instanceof CategoryItem) {
      // Category Item
      if (draft?.model?.uid === folderId) {
        return draft?.model?.uid === folderId;
      }
    }
  };
  const getDateDifferenceInDays = (date1: Date, date2: Date): number => {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const filteredDrafts = useMemo(() => {
    const currentDate = new Date();
    return allDrafts.filter((draft: any) => {
      const matchesSearchQuery =
        draft?.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        draft?.message.toLowerCase().includes(searchQuery.toLowerCase());

      const matchesType =
        !type ||
        (typeMappings[type.toLowerCase() as keyof typeof typeMappings]
          ? typeMappings[type.toLowerCase() as keyof typeof typeMappings].some(
              (val) => draft?.changeType === val
            )
          : draft?.changeType === type);

      const matchesModifiedBy =
        !modifiedBy || draft?.model?.modifiedBy?.id === modifiedBy;
      const draftDate = new Date(draft?.model?.modifiedBy?.createdAt);
      const matchesFolder = !folder || getCategoryList(draft, folder);
      let matchesChanged = true;

      if (changed === 'date10') {
        matchesChanged = getDateDifferenceInDays(draftDate, currentDate) <= 10;
      } else if (changed === 'date20') {
        matchesChanged = getDateDifferenceInDays(draftDate, currentDate) <= 20;
      } else if (changed === 'date30') {
        matchesChanged = getDateDifferenceInDays(draftDate, currentDate) <= 30;
      }
      return (
        matchesSearchQuery &&
        matchesType &&
        matchesFolder &&
        matchesModifiedBy &&
        matchesChanged
      );
    });
  }, [allDrafts, searchQuery, type, folder, modifiedBy, changed]);

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const sourceArray =
      source.droppableId === 'drafts' ? allDrafts : draftGroup.drafts;
    const destinationArray =
      destination.droppableId === 'drafts' ? allDrafts : draftGroup.drafts;

    if (selectedDrafts.length > 0) {
      // Handle multi-select drag
      const itemsToMove = selectedDrafts.filter((draft: Draft) =>
        sourceArray.some(
          (item: Draft) =>
            item.model.uid === draft.model.uid &&
            item.changeType === draft.changeType
        )
      );

      // Remove selected items from the source array
      const newSourceArray = sourceArray.filter(
        (item: Draft) =>
          !itemsToMove.some(
            (draft: Draft) =>
              draft.model.uid === item.model.uid &&
              draft.changeType === item.changeType
          )
      );

      // Insert selected items into the destination array
      const newDestinationArray = [...destinationArray];
      newDestinationArray.splice(destination.index, 0, ...itemsToMove);

      // Update the state
      if (source.droppableId === 'drafts') {
        setAllDrafts(newSourceArray);
      } else {
        setDraftGroups((prevGroups: any) =>
          prevGroups.map((group: any, index: number) =>
            index === curIndex ? { ...group, drafts: newSourceArray } : group
          )
        );
      }

      if (destination.droppableId === 'drafts') {
        setAllDrafts(newDestinationArray);
      } else {
        setDraftGroups((prevGroups: any) =>
          prevGroups.map((group: any, index: number) =>
            index === curIndex
              ? { ...group, drafts: newDestinationArray }
              : group
          )
        );
      }
    } else {
      // Handle single item drag (your existing logic)
      const sourceIndex =
        source.droppableId === 'drafts'
          ? allDrafts.findIndex(
              (draft: any) =>
                draft.model.uid === filteredDrafts[source.index].model.uid &&
                draft.changeType === filteredDrafts[source.index].changeType
            )
          : source.index;

      const [movedItem] = sourceArray.splice(sourceIndex, 1);

      if (destination.droppableId === 'drafts') {
        const destinationIndex =
          destination.index < filteredDrafts.length
            ? allDrafts.findIndex(
                (draft: any) =>
                  draft.model.uid ===
                    filteredDrafts[destination.index].model.uid &&
                  draft.changeType ===
                    filteredDrafts[destination.index].changeType
              )
            : allDrafts.length;
        allDrafts.splice(destinationIndex, 0, movedItem);
      } else {
        draftGroup.drafts.splice(destination.index, 0, movedItem);
      }

      setAllDrafts([...allDrafts]);
      setDraftGroups((prevGroups: any) =>
        prevGroups.map((group: any, index: number) =>
          index === curIndex
            ? { ...group, drafts: [...draftGroup.drafts] }
            : group
        )
      );
    }

    // Clear selection after drag
    setIsDraggingOver(false);
    setSelectedDrafts([]);
    setSelectAll(false);
  };

  const handleContinue = async (description: string) => {
    setLoading('Creating Review...');
    try {
      /* Loop through every DraftGroup and create the DraftChanges */
      let draftGroupsJSONs: DraftGroupJSON[] = [];
      for (let i = 0; i < draftGroups.length; i++) {
        let dg: DraftGroupJSON = {
          title: draftGroups[i].title,
          description: draftGroups[i].description,
          createdBy: user,
          draftChanges: [],
        };
        let draftChanges: DraftChangeJSON[] = [];
        for (let j = 0; j < draftGroups[i].drafts.length; j++) {
          let dc = draftGroups[i].drafts[j];
          if (!dc.model) {
            console.warn('DraftChange Model is undefined:', dc);
            continue;
          }
          let draftChange: DraftChangeJSON = {
            changeType: dc.changeType,
            changeItem: dc.model,
            previousItem: dc.model.activeItem,
            isClosed: false,
          };
          if (globals.debug) console.log('DraftChange:', draftChange);
          draftChanges.push(draftChange);
        }
        dg.draftChanges = draftChanges;
        draftGroupsJSONs.push(dg);
      }

      let createEvent: LogEventInput = {
        title: 'Review Created by ' + user.firstName + ' ' + user.lastName,
        description:
          'Review was created by ' +
          user.firstName +
          ' ' +
          user.lastName +
          ' on ' +
          new Date().toLocaleDateString(),
        timestamp: new Date().toISOString(),
        color: '#00534C',
      };

      let reviewJSON: ReviewalJSON = {
        title: title,
        description: description,
        reviewers: reviewers,
        user: user,
        draftGroups: draftGroupsJSONs,
        events: [createEvent],
      };

      const response: Response = await createReviewalProcess(
        database,
        reviewJSON,
        false
      );

      if (response.type === ResponseType.Success) {
        const review: ReviewalItem = response.data;
        if (globals.debug) console.log('Successfully Created Review:', review);
        navigate('/reviewDetails', {
          state: {
            data: review,
          },
        });
      } else {
        console.error('Failed to Create Review:', response);
        setLoading(null);
      }
    } catch (error) {
      console.error('Failed to Create Review:', error);
      setLoading(null);
    }
  };

  const handleTitleChange = (event: any) => {
    event.preventDefault();
    setTitle(event.target.value);
    if (!event.target.value) setTitleError('Title is required');
    else if (event.target.value.length > 50) setTitleError('Title is too long');
    else setTitleError('');
  };

  const handleDraftTitleChange = (event: any) => {
    event.preventDefault();
    setDraftGroups(
      draftGroups.map((group: any, index: number) =>
        index === curIndex ? { ...group, title: event.target.value } : group
      )
    );
  };

  const isContinueValid = useMemo(() => {
    if (!title || reviewers.length === 0 || draftGroups.length === 0)
      return false;
    /* Make sure all draftGroups have at least one draft & title */
    for (let i = 0; i < draftGroups.length; i++) {
      if (draftGroups[i].drafts.length === 0 || !draftGroups[i].title)
        return false;
    }

    return true;
  }, [title, draftGroups, reviewers]);

  const handleCreateDraftGroup = () => {
    setDraftGroups([
      ...draftGroups,
      {
        title: 'Review Group ' + (draftGroups.length + 1),
        description: '',
        drafts: [],
      },
    ]);
    setCurIndex(draftGroups.length);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedDrafts(selectAll ? [] : filteredDrafts);
  };

  const handleRemoveFromDraftGroup = (draftToRemove: any) => {
    // Remove the draft from the draftGroup
    const updatedDrafts = draftGroup.drafts.filter(
      (draft: any) => draft.model.uid !== draftToRemove.model.uid
    );

    // Add the draft back to allDrafts
    setAllDrafts((prevDrafts: any) => [...prevDrafts, draftToRemove]);

    // Update the draftGroups
    setDraftGroups((prevGroups: any) =>
      prevGroups.map((group: any, index: number) =>
        index === curIndex ? { ...group, drafts: updatedDrafts } : group
      )
    );
  };

  const handleClearDraftGroup = () => {
    // Add all drafts from the current group back to allDrafts
    setAllDrafts((prevDrafts: any) => [...prevDrafts, ...draftGroup.drafts]);

    // Clear the drafts from the current group
    setDraftGroups((prevGroups: any) =>
      prevGroups.map((group: any, index: number) =>
        index === curIndex ? { ...group, drafts: [] } : group
      )
    );
  };

  const handleDragUpdate = (update: any) => {
    //  set isDraggingOver to true when dragging over the droppable area DraftGroup
    setIsDraggingOver(update.destination?.droppableId === 'draft-group');
  };
  const handleDeleteDraftGroup = (index: number) => {
    //  delete the draft group at the index
    const newDraftGroups = draftGroups.filter(
      (group: any, i: number) => i !== index
    );
    setDraftGroups(newDraftGroups);
    setCurIndex(0);
  };

  const filteredUsers = useMemo(() => {
    return allUsers.filter(
      (_user: User) =>
        _user.id !== user.id &&
        !reviewers.some((option: User) => option.id === _user.id)
    );
  }, [allUsers, reviewers, user]);

  return (
    <div
      className="screen-container"
      style={{
        height: '100%',
        padding: '0 0rem',
      }}
    >
      <ProtocolHeader
        //  review + newDate (month-date)
        name={
          <div
            style={{
              paddingBottom: '10px',
            }}
          >
            <HiPencil
              className="icon-black rightIcon"
              onClick={() => setEditMode('header')}
              style={{
                height: '2rem',
                width: '2rem',
                cursor: 'pointer',
                color: '#00534C',
              }}
            />
            <InputText
              value={title}
              onChange={handleTitleChange}
              placeholder="Add Review Title..."
              className="title-input-text-box"
              style={{
                fontWeight: 'bold',
                width: `calc(1rem*${title.length})`,
                minWidth: '350px',
              }}
            />
            {titleError && (
              <p style={{ color: 'red', fontSize: '14px', marginTop: '8px' }}>
                {titleError}
              </p>
            )}
          </div>
        }
        description={
          'Create a new review by dragging drafts into the group, adding reviewers, and setting a title'
        }
        rightSideBtn={'edit'}
        type={'protocol'}
        handleCreate={() => setTextInputModal(true)}
        isBackButton={true}
        handleCancel={handleBack}
        page={'Review Home Page'}
        isEditButton={false}
        isReviewButton={false}
        isReviewActive={false}
        isCancelButton={true}
        isCreateButton={true}
        isCreateActive={isContinueValid}
        handleCancelEdit={handleBack}
      />
      <TextConfirmModal
        isVisible={textInputModal}
        title={'Finish your Review'}
        handleClose={() => {
          setTextInputModal(false);
        }}
        required={true}
        handleSubmit={(comment: string) => handleContinue(comment)}
        isDeleteBtn={false}
        primaryBtnName="Cancel"
        secondaryBtnName={'Submit'}
        primaryDescription={
          'Leave the reviewers a description about the changes in this review.'
        }
        inputType="text"
      />

      {loading && <Loading loading={loading} />}
      <div className="review-create-container" style={{ padding: '2px 10px' }}>
        <div className="menu-bar" style={{ marginBottom: '10px' }}>
          <Row gutterWidth={16}>
            <Col sm={3}>
              <SearchBar
                containerStyle={{ width: '100%', height: '30px' }}
                value={searchQuery}
                onChange={(searchTerm: string) => {
                  handleSearchChange(searchTerm);
                }}
                onSubmit={(searchTerm: string) => {}}
                placeholder={'Search Reviews...'}
              />
            </Col>
            <Col sm={2}>
              <Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                displayEmpty
                sx={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #E0EADD',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove default border
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border when focused
                  },

                  backgroundColor: '#E0EADD',
                  fontSize: '14px',
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return 'Type';
                  }
                  return selected;
                }}
              >
                <MenuItem disabled value="" className="menuItemFontSize">
                  Type
                </MenuItem>
                <MenuItem value="" className="menuItemFontSize">
                  All
                </MenuItem>
                <MenuItem
                  value={DraftChangeType.PROTOCOL}
                  className="menuItemFontSize"
                >
                  Protocol
                </MenuItem>
                <MenuItem
                  value={DraftChangeType.FOLDER}
                  className="menuItemFontSize"
                >
                  Folder
                </MenuItem>
                <MenuItem
                  value={DraftChangeType.MEDICATION}
                  className="menuItemFontSize"
                >
                  Medication
                </MenuItem>
                <MenuItem
                  value={DraftChangeType.INFUSION}
                  className="menuItemFontSize"
                >
                  Infusion
                </MenuItem>
                <MenuItem
                  value={DraftChangeType.ELECTRICAL}
                  className="menuItemFontSize"
                >
                  Electrical
                </MenuItem>
                <MenuItem
                  value={DraftChangeType.EQUIPMENT}
                  className="menuItemFontSize"
                >
                  Equipment
                </MenuItem>
                <MenuItem
                  value={DraftChangeType.VITAL}
                  className="menuItemFontSize"
                >
                  Vital
                </MenuItem>
              </Select>
            </Col>
            <Col sm={2}>
              <Select
                value={folder}
                onChange={(e) => setFolder(e.target.value)}
                displayEmpty
                sx={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #E0EADD',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Remove border initially
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Remove border on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Add border when focused
                  },

                  backgroundColor: '#E0EADD',
                  fontSize: '14px',
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return 'Folder';
                  }
                  let folder = database.categories.find(
                    (category: CategoryItem) => category.uid === selected
                  );
                  return folder?.name;
                }}
              >
                <MenuItem value="" disabled className="menuItemFontSize">
                  Folder
                </MenuItem>
                <MenuItem value="" className="menuItemFontSize">
                  All
                </MenuItem>
                {database.categories.map((category: CategoryItem) => (
                  <MenuItem
                    key={category.uid}
                    value={category.uid}
                    className="menuItemFontSize"
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </Col>
            <Col sm={2}>
              <Select
                value={modifiedBy}
                onChange={(e) => setModifiedBy(e.target.value)}
                displayEmpty
                sx={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #E0EADD',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove default border
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border when focused
                  },
                  backgroundColor: '#E0EADD',
                  fontSize: '14px',
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return 'Modified By';
                  }
                  //  return first and last name of the user
                  let user = database.users.find(
                    (user: User) => user.id === selected
                  );
                  return user?.firstName + ' ' + user?.lastName;
                }}
              >
                <MenuItem value="" disabled className="menuItemFontSize">
                  Modified By
                </MenuItem>
                <MenuItem value="" className="menuItemFontSize">
                  All
                </MenuItem>

                {database.users.map((user: User) => (
                  <MenuItem
                    key={user.id}
                    value={user.id}
                    className="menuItemFontSize"
                  >
                    {user.firstName} {user.lastName}
                  </MenuItem>
                ))}
              </Select>
            </Col>
            <Col sm={2}>
              <Select
                value={changed}
                onChange={(e) => setChanged(e.target.value)}
                displayEmpty
                sx={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #E0EADD',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove default border
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border when focused
                  },

                  backgroundColor: '#E0EADD',
                  fontSize: '14px',
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return 'Last Modified Date';
                  }
                  //  return Changed: Last 10 days, 20 days, 30 days
                  if (selected === 'date10') return 'Changed: Last 10 days';
                  if (selected === 'date20') return 'Changed: Last 20 days';
                  if (selected === 'date30') return 'Changed: Last 30 days';
                }}
              >
                <MenuItem disabled value="" className="menuItemFontSize">
                  Last Modified Date
                </MenuItem>
                <MenuItem value="" className="menuItemFontSize">
                  All
                </MenuItem>
                <MenuItem value="date10" className="menuItemFontSize">
                  Changed: Last 10 days
                </MenuItem>
                <MenuItem value="date20" className="menuItemFontSize">
                  Changed: Last 20 days
                </MenuItem>
                <MenuItem value="date30" className="menuItemFontSize">
                  Changed: Last 30 days
                </MenuItem>
              </Select>
            </Col>
            <Col sm={1}>
              <Button
                className="primary-button btn-rightMargin btn btn-primary"
                style={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleReset} // Call reset function on click
              >
                Reset
              </Button>
            </Col>
          </Row>
        </div>
        <div
          className="review-box"
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <DragDropContext
            onDragEnd={handleDragEnd}
            onDragUpdate={handleDragUpdate}
          >
            <Droppable droppableId="drafts">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`drafts ${selectAll ? 'select-all' : ''}`}
                  style={{
                    fontSize: '16px',
                    flex: '0 0 23%',
                    padding: '20px',
                    display: 'flex',
                    marginTop: '5px',
                    flexDirection: 'column',
                    border: '1px solid #e0e0e0',
                    height: 'calc(100vh - 327px)',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <label
                      style={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#333',
                      }}
                    >
                      Drafts:
                      {selectedDrafts.length > 0 &&
                        ` ${selectedDrafts.length} /`}
                      {` ${filteredDrafts.length}`}
                    </label>
                    <div
                      className={`select-all-button ${selectAll ? 'selected' : ''}`}
                      onClick={handleSelectAll}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        backgroundColor: selectAll ? '#00534c' : '#f0f0f0',
                        color: selectAll ? 'white' : '#333',
                        transition: 'all 0.3s ease',
                        fontSize: '12px',
                      }}
                    >
                      {selectAll ? 'Deselect All' : 'Select All'}
                    </div>
                  </div>
                  {loadingDrafts ? (
                    <div>
                      <ReactLoading
                        className="load"
                        type={props.type ? props.type : 'bubbles'}
                        height={60}
                        width={60}
                      />
                    </div>
                  ) : (
                    <div
                      className="drafts-scroll"
                      style={{ overflowY: 'auto' }}
                    >
                      {filteredDrafts.map((draft: any, index: number) => (
                        <Draggable
                          key={draft.model.uid + '-' + draft.changeType}
                          draggableId={`draggable-${draft.model.uid + '-' + draft.changeType}`}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="draggable-item"
                            >
                              <div
                                className={`draftItemWrapper ${selectedDrafts.includes(draft) ? 'selected' : ''}`}
                                style={{
                                  position: 'relative', // for positioning the tooltip
                                  border: `2px solid ${selectedDrafts.includes(draft) ? '#1890ff' : '#e0e0e0'}`,
                                  borderRadius: '4px',
                                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                  transition: 'all 0.3s ease',
                                  backgroundColor: 'white',
                                  marginBottom: '10px',
                                  overflow: 'hidden',
                                  cursor: 'pointer',
                                  borderTopLeftRadius: '6px',
                                  borderTopRightRadius: '6px',
                                }}
                                onClick={() => {
                                  const newSelectedDrafts =
                                    selectedDrafts.includes(draft)
                                      ? selectedDrafts.filter(
                                          (item: any) => item !== draft
                                        )
                                      : [...selectedDrafts, draft];
                                  setSelectedDrafts(newSelectedDrafts);
                                }}
                              >
                                <div
                                  className="draftItemsInput"
                                  style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    backgroundColor: getStatusStyles(
                                      draft.changeType
                                    ).backgroundColor,
                                    color: getStatusStyles(draft.changeType)
                                      .color,
                                    padding: '6px',
                                    fontSize: '14px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderTopRightRadius: '4px',
                                    borderTopLeftRadius: '4px',
                                  }}
                                >
                                  <span
                                    style={{
                                      display: 'flex',
                                      flex: '1',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {draft.title}
                                  </span>
                                  {selectedDrafts.includes(draft) && (
                                    <span
                                      style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        height: '22px',
                                        width: '22px',
                                        backgroundColor: `${getStatusStyles(draft.changeType).color}`,
                                        borderRadius: '50%',
                                        // border: `1px solid ${getStatusStyles(draft.changeType).color}`,
                                      }}
                                    >
                                      <FaCircleCheck
                                        size={20}
                                        color={'#FFF'}
                                        style={{
                                          marginBottom: '2px',
                                        }}
                                      />
                                      {/* <svg
                                        viewBox="0 0 24 24"
                                        width="20"
                                        height="20"
                                        fill="currentColor"
                                      >
                                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                                      </svg> */}
                                    </span>
                                  )}
                                </div>
                                <div style={{ padding: '10px' }}>
                                  <div
                                    style={{
                                      color: '#6a6a6a',
                                      fontSize: '12px',
                                      textAlign: 'center',
                                    }}
                                  >
                                    {draft.message}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId="draft-group">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="draft-group"
                  style={{
                    flex: '0 0 56.5%',
                    padding: '10px',
                    display: 'flex',
                    marginTop: '5px',
                    flexDirection: 'column',
                    border: '1px solid rgb(224, 224, 224)',
                    height: 'calc(100vh - 327px)',
                    overflow: 'hidden',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    <div
                      style={{
                        flex: 8,
                        display: 'flex',
                        // justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '10px',
                      }}
                    >
                      <HiPencil
                        className="icon-black rightIcon"
                        onClick={() => setEditMode('header')}
                        style={{
                          height: '2rem',
                          width: '2rem',
                          cursor: 'pointer',
                          color: '#00534C',
                        }}
                      />
                      <InputText
                        value={draftGroup.title}
                        onChange={handleDraftTitleChange}
                        placeholder="Add Title..."
                        className="title-input-text-box"
                        style={{
                          width: `calc(1rem*${draftGroup.title.length})`,
                        }}
                      />
                    </div>
                    {draftGroups.length > 1 && (
                      <button
                        className="secondary-button btn-rightMargin btn btn-primary"
                        onClick={() => handleDeleteDraftGroup(curIndex)}
                      >
                        Delete Group
                      </button>
                    )}
                    <button
                      className="red-background-button btn-rightMargin  btn btn-primary"
                      onClick={handleClearDraftGroup}
                    >
                      Clear All
                    </button>
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <button
                        className="primary-button btn-rightMargin btn btn-primary"
                        style={{ padding: '6px' }}
                        onClick={handleCreateDraftGroup}
                      >
                        + Create Draft Group
                      </button>
                    </div>
                  </div>

                  <InputText
                    placeholder="Add Description..."
                    style={{
                      marginBottom: '20px',
                      background: 'transparent',
                      // border: 'none',
                      // borderBottom: '1px solid #636363',
                      // borderRadius: '0',
                    }}
                    value={draftGroup.description}
                    onChange={(e) =>
                      setDraftGroups(
                        draftGroups.map((group: any, index: number) =>
                          index === curIndex
                            ? { ...group, description: e.target.value }
                            : group
                        )
                      )
                    }
                  />
                  {draftGroup && draftGroup.drafts.length === 0 && (
                    <div
                      style={{
                        textAlign: 'center',
                        padding: '30px',
                        border: `2px dashed ${isDraggingOver ? '#4A90E2' : '#BEBEBE'}`,
                        backgroundColor: isDraggingOver ? '#E8F4FD' : '#F9F9F9',
                        borderRadius: '12px',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: isDraggingOver
                          ? '0 6px 12px rgba(0, 0, 0, 0.2)'
                          : '0 4px 8px rgba(0, 0, 0, 0.1)',
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <FaArrowDown
                        size={48}
                        color={isDraggingOver ? '#4A90E2' : '#7D7D7D'}
                        style={{
                          marginBottom: '15px',
                          transition: 'color 0.3s ease',
                        }}
                      />
                      <p
                        style={{
                          fontSize: '16px',
                          color: isDraggingOver ? '#4A90E2' : '#7D7D7D',
                          transition: 'color 0.3s ease, font-weight 0.3s ease',
                        }}
                      >
                        <div
                          style={{
                            marginLeft: '5px',
                            fontSize: '30px',
                            fontWeight: 'bold',
                            color: isDraggingOver ? '#4A90E2' : '#7D7D7D',
                          }}
                        >
                          Drag and drop
                        </div>{' '}
                        Drafts here to initiate the review process
                      </p>
                    </div>
                  )}

                  <div
                    className="draft-drag-list"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '15px',
                      maxHeight: '100%',
                      overflowY: 'auto',
                    }}
                  >
                    {draftGroup.drafts.map((draft: any, itemIndex: number) => (
                      <Draggable
                        key={draft.model.uid + '-' + draft.changeType}
                        draggableId={`draggable-${draft.model.uid + '-' + draft.changeType}`}
                        index={itemIndex}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="draggable-item"
                            style={{
                              ...provided.draggableProps.style, // Ensure this is included
                              flex: '0 0 calc(33.333% - 10px)',
                              boxSizing: 'border-box',
                              minWidth: '200px', // Adjust as needed to fit your layout
                              marginBottom: '5px', // Vertical gap
                            }}
                          >
                            <div
                              style={{ position: 'relative' }}
                              onMouseEnter={() =>
                                setHoveredDraft(
                                  draft.model.uid + '-' + draft.changeType
                                )
                              }
                              onMouseLeave={() => setHoveredDraft(null)}
                            >
                              <div
                                className="draftItemWrapper"
                                style={{
                                  display: 'inline-block',
                                  border: '1px solid rgb(224, 224, 224)',
                                  borderRadius: '4px',
                                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                  cursor: 'pointer',
                                  transition:
                                    'border-color 0.3s, background-color 0.3s, box-shadow 0.3s',
                                  backgroundColor: 'white',
                                  width: '100%',
                                }}
                              >
                                <div
                                  className="draftItemsInput"
                                  style={{
                                    fontWeight: 'bold',
                                    textAlign: 'center',
                                    backgroundColor: getStatusStyles(
                                      draft.changeType
                                    ).backgroundColor,
                                    color: getStatusStyles(draft.changeType)
                                      .color,
                                    padding: '6px',
                                    fontSize: '14px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderTopRightRadius: '4px',
                                    borderTopLeftRadius: '4px',
                                  }}
                                >
                                  <span
                                    style={{
                                      display: 'flex',
                                      flex: '1',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {draft.title}
                                  </span>
                                  {hoveredDraft ===
                                    draft.model.uid +
                                      '-' +
                                      draft.changeType && (
                                    <span
                                      className="hoverable-button-container"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleRemoveFromDraftGroup(draft);
                                      }}
                                    >
                                      <FaTimes
                                        style={{
                                          cursor: 'pointer',
                                          // color: '#DC143C',
                                          fontSize: '16px',
                                        }}
                                      />
                                    </span>
                                  )}
                                </div>
                                <div style={{ padding: '4px 8px 4px 8px' }}>
                                  <div
                                    style={{
                                      color: '#6a6a6a',
                                      fontSize: '12px',
                                      padding: '5px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    {draft.message}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div
            className="reviewers"
            style={{
              flex: '0 0 19%',
              display: 'flex',
              marginTop: '5px',
              flexDirection: 'column',
              border: '1px solid rgb(224, 224, 224)',
              height: 'calc(100vh - 327px)',
              overflow: 'hidden',
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              overflowY: 'auto',
            }}
          >
            <div
              style={{
                padding: '0px 15px',
                height: '100%',
                paddingTop: '15px',
              }}
            >
              <div
                className="contentText"
                style={{
                  fontSize: '20px',
                  fontWeight: '500',
                  marginBottom: '5%',
                }}
              >
                Reviewers
              </div>

              <SearchableDropdown
                id="searchDropdown"
                options={filteredUsers}
                labelField={(option) =>
                  option.firstName + ' ' + option.lastName
                }
                valueField={(option) =>
                  option.firstName + ' ' + option.lastName
                }
                keyField={(option) => option.id}
                multiSelect={true}
                onChange={(option: User) => handleAddReviwer(option)}
                onClear={() => {}}
                placeholder="Search Reviewers..."
              />
              {reviewers && reviewers.length > 0 && (
                <div className="contentLabel" style={{ display: 'block' }}>
                  <div className="contentLabel" style={{ fontSize: '15px' }}>
                    Users
                  </div>
                </div>
              )}
              <div className="contentBorder protocolCalculationPad reviewListScroll">
                <ViewportList items={reviewers}>
                  {(item: any, index: any) => (
                    <div
                      key={item + index}
                      className={`listhover spaceBetween cursorPointer item contentHeading ${reviewers.length !== index + 1 ? 'contentUnderline' : ''}`}
                    >
                      <div>
                        {item?.firstName} {item?.lastName}
                      </div>

                      <div>
                        <FaTimes
                          style={{ cursor: 'pointer', color: 'red' }}
                          onClick={() => handleRemoveReviewer(item)}
                        />
                      </div>
                    </div>
                  )}
                </ViewportList>
              </div>
              <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
                {draftGroups.length > 0 && (
                  <div
                    className="draftGroupsHeader"
                    style={{
                      fontSize: '20px',
                      fontWeight: '500',
                      marginTop: '5%',
                    }}
                  >
                    Draft Groups
                  </div>
                )}
                {draftGroups.length > 0 &&
                  draftGroups.map((group: DraftGroupJSON, index: number) => (
                    <div
                      key={index}
                      className={`draftGroup ${curIndex === index ? 'active' : ''}`}
                      onClick={() => setCurIndex(index)}
                    >
                      <div className="contentText">{group.title}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCreate;
