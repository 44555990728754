import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import {
  DatabaseResponse,
  ResponseType,
  Response,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  findDepartmentOwner,
  getFormattedDate,
  globals,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import { ProgressStatus, User } from '../../../../models';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import NewModelItemSideout from '../../../components/SideOut/NewModelItemSideout';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import {
  deleteEquipment,
  fetchEquipment,
} from '../../../../data/functions/EquipmentDB';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { handleToggleEnabled } from '../../../../data/functions/ModelDB';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../data/AmplifyVersion';
import HMSwitch from '../../../components/general/HMSwitch';
import HMCheckbox from '../../../components/general/HMCheckbox';
import DraftSaveModal from '../../../components/Modal/DraftSaveModal';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
import DataList, { Header } from './DataList';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListEquipment = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const [isCreate, setIsCreate] = useState(false);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const department = database.department;
  const [equipment, setEquipment] = useState<EquipmentItem[]>(
    database.equipment
  );
  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);
  const reducerState = useSelector((state: any) => state.department);

  const scrollPosition = useRef(0);

  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [filters, setFilters] = useState<any[]>(state?.search?.filters ?? []);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state?.search?.categoriesFilter ?? []
  );
  const [list, setList] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<EquipmentItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  // allCheckboxBtn is to set the state for "delete all" button
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  //  mainCheckbox is to set the state for the main checkbox
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });
  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    EquipmentItem[]
  >([]);
  const [viewing, setViewing] = useState<string | null>('all');

  const allDepartmentOwners = useMemo(() => {
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < equipment.length; i++) {
      let owner = findDepartmentOwner(department, reducerState, equipment[i]);
      if (owner && !deps.find((dep) => dep.id === owner?.id)) deps.push(owner);
    }
    setViewing('all');
    setFilters([]);
    return deps.sort((a, b) => a.name.localeCompare(b.name));
  }, [equipment, department, reducerState]);
  // const reloadDatabase = async () => {
  //   const response = await loadDatabase(department);
  //   if (response.type === ResponseType.Success) {
  //     const database = response.data;
  //     setDatabase(database);
  //     setEquipment(database.equipment);
  //     handleFilterChange(database.equipment);
  //   }
  // };

  const reloadDatabase = async () => {
    const response = await fetchEquipment(department, database, true, true);
    if (response.type === ResponseType.Success) {
      setEquipment(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          equipment: response.data,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : equipment)];
    if (searchQuery !== '' || filters.length > 0) {
      filteredList = filteredList.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
      if (filters.length > 0) {
        filteredList = filteredList.filter((item) => {
          //Check if the Filters (Department ID)
          return filters.some((filter) => {
            return (
              (item.status === ProgressStatus.DEACTIVATED &&
                item.overrideItem &&
                item.overrideItem.departmentID === filter.id) ||
              (item.status !== ProgressStatus.DEACTIVATED &&
                item.departmentID === filter.id)
            );
          });
        });
      }
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: EquipmentItem, b: EquipmentItem) => {
      return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  const handleSave = async () => {
    if (itemDeactivationStatus && itemDeactivationStatus.length > 0) {
      try {
        let promises: any[] = [];
        for (let i = 0; i < itemDeactivationStatus.length; i++) {
          promises.push(
            handleToggleEnabled(
              user,
              database.department,
              reducerState,
              itemDeactivationStatus[i]
            )
          );
        }
        let results = await Promise.all(promises);
        for (let i = 0; i < results.length; i++) {
          if (results[i].type === ResponseType.Success) {
            if (globals.debug) console.log('SAVED ITEM', results[i].data);
          } else {
            console.error('ERROR SAVING ITEM', results[i].data);
          }
        }

        let n = results.filter(
          (result) => result.type === ResponseType.Success
        ).length;
        setSnackbar({
          ...snackbar,
          open: true,
          message: `Successfully updated ${n} equipment` + (n === 1 ? '' : 's'),
        });

        reloadDatabase().then(() => {
          setItemDeactivationStatus([]);
        });
      } catch (error) {
        console.error('ERROR SAVING DEACTIVATE STATE', error);
      }
    }
  };

  useEffect(() => {
    handleFilterChange(equipment);
  }, [searchQuery, filters, categoriesFilter, equipment]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (elec: EquipmentItem) => {
    const state = {
      selectedProtocol: null,
      value: elec,
      subValue: null,
      type: 'Equipment',
      editType: 'edit',
      editMode: false,
      page: 'listEquipmentPage',
    };
    navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = async () => {
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
  };

  const headers: Header<EquipmentItem>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.25,
        render: (item: EquipmentItem) => {
          const isOwner = item.departmentID === department.id;
          const adminAccess = hasAdminUserAccess(
            department,
            reducerState,
            user
          );
          const isSwitchChecked = !itemDeactivationStatus.find(
            (newItem) => newItem.uid === item.uid
          )
            ? item.status !== ProgressStatus.DEACTIVATED
            : item.status === ProgressStatus.DEACTIVATED;

          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              {adminAccess && (
                <>
                  {isOwner && item.status !== ProgressStatus.DEACTIVATED ? (
                    <HMCheckbox
                      checked={selectedItems.some(
                        (selected) => selected.uid === item.uid
                      )}
                      onChange={() => handleSelectionChange(item)}
                    />
                  ) : (
                    <HMSwitch
                      checked={isSwitchChecked}
                      onChange={() => handleToggleEnabledUI(item)}
                    />
                  )}
                </>
              )}
            </div>
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 2,
        render: (item: EquipmentItem) => {
          const isOwner = item.departmentID === department.id;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!(isOwner && item.status !== 'DEACTIVATED') && (
                <FaLock
                  className="table-icon"
                  style={{ marginRight: '8px' }}
                  color="#A3A3A3"
                />
              )}
              {item.name}
              {item.status !== 'ACTIVE' && (
                <Status status={item.status} style={{ marginLeft: '8px' }} />
              )}
            </div>
          );
        },
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 1,
        render: (item: EquipmentItem) =>
          getFormattedDate(
            item?.model?.updatedAt
              ? new Date(item.model.updatedAt)
              : new Date(),
            true
          ),
      },
      {
        key: 'modified_by',
        name: 'Modified By',
        sortable: true,
        flex: 1,
        render: (item: EquipmentItem) => {
          const id = item.model?.modifiedBy || item.model?.createdBy;
          let username = 'Hinckley Medical';
          if (id) {
            const user = database.users.find((user) => user.id === id);
            if (user) {
              username = `${user.firstName} ${user.lastName}`;
            }
          }
          return username;
        },
      },
      {
        key: 'owner',
        name: 'Owner',
        sortable: true,
        flex: 1,
        render: (item: EquipmentItem) => {
          const departmentOwner = findDepartmentOwner(
            department,
            reducerState,
            item
          );
          return departmentOwner ? (
            <OwnerImage
              owner={departmentOwner}
              size={32}
              style={{ padding: 0, margin: 0 }}
            />
          ) : null;
        },
      },
      {
        key: 'sizes',
        name: 'Sizes',
        sortable: true,
        flex: 1,
        render: (item: EquipmentItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 1,
              marginLeft: '0.5rem',
            }}
          >
            <span>{item.options.length}</span>
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [
      department,
      reducerState,
      user,
      itemDeactivationStatus,
      selectedItems,
      database.users,
    ]
  );

  const handleSortItem = (
    key: string,
    direction: string,
    a: EquipmentItem,
    b: EquipmentItem
  ) => {
    if (key === 'name') {
      return direction === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (key === 'modified_date') {
      const aDate = new Date(a.model?.updatedAt ?? new Date());
      const bDate = new Date(b.model?.updatedAt ?? new Date());
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'sizes') {
      return direction === 'asc'
        ? a.options.length - b.options.length
        : b.options.length - a.options.length;
    } else if (key === 'modified_by') {
      const aName = getModifiedByName(a);
      const bName = getModifiedByName(b);
      return direction === 'asc'
        ? aName.localeCompare(bName)
        : bName.localeCompare(aName);
    } else if (key === 'owner') {
      const aOwner =
        findDepartmentOwner(department, reducerState, a)?.name ?? '';
      const bOwner =
        findDepartmentOwner(department, reducerState, b)?.name ?? '';
      return direction === 'asc'
        ? aOwner.localeCompare(bOwner)
        : bOwner.localeCompare(aOwner);
    }
    return 0;
  };

  const handleSelectAll = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedItems([
        ...equipment.filter(
          (item) =>
            item.status !== 'DEACTIVATED' && department.id === item.departmentID
        ),
      ]);
    } else {
      setSelectedItems([]);
    }
  };

  // Helper function for getting modified by name
  const getModifiedByName = (item: EquipmentItem) => {
    const id = item.model?.modifiedBy || item.model?.createdBy;
    if (id) {
      const user = database.users.find((user) => user.id === id);
      if (user) {
        return `${user.firstName} ${user.lastName}`;
      }
    }
    return 'Hinckley Medical';
  };

  const handleSelectionChange = (protocol: EquipmentItem) => {
    if (selectedItems.find((item) => item.uid === protocol.uid)) {
      setSelectedItems((prevItems: EquipmentItem[]) => {
        const updatedItems = prevItems.filter(
          (item: EquipmentItem) => item.uid !== protocol.uid
        );
        if (updatedItems.length === 0) {
          setMainCheckbox(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, protocol];
      let available = equipment.filter(
        (item) =>
          item.status !== 'DEACTIVATED' && department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setMainCheckbox(true);
      } else if (mainCheckbox) {
        setMainCheckbox(false);
      }
    }
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];
      const isDraft =
        currentMedication.status === ProgressStatus.DRAFT &&
        currentMedication.activeID == null;

      // Delete the medication
      let response = await deleteEquipment(currentMedication, !isDraft);
      if (response.type === ResponseType.Success) {
        setSnackbar({
          ...snackbar,
          open: true,
          message: `Successfully deleted equipment: ${currentMedication.name}`,
          severity: 'success',
        });

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: EquipmentItem[]) => {
          const updatedItems = prevItems.filter(
            (item: EquipmentItem) => item.uid !== currentMedication.uid
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
            setMainCheckbox(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Failed to delete equipment',
          severity: 'error',
        });
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbar({
        ...snackbar,
        open: true,
        message: 'Failed to delete equipment',
        severity: 'error',
      });
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          const isDraft =
            parmMedication.status === ProgressStatus.DRAFT &&
            parmMedication.activeID == null;
          let response = await deleteEquipment(parmMedication, !isDraft);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbar({
            ...snackbar,
            open: true,
            message: `Successfully deleted ${successCount} equipments.`,
            severity: 'success',
          });
        }
        if (failureCount > 0) {
          setSnackbar({
            ...snackbar,
            open: true,
            message: `Failed to delete ${failureCount} equipments.`,
            severity: 'error',
          });
        }
        if (successCount === selectedItems.length) {
          setMainCheckbox(false);
        }

        setSnackbar({
          ...snackbar,
          open: true,
          message: `Successfully deleted ${successCount} equipments.`,
          severity: 'success',
        });
        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Failed to delete equipment',
          severity: 'error',
        });
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };
  const handleSnackbarClose = () => {
    setSnackbar({
      ...snackbar,
      open: false,
      message: '',
      severity: 'success',
    });
  };
  const handleToggleEnabledUI = async (item: EquipmentItem) => {
    let find = itemDeactivationStatus.find(
      (newItem) => newItem.uid === item.uid
    );
    if (find)
      setItemDeactivationStatus(
        itemDeactivationStatus.filter((newItem) => newItem.uid !== item.uid)
      );
    else setItemDeactivationStatus([...itemDeactivationStatus, item]);
  };

  const getRowClassName = useCallback(
    (params: EquipmentItem) => {
      const rowItem = list.find((item) => item.uid === params.uid);
      const adminAccess = hasAdminUserAccess(
        department,
        reducerState,
        user,
        rowItem
      );
      // Apply background color when adminAccess is false or the item status is not DEACTIVATED.
      if (!(adminAccess && rowItem?.status !== 'DEACTIVATED')) {
        return 'inactive';
      }

      // If the row is selected, give it the selected class.
      const isSelected = selectedItems.some((item) => item.uid === params.id);
      if (isSelected) return 'selectedRow';

      // Default return for other cases
      return '';
    },
    [department, list, user, reducerState, selectedItems]
  );

  const customFilterComponent = useMemo(
    () =>
      allDepartmentOwners.length > 1 ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            margin: '4px 0px',
          }}
        >
          <ToggleButtonGroup
            value={viewing}
            exclusive
            onChange={(event, newAlignment) => setViewing(newAlignment)}
            aria-label="owner"
            sx={{
              maxHeight: '50px',
            }}
          >
            <Tooltip title={'View All Medications'} placement="bottom" arrow>
              <ToggleButton
                value="all"
                aria-label="all"
                autoCapitalize="none"
                sx={{
                  padding: '0px 8px', // Adjust padding as needed for the "All" button
                  minWidth: 'auto', // Ensures the width is only as wide as the content
                }}
                onChange={() => {
                  setViewing('all');
                  setFilters([]);
                }}
              >
                All
              </ToggleButton>
            </Tooltip>
            {allDepartmentOwners.map((owner) => (
              <ToggleButton
                key={owner.id}
                value={owner.id}
                onClick={() => {
                  setViewing(owner.id);
                  setFilters([owner]);
                }}
              >
                <Tooltip title={owner.name} placement="bottom" arrow>
                  <img
                    className="no-select owner-logo"
                    src={owner.logoVerifiedUrl ?? ''}
                    alt="Agency Logo"
                    style={{ width: 40, height: 40 }}
                  />
                </Tooltip>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      ) : null,
    [allDepartmentOwners, viewing]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Equipment?'}
          handleClose={() => {
            setIsDelete(false);
            setAllCheckedBtn(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} equipments?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}
      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            setIsOpen(false);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}
      <NewModelItemSideout
        type="equipment"
        isVisible={isCreate}
        handleClose={() => setIsCreate(false)}
      />
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Equipment: ' + list.length + ' items'
            : 'Equipment: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        isSaveButton={itemDeactivationStatus.length > 0}
        isSaveActive={itemDeactivationStatus.length > 0}
        page={department.name}
        rightSideBtn={'edit'}
        isEditIcon={false}
        handleSave={() => handleSave()}
        handleCancelEdit={() => {
          console.log('Cancel Edit');
          setItemDeactivationStatus([]);
          setEquipment([...equipment]);
          handleFilterChange([...equipment]);
        }}
        isCreateButton={adminLevel > 2}
        isCreateActive={true}
        handleCreate={() => setIsCreate(true)}
        handleEdit={() => {
          handleCheckIsDraft();
        }}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />
      <DataList<EquipmentItem, never>
        items={list}
        headers={headers}
        onItemClick={handleItemClick}
        onSelectionChange={setSelectedItems}
        searchPlaceholder="Search Equipment..."
        customFilterComponent={customFilterComponent}
        sortItem={handleSortItem}
        getRowClassName={getRowClassName}
        handleSelectAll={handleSelectAll}
        selectedItems={selectedItems}
      />
    </div>
  );
};

export default ListEquipment;
