import PatientWeight from '../../ui/_global/common/PatientWeight';
import { getObjectDifference } from '../../ui/_global/common/Utils';
import { DatabaseResponse } from '../AmplifyDB';
import UserCommentItem from './UserCommentItem';
import AcknowledgeItem, { cloneAcknowledgeItem } from './AcknowledgeItem';
import DraftGroupItem from './DraftGroupItem';
import ModelItem from './ModelItem';
import ModelInterface from './ModelInterface';
import { Acknowledge, DraftChange } from '../../API';
import { User } from '../../models';
import { findUserComments } from '../functions/ReviewalDB';
import ModelSubItem from './ModelSubItem';
import ReviewalItem from './ReviewalItem';

export enum DraftChangeType {
  FOLDER = 'FOLDER',
  PROTOCOL = 'PROTOCOL',
  PROTOCOL_INDEX = 'PROTOCOL_INDEX',
  PROTOCOL_PDF = 'PROTOCOL_PDF',
  MEDICATION = 'MEDICATION',
  MEDICATION_DOSE = 'MEDICATION_DOSE',
  MEDICATION_INDEX = 'MEDICATION_INDEX',
  INFUSION = 'INFUSION',
  INFUSION_DOSE = 'INFUSION_DOSE',
  INFUSION_INDEX = 'INFUSION_INDEX',
  ELECTRICAL = 'ELECTRICAL',
  ELECTRICAL_INDEX = 'ELECTRICAL_INDEX',
  ELECTRICAL_SHOCK = 'ELECTRICAL_SHOCK',
  EQUIPMENT = 'EQUIPMENT',
  VITAL = 'VITAL',
  CHECKLIST = 'CHECKLIST',
  UNKNOWN = 'UNKNOWN',
  CPR = 'CPR',
}

export function cloneDraftChangeItem(
  item: DraftChangeItem,
  db: DatabaseResponse,
  curUser?: User
): DraftChangeItem {
  let newItem = new DraftChangeItem(
    item.reviewal,
    item.draftGroup,
    item.model,
    db,
    curUser
  ) as DraftChangeItem;
  newItem.changeItem = item.changeItem;
  newItem.previousItem = item.previousItem;
  newItem.reviewACK = item.reviewACK
    ? cloneAcknowledgeItem(item.reviewACK)
    : null;
  newItem.allAcknowledgements = item.allAcknowledgements.map((ack) =>
    cloneAcknowledgeItem(ack)
  );
  newItem.acknowledgements = newItem.allAcknowledgements.filter(
    (ack) => !ack.isArchived
  );
  newItem.comments = item.comments;
  return newItem;
}

export function updateDraftChangeItem(
  item: DraftChangeItem,
  draftChange: DraftChange,
  db: DatabaseResponse
): DraftChangeItem {
  let newItem = new DraftChangeItem(
    item.reviewal,
    item.draftGroup,
    draftChange,
    db
  );
  newItem.changeItem = item.changeItem;
  newItem.previousItem = item.previousItem;
  newItem.reviewACK = item.reviewACK
    ? cloneAcknowledgeItem(item.reviewACK)
    : null;
  newItem.allAcknowledgements = item.allAcknowledgements.map((ack) =>
    cloneAcknowledgeItem(ack)
  );
  newItem.acknowledgements = newItem.allAcknowledgements.filter(
    (ack) => !ack.isArchived
  );
  newItem.comments = item.comments;
  return newItem;
}

export function getChangeType(type: string): DraftChangeType {
  switch (type) {
    case 'FOLDER':
      return DraftChangeType.FOLDER;
    case 'PROTOCOL':
      return DraftChangeType.PROTOCOL;
    case 'PROTOCOL_PDF':
      return DraftChangeType.PROTOCOL_PDF;
    case 'PROTOCOL_INDEX':
      return DraftChangeType.PROTOCOL_INDEX;
    case 'MEDICATION':
      return DraftChangeType.MEDICATION;
    case 'MEDICATION_DOSE':
      return DraftChangeType.MEDICATION_DOSE;
    case 'MEDICATION_INDEX':
      return DraftChangeType.MEDICATION_INDEX;
    case 'INFUSION':
      return DraftChangeType.INFUSION;
    case 'INFUSION_DOSE':
      return DraftChangeType.INFUSION_DOSE;
    case 'INFUSION_INDEX':
      return DraftChangeType.INFUSION_INDEX;
    case 'ELECTRICAL':
      return DraftChangeType.ELECTRICAL;
    case 'ELECTRICAL_SHOCK':
      return DraftChangeType.ELECTRICAL_SHOCK;
    case 'ELECTRICAL_INDEX':
      return DraftChangeType.ELECTRICAL_INDEX;
    case 'EQUIPMENT':
      return DraftChangeType.EQUIPMENT;
    case 'VITAL':
      return DraftChangeType.VITAL;
    case 'CHECKLIST':
      return DraftChangeType.CHECKLIST;
    case 'CPR':
      return DraftChangeType.CPR;
    default:
      return DraftChangeType.UNKNOWN;
  }
}

class DraftChangeItem extends ModelItem<DraftChange> implements ModelInterface {
  draftGroup: DraftGroupItem | undefined;
  comments: UserCommentItem[] = [];
  changeItem: ModelItem<any> | ModelSubItem<any> | null | undefined;
  previousItem: ModelItem<any> | ModelSubItem<any> | null | undefined;
  changeType: DraftChangeType;
  acknowledgements: AcknowledgeItem[] = [];
  allAcknowledgements: AcknowledgeItem[] = [];
  reviewACK: AcknowledgeItem | null | undefined;
  isClosed: boolean = false;
  reviewal: ReviewalItem;
  curUserID: string | undefined;
  constructor(
    reviewal: ReviewalItem,
    draftGroup: DraftGroupItem | undefined,
    draftChange: any,
    db?: DatabaseResponse,
    curUser?: User,
    isClone?: boolean
  ) {
    super(draftChange, draftChange.id, draftChange.departmentID);
    this.TAG = 'DraftChangeItem';
    this.curUserID = curUser?.id;
    this.reviewal = reviewal;
    this.draftGroup = draftGroup;
    this.changeType = getChangeType(draftChange.changeType);
    this.comments = [];
    this.isClosed = draftChange.isClosed;
    this.allAcknowledgements = [];
    this.acknowledgements = [];

    if (db) {
      if (draftChange.Comments) {
        let items = draftChange.Comments.items;
        items.forEach((item: any) => {
          let user = db.users.find((u) => u.id === item.userID);
          if (!user) {
            console.error('Owner not found for comment', item);
            return;
          }
          this.comments.push(new UserCommentItem(item, this, user, []));
        });
      }
      if (draftChange.Acknowledges) {
        let ackItems: AcknowledgeItem[] = [];
        let items = draftChange.Acknowledges.items;
        items.forEach((item: Acknowledge) => {
          if (item._deleted) return;
          let user =
            curUser && item.userID === curUser.id
              ? curUser
              : db.users.find((u) => u.id === item.userID);
          if (!user) {
            console.error('Owner not found for Acknowledgement', item);
            return;
          }
          let ack = new AcknowledgeItem(item, this, user, reviewal);
          ackItems.push(ack);
        });
        this.setAcknowledgements(ackItems);
      }
    }
  }

  getChangeModelItem(
    itemID: string,
    type: DraftChangeType,
    db: DatabaseResponse
  ): ModelItem<any> | undefined {
    switch (type) {
      case DraftChangeType.FOLDER:
        return db.categories.find((f) => f.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.PROTOCOL:
        return db.protocols.find((p) => p.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.PROTOCOL_PDF:
        return db.protocols.find((p) => p.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.MEDICATION:
        return db.medications.find((m) => m.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.INFUSION:
        return db.infusions.find((i) => i.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.ELECTRICAL:
        return db.electrical.find((e) => e.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.EQUIPMENT:
        return db.equipment.find((e) => e.uid === itemID) as
          | ModelItem<any>
          | undefined;
      case DraftChangeType.VITAL:
        return db.vitals.find((v) => v.uid === itemID) as
          | ModelItem<any>
          | undefined;
      default:
        return undefined;
    }
  }

  setAcknowledgements(acknowledgements: AcknowledgeItem[]) {
    this.allAcknowledgements = acknowledgements.sort((a, b) =>
      a.user.firstName.localeCompare(b.user.firstName)
    );
    this.acknowledgements = acknowledgements
      .filter((ack) => !ack.isArchived)
      .sort((a, b) => a.user.firstName.localeCompare(b.user.firstName));
    if (this.curUserID) {
      this.reviewACK = this.allAcknowledgements.find(
        (ack) => ack.user.id === this.curUserID
      );
    }

    /* Filter out duplicate acknowledgements */
    this.acknowledgements = this.acknowledgements
      .filter(
        (ack, index, self) =>
          index === self.findIndex((t) => t.user.id === ack.user.id)
      )
      .sort((a, b) => a.user.firstName.localeCompare(b.user.firstName));
  }

  getName(): string {
    return this.changeType + ' -> ' + this.uid;
  }

  getAmount(weight: PatientWeight): string {
    return 'N/A';
  }

  getUid(): string {
    return this.uid;
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof DraftChangeItem)) return false;
    return getObjectDifference(this, obj).length === 0;
  }

  toString(): string {
    return `DraftChangeItem -> {
            uid=${this.uid},
            draftGroup=${this.draftGroup},
            draftChange=${this.draftChange},
            changeItem=${this.changeItem},
            previousItem=${this.previousItem},
            changeType=${this.changeType},
        }`;
  }
}

export default DraftChangeItem;
