import { DeviceFrameset } from 'react-device-frameset';
import 'react-device-frameset/styles/marvel-devices.min.css';
import { Concentration, MedicationConcentration } from '../../../models';
import MedicationItem, {
  getConcentrationString,
  getConcentrationsArrayString,
} from '../../../data/model/MedicationItem';
import GeneralSelection from '../Selection/GeneralSelection';
import { useEffect, useMemo, useState } from 'react';
import './DeviceMockup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faChevronUp,
  faFileLines,
  faGear,
  faHouse,
  faRectangleList,
} from '@fortawesome/free-solid-svg-icons';
import ProtocolItem from '../../../data/model/ProtocolItem';
import PatientWeight from '../../_global/common/PatientWeight';
import { Slider } from '@mui/material';

import { globals, roundToDec } from '../../_global/common/Utils';
import InfusionItem from '../../../data/model/InfusionItem';
import { FaChevronUp } from 'react-icons/fa6';
import { useSelector } from 'react-redux';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { DatabaseResponse } from '../../../data/AmplifyDB';

interface InfusionMockupProps {
  parentModel: InfusionItem;
  formik: any;
  protocol: ProtocolItem;
  weightValue: number;
}
export const InfusionMockup: React.FC<InfusionMockupProps> = (props) => {
  const { parentModel, formik, protocol, weightValue } = props;
  const concentrations = parentModel.concentrations;
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const department: DepartmentItem = useMemo(() => {
    return database?.department;
  }, [database]);

  const [selectedConcentration, setSelectedConcentration] =
    useState<string>('');
  const [selectedIndex, setSelectedIndex] = useState<number>(0); // State for selected index
  const [patientWeightValue, setPatientWeightValue] = useState<PatientWeight>(
    new PatientWeight(weightValue, false)
  );

  const isEstimateOnly = true;
  const [sliderValue, setSliderValue] = useState(0);
  const [isDosageCalculator, setIsDosageCalculator] = useState<boolean>(false);

  useEffect(() => {
    if (concentrations && concentrations.length > 0) {
      const initialConcentration = getConcentrationString(concentrations[0]);
      setSelectedConcentration(initialConcentration);
    }
  }, [concentrations]);

  const concentrationsArray = useMemo(() => {
    if (!concentrations) return [];

    return getConcentrationsArrayString(
      concentrations as Concentration[]
    ).split(', ');
  }, [concentrations]);

  const handleSelection = (value: string) => {
    setSelectedConcentration(value);
    const index = concentrationsArray.indexOf(value); // Get the index of the selected value
    setSelectedIndex(index); // Update the selected index
  };

  useEffect(() => {
    setPatientWeightValue(new PatientWeight(weightValue, false));
  }, [weightValue]);

  const getAmountLow = (
    weight: PatientWeight,
    selectedConcentration: number,
    isLiquid: boolean
  ): number => {
    /* Base case check if the concentration is null or empty */
    if (
      concentrations == null ||
      concentrations.length == 0 ||
      selectedConcentration >= concentrations.length
    )
      return 0.0;
    let basisHigh = globals.MAX_VALUE;
    let calcUnit = '';
    let timeUnit = '';
    let basisUnit = '';
    let fullBasisUnit = '';
    let basisValue = 0;

    let maxDoseValueArray: string[] = formik.values.maxDose.split(' ', 2);
    let maxDoseValue = isNaN(parseFloat(maxDoseValueArray[0]))
      ? globals.MAX_VALUE
      : parseFloat(maxDoseValueArray[0]);
    let minDoseValueArray: string[] = formik.values.minDose.split(' ', 2);
    let minDoseValue = isNaN(parseFloat(minDoseValueArray[0]))
      ? 0
      : parseFloat(minDoseValueArray[0]);

    /* Check if there are multiple concentrations and if one has been selected */
    if (concentrations.length > 1 && selectedConcentration == -1) return -1.0;
    /* If there is only one concentration set the selected concentration to index 0 */ else if (
      concentrations.length == 1
    )
      selectedConcentration = 0;
    if (formik.values.basis === '-1' || formik.values.basis === '+Add') {
      basisHigh = globals.MAX_VALUE;
    } else {
      let basisArr: string[] = formik.values.basis.split(' ', 2);
      let basisNums: string[] = basisArr[0].split('-');
      basisValue = parseFloat(basisNums[0]);
      if (basisNums.length > 1) basisHigh = parseFloat(basisNums[1]);
      else basisHigh = globals.MAX_VALUE;

      fullBasisUnit = basisArr[1];
      let basisUnitsArr: string[] = basisArr[1].split('/');
      basisUnit = basisUnitsArr[0];
      if (basisUnitsArr.length > 1) {
        calcUnit = basisUnitsArr[1];
        if (basisUnitsArr.length > 2) {
          timeUnit = basisUnitsArr[2];
          if (timeUnit !== 'hr' && timeUnit !== 'min') {
            // console.log(
            //   TAG,
            //   'ERROR: ' +
            //     parent.getName() +
            //     ' second infusion unit is not kg, min, or hr  -> ' +
            //     this.calcUnit +
            //     ' basis: ' +
            //     range.basis
            // );
          }
        } else if (calcUnit === 'hr' || calcUnit === 'min') {
          timeUnit = calcUnit;
          calcUnit = '';
        } //else
        // console.log(
        //   this.TAG,
        //   'ERROR: ' +
        //     parent.getName() +
        //     ' second infusion unit is not kg, min, or hr  -> ' +
        //     this.calcUnit +
        //     ' basis: ' +
        //     range.basis
        // );
      }
    }

    if (department.infusionCalculation === false) {
      /* Return the low basis value */
      return basisValue;
    }

    /* Perform the calculation */
    let concen: Concentration = concentrations[selectedConcentration];
    let concentrationAmount: number = concen.firstAmnt / (concen.secAmnt ?? -1);
    let amnt: number = !calcUnit
      ? basisValue
      : basisValue * weight.getWeightKg();
    amnt = isLiquid
      ? Math.min(amnt, maxDoseValue) / concentrationAmount
      : Math.min(amnt, maxDoseValue);
    amnt = Math.max(amnt, 0, minDoseValue);
    let dec = amnt > 1.0 ? 1 : 2;
    return roundToDec(amnt, dec);
  };

  const getAmountHigh = (
    weight: PatientWeight,
    selectedConcentration: number,
    isLiquid: boolean
  ): number => {
    /* Base case check if the concentration is null or empty */
    if (
      concentrations == null ||
      concentrations.length == 0 ||
      selectedConcentration >= concentrations.length
    )
      return 0.0;

    /* Check if there are multiple concentrations and if one has been selected */
    if (concentrations.length > 1 && selectedConcentration == -1) return -1.0;
    /* If there is only one concentration set the selected concentration to index 0 */ else if (
      concentrations.length == 1
    )
      selectedConcentration = 0;
    let basisHigh = globals.MAX_VALUE;
    let calcUnit = '';
    let timeUnit = '';
    let basisUnit = '';
    let fullBasisUnit = '';
    let basisValue = 0;

    let maxDoseValueArray: string[] = formik.values.maxDose.split(' ', 2);
    let maxDoseValue = isNaN(parseFloat(maxDoseValueArray[0]))
      ? globals.MAX_VALUE
      : parseFloat(maxDoseValueArray[0]);
    let minDoseValueArray: string[] = formik.values.minDose.split(' ', 2);
    let minDoseValue = isNaN(parseFloat(minDoseValueArray[0]))
      ? 0
      : parseFloat(minDoseValueArray[0]);
    if (formik.values.basis === '-1' || formik.values.basis === '+Add') {
      basisHigh = globals.MAX_VALUE;
    } else {
      /* ---------------- Parse the data for the basis variables ---------------- */
      let basisArr: string[] = formik.values.basis.split(' ', 2);

      /* Parse basis amount to a double for comparison */
      let basisNums: string[] = basisArr[0].split('-');
      basisValue = parseFloat(basisNums[0]);
      if (basisNums.length > 1) basisHigh = parseFloat(basisNums[1]);
      else
        basisHigh =
          globals.MAX_VALUE; /* Parse the units for the basis to validate calculations */
      fullBasisUnit = basisArr[1];
      let basisUnitsArr: string[] = basisArr[1].split('/');
      basisUnit = basisUnitsArr[0];
      if (basisUnitsArr.length > 1) {
        calcUnit = basisUnitsArr[1];
        if (basisUnitsArr.length > 2) {
          timeUnit = basisUnitsArr[2];
          if (timeUnit !== 'hr' && timeUnit !== 'min') {
            // console.log(
            //   TAG,
            //   'ERROR: ' +
            //     parent.getName() +
            //     ' second infusion unit is not kg, min, or hr  -> ' +
            //     this.calcUnit +
            //     ' basis: ' +
            //     range.basis
            // );
          }
        } else if (calcUnit === 'hr' || calcUnit === 'min') {
          timeUnit = calcUnit;
          calcUnit = '';
        } //else
        // console.log(
        //   this.TAG,
        //   'ERROR: ' +
        //     parent.getName() +
        //     ' second infusion unit is not kg, min, or hr  -> ' +
        //     this.calcUnit +
        //     ' basis: ' +
        //     range.basis
        // );
      }
    }

    if (department.infusionCalculation === false) {
      /* Return the high basis value */
      return basisHigh;
    }

    /* Perform the calculation */
    let concen: Concentration = concentrations[selectedConcentration];
    let concentrationAmount: number = concen.firstAmnt / (concen.secAmnt ?? -1);
    let amnt: number = !calcUnit ? basisHigh : basisHigh * weight.getWeightKg();
    amnt = isLiquid
      ? Math.min(amnt, maxDoseValue) / concentrationAmount
      : Math.min(amnt, maxDoseValue);
    amnt = Math.max(amnt, 0, minDoseValue);
    let dec = amnt > 1.0 ? 1 : 2;
    return roundToDec(amnt, dec);
  };

  useEffect(() => {
    if (isEstimateOnly) {
      setSliderValue(
        getAmountLow(
          patientWeightValue,
          selectedIndex,
          !isSolidOnly(selectedIndex)
        )
      );
    }
  }, [selectedConcentration, patientWeightValue]);

  useEffect(() => {
    // if (department && department.getPackageLevel() !== 3) {
    //   setIsDosageCalculator(false);
    // } else {
    let basisHigh = globals.MAX_VALUE;
    let basisValue = 0;
    let basisArr: string[] = formik.values.basis.split(' ', 2);

    /* Parse basis amount to a double for comparison */
    let basisNums: string[] = basisArr[0].split('-');
    basisValue = parseFloat(basisNums[0]);
    if (basisNums.length > 1) basisHigh = parseFloat(basisNums[1]);
    else basisHigh = globals.MAX_VALUE;

    let dosageCalc: boolean =
      formik.values.basis !== '-1' &&
      formik.values.basis !== '+Add' &&
      basisHigh !== globals.MAX_VALUE;

    dosageCalc =
      dosageCalc &&
      getDoseSolid(patientWeightValue as PatientWeight, selectedIndex) !==
        'OUT_OF_RANGE';

    if (dosageCalc) {
      let low = getAmountLow(
        patientWeightValue,
        selectedIndex,
        !isSolidOnly(selectedIndex)
      );
      console.log('useEffect -> low', low);
      let high = getAmountHigh(
        patientWeightValue,
        selectedIndex,
        !isSolidOnly(selectedIndex)
      );
      console.log('useEffect -> high', high);
      if (low === high) dosageCalc = false;
      else if (parentModel.concentrations.length > 1 && selectedIndex === -1)
        dosageCalc = false;
    }
    setIsDosageCalculator(dosageCalc);
    // }
  }, [
    selectedConcentration,
    patientWeightValue,
    formik.values.basis,
    department,
    selectedIndex,
  ]);

  const formatDose = (dose: number) => {
    // Round the dose to two decimal places
    let dec = dose > 99.9 || dose === 0 ? 0 : dose < 1.0 ? 2 : 1;
    const roundedDose = roundToDec(dose, dec);
    // Convert to string and use regular expression to remove unnecessary trailing zeros
    return roundedDose
      .toString()
      .replace(/\.00$/, '')
      .replace(/(\.\d)0$/, '$1');
  };

  const inRange = (weight: PatientWeight): boolean => {
    let rangeLow = formik.values.rangeLow ? formik.values.rangeLow : 0;
    let rangeHigh = formik.values.rangeHigh
      ? formik.values.rangeHigh
      : globals.MAX_VALUE;
    return rangeLow <= weight.getWeightKg() && weight.getWeightKg() < rangeHigh;
  };

  const isInRange = useMemo(() => {
    return inRange(patientWeightValue);
  }, [patientWeightValue, formik.values.rangeLow, formik.values.rangeHigh]);

  const getDoseSolid = (
    weight: PatientWeight,
    selectedConcentration: number,
    includeUnits: boolean = true
  ): string => {
    if (!inRange(weight)) return 'OUT_OF_RANGE';
    /* Base case is no concentrations */
    if (
      concentrations == null ||
      concentrations.length == 0 ||
      formik.values.basis == 0.0
    )
      return '';

    /* If there is multiple concentrations and it has not been selected return nothing */
    if (concentrations.length > 1 && selectedConcentration == -1)
      return '--' + concentrations[0].firstUnit;
    else if (concentrations.length == 1) selectedConcentration = 0;

    let concentration: Concentration = concentrations[selectedConcentration];

    if (concentration == null) return '';
    let basisHigh = globals.MAX_VALUE;

    let calcUnit = null;
    let timeUnit = null;
    let basisUnit = '';
    let fullBasisUnit = '';
    let basisValue = 0;
    let maxDoseValueArray: string[] = formik.values.maxDose.split(' ', 2);
    let maxDoseValue = isNaN(parseFloat(maxDoseValueArray[0]))
      ? globals.MAX_VALUE
      : parseFloat(maxDoseValueArray[0]);
    let minDoseValueArray: string[] = formik.values.minDose.split(' ', 2);
    let minDoseValue = isNaN(parseFloat(minDoseValueArray[0]))
      ? 0
      : parseFloat(minDoseValueArray[0]);
    if (formik.values.basis === '-1' || formik.values.basis === '+Add') {
      basisHigh = globals.MAX_VALUE;
    } else {
      /* ---------------- Parse the data for the basis variables ---------------- */
      let basisArr: string[] = formik.values.basis.split(' ', 2);

      /* Parse basis amount to a double for comparison */
      let basisNums: string[] = basisArr[0].split('-');
      basisValue = parseFloat(basisNums[0]);
      if (basisNums.length > 1) basisHigh = parseFloat(basisNums[1]);
      else basisHigh = globals.MAX_VALUE;

      /* Parse the units for the basis to validate calculations */
      fullBasisUnit = basisArr[1];
      let basisUnitsArr: string[] = basisArr[1].split('/');
      basisUnit = basisUnitsArr[0];
      if (basisUnitsArr.length > 1) {
        calcUnit = basisUnitsArr[1];
        if (basisUnitsArr.length > 2) {
          timeUnit = basisUnitsArr[2];
          if (timeUnit !== 'hr' && timeUnit !== 'min') {
            // console.log(
            //   TAG,
            //   'ERROR: ' +
            //     parent.getName() +
            //     ' second infusion unit is not kg, min, or hr  -> ' +
            //     this.calcUnit +
            //     ' basis: ' +
            //     range.basis
            // );
          }
        } else if (calcUnit === 'hr' || calcUnit === 'min') {
          timeUnit = calcUnit;
          calcUnit = '';
        } //else
        // console.log(
        //   this.TAG,
        //   'ERROR: ' +
        //     parent.getName() +
        //     ' second infusion unit is not kg, min, or hr  -> ' +
        //     this.calcUnit +
        //     ' basis: ' +
        //     range.basis
        // );
      }
    }

    /* if there is no calculation needed just return required amount - Ex. Glucagon (0.5 mg) */
    if (concentration.secAmnt == null || concentration.secAmnt <= 0) {
      //TODO need to validate it will be null in a single concentration
      /* No dose range, return the dose in primary units */
      let dose: number =
        calcUnit === null ? basisValue : basisValue * weight.getWeightKg();

      dose = Math.min(dose, maxDoseValue);
      dose = Math.max(dose, 0, minDoseValue);
      let dec1 = dose > 99.9 ? 0 : dose > 9.9 ? 1 : 2;

      if (basisHigh == globals.MAX_VALUE)
        return `${roundToDec(dose, dec1).toFixed(dec1)} ${concentration.firstUnit}`;
      /* Dose range supplied, check if both doses are the same otherwise return a range in primary units */ else {
        let doseHigh: number =
          calcUnit === null ? basisHigh : basisHigh * weight.getWeightKg();
        doseHigh = Math.min(dose, maxDoseValue);
        doseHigh = Math.max(doseHigh, 0, minDoseValue);
        let dec2 = doseHigh > 99.9 ? 0 : doseHigh > 9.9 ? 1 : 2;

        /* If the doses are the same return the dose in primary units */
        if (dose == doseHigh)
          return `${roundToDec(dose, dec1).toFixed(dec1)} ${concentration.firstUnit}`;

        /* If the doses are different return a range in primary units */
        return `${roundToDec(dose, dec1).toFixed(dec1)}-${roundToDec(doseHigh, dec2).toFixed(dec2)} ${concentration.firstUnit}`;
      }
    } else {
      /* No dose range, return the dose in primary units */
      let dose: number = !calcUnit
        ? basisValue
        : basisValue * weight.getWeightKg();
      dose = Math.min(dose, maxDoseValue);
      dose = Math.max(dose, 0, minDoseValue);
      let dec1 = dose > 99.9 || dose === 0 ? 0 : 1;
      if (basisHigh == globals.MAX_VALUE)
        return (
          formatDose(dose) +
          (includeUnits
            ? ' ' + concentration.firstUnit + (timeUnit ? `/${timeUnit}` : '')
            : '')
        );
      /* Dose range supplied, check if both doses are the same otherwise return a range in primary units */ else {
        let doseHigh: number = !calcUnit
          ? basisHigh
          : basisHigh * weight.getWeightKg();
        doseHigh = Math.min(doseHigh, maxDoseValue);
        doseHigh = Math.max(doseHigh, 0, minDoseValue);
        let dec2 = doseHigh > 99.9 ? 0 : 1;
        /* If the doses are the same return the dose in primary units */
        if (dose == doseHigh)
          return (
            formatDose(dose) +
            (includeUnits
              ? ' ' + concentration.firstUnit + (timeUnit ? `/${timeUnit}` : '')
              : '')
          );

        /* If the doses are different return a range in primary units */
        return `${formatDose(dose)}-${formatDose(doseHigh)}${
          includeUnits
            ? ' ' + concentration.firstUnit + (timeUnit ? `/${timeUnit}` : '')
            : ''
        }`;
      }
    }
  };

  const getDoseLiquid = (
    weight: PatientWeight,
    selectedConcentration: number,
    includeUnits: boolean = true
  ): string => {
    if (!inRange(weight)) return 'OUT_OF_RANGE';
    /* Base case is no concentration */
    if (
      concentrations == null ||
      concentrations.length == 0 ||
      formik.values.basis == 0.0
    )
      return '';
    let basisHigh = globals.MAX_VALUE;
    let calcUnit = '';
    let timeUnit = '';
    let basisUnit = '';
    let fullBasisUnit = '';
    let basisValue = 0;
    let maxDoseValueArray: string[] = formik.values.maxDose.split(' ', 2);
    let maxDoseValue = isNaN(parseFloat(maxDoseValueArray[0]))
      ? globals.MAX_VALUE
      : parseFloat(maxDoseValueArray[0]);
    let minDoseValueArray: string[] = formik.values.minDose.split(' ', 2);
    let minDoseValue = isNaN(parseFloat(minDoseValueArray[0]))
      ? 0
      : parseFloat(minDoseValueArray[0]);

    if (formik.values.basis === '-1' || formik.values.basis === '+Add') {
      basisHigh = globals.MAX_VALUE;
    } else {
      /* ---------------- Parse the data for the basis variables ---------------- */
      let basisArr: string[] = formik.values.basis.split(' ', 2);

      /* Parse basis amount to a double for comparison */
      let basisNums: string[] = basisArr[0].split('-');
      basisValue = parseFloat(basisNums[0]);
      if (basisNums.length > 1) basisHigh = parseFloat(basisNums[1]);
      else basisHigh = globals.MAX_VALUE;

      /* Parse the units for the basis to validate calculations */
      fullBasisUnit = basisArr[1];
      let basisUnitsArr: string[] = basisArr[1].split('/');
      basisUnit = basisUnitsArr[0];
      if (basisUnitsArr.length > 1) {
        calcUnit = basisUnitsArr[1];
        if (basisUnitsArr.length > 2) {
          timeUnit = basisUnitsArr[2];
          if (timeUnit !== 'hr' && timeUnit !== 'min') {
            // console.log(
            //   TAG,
            //   'ERROR: ' +
            //     parent.getName() +
            //     ' second infusion unit is not kg, min, or hr  -> ' +
            //     this.calcUnit +
            //     ' basis: ' +
            //     range.basis
            // );
          }
        } else if (calcUnit === 'hr' || calcUnit === 'min') {
          timeUnit = calcUnit;
          calcUnit = '';
        }
      }
    }
    /* If there is multiple concentrations and it has not been selected return nothing */
    if (concentrations.length > 1 && selectedConcentration == -1)
      return '--' + concentrations[0].secUnit;
    else if (concentrations.length == 1) selectedConcentration = 0;

    let concentration: Concentration = concentrations[selectedConcentration];

    if (
      concentration == null ||
      !concentration.secAmnt ||
      concentration.secAmnt <= 0
    )
      return '';

    /* No dose range, return the dose in primary units */
    let concentrationAmount: number =
      concentration.firstAmnt / concentration.secAmnt;
    let dose: number = !calcUnit
      ? basisValue
      : basisValue * weight.getWeightKg();
    dose = Math.min(dose, maxDoseValue) / concentrationAmount;
    dose = Math.max(dose, 0, minDoseValue);
    let dec1 = dose > 99.9 || dose === 0 ? 0 : dose < 0.1 ? 2 : 1;

    if (basisHigh == globals.MAX_VALUE)
      return (
        formatDose(dose) +
        (includeUnits
          ? ' ' + concentration.secUnit + (timeUnit ? `/${timeUnit}` : '')
          : '')
      );
    /* Dose range supplied, check if both doses are the same otherwise return a range in primary units */ else {
      let doseHigh: number = !calcUnit
        ? basisHigh
        : basisHigh * weight.getWeightKg();
      doseHigh = Math.min(doseHigh, maxDoseValue) / concentrationAmount;
      doseHigh = Math.max(doseHigh, 0, minDoseValue);
      // let doseHigh : number = roundToDec(Math.min(weight.getWeightKg() * basisHigh, maxDose), 1) / concentrationAmount;
      let dec2 = doseHigh > 99.9 || doseHigh === 0 ? 0 : doseHigh < 0.1 ? 2 : 1;

      /* If the doses are the same return the dose in primary units */
      if (dose == doseHigh)
        return (
          formatDose(dose) +
          (includeUnits
            ? ' ' + concentration.secUnit + (timeUnit ? `/${timeUnit}` : '')
            : '')
        );

      /* If the doses are different return a range in primary units */
      return `${formatDose(dose)}-${formatDose(doseHigh)}${
        includeUnits
          ? ' ' + concentration.secUnit + (timeUnit ? `/${timeUnit}` : '')
          : ''
      }`;
    }
  };

  /**
   * Get the amount to administer in liquid and solid form to the patient based on their weight
   * @param weight The patient's weight
   * @returns The amount to administer in liquid and solid form Ex. "1.5 mL (10.2 mg)"
   */
  const getAmount = (): string => {
    let basisHigh = globals.MAX_VALUE;

    let calcUnit = null;
    let timeUnit = null;
    let basisUnit = '';
    let fullBasisUnit = '';
    let basisValue = 0;
    let maxDoseValueArray: string[] = formik.values.maxDose.split(' ', 2);
    let maxDoseValue = isNaN(parseFloat(maxDoseValueArray[0]))
      ? globals.MAX_VALUE
      : parseFloat(maxDoseValueArray[0]);
    let minDoseValueArray: string[] = formik.values.minDose.split(' ', 2);
    let minDoseValue = isNaN(parseFloat(minDoseValueArray[0]))
      ? 0
      : parseFloat(minDoseValueArray[0]);
    if (formik.values.basis === '-1' || formik.values.basis === '+Add') {
      basisHigh = globals.MAX_VALUE;
    } else {
      /* ---------------- Parse the data for the basis variables ---------------- */
      let basisArr: string[] = formik.values.basis.split(' ', 2);

      /* Parse basis amount to a double for comparison */
      let basisNums: string[] = basisArr[0].split('-');
      basisValue = parseFloat(basisNums[0]);
      if (basisNums.length > 1) basisHigh = parseFloat(basisNums[1]);
      else basisHigh = globals.MAX_VALUE;

      /* Parse the units for the basis to validate calculations */
      fullBasisUnit = basisArr[1];
      let basisUnitsArr: string[] = basisArr[1].split('/');
      basisUnit = basisUnitsArr[0];
      if (basisUnitsArr.length > 1) {
        calcUnit = basisUnitsArr[1];
        if (basisUnitsArr.length > 2) {
          timeUnit = basisUnitsArr[2];
          if (timeUnit !== 'hr' && timeUnit !== 'min') {
            // console.log(
            //   TAG,
            //   'ERROR: ' +
            //     parent.getName() +
            //     ' second infusion unit is not kg, min, or hr  -> ' +
            //     this.calcUnit +
            //     ' basis: ' +
            //     range.basis
            // );
          }
        } else if (calcUnit === 'hr' || calcUnit === 'min') {
          timeUnit = calcUnit;
          calcUnit = '';
        } //else
        // console.log(
        //   this.TAG,
        //   'ERROR: ' +
        //     parent.getName() +
        //     ' second infusion unit is not kg, min, or hr  -> ' +
        //     this.calcUnit +
        //     ' basis: ' +
        //     range.basis
        // );
      }
    }

    if (basisValue === globals.MAX_VALUE) return '';

    if (basisValue < 0.0) return 'Contact Medical Control';

    if (basisHigh === globals.MAX_VALUE) return `${formatDose(basisValue)}`;
    return `${formatDose(basisValue)}-${formatDose(basisHigh)}`;
  };

  const getLiquidUnit = () => {
    if (concentrations[0] == null || concentrations[0].secAmnt == null)
      return '';
    let calcUnit = '';
    let timeUnit = '';
    let basisArr: string[] = formik.values.basis.split(' ', 2);
    let basisUnitsArr: string[] = basisArr[1].split('/');
    if (isSolidOnly(0)) return concentrations[0].firstUnit;
    if (basisUnitsArr.length > 1) {
      calcUnit = basisUnitsArr[1];
      if (basisUnitsArr.length > 2) {
        timeUnit = basisUnitsArr[2];
        if (timeUnit !== 'hr' && timeUnit !== 'min') {
          // console.log(
          //   TAG,
          //   'ERROR: ' +
          //     parent.getName() +
          //     ' second infusion unit is not kg, min, or hr  -> ' +
          //     this.calcUnit +
          //     ' basis: ' +
          //     range.basis
          // );
        }
      } else if (calcUnit === 'hr' || calcUnit === 'min') {
        timeUnit = calcUnit;
        calcUnit = '';
      }
    }
    console.log(
      'getLiquidUnit -> concentrations[0].secUnit',
      concentrations[0].secUnit,
      'timeUnit',
      timeUnit,
      'basisUnitsArr',
      basisUnitsArr
    );
    return concentrations[0].secUnit
      ? concentrations[0].secUnit + (timeUnit ? `/${timeUnit}` : '')
      : '';
  };

  const getSolidUnit = (): string => {
    if (concentrations == null || concentrations.length == 0) return '';

    if (department.infusionCalculation === false) return getFullBasisUnit();

    let calcUnit = '';
    let timeUnit = '';
    let basisArr: string[] = formik.values.basis.split(' ', 2);
    let basisUnitsArr: string[] = basisArr[1].split('/');
    if (isSolidOnly(0)) return concentrations[0].firstUnit;
    if (basisUnitsArr.length > 1) {
      calcUnit = basisUnitsArr[1];
      if (basisUnitsArr.length > 2) {
        timeUnit = basisUnitsArr[2];
        if (timeUnit !== 'hr' && timeUnit !== 'min') {
          // console.log(
          //   TAG,
          //   'ERROR: ' +
          //     parent.getName() +
          //     ' second infusion unit is not kg, min, or hr  -> ' +
          //     this.calcUnit +
          //     ' basis: ' +
          //     range.basis
          // );
        }
      } else if (calcUnit === 'hr' || calcUnit === 'min') {
        timeUnit = calcUnit;
        calcUnit = '';
      }
    }
    return concentrations[0].firstUnit + (timeUnit ? `/${timeUnit}` : '');
  };

  const isSolidOnly = (selectedConcen: number): boolean => {
    if (
      concentrations == null ||
      concentrations.length == 0 ||
      selectedConcen >= concentrations.length ||
      department.infusionCalculation === false
    )
      return false;
    if (concentrations.length > 1 && selectedConcen == -1) return false;
    else if (concentrations.length == 1) selectedConcen = 0;
    let concen: MedicationConcentration = concentrations[selectedConcen];
    return (
      concen.secUnit === null ||
      concen.secUnit === '' ||
      concen.secUnit === undefined
    );
  };

  const getInterval = (
    weight: PatientWeight,
    selectedConcentration: number,
    isLiquid?: boolean,
    isCalc: boolean = false
  ): number => {
    let low: number = getAmountLow(
      weight,
      selectedConcentration,
      isLiquid ?? true
    );
    let high: number = getAmountHigh(
      weight,
      selectedConcentration,
      isLiquid ?? true
    );
    if (high - low > 100) return 10;
    else if (high - low > 50) return 5;
    else if (high - low > 10) return 1;
    else if (high - low < 1.99) return 0.01;
    else return 0.1;
  };

  const reverseLiquidCalculation = (
    amount: number,
    weight: PatientWeight,
    selectedConcentration: number
  ): number => {
    /* Base case check if the concentration is null or empty */
    if (
      concentrations == null ||
      concentrations.length == 0 ||
      selectedConcentration >= concentrations.length
    )
      return 0.0;

    /* Check if there are multiple concentrations and if one has been selected */
    if (concentrations.length > 1 && selectedConcentration == -1) return -1.0;
    /* If there is only one concentration set the selected concentration to index 0 */ else if (
      concentrations.length == 1
    )
      selectedConcentration = 0;

    /* Perform the calculation */
    let concen: Concentration = concentrations[selectedConcentration];
    let concentrationAmount: number = concen.firstAmnt / (concen.secAmnt ?? -1);
    let amnt: number = amount * concentrationAmount;
    let dec = amnt > 1.0 ? 1 : 2;
    let minSolid = getAmountLow(weight, selectedConcentration, false);
    let maxSolid = getAmountHigh(weight, selectedConcentration, false);
    amnt = Math.max(Math.min(amnt, maxSolid), minSolid);
    return roundToDec(amnt, dec);
  };

  const handleSliderChange = (event: Event, value: any) => {
    console.log('handleSliderChange -> value', value);
    let decimals = 0;
    let interval = getInterval(
      patientWeightValue,
      selectedIndex,
      !isSolidOnly(selectedIndex)
    );
    if (interval < 1) decimals = 1;
    if (interval < 0.1) decimals = 2;
    setSliderValue(value.toFixed(decimals));
  };

  const getDoseTop = (
    weight: PatientWeight,
    selectedIndex: number,
    isCalculation: boolean = false
  ): string => {
    if (isCalculation) {
      return getDoseLiquid(weight, selectedIndex);
    } else {
      return getAmount();
    }
  };

  const getDoseBottom = (
    weight: PatientWeight,
    selectedIndex: number,
    isCalculation: boolean = false
  ): string => {
    if (isCalculation) {
      return getDoseSolid(weight, selectedIndex);
    } else {
      return getFullBasisUnit();
    }
  };

  /**
   * Get the full calculation basis units
   * @returns The string representation of the basis units Ex. "mg/kg/hr"
   */
  const getFullBasisUnit = (): string => {
    let fullBasisUnit = '';
    let basisArr: string[] = formik.values.basis.split(' ', 2);
    if (basisArr.length < 2) return '';
    fullBasisUnit = basisArr[1];
    return fullBasisUnit;
  };

  const isMultiConcentration = useMemo(() => {
    return parentModel.concentrations.length > 1;
  }, [parentModel]);

  return (
    <div
      className={`accordHeader border-active`}
      style={{
        borderBottom: 'none',
        borderRadius: '8px',
        padding: '40px 12px 40px 12px',
        fontSize: '15px',
        backgroundColor: '#E0EADD',
        height: '100%',
      }}
    >
      <div
        style={{
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow
        }}
      >
        <div
          style={{
            borderTopLeftRadius: '10px', // Set radius for top-left corner
            borderTopRightRadius: '10px', // Set radius for top-right corner
            padding: '10px',
            backgroundColor: (patientWeightValue as PatientWeight)
              .getColorTheme()
              .getPrimary(),
            color: 'white',
            fontSize: '16px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon icon={faChevronUp} color={'white'} size={'sm'} />
          <div
            style={{
              paddingLeft: '10px',
            }}
          >
            Infusion
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            backgroundColor: (patientWeightValue as PatientWeight)
              .getColorTheme()
              .getSecondarySelected(),
            justifyContent: 'space-between',
            borderBottom: '1px solid #d9d9d9',
            minHeight: '80px',
            paddingLeft: '2px',
            paddingRight: '2px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // marginLeft: '-8px',
              // padding: '5px',
              // width: '5%',
              justifyContent: 'center',
            }}
          >
            <FaChevronUp
              style={{
                color: 'black',
                fontSize: '18px',
                // marginLeft: '10px',
              }}
            />
          </div>
          <div
            style={{
              padding: '5px',
              width: '57%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {formik.values.warning && (
              <span
                className="warning-text"
                style={{
                  color: '#880808',
                  fontSize: '12px',
                  whiteSpace: 'nowrap', // Prevent the text from wrapping to the next line
                  overflow: 'hidden', // Hide any overflow content
                  textOverflow: 'ellipsis', // Add ellipses if the text overflows
                  display: 'inline-block', // Ensure the span respects the width constraint
                  width: '100%', // Adjust this value as needed based on the container's width
                }}
              >
                {formik.values.warning}
              </span>
            )}
            {!formik.values.warning && (
              <span
                className="title-text"
                style={{
                  fontSize: '12px',
                  whiteSpace: 'nowrap', // Prevent the text from wrapping to the next line
                  overflow: 'hidden', // Hide any overflow content
                  textOverflow: 'ellipsis', // Add ellipses if the text overflows
                  display: 'inline-block', // Ensure the span respects the width constraint
                  width: '100%', // Adjust this value as needed based on the container's width
                }}
              >
                {formik.values.title || ' '}
              </span>
            )}

            {parentModel.name && (
              <div>
                <div
                  style={{
                    alignItems: 'center',
                    fontWeight: 'bold',
                    fontSize: '14px',
                    display: 'flex',
                  }}
                >
                  <span
                    className="med-large-text"
                    style={{
                      flex: 1,
                    }}
                  >
                    {parentModel.name}
                  </span>
                  <span
                    style={{
                      fontSize: '14px',
                    }}
                    className="concentrations-text"
                  >
                    {selectedConcentration}
                  </span>
                </div>
              </div>
            )}
            {formik.values.instruction && (
              <span
                className="instruction-text"
                style={{
                  fontSize: '12px',
                  whiteSpace: 'nowrap', // Prevent the text from wrapping to the next line
                  overflow: 'hidden', // Hide any overflow content
                  textOverflow: 'ellipsis', // Add ellipses if the text overflows
                  display: 'inline-block', // Ensure the span respects the width constraint
                  width: '100%', // Adjust this value as needed based on the container's width
                }}
              >
                {formik.values.instruction || ' '}
              </span>
            )}
          </div>

          {formik.values.basis && (
            <div
              style={{
                textAlign: 'center',
                padding: '5px',
                backgroundColor: (patientWeightValue as PatientWeight)
                  .getColorTheme()
                  .getContrast(),
                width: '36%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px',
              }}
            >
              {getDoseSolid(
                patientWeightValue as PatientWeight,
                selectedIndex
              ) === 'OUT_OF_RANGE' ||
              getDoseLiquid(
                patientWeightValue as PatientWeight,
                selectedIndex
              ) === 'OUT_OF_RANGE' ? (
                <div className="dose-container solid">
                  <div
                    className="dose-text"
                    style={{
                      color: 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    Out of Range
                  </div>
                </div>
              ) : isSolidOnly(selectedIndex) ? (
                <div className="dose-container solid">
                  <div className="dose-text">
                    {getDoseBottom(
                      patientWeightValue as PatientWeight,
                      selectedIndex,
                      department.infusionCalculation
                    )}
                  </div>
                </div>
              ) : (
                <div className="dose-container liquid">
                  <div
                    className="dose-text"
                    style={{ fontWeight: 'bold', fontSize: '14px' }}
                  >
                    {getDoseTop(
                      patientWeightValue as PatientWeight,
                      selectedIndex,
                      department.infusionCalculation
                    )}
                  </div>
                  <div className="dose-text">
                    {getDoseBottom(
                      patientWeightValue as PatientWeight,
                      selectedIndex,
                      department.infusionCalculation
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            className="routes-container"
            style={{
              padding: '5px',
              fontWeight: 'bold',
              width: '15%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '14px',
            }}
          >
            <span className="routes-text">
              {formik.values.routes.length > 0
                ? formik.values.routes.join('/')
                : '--- '}
            </span>
          </div>
        </div>
        <div
          style={{
            backgroundColor: 'white', // White background

            padding: '0px 10px 10px 10px',
          }}
        >
          {concentrationsArray.length > 1 ? (
            <>
              <div
                style={{
                  width: '100%',
                  paddingTop: '4px', // Adjust padding values as needed
                }}
              >
                <span
                  style={{
                    // Adjust font size as needed
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Select Concentration:
                </span>
                <div style={{}}>
                  <GeneralSelection
                    items={concentrationsArray}
                    selectedIndex={selectedIndex} // Use selectedIndex state here
                    onPress={handleSelection} // Use the handleSelection function
                    labelField={(option) => option}
                    valueField={(option) => option}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  paddingTop: '4px', // Adjust padding values as needed
                  width: '100%',
                  display: 'flex',
                }}
              >
                <span
                  style={{
                    // Adjust font size as needed
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Concentration:
                </span>
                <div style={{ flex: 1, textAlign: 'right' }}>
                  <span
                    style={{
                      // Adjust font size as needed
                      textAlign: 'center',
                    }}
                  >
                    {selectedConcentration}
                  </span>
                </div>
              </div>
            </>
          )}
          {formik.values.warning && (
            <div
              style={{
                marginTop: '4px', // Adjust padding values as needed
                border: '1px solid #880808',
                padding: '2px 8px 2px 8px',
              }}
            >
              <span
                style={{
                  fontWeight: 'bold',
                  color: '#880808', // Assuming colors.declineDark is red
                }}
              >
                Warning:
              </span>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <span
                    style={{
                      fontSize: '1rem', // Adjust font size as needed
                      fontWeight: 'bold',
                    }}
                  >
                    {formik.values.warning}
                  </span>
                </div>
              </div>
            </div>
          )}

          {
            <div
              style={{
                marginTop: '4px', // theme.padding.vertical_half
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span
                style={{
                  // theme.fontSize.medium_large
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                Calculation Basis
              </span>
              <div style={{ flex: 1, textAlign: 'right' }}>
                <span
                  style={
                    {
                      // theme.fontSize.medium_large
                    }
                  }
                >
                  {formik.values.basis === '+Add' ? '---' : formik.values.basis}
                </span>
              </div>
            </div>
          }
          {formik.values.maxDose && (
            <div style={{ marginTop: '4px' }}>
              {' '}
              {/* theme.padding.vertical_half */}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span
                  style={{
                    // fontSize.medium_large
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Max Dose:
                </span>
                <div style={{ flex: 1, textAlign: 'right' }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {/* {isMaxDose ? '*Reached max dose - ' : ''} */}
                    {formik.values.maxDose}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formik.values.minDose && (
            <div style={{ marginTop: '4px' }}>
              {' '}
              {/* theme.padding.vertical_half */}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span
                  style={{
                    // fontSize.medium_large
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Min Dose:
                </span>
                <div style={{ flex: 1, textAlign: 'right' }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {formik.values.minDose}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formik.values.repeatTime && (
            <>
              <div style={{ marginTop: '4px' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <span
                    style={{
                      // fontSize.medium_large
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}
                  >
                    Repeat Time:
                  </span>
                  <div style={{ flex: 1, textAlign: 'right' }}>
                    <span
                      style={
                        {
                          // fontSize.medium_large
                        }
                      }
                    >
                      {formik.values.repeatTime}
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}

          {formik.values.maxTotalDose && (
            <div style={{ marginTop: '4px' }}>
              <div style={{ display: 'flex' }}>
                <span
                  style={{
                    // fontSize.medium_large
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Max Total Dose:
                </span>
                <div style={{ flex: 1, textAlign: 'right' }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {/* {isMaxTotalDose ? '*Reached max total dose - ' : ''} */}
                    {formik.values.maxTotalDose}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formik.values.title && (
            <div style={{ marginTop: '4px', textAlign: 'left' }}>
              <span
                style={{
                  // fontSize.medium_large
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                Title:
              </span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {formik.values.title}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formik.values.instruction && (
            <div style={{ marginTop: '4px', textAlign: 'left' }}>
              <span
                style={{
                  // fontSize.medium_large
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                Instruction:
              </span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {formik.values.instruction}
                  </span>
                </div>
              </div>
            </div>
          )}

          {formik.values.note && (
            <div style={{ marginTop: '4px', textAlign: 'left' }}>
              <span
                style={{
                  // fontSize.medium_large
                  textAlign: 'left',
                  fontWeight: 'bold',
                }}
              >
                Note:
              </span>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ flex: 1 }}>
                  <span
                    style={
                      {
                        // fontSize.medium_large
                      }
                    }
                  >
                    {formik.values.note}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Dosage Calculator added */}
          {isDosageCalculator &&
            (!isMultiConcentration ||
              (isMultiConcentration && selectedIndex !== -1)) && (
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                  }}
                >
                  <div
                    style={{
                      marginTop: '4px',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <div
                      style={{
                        // ...styles.medLargeText,
                        // fontSize: fontSize.medium_large,
                        textAlign: 'center',
                        fontWeight: 'bold',
                      }}
                    >
                      Dosage Calculator:
                    </div>
                  </div>

                  <div
                    style={{
                      flexDirection: 'column',
                      marginBottom: 0,
                      borderRadius: '8px',
                      padding: '4px 10px 4px 10px',
                      backgroundColor: 'rgb(255, 214, 153)',
                    }}
                  >
                    {department.infusionCalculation === false ||
                    isSolidOnly(selectedIndex) ? (
                      <div
                        style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          padding: '10px 4px',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: '700',
                          }}
                        >
                          {sliderValue} {getSolidUnit()}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            fontWeight: '700',
                          }}
                        >
                          {sliderValue} {getLiquidUnit()}
                        </div>
                        <div>
                          {reverseLiquidCalculation(
                            sliderValue,
                            patientWeightValue,
                            selectedIndex
                          )}{' '}
                          {getSolidUnit()}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{}}>
                  <div style={{ marginTop: '4px', padding: '0px 4px 0px 4px' }}>
                    <Slider
                      value={sliderValue}
                      min={getAmountLow(
                        patientWeightValue,
                        selectedIndex,
                        !isSolidOnly(selectedIndex)
                      )}
                      max={getAmountHigh(
                        patientWeightValue,
                        selectedIndex,
                        !isSolidOnly(selectedIndex)
                      )}
                      step={getInterval(
                        patientWeightValue,
                        selectedIndex,
                        !isSolidOnly(selectedIndex)
                      )}
                      onChange={handleSliderChange}
                      sx={{
                        color: '#5A8F5C', // Color of the slider
                        '& .MuiSlider-thumb': {
                          backgroundColor: '#808080', // Color of the thumb
                          '&:hover': {
                            boxShadow: 'inherit', // Shadow on hover
                          },
                        },
                        '& .MuiSlider-track': {
                          backgroundColor: '#5A8F5C', // Color of the track
                        },
                        '& .MuiSlider-rail': {
                          backgroundColor: '#5A8F5C', // Color of the rail
                          height: '10px', // Height of the rail
                        },
                      }}
                    />

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          alignItems: 'flex-start',
                          flexDirection: 'column',
                          marginTop: -10,
                          justifyContent: 'flex-start',
                        }}
                      >
                        <div style={{ fontWeight: 'bold' }}>
                          {getAmountLow(
                            patientWeightValue,
                            selectedIndex,
                            !isSolidOnly(selectedIndex)
                          )}{' '}
                          {isSolidOnly(selectedIndex) ||
                          department.infusionCalculation === false
                            ? getSolidUnit()
                            : getLiquidUnit()}
                        </div>
                        {!isSolidOnly(selectedIndex) &&
                          department.infusionCalculation === true && (
                            <div>
                              (
                              {getAmountLow(
                                patientWeightValue,
                                selectedIndex,
                                false
                              )}{' '}
                              {getSolidUnit()})
                            </div>
                          )}
                      </div>
                      <div
                        style={{
                          alignItems: 'flex-end',
                          marginTop: -10,
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <div style={{ fontWeight: 'bold' }}>
                          {getAmountHigh(
                            patientWeightValue,
                            selectedIndex,
                            !isSolidOnly(selectedIndex)
                          )}{' '}
                          {isSolidOnly(selectedIndex) ||
                          department.infusionCalculation === false
                            ? getSolidUnit()
                            : getLiquidUnit()}
                        </div>
                        {!isSolidOnly(selectedIndex) &&
                          department.infusionCalculation === true && (
                            <div>
                              (
                              {getAmountHigh(
                                patientWeightValue,
                                selectedIndex,
                                false
                              )}{' '}
                              {getSolidUnit()})
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
