// Note: EditDB for CategoryItem
// Date: 01/09/2024
// Author: Colton Hazlett
//..................................................................................................
import { DataStore } from '@aws-amplify/datastore';
import { Category, User } from '../../models';
import {
  DatabaseResponse,
  executeSingleQuery,
  mapModelItems,
  Response,
  ResponseType,
} from '../AmplifyDB';
import CategoryItem, { cloneCategory } from '../model/CategoryItem';
import { ProgressStatus } from '../../API';
import {
  findItemByID,
  globals,
  upgradeVersion,
} from '../../ui/_global/common/Utils';
import DepartmentItem from '../model/DepartmentItem';
import { over, update } from 'lodash';
import { prefix } from '@fortawesome/free-solid-svg-icons';
import ProtocolItem, { cloneProtocol } from '../model/ProtocolItem';
import {
  createProtocol,
  deleteProtocol,
  duplicateProtocol,
} from './ProtocolDB';
import KeychainItem from '../model/KeychainItem';
import { exec } from 'child_process';
import { getCategory } from '../../graphql/queries';
import { graphqlOperation } from 'aws-amplify';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import { Draft } from '../AmplifyVersion';
import { findUsersForModelItems } from './ModelDB';

export type CategoryJSON = {
  name: string;
  index: number;
  departmentID: string;
  pairedDeps: string[];

  keychainID: string | null | undefined;
  isPublic: boolean;
  isRestrictive: boolean;

  modifiedBy?: string;
  createdBy: string;

  status: ProgressStatus | keyof typeof ProgressStatus;
  overrideID?: string | null;
  activeID: string | null | undefined;
  version: string | null | undefined;
};

export const updateCategoryAccess = async (
  category: CategoryItem,
  isPublic?: boolean,
  keychain?: KeychainItem,
  override?: boolean
): Promise<Response> => {
  try {
    const dbItem = await DataStore.query(Category, category.uid);
    if (!dbItem) {
      return {
        type: ResponseType.Failure,
        data: 'Category does not exist',
      };
    }

    if (!override && keychain && dbItem.keychainID) {
      return {
        type: ResponseType.Failure,
        data: 'Category already has a keychain',
      };
    }

    let newItem = await DataStore.save(
      Category.copyOf(dbItem, (updated) => {
        updated.keychainID = keychain ? keychain.uid : null;
        updated.isPublic = isPublic ? isPublic : false;
      })
    );

    let newCategory = cloneCategory(category);
    newCategory.keychainID = keychain ? keychain.uid : null;
    newCategory.model = newItem;
    newCategory.isPublic = isPublic ? isPublic : false;

    return {
      type: ResponseType.Success,
      data: newCategory,
    };
  } catch (error: any) {
    return {
      type: ResponseType.Failure,
      data: error.message,
    };
  }
};

/**
 * This function will check if the category is a draft version and delete it
 * @param categoryItem The category to check
 * @returns Success if ready to create a new category or Failure if there is a draft version
 */
export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    if (globals.debug)
      if (globals.debug)
        console.log(
          'Checking if version already contains a DRAFT:',
          id,
          'IS ALREADT ACTIVE',
          isActive
        );
    let results: Category[];
    if (isActive)
      results = await DataStore.query(Category, (c) => c.activeID.eq(id));
    else
      results = await DataStore.query(Category, (c) =>
        c.and((c) => [c.status.eq('DRAFT'), c.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbCat = results[0];
    if (dbCat.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Category, dbCat.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The category did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbCat,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new category in the database and choose the version
 * @param category CategoryDB JSON format
 * @param currentID The current ID of the category (Use case: upgrading a draft version where there is NO active version yet)
 * @returns The successful CategoryItem or the error
 */
export const createCategory = async (
  category: CategoryJSON | CategoryItem,
  previousItem?: CategoryItem
): Promise<Response> => {
  try {
    let json: CategoryJSON;
    if (category instanceof CategoryItem) {
      json = {
        name: category.name,
        index: category.index,
        departmentID: category.departmentID,
        pairedDeps: category.pairedDepIDs != null ? category.pairedDepIDs : [],
        status: category.status,
        activeID: category.activeID,
        overrideID: category.overrideID,
        version: category.version ? category.version : 'v1.0.0',
        modifiedBy: category.modifiedBy
          ? category.modifiedBy.id
          : category.model.modifiedBy
            ? category.model.modifiedBy
            : undefined,
        createdBy: category.model.createdBy ? category.model.createdBy : '',
        isPublic: category.isPublic,
        isRestrictive: category.isRestrictive,
        keychainID: category.keychainID,
      };
    } else json = category;

    console.log('Creating Category:', json);

    let ids = [...json.pairedDeps];
    console.log('Paired Deps:', ids, json.departmentID);
    if (!ids.includes(json.departmentID)) ids.push(json.departmentID);

    console.log('Paired Deps:', ids);

    let c: Category;

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status === ProgressStatus.DRAFT &&
      json.status === ProgressStatus.DRAFT
    ) {
      let dbCat = await DataStore.query(Category, previousItem.uid);
      if (dbCat == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT category does not exist could not update',
        };
      }

      c = await DataStore.save(
        Category.copyOf(dbCat, (updated) => {
          updated.departmentID = json.departmentID;
          updated.name = json.name;
          updated.index = json.index;
          updated.pairedDepIDs = ids;
          updated.modifiedBy = json.modifiedBy;
          updated.isPublic = json.isPublic;
          updated.keychainID = json.keychainID;
          updated.overrideID = json.overrideID;
          updated.isRestrictive = json.isRestrictive;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a new Item */
      c = await DataStore.save(
        new Category({
          name: json.name,
          index: Number(json.index),
          departmentID: json.departmentID,
          pairedDepIDs: ids,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version,
          modifiedBy: json.modifiedBy,
          createdBy: json.createdBy,
          isPublic: json.isPublic != null ? json.isPublic : false,
          isRestrictive:
            json.isRestrictive != null ? json.isRestrictive : false,
          keychainID: json.keychainID,
        })
      );
    }

    if (globals.debug) console.log('Created category:', c);
    let catItem = new CategoryItem(c);
    return {
      type: ResponseType.Success,
      data: catItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the category to the database
 *    1. Create a new ARCHEIVED category based on the current ACTIVE category
 *    2. Update the ACTIVE category with the new information
 *    3. Delete the DRAFT category
 * @param draftCategoryItem The CategoryItem to publish
 * @returns The successful ACTIVE CategoryItem or the error
 */
export const publishCategory = async (
  draftCategoryItem: CategoryItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the category is configured correctly as a draft version */
    if (draftCategoryItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The category is not a draft version',
      };
    }

    /* Confirm the Category exists */
    let dbCat = await DataStore.query(Category, draftCategoryItem.uid);
    if (dbCat == null) {
      return {
        type: ResponseType.Failure,
        data: 'The category does not exist',
      };
    }

    let activeCategory: Category;

    /* Use case 1: Creating the FIRST active version */
    if (draftCategoryItem.activeID == null) {
      /* Update the draft category to be active */
      activeCategory = await DataStore.save(
        Category.copyOf(dbCat, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active category item */
      let id: string = draftCategoryItem.activeID;
      let curCategory = await DataStore.query(Category, id);

      /* Base Case 3 -- check if the active category exists */
      if (curCategory == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active category does not exist',
        };
      }

      let newArcheivedCategory = new CategoryItem(curCategory);
      newArcheivedCategory.status = ProgressStatus.ARCHIVE;
      newArcheivedCategory.activeID = curCategory.id;

      // 2. Create a new ARCHEIVED category based on the current ACTIVE category
      let archiveResult: Response = await createCategory(newArcheivedCategory);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      newArcheivedCategory = archiveResult.data as CategoryItem;

      // 2. Update the ACTIVE category with the new information
      activeCategory = await DataStore.save(
        Category.copyOf(curCategory, (updated) => {
          updated.name = draftCategoryItem.name;
          updated.index = draftCategoryItem.index;
          updated.pairedDepIDs = draftCategoryItem.pairedDepIDs;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftCategoryItem.version; //upgradeVersion(draftCategoryItem.version == null ? "v1.0.0" : draftCategoryItem.version);
          updated.modifiedBy = draftCategoryItem.model.modifiedBy;
          updated.isPublic = draftCategoryItem.model.isPublic;
          updated.createdBy = draftCategoryItem.model.createdBy;
          updated.isRestrictive = draftCategoryItem.model.isRestrictive;
          updated.keychainID = draftCategoryItem.model.keychainID
            ? draftCategoryItem.model.keychainID
            : null;
        })
      );

      // 3. Delete the DRAFT category
      let draftCategory = await DataStore.delete(
        Category,
        draftCategoryItem.uid
      );
      if (draftCategory == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft category does not exist',
        };
      }
    }

    let catItem = new CategoryItem(activeCategory);
    return {
      type: ResponseType.Success,
      data: catItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteCategory = async (
  department: DepartmentItem,
  categoryItem: CategoryItem,
  modifiedBy: User,
  isSoft: boolean,
  includeSubItems: boolean = false
): Promise<Response> => {
  try {
    let id: string = categoryItem.uid;
    if (isSoft) {
      let category = await DataStore.query(Category, id);
      if (category == null) {
        return {
          type: ResponseType.Failure,
          data: 'The category does not exist',
        };
      }

      let result = await DataStore.save(
        Category.copyOf(category, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The category did not update correctly',
        };
      }

      if (globals.debug) console.log('Soft Deleted category:', category);
    } else {
      let category = await DataStore.delete(Category, id);
      if (category == null) {
        return {
          type: ResponseType.Failure,
          data: 'The category does not exist',
        };
      }

      if (globals.debug) console.log('Hard Deleted category:', category);
    }

    if (includeSubItems) {
      for (let i = 0; i < categoryItem.protocols.length; i++) {
        let protocol = categoryItem.protocols[i];
        deleteProtocol(department, protocol, modifiedBy, isSoft, true)
          .then((response) => {
            if (response.type === ResponseType.Failure) {
              console.error('ERROR DELETING PROTOCOL:', response.data);
            }
          })
          .catch((error) => {
            console.error('ERROR DELETING PROTOCOL:', error);
          });
      }
      for (let i = 0; i < categoryItem.subCategories.length; i++) {
        let category = categoryItem.subCategories[i];
        deleteCategory(department, category, modifiedBy, isSoft, true)
          .then((response) => {
            if (response.type === ResponseType.Failure) {
              console.error('ERROR DELETING CATEGORY:', response.data);
            }
          })
          .catch((error) => {
            console.error('ERROR DELETING CATEGORY:', error);
          });
      }
    }
    return {
      type: ResponseType.Success,
      data: categoryItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isCategryDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Category, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchCategories = async (
  dep: DepartmentItem,
  waitForUsers: boolean = false
): Promise<Response> => {
  try {
    let start = new Date();
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);
    if (globals.debug) console.log('Department IDs:', depIDs);

    const categoriesList = await DataStore.query(Category, (c) =>
      c.and((c) => [
        c.or((c) => [
          c.and((c) => [
            c.isRestrictive.eq(false),
            c.or((c) => depIDs.map((id) => c.departmentID.contains(id))),
          ]),
          c.and((c) => [
            c.isRestrictive.eq(true),
            c.pairedDepIDs.contains(dep.id),
          ]),
        ]),
        c.and((c) => [c.status.ne('ARCHIVE'), c.status.ne('DELETED')]),
      ])
    );

    let categories: CategoryItem[] = [];
    // let userPromises = [];
    for (let i = 0; i < categoriesList.length; i++) {
      let category = new CategoryItem(categoriesList[i]);
      /* Take out the active version if there is one */
      mapModelItems(category, categories, category.status, dep);
    }
    if (waitForUsers) await findUsersForModelItems(categories);
    else findUsersForModelItems(categories);

    categories.sort((a, b) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });
    return {
      type: ResponseType.Success,
      data: categories,
    };
  } catch (error) {
    console.error('Error fetching categories:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getCategoryDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Category, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(db.department.id)])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = new CategoryItem(modelUpdates[i]);
      let activeItem = db.categories.find(
        (c) =>
          c.uid === model.activeID ||
          (c.activeItem && c.activeItem.uid === model.activeID)
      );
      if (activeItem) {
        model.activeItem =
          activeItem.uid === model.activeID
            ? activeItem
            : activeItem.activeItem;
      }
      updates.push({
        model: model,
        title: 'Folder ' + model.name,
        message: getChangeDescription(model),
        changeType: DraftChangeType.FOLDER,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertCategoryChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Folder ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as CategoryItem, true),
      changeType: DraftChangeType.FOLDER,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(
  draftItem: CategoryItem,
  isProtocolIndex: boolean = false
): string {
  if (isProtocolIndex) return `Reordered ${draftItem.name} Protocols`;
  else if (draftItem.activeItem == null)
    return `Created Folder: ${draftItem.name}`;
  else return `Updated ${draftItem.name}`;
}

export const duplicateCategory = async (
  department: DepartmentItem,
  category: CategoryItem,
  previousCategory: CategoryItem,
  user: User
): Promise<Response> => {
  try {
    let ids: string[] =
      category.pairedDepIDs != null ? category.pairedDepIDs : [];
    ids.push(category.departmentID);
    let json: CategoryJSON = {
      name: category.name,
      index: category.index,
      departmentID: category.departmentID,
      pairedDeps: ids,
      status: ProgressStatus.DRAFT,
      activeID: category.activeID,
      version: category.version,
      modifiedBy: undefined,
      createdBy: user.id,
      isPublic: category.isPublic,
      isRestrictive: category.isRestrictive,
      keychainID: category.keychainID,
    };

    let result = await createCategory(json);
    if (result.type === ResponseType.Failure) return result;
    let newCategory = result.data as CategoryItem;

    /* Add the previous categories associated protocols */
    let promises = [];
    for (let i = 0; i < previousCategory.protocols.length; i++) {
      let copyProtocol = previousCategory.protocols[i];
      let newProtocol = cloneProtocol(copyProtocol);
      newProtocol.createdBy = user;
      newProtocol.parent = newCategory;
      promises.push(
        duplicateProtocol(department, newProtocol, copyProtocol, user)
      );
    }

    let results = await Promise.all(promises);

    for (let i = 0; i < results.length; i++) {
      if (results[i].type === ResponseType.Failure) {
        console.error('ERROR CREATING PROTOCOL:', results[i].data);
        if (globals.debug)
          console.log('Protocol Data', previousCategory.protocols[i]);
      } else newCategory.addProtocol(results[i].data as ProtocolItem);
    }

    return {
      type: ResponseType.Success,
      data: newCategory,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const removeCurrentCategoryDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftCategories = await DataStore.query(Category, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(db.department.id)])
    );
    for (let i = 0; i < draftCategories.length; i++) {
      let cat: Category = draftCategories[i];
      await DataStore.delete(cat);
      updates.push({
        model: cat,
        message: `Removed Folder: ${cat.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getCategoryByID = async (
  db: DatabaseResponse,
  id: string
): Promise<CategoryItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbCat = findItemByID(id, db.categories);
      if (dbCat != null) return resolve(dbCat as CategoryItem);
      else {
        executeSingleQuery(getCategory, { id: id }, 1500)
          .then((category: Category | null | undefined) => {
            if (category == null) {
              resolve(null);
            } else {
              let cat = new CategoryItem(category);
              resolve(cat);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
