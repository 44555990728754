// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PwsType = {
  "PWS_A": "PWS_A",
  "PWS_B": "PWS_B",
  "PWS_C": "PWS_C",
  "PWS_D": "PWS_D"
};

const UserType = {
  "ADMIN": "ADMIN",
  "DEPT_ADMIN": "DEPT_ADMIN",
  "DEPT": "DEPT",
  "USER": "USER",
  "DEPT_SUPER_ADMIN": "DEPT_SUPER_ADMIN",
  "DEVELOPER": "DEVELOPER"
};

const ProgressStatus = {
  "DRAFT": "DRAFT",
  "ACTIVE": "ACTIVE",
  "ARCHIVE": "ARCHIVE",
  "DELETED": "DELETED",
  "DRAFT_DELETE": "DRAFT_DELETE",
  "DEACTIVATED": "DEACTIVATED"
};

const UserStatus = {
  "ACTIVE": "ACTIVE",
  "SUSPENDED": "SUSPENDED",
  "DELETED": "DELETED"
};

const FormType = {
  "SHORT_ANSWER": "SHORT_ANSWER",
  "LONG_ANSWER": "LONG_ANSWER",
  "DROPDOWN": "DROPDOWN",
  "SINGLE_SELECT": "SINGLE_SELECT",
  "MULTI_SELECT": "MULTI_SELECT",
  "DATE_ENTRY": "DATE_ENTRY",
  "NUMBER_ANSWER": "NUMBER_ANSWER",
  "TRUE_FALSE": "TRUE_FALSE"
};

const SoftwareType = {
  "ENTERPRISE": "ENTERPRISE",
  "PROFESSIONAL": "PROFESSIONAL",
  "STARTER": "STARTER",
  "PREMIUM": "PREMIUM",
  "PLUS": "PLUS",
  "ESSENTIALS": "ESSENTIALS",
  "SOFTWARE_ONLY": "SOFTWARE_ONLY",
  "VIEW_ONLY": "VIEW_ONLY"
};

const CalculatorType = {
  "PARKLAND": "PARKLAND",
  "RACE": "RACE",
  "RASS": "RASS",
  "APGAR_CALC": "APGAR_CALC",
  "CPSSS_CALC": "CPSSS_CALC",
  "ETT_DEPTH_TIDAL_VOL_CALC": "ETT_DEPTH_TIDAL_VOL_CALC",
  "GCS_CALC": "GCS_CALC",
  "IBW_ABW_CALC": "IBW_ABW_CALC",
  "PETT_CALC": "PETT_CALC",
  "PGCS_CALC": "PGCS_CALC"
};

const NotificationType = {
  "GENERAL": "GENERAL",
  "ACKNOWLEDGE": "ACKNOWLEDGE",
  "TRAINING": "TRAINING"
};

const DevNotifyType = {
  "FORCE_UPDATE": "FORCE_UPDATE",
  "GENERAL_UPDATE": "GENERAL_UPDATE"
};

const PatientAgeGroup = {
  "NEONATE": "NEONATE",
  "PEDIATRIC": "PEDIATRIC",
  "ADULT": "ADULT",
  "GERIATRIC": "GERIATRIC"
};

const GroupType = {
  "NOTIFICATION": "NOTIFICATION"
};

const PatientOutcomeType = {
  "SKIP": "SKIP",
  "ROSC_FIELD": "ROSC_FIELD",
  "ROSC_ED": "ROSC_ED",
  "RESUSC_OTHER_TEAM": "RESUSC_OTHER_TEAM",
  "RESUSC_OUR_TEAM": "RESUSC_OUR_TEAM"
};

const AckStatus = {
  "DRAFT": "DRAFT",
  "PENDING": "PENDING",
  "APPROVED": "APPROVED",
  "REJECTED": "REJECTED",
  "CLOSED": "CLOSED",
  "PUBLISHED": "PUBLISHED",
  "VIEWED": "VIEWED"
};

const { Department, Keychain, Contact, FormLog, InputForm, Drip, InfusionDose, Equipment, Medication, MedicationDose, Protocol, Ambulance, User, Category, ElectricalShock, ElectricalDose, Vitals, Form, Workbook, WeightObject, Concentration, CPRAssist, Invite, LogElectrical, CPRMetaData, FormQuestion, FormResponse, AdultRange, Question, LogCPR, LogEMSCalculator, LogMedication, LogDrip, LogEquipment, LogForm, LogWeightObject, LogEvent, LogMedicShift, ElectricalRange, ElectricalShockRange, ElectricalOption, ElectricalShockOption, VitalOption, ModelMetaData, MedicationRoute, MedicationProtocol, MedicationConcentration, MedicationRange, EquipmentOption, FormOption, FormGroup, DepartmentConfig } = initSchema(schema);

export {
  Department,
  Keychain,
  Contact,
  FormLog,
  InputForm,
  Drip,
  InfusionDose,
  Equipment,
  Medication,
  MedicationDose,
  Protocol,
  Ambulance,
  User,
  Category,
  ElectricalShock,
  ElectricalDose,
  Vitals,
  Form,
  Workbook,
  WeightObject,
  Concentration,
  CPRAssist,
  PwsType,
  UserType,
  ProgressStatus,
  UserStatus,
  FormType,
  SoftwareType,
  CalculatorType,
  NotificationType,
  DevNotifyType,
  PatientAgeGroup,
  GroupType,
  PatientOutcomeType,
  AckStatus,
  Invite,
  LogElectrical,
  CPRMetaData,
  FormQuestion,
  FormResponse,
  AdultRange,
  Question,
  LogCPR,
  LogEMSCalculator,
  LogMedication,
  LogDrip,
  LogEquipment,
  LogForm,
  LogWeightObject,
  LogEvent,
  LogMedicShift,
  ElectricalRange,
  ElectricalShockRange,
  ElectricalOption,
  ElectricalShockOption,
  VitalOption,
  ModelMetaData,
  MedicationRoute,
  MedicationProtocol,
  MedicationConcentration,
  MedicationRange,
  EquipmentOption,
  FormOption,
  FormGroup,
  DepartmentConfig
};