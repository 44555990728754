import { graphqlOperation } from 'aws-amplify';
import {
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
  Response,
  ResponseType,
} from '../AmplifyDB';
import ReviewalItem from '../model/ReviewalItem';
import {
  acknowledgesByOwnerID,
  commentsByOwnerID,
  draftChangesByChangeID,
  draftChangesByDepartmentID,
  draftChangesByDraftGroupID,
  getReviewal,
  reviewalsByDepartmentID,
} from '../../graphql/queries';
import {
  DraftChange,
  DraftGroup,
  Acknowledge,
  Reviewal,
  UserComment,
  UpdateReviewalInput,
  LogEventInput,
  UpdateDraftGroupInput,
  UpdateDraftChangeInput,
} from '../../API';
import AcknowledgeItem from '../model/AcknowledgeItem';
import UserCommentItem from '../model/UserCommentItem';
import DraftGroupItem from '../model/DraftGroupItem';
import DraftChangeItem, {
  DraftChangeType,
  getChangeType,
} from '../model/DraftChangeItem';
import { getProtocolByID } from './ProtocolDB';
import { getCategoryByID } from './CategoryDB';
import { getMedicationByID, getMedicationDoseByID } from './MedicationDB';
import { getInfusionByID, getInfusionDoseByID } from './InfusionDB';
import { getElectricalByID, getElectricalDoseByID } from './ElectricalDB';
import { getEquipmentByID } from './EquipmentDB';
import {
  ACKStatus,
  CreateAcknowledgeInput,
  CreateDraftChangeInput,
  CreateDraftGroupInput,
  CreateReviewalInput,
  CreateUserCommentInput,
  DeleteAcknowledgeInput,
  DeleteDraftChangeInput,
  DeleteDraftGroupInput,
  DeleteReviewalInput,
  DeleteUserCommentInput,
  UpdateAcknowledgeInput,
  UpdateUserCommentInput,
} from '../../API';
import {
  createAcknowledge,
  createDraftChange,
  createDraftGroup,
  createReviewal,
  createUserComment,
  deleteAcknowledge,
  deleteDraftChange,
  deleteDraftGroup,
  deleteReviewal,
  deleteUserComment,
  updateAcknowledge,
  updateDraftChange,
  updateDraftGroup,
  updateReviewal,
  updateUserComment,
} from '../../graphql/mutations';
import ModelItem from '../model/ModelItem';
import ModelInterface from '../model/ModelInterface';
import { User, LogEvent } from '../../models';
import { UserType } from '../../models';
import { globals } from '../../ui/_global/common/Utils';
import MedicationItem from '../model/MedicationItem';
import { cloneMedicationSubItem } from '../model/MedicationSubItem';
import ProtocolItem from '../model/ProtocolItem';
import CategoryItem from '../model/CategoryItem';
import ModelSubItem from '../model/ModelSubItem';
import { getVitalByID } from './VitalDB';
import { getChecklistByID } from './CheckListDB';
import { Dispatch } from 'react';

export type ReviewalJSON = {
  previousItem?: ReviewalItem | null;
  title: string;
  description?: string | null;
  reviewers: User[];
  user: User;
  draftGroups?: DraftGroupJSON[];
  acknowledgements?: AcknowledgeItem[];
  events?: LogEvent[];
  comments?: UserCommentItem[];
  status?: ACKStatus;
  createdAt?: Date | null;
  endedAt?: Date | null;
};

export type DraftGroupJSON = {
  previousItem?: DraftGroupItem | null;
  title: string;
  description?: string | null;
  createdBy: User;
  draftChanges?: DraftChangeJSON[];
  createdAt?: Date | null;
};

export type DraftChangeJSON = {
  previousDraftChange?: DraftChangeItem | DraftChange | null;
  changeType: string;
  changeItem: ModelItem<any> | ModelSubItem<any> | string;
  previousItem?: ModelItem<any> | ModelSubItem<any> | string | null;
  isClosed?: boolean;
};

export type UserCommentJSON = {
  previousItem?: UserCommentItem | null;
  owner: ModelItem<any>;
  user: User;
  message: string;
  relatedItems: ModelItem<any>[] | ModelSubItem<any>[];
};

export type AcknowledgeJSON = {
  previousItem?: AcknowledgeItem | null;
  user: User;
  reviewal: ReviewalItem;
  owner: ModelItem<any> | ModelSubItem<any>;
  state: ACKStatus;
  isArchived?: boolean;
};

/* ------------------ GRAPH QL API QUEIRES ------------------ */
export const getReviewals = async (
  db: DatabaseResponse,
  user: User
): Promise<ReviewalItem[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      let filter =
        user.type === UserType.ADMIN
          ? {
              _deleted: { ne: true },
            }
          : {
              and: {
                _deleted: { ne: true },
                or: [
                  { userID: { eq: user.id } },
                  { reviewers: { contains: user.id } },
                  { status: { eq: ACKStatus.PUBLISHED } },
                  { status: { eq: ACKStatus.CLOSED } },
                ],
              },
            };
      const reviewalData = await executeQuery(reviewalsByDepartmentID, {
        departmentID: db.department.id,
        sortDirection: 'DESC',
        filter: filter,
      });

      if (reviewalData.length === 0) resolve([]);
      else {
        let reviewals: ReviewalItem[] = [];
        for (let reviewal of reviewalData) {
          let reviewalItem = new ReviewalItem(reviewal, db);
          if (user.type === UserType.ADMIN) reviewals.push(reviewalItem);
          else if (
            reviewalItem.state === ACKStatus.DRAFT &&
            reviewalItem.user.id === user.id
          )
            reviewals.push(reviewalItem);
          else if (reviewalItem.state !== ACKStatus.DRAFT)
            reviewals.push(reviewalItem);
        }
        resolve(reviewals);
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

export const getFullReviewal = async (
  db: DatabaseResponse,
  reviewal: ReviewalItem,
  user: User,
  dispatch: Dispatch<any>,
  reloadEverything = false,
  waitForAll = false
): Promise<ReviewalItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      let promises = [];
      if (reloadEverything) {
        let resp = await executeSingleQuery(getReviewal, { id: reviewal.uid });
        if (resp) reviewal = new ReviewalItem(resp, db);
      }

      promises.push(findUserComments(db, reviewal));
      for (let review of reviewal.acknowledgements)
        promises.push(findUserComments(db, review));
      for (let dg of reviewal.draftGroups)
        promises.push(findDraftChanges(db, reviewal, dg, user, waitForAll));

      await Promise.all(promises);

      reviewal.isFullyLoaded = true;
      resolve(reviewal);
    } catch (error: any) {
      reject(error);
    }
  });
};

export const handleLoadReviewAndNavigate = async (
  db: DatabaseResponse,
  change: DraftChangeItem,
  user: User,
  dispatch: Dispatch<any>,
  navigate: any
): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Confirm the user is tagged in the reviewal otherwise do nothing */
      let reviewal = change.reviewal;
      if (
        !reviewal.model.reviewers.some((userID) => userID === user.id) &&
        reviewal.model.userID !== user.id
      ) {
        return reject('User is not tagged in the reviewal');
      }
      let response: ReviewalItem | null = await getFullReviewal(
        db,
        change.reviewal,
        user,
        dispatch,
        true,
        true
      );
      if (response) {
        navigate('/reviewChanges', {
          state: {
            data: response,
            activeDraftChange: change,
          },
        });
        return resolve(true);
      }
      return reject('Failed to load review and navigate');
    } catch (error) {
      reject(error);
    }
  });
};

export const findDraftChanges = async (
  db: DatabaseResponse,
  reviewal: ReviewalItem,
  draftGroup: DraftGroupItem,
  user: User,
  waitForAll = false
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let promises: any[] = [];
      const draftChangeData = await executeQuery(draftChangesByDraftGroupID, {
        draftGroupID: draftGroup.uid,
        filter: {
          _deleted: { ne: true },
        },
      });
      let draftChanges: DraftChangeItem[] = [];
      for (let dc of draftChangeData) {
        let draftChange = new DraftChangeItem(
          reviewal,
          draftGroup,
          dc,
          db,
          user
        );
        if (draftChange.reviewACK)
          promises.push(findUserComments(db, draftChange.reviewACK));

        if (
          draftChange.acknowledgements &&
          draftChange.acknowledgements.length > 0
        ) {
          for (let ack of draftChange.acknowledgements) {
            promises.push(findUserComments(db, ack));
          }
        }

        let type: DraftChangeType = getChangeType(dc.changeType);
        try {
          let changeItem;
          let _function:
            | ((db: DatabaseResponse, id: string) => Promise<any>)
            | null = null;
          switch (type) {
            case DraftChangeType.MEDICATION_INDEX:
            case DraftChangeType.PROTOCOL_PDF:
            case DraftChangeType.PROTOCOL:
              _function = getProtocolByID;
              break;
            case DraftChangeType.FOLDER:
            case DraftChangeType.PROTOCOL_INDEX:
              _function = getCategoryByID;
              break;
            case DraftChangeType.MEDICATION:
              _function = getMedicationByID;
              break;
            case DraftChangeType.MEDICATION_DOSE:
              _function = getMedicationDoseByID;
              break;
            case DraftChangeType.INFUSION:
              _function = getInfusionByID;
              break;
            case DraftChangeType.INFUSION_DOSE:
              _function = getInfusionDoseByID;
              break;
            case DraftChangeType.ELECTRICAL:
              _function = getElectricalByID;
              break;
            case DraftChangeType.ELECTRICAL_SHOCK:
              _function = getElectricalDoseByID;
              break;
            case DraftChangeType.EQUIPMENT:
              _function = getEquipmentByID;
              break;
            case DraftChangeType.VITAL:
              _function = getVitalByID;
              break;
            case DraftChangeType.CHECKLIST:
              _function = getChecklistByID;
              break;
            default:
              throw new Error(
                'Unknown change type for findDraftChanges. Notify Colton'
              );
          }
          if (_function != null) {
            changeItem = await _function(db, dc.changeID);
            draftChange.changeItem = changeItem;
            if (dc.previousID) {
              promises.push(
                _function(db, dc.previousID)
                  .then((previousItem) => {
                    draftChange.previousItem = previousItem;
                  })
                  .catch((error) => {
                    console.error('Failed to get previous item', error);
                  })
              );
            }
          }
        } catch (error) {
          console.error('Failed to get change item', error);
          continue;
        }
        draftChanges.push(draftChange);
      }

      /* Sort the draft changes by change type */
      draftGroup.draftChanges = draftChanges.sort((a, b) => {
        return a.changeType.localeCompare(b.changeType);
      });

      if (waitForAll) await Promise.all(promises);

      resolve({
        type: ResponseType.Success,
        data: draftChanges,
      });
    } catch (error: any) {
      reject(error);
    }
  });
};

export const getDraftChangesByDepartment = async (
  db: DatabaseResponse,
  addClosed = false
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      const draftChangeData = await executeQuery(
        draftChangesByDepartmentID,
        {
          departmentID: db.department.id,
          filter: {
            and: [{ _deleted: { ne: true } }, { isClosed: { ne: true } }],
          },
          sortDirection: 'DESC',
        },
        {
          timeout: 10000,
        }
      );

      let drafts: DraftChange[] = [];
      for (let dc of draftChangeData) {
        // let draftChange = new DraftChangeItem(undefined, dc, db);
        drafts.push(dc);
      }

      resolve({
        type: ResponseType.Success,
        data: drafts,
      });
    } catch (error: any) {
      reject(error);
    }
  });
};

/**
 * Get all draft changes by the change ID
 * @param id The ACTIVE ID of the change that is being searched for
 * @param isClosed Use Closed status for the filter. Default is true
 * @returns Promise<Response> data: DraftChangeItem[]
 */
export const getDraftChangesByChangeID = async (
  id: string,
  isClosed: boolean = true
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let filter = isClosed
        ? {
            and: [{ _deleted: { ne: true } }, { isClosed: { eq: true } }],
          }
        : { _deleted: { ne: true } };
      const draftChangeData = await executeQuery(draftChangesByChangeID, {
        changeID: id,
        filter: filter,
      });
      let drafts: DraftChange[] = [];
      for (let dc of draftChangeData) {
        // let draftChange = new DraftChangeItem(undefined, dc);
        drafts.push(dc);
      }

      resolve({
        type: ResponseType.Success,
        data: drafts,
      });
    } catch (error: any) {
      reject(error);
    }
  });
};

export const findReviewACKs = async (
  db: DatabaseResponse,
  reviewal: ReviewalItem,
  owner: ReviewalItem | DraftChangeItem
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      const reviewData = await executeQuery(acknowledgesByOwnerID, {
        ownerID: owner.uid,
        sortDirection: 'DESC',
        filter: {
          _deleted: { ne: true },
        },
      });
      let reviewACKs: AcknowledgeItem[] = [];
      for (let review of reviewData) {
        let userOwner = db.users.find((u) => u.id === review.userID);
        if (!userOwner) {
          console.error('Owner not found for review', review);
          continue;
        }
        let reviewACK = new AcknowledgeItem(review, owner, userOwner, reviewal);
        reviewACKs.push(reviewACK);
      }
      if (owner.TAG === 'DraftChangeItem') {
        (owner as DraftChangeItem).setAcknowledgements(reviewACKs);
      } else owner.acknowledgements = reviewACKs;

      resolve({
        type: ResponseType.Success,
        data: reviewACKs,
      });
    } catch (error: any) {
      reject(error);
    }
  });
};

export const findUserComments = async (
  db: DatabaseResponse,
  item: ReviewalItem | DraftChangeItem | AcknowledgeItem
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      const reviewData = await executeQuery(commentsByOwnerID, {
        ownerID: item.uid,
        sortDirection: 'DESC',
        filter: {
          _deleted: { ne: true },
        },
      });
      let userComments: UserCommentItem[] = [];
      for (let comment of reviewData) {
        let user = db.users.find((u) => u.id === comment.userID);
        if (!user) {
          console.error('User not found comment', comment);
          continue;
        }
        if (comment.relatedItems.length > 0) {
          //TODO Search for related items
        }
        let userComment = new UserCommentItem(comment, item, user, []);
        userComments.push(userComment);
      }

      if (userComments.length > 0) {
        if (item.TAG === 'AcknowledgeItem')
          (item as AcknowledgeItem).comment = userComments[0];
        else (item as ReviewalItem | DraftChangeItem).comments = userComments;
      }

      resolve({
        type: ResponseType.Success,
        data: userComments,
      });
    } catch (error: any) {
      reject(error);
    }
  });
};

/* ------------------ GRAPH QL API CREATIONS ------------------ */

export const createReviewalProcess = async (
  db: DatabaseResponse,
  reviewal: ReviewalJSON,
  lazyLoad = true
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let json: CreateReviewalInput | UpdateReviewalInput;
      if (reviewal.previousItem) {
        let events: LogEventInput[] | undefined =
          reviewal.events == null
            ? undefined
            : reviewal.events.map((e) => {
                return {
                  title: e.title,
                  description: e.description,
                  timestamp: e.timestamp,
                  icon: e.icon,
                  color: e.color,
                };
              });

        json = {
          id: reviewal.previousItem.uid,
          _version: reviewal.previousItem.any_model._version,
          title: reviewal.title,
          description: reviewal.description,
          reviewers: reviewal.reviewers
            ? [...new Set(reviewal.reviewers.map((r) => r.id))]
            : undefined,
          status: reviewal.status ? reviewal.status : ACKStatus.DRAFT,
          userID: reviewal.user.id,
          departmentID: db.department.id,
          events: events,
          createdAt: reviewal.createdAt
            ? reviewal.createdAt.toISOString()
            : undefined,
          endedAt: reviewal.endedAt
            ? reviewal.endedAt.toISOString()
            : undefined,
        };
      } else {
        json = {
          title: reviewal.title,
          description: reviewal.description,
          reviewers: reviewal.reviewers?.map((r) => r.id),
          userID: reviewal.user.id,
          status: reviewal.status ? reviewal.status : ACKStatus.DRAFT,
          departmentID: db.department.id,
          events: reviewal.events ? reviewal.events : [],
        };
      }
      if (globals.debug)
        console.log(
          (reviewal.previousItem ? 'Update' : 'Create') + ' reviewal',
          json
        );

      const response = await executeSingleQuery(
        reviewal.previousItem ? updateReviewal : createReviewal,
        {
          input: json,
        }
      );
      if (response) {
        let reviewalDB: Reviewal = response;
        let reviewalItem = new ReviewalItem(reviewalDB, db);
        if (reviewal.draftGroups) {
          if (lazyLoad)
            createDraftGroupForReviewal(db, reviewalItem, reviewal.draftGroups);
          else
            await createDraftGroupForReviewal(
              db,
              reviewalItem,
              reviewal.draftGroups
            );
        }
        resolve({
          type: ResponseType.Success,
          data: reviewalItem,
        });
      } else {
        console.error('Failed to create reviewal', response);
        resolve({
          type: ResponseType.Failure,
          data: null,
        });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

// export const editReviewalProcess = async (

export const createDraftGroupForReviewal = async (
  db: DatabaseResponse,
  reviewal: ReviewalItem,
  draftGroups: DraftGroupJSON[]
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      const createDraftGroupWithChanges = async (
        json: CreateDraftGroupInput | UpdateDraftGroupInput,
        dg: DraftGroupJSON
      ): Promise<DraftGroupItem | null> => {
        return new Promise(async (resolve, reject) => {
          executeSingleQuery(
            dg.previousItem ? updateDraftGroup : createDraftGroup,
            {
              input: json,
            }
          )
            .then((response) => {
              if (response) {
                let draftGroup: DraftGroup = response;
                let draftGroupItem = new DraftGroupItem(draftGroup, db);
                if (globals.debug)
                  console.log(
                    (dg.previousItem ? 'Update' : 'Create') + ' draft group',
                    draftGroupItem.title,
                    'adding changes to it',
                    dg.draftChanges
                  );
                if (dg.draftChanges)
                  createDraftChangesForDraftGroup(
                    db,
                    reviewal,
                    draftGroupItem,
                    dg.draftChanges
                  )
                    .then((response) => resolve(draftGroupItem))
                    .catch((error) => reject(error));
                else resolve(draftGroupItem);
              } else {
                console.error('Failed to create draft group', response);
                resolve(null);
              }
            })
            .catch((error) => reject(error));
        });
      };

      let promises = [];
      for (let dg of draftGroups) {
        let json: CreateDraftGroupInput | UpdateDraftGroupInput;
        if (dg.previousItem) {
          json = {
            id: dg.previousItem.uid,
            _version: dg.previousItem.anyModel._version,
            reviewalID: reviewal.uid,
            ownerID: dg.createdBy.id,
            title: dg.title,
            description: dg.description,
          } as UpdateDraftGroupInput;
        } else {
          json = {
            reviewalID: reviewal.uid,
            ownerID: dg.createdBy.id,
            title: dg.title,
            description: dg.description,
            departmentID: db.department.id,
            isClosed: false,
          } as CreateDraftGroupInput;
        }

        promises.push(createDraftGroupWithChanges(json, dg));
      }
      let responses = await Promise.all(promises);
      let draftGroupItems: DraftGroupItem[] = [];
      for (let response of responses) {
        if (response) draftGroupItems.push(response);
      }
      reviewal.draftGroups = draftGroupItems;
      resolve({
        type: ResponseType.Success,
        data: draftGroupItems,
      });
    } catch (error: any) {
      reject(error);
    }
  });
};

export const createDraftChangesForDraftGroup = async (
  db: DatabaseResponse,
  reviewal: ReviewalItem,
  dg: DraftGroupItem,
  draftChanges: DraftChangeJSON[]
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let promises = [];
      for (let dc of draftChanges) {
        let json: CreateDraftChangeInput = {
          draftGroupID: dg.uid,
          changeType: dc.changeType,
          changeID:
            dc.changeItem instanceof ModelItem ||
            dc.changeItem instanceof ModelSubItem
              ? dc.changeItem.uid
              : dc.changeItem,
          previousID:
            dc.previousItem instanceof ModelItem ||
            dc.previousItem instanceof ModelSubItem
              ? dc.previousItem.uid
              : dc.previousItem,
          isClosed: false,
          departmentID: db.department.id,
        };

        promises.push(
          executeSingleQuery(createDraftChange, {
            input: json,
          })
        );
      }
      let responses = await Promise.all(promises);
      let draftChangeItems: DraftChangeItem[] = [];
      for (let response of responses) {
        let dc = draftChanges[responses.indexOf(response)];
        if (response) {
          let draftChange: DraftChange = response;
          draftChangeItems.push(
            new DraftChangeItem(reviewal, dg, draftChange, db)
          );
        } else {
          console.error('Failed to create draft change', response);
        }
      }

      dg.draftChanges = draftChangeItems;
      resolve({
        type: ResponseType.Success,
        data: draftChangeItems,
      });
    } catch (error: any) {
      reject(error);
    }
  });
};

/**
 * Update the draft change item
 * @param dc The draft change JSON to update
 * @returns Promise<Response> data: DraftChangeItem
 */
export const updateDraftChangeItem = async (
  dc: DraftChangeJSON
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (dc.previousDraftChange == null)
        return reject('No previous draft change to update');

      let id =
        dc.previousDraftChange instanceof DraftChangeItem
          ? dc.previousDraftChange.uid
          : dc.previousDraftChange.id;
      let version =
        dc.previousDraftChange instanceof DraftChangeItem
          ? dc.previousDraftChange.anyModel._version
          : dc.previousDraftChange._version;

      let json: UpdateDraftChangeInput = {
        id: id,
        _version: version,
        changeID:
          dc.changeItem instanceof ModelItem ||
          dc.changeItem instanceof ModelSubItem
            ? dc.changeItem.uid
            : dc.changeItem,
        previousID:
          dc.previousItem instanceof ModelItem ||
          dc.previousItem instanceof ModelSubItem
            ? dc.previousItem.uid
            : dc.previousItem,
        isClosed: dc.isClosed != null ? dc.isClosed : undefined,
      };

      let response = await executeSingleQuery(updateDraftChange, {
        input: json,
      });

      if (response) {
        let draftChange: DraftChange = response;

        resolve({
          type: ResponseType.Success,
          data: draftChange,
        });
      } else {
        console.error('Failed to update draft change', response);
        resolve({
          type: ResponseType.Failure,
          data: null,
        });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};

/**
 * Create/Update a UserCommentItem for the ReviewalItem, DraftChangeItem or AcknowledgeItem
 * @param userComment UserCommentJSON
 * @returns Promise<Response> data: UserCommentItem
 */
export const createUserCommentItem = async (
  userComment: UserCommentJSON
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let json: CreateUserCommentInput | UpdateUserCommentInput;
      if (userComment.previousItem) {
        json = {
          id: userComment.previousItem.uid,
          _version: userComment.previousItem.anyModel._version,
          message: userComment.message,
        };
      } else {
        json = {
          ownerID: userComment.owner.uid,
          userID: userComment.user.id,
          message: userComment.message,
          relatedItems: userComment.relatedItems.map((ri) => ri.uid),
        };
      }
      const response = await executeSingleQuery(
        userComment.previousItem ? updateUserComment : createUserComment,
        {
          input: json,
        }
      );
      if (response) {
        let uc: UserComment = response;
        let userCommentItem = new UserCommentItem(
          uc,
          userComment.owner,
          userComment.user,
          []
        );
        resolve({
          type: ResponseType.Success,
          data: userCommentItem,
        });
      } else {
        console.error('Failed to create/update user comment', response);
        resolve({
          type: ResponseType.Failure,
          data: null,
        });
      }
    } catch (error: any) {
      resolve({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

/**
 * Create/Update an AcknowledgeItem for the ReviewalItem or DraftChangeItem
 * @param ackJSON AcknowledgeJSON
 * @returns Promise<Response> data: AcknowledgeItem
 */
export const createAcknowledgeItem = async (
  ackJSON: AcknowledgeJSON
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let json: CreateAcknowledgeInput | UpdateAcknowledgeInput;
      if (ackJSON.previousItem) {
        json = {
          id: ackJSON.previousItem.uid,
          _version: ackJSON.previousItem.anyModel._version,
          status: ackJSON.state,
          reviewalID: ackJSON.reviewal.uid,
          isArchived:
            ackJSON.isArchived == null
              ? ackJSON.previousItem.isArchived
              : ackJSON.isArchived,
        };
      } else {
        json = {
          ownerID: ackJSON.owner.uid,
          userID: ackJSON.user.id,
          status: ackJSON.state,
          reviewalID: ackJSON.reviewal.uid,
          isArchived: ackJSON.isArchived,
        };
      }

      const response = await executeSingleQuery(
        ackJSON.previousItem ? updateAcknowledge : createAcknowledge,
        {
          input: json,
        }
      );
      if (response) {
        let ack: Acknowledge = response;
        let ackItem = new AcknowledgeItem(
          ack,
          ackJSON.owner,
          ackJSON.user,
          ackJSON.reviewal
        );
        resolve({
          type: ResponseType.Success,
          data: ackItem,
        });
      } else {
        console.error('Failed to create/update review ack', response);
        resolve({
          type: ResponseType.Failure,
          data: null,
        });
      }
    } catch (error: any) {
      resolve({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

/* ------------------ GRAPH QL API DELETIONS ------------------ */
export const deleteReviewalProcess = async (
  reviewalItem: ReviewalItem,
  includeOtherItems = true
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let _version = reviewalItem.any_model._version;
      const input: DeleteReviewalInput = {
        id: reviewalItem.uid,
        _version: _version,
      };
      const response = await executeSingleQuery(deleteReviewal, {
        input: input,
      });
      if (response) {
        let reviewal: Reviewal = response;
        if (includeOtherItems) {
          let promises = [];
          for (let dg of reviewalItem.draftGroups)
            promises.push(deleteDraftGroupItem(dg));

          for (let ack of reviewalItem.acknowledgements)
            promises.push(deleteAcknowledgeItem(ack));

          for (let comment of reviewalItem.comments)
            promises.push(deleteUserCommentItem(comment));

          await Promise.all(promises);
        }

        resolve({
          type: ResponseType.Success,
          data: reviewal,
        });
      } else {
        console.error('Failed to delete reviewal', response);
        resolve({
          type: ResponseType.Failure,
          data: null,
        });
      }
    } catch (error: any) {
      resolve({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

export const deleteDraftGroupItem = async (
  draftGroup: DraftGroupItem,
  includeOtherItems = true
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let _version = draftGroup.anyModel._version;
      const input: DeleteDraftGroupInput = {
        id: draftGroup.uid,
        _version: _version,
      };
      const response = await executeSingleQuery(deleteDraftGroup, {
        input: input,
      });
      if (response) {
        let dg: DraftGroup = response;
        if (includeOtherItems) {
          let promises = [];
          for (let dc of draftGroup.draftChanges)
            promises.push(deleteDraftChangeItem(dc));
          await Promise.all(promises);
        }
        resolve({
          type: ResponseType.Success,
          data: dg,
        });
      } else {
        console.error('Failed to delete draft group', response);
        resolve({
          type: ResponseType.Failure,
          data: null,
        });
      }
    } catch (error: any) {
      resolve({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

export const deleteDraftChangeItem = async (
  draftChange: DraftChangeItem,
  includeOtherItems = true
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let _version = draftChange.anyModel._version;
      const input: DeleteDraftChangeInput = {
        id: draftChange.uid,
        _version: _version,
      };
      const response = await executeSingleQuery(deleteDraftChange, {
        input: input,
      });
      if (response) {
        let dc: DraftChange = response;
        if (includeOtherItems) {
          let promises = [];
          // for (let ack of draftChange.allAcknowledgements)
          //   promises.push(deleteReviewACKItem(ack));
          for (let comment of draftChange.comments)
            promises.push(deleteUserCommentItem(comment));
          await Promise.all(promises);
        }
        resolve({
          type: ResponseType.Success,
          data: dc,
        });
      } else {
        console.error('Failed to delete draft change', response);
        resolve({
          type: ResponseType.Failure,
          data: null,
        });
      }
    } catch (error: any) {
      resolve({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

export const deleteUserCommentItem = async (
  userComment: UserCommentItem
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let _version = userComment.anyModel._version;
      const input: DeleteUserCommentInput = {
        id: userComment.uid,
        _version: _version,
      };
      const response = await executeSingleQuery(deleteUserComment, {
        input: input,
      });
      if (response) {
        let userComment: UserCommentItem = response;
        resolve({
          type: ResponseType.Success,
          data: userComment,
        });
      } else {
        console.error('Failed to delete user comment', response);
        resolve({
          type: ResponseType.Failure,
          data: null,
        });
      }
    } catch (error: any) {
      resolve({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

export const deleteAcknowledgeItem = async (
  ackItem: AcknowledgeItem,
  includeOtherItems = true
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let _version = ackItem.anyModel._version;
      const input: DeleteAcknowledgeInput = {
        id: ackItem.uid,
        _version: _version,
      };
      const response = await executeSingleQuery(deleteAcknowledge, {
        input: input,
      });
      if (response) {
        let ack: Acknowledge = response;
        if (includeOtherItems && ackItem.comment) {
          deleteUserCommentItem(ackItem.comment);
        }

        resolve({
          type: ResponseType.Success,
          data: ack,
        });
      } else {
        console.error('Failed to delete acknowledgement', response);
        resolve({
          type: ResponseType.Failure,
          data: null,
        });
      }
    } catch (error: any) {
      resolve({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};
