import { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import { BiCopy, BiExit, BiRename, BiSolidCopy } from 'react-icons/bi';
import { InputSwitch } from 'primereact/inputswitch';
import {
  USAStatesAbrev,
  generateDepartmentURL,
  getDepartmentsFromState,
  getFormattedDate,
  handleCopy,
  hasAdminUserAccess,
  toTitleCase,
} from '../../../_global/common/Utils';
import {
  encrypt,
  getHashedPin,
  getSalt,
} from '../../../_global/common/Encrypt';
import Dropdown from '../../../components/Dropdown/Dropdown';
import {
  DatabaseResponse,
  deleteDepartment,
  outputAllDataStoreValues,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { User } from '../../../../models';
import { PiPencil } from 'react-icons/pi';
import ResetPinModal from './ResetPinModal';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';
import { FaInfoCircle } from 'react-icons/fa';
import { Menu, Tooltip } from '@mui/material';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import EditAccessModal from '../../../components/Modal/EditAccessModal';
import CategoryItem from '../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { RadioButton } from 'primereact/radiobutton';
import { IoSend } from 'react-icons/io5';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import SearchBar from '../../../components/Search/SearchBar';
import ShareURLModal from '../../../components/Modal/ShareURLModal';
import CopyToClipboard from '../../../components/CopyToClipboard';

interface DepartmentProps {
  department: DepartmentItem;
  formik: any;
  isEditMode: boolean;
  logo: File | null;
  setLogo: any;
  setDepartment: any;
  parentDepartment: DepartmentItem;
}

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const EditDepartmentPage = (props: DepartmentProps) => {
  const {
    parentDepartment,
    department,
    formik,
    isEditMode,
    logo,
    setLogo,
    setDepartment,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const reducerState = useSelector((state: any) => state.department);
  const parentAllSubDeps = useMemo(
    () => getDepartmentsFromState(parentDepartment, reducerState).allSubDeps,
    [parentDepartment, reducerState]
  );
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [pin, setPin] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(isEditMode);
  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const [isAccessVisible, setAccessVisible] = useState<boolean>(false);

  const user: User = useSelector((state: any) => state?.user);
  const dropRef = useRef(null);
  const [isSubDepChoiceOpen, setIsSubDepChoiceOpen] = useState(false);
  const divRef = useRef<HTMLUListElement>(null);
  const [activeDepartment, setActiveDepartment] =
    useState<DepartmentItem>(department);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [isResetPinModal, setIsResetPinModal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [gttsInput, setGttsInput] = useState<string>('');
  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(activeDepartment, reducerState, user);
  }, [user, activeDepartment, reducerState]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debugLoadingSubDeps, setDebugLoadingSubDeps] = useState<boolean>(
    localStorage.getItem('debugLoadingSubDeps') === 'true'
  );
  const [debugLoadingUsers, setDebugLoadingUsers] = useState<boolean>(
    localStorage.getItem('debugLoadingUsers') === 'true'
  );
  const [isShareURLModalVisible, setIsShareURLModalVisible] = useState(false);
  // const [options, setOptions] = useState<any[] | null>(
  //   activeDepartment.subDeps ? [] :
  // );

  // useEffect(() => {
  //   setActiveDepartment(activeDepartment);
  // }, [activeDepartment]);

  useEffect(() => {
    setDebugLoadingSubDeps(
      localStorage.getItem('debugLoadingSubDeps') === 'true'
    );
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('DEPARTMENT', activeDepartment);
      } else if (event.ctrlKey && event.shiftKey && event.key === 'L') {
        outputAllDataStoreValues();
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [formik, activeDepartment]);

  const options: any[] | null = useMemo(() => {
    if (parentAllSubDeps && parentAllSubDeps.length > 0) {
      let options = [
        {
          id: parentDepartment.id,
          label: parentDepartment.name,
          value: parentDepartment,
          img: parentDepartment.logoVerifiedUrl,
        },
      ];
      for (let i = 0; i < parentAllSubDeps.length; i++) {
        const subDep = parentAllSubDeps[i];
        options.push({
          id: subDep.id,
          label: subDep.name,
          value: subDep,
          img: subDep.logoVerifiedUrl,
        });
      }
      return options.filter(
        (dep, index, self) =>
          self.findIndex((t) => t.id === dep.id) === index &&
          (searchTerm === '' ||
            dep.label.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }
    return null;
  }, [parentDepartment, parentAllSubDeps, searchTerm]);

  const handleDeleteDepartment = async () => {
    console.log('Deleting department', activeDepartment);
    let resp: Response = await deleteDepartment(activeDepartment).catch(
      (error) => {
        console.log('Error deleting department', error);
        return {
          type: ResponseType.Failure,
          data: error,
        };
      }
    );
    if (resp.type === ResponseType.Success) {
      alert('Department deleted successfully');
      navigate('/actions');
    } else {
      alert('Error deleting department: ' + resp.data);
    }
  };

  const objURL = useMemo(() => {
    if (logo) return URL.createObjectURL(logo);
    return activeDepartment?.logoVerifiedUrl;
  }, [activeDepartment, logo]);

  useEffect(() => {
    setIsEdit(isEditMode === true ? true : false);
  }, [isEditMode]);

  const handleBack = () => {
    navigate('/actions');
  };

  const handleFileSelect = (e: any) => {
    console.log('handleFileSelect', e);
    const file = e.target.files[0];
    setLogo(file);

    // if (e.target.files.length) {
    //   const data = e.target.files[0];
    //   props.pdfUploaded(data);
    // }
  };

  const SoftwareBadge = ({ softwarePlan }: { softwarePlan: string }) => {
    let text_color = '#037F02';
    let bg_color = '#C3DBB0';
    if (softwarePlan === 'Plus' || softwarePlan === 'Professional') {
      text_color = '#0d4d8e';
      bg_color = '#ADD8E6';
    } else if (softwarePlan === 'View Only') {
      text_color = '#3d3d3d';
      bg_color = '#D3D3D3';
    } else if (softwarePlan === 'Essentials' || softwarePlan === 'Starter') {
      text_color = '#BB5C34';
      bg_color = '#FEDED1';
    }
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '3px 6px',
          borderRadius: '4px',
          backgroundColor: bg_color,
          color: text_color,
          fontSize: '0.75rem',
        }}
      >
        {softwarePlan ? softwarePlan : 'Premium'}
      </div>
    );
  };

  // /* Use this effect to close the dropdown when clicked outside */
  // useEffect(() => {
  //   const handleClick = (e: MouseEvent) => {
  //     if (
  //       isSubDepChoiceOpen &&
  //       divRef.current &&
  //       !divRef.current.contains(e.target as Node)
  //     ) {
  //       setTimeout(() => {
  //         if (isSubDepChoiceOpen) {
  //           setIsSubDepChoiceOpen(false);
  //           setAnchorEl(null);
  //         }
  //       }, 150);
  //     }
  //   };
  //   document.addEventListener('mousedown', handleClick);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClick);
  //   };
  // }, [divRef, isSubDepChoiceOpen]);

  return (
    <div className="">
      <ResetPinModal
        isVisible={isResetPinModal}
        department={activeDepartment}
        handleClose={() => {
          setIsResetPinModal(false);
        }}
        handleSubmit={(pin: string, salt: string, hash: string) => {
          formik.setFieldValue('pin', pin);
          formik.setFieldValue('saltedPin', salt);
          formik.setFieldValue('hashedPin', hash);
          setIsResetPinModal(false);
        }}
        value={formik.values.pin === '' ? undefined : formik.values.pin}
      />

      <ShareURLModal
        isVisible={isShareURLModalVisible}
        handleClose={() => setIsShareURLModalVisible(false)}
        department={department}
      />

      <ConfirmModal
        isVisible={isDeleteModal}
        title={
          'Do you want to delete department ' + activeDepartment.name + '?'
        }
        handleClose={() => {
          setIsDeleteModal(false);
        }}
        handleSubmit={() => {
          setIsDeleteModal(false);
          handleDeleteDepartment();
        }}
        isSingleBtn={false}
        primaryBtnName={`Cancel`}
        secondaryBtnName="Delete"
        isDeleteBtn={true}
        primaryDescription={
          'This will delete the department and its references.'
        }
      />

      <EditAccessModal
        isVisible={isAccessVisible}
        handleClose={() => setAccessVisible(false)}
        handleSubmit={(
          newItem: DepartmentItem | CategoryItem | ProtocolItem,
          metaData: any
        ) => {
          if (newItem instanceof DepartmentItem) {
            console.log('Updated item:', newItem);
            setAccessVisible(false);
            department.isPublic = newItem.isPublic;
            department.keychainID = newItem.keychainID;
            department.keychain = newItem.keychain;
          }
        }}
        department={department}
        keychains={database.keychains}
        item={department}
      />
      {/* <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
        <div
          style={{ margin: '0px 10px', display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'space-between',
              // padding: '10px 0px',
              flex: 1,
              overflowX: 'auto',
            }}
          >
            <div className={`titleStyling`}>
              <div
                className=""
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <h6 className={`backTextLight`} onClick={handleBack}></h6>
              </div>

              <div className="titleItemContainer">
                <div className="titleTextPH">Edit Department</div>
              </div>

              {/* <h6
                className={`headerTextLight`}
                style={{
                  fontWeight: '400',
                  color: '#616161',
                  marginRight: '2rem',
                }}
              >
                Description here
              </h6> 
            </div>
          </div>
        </div>
        <hr />
      </div> */}
      <Row>
        <Col
          sm={8}
          style={{
            padding: '0 5rem',
            alignItems: 'center',
            // overflowX: 'hidden',
          }}
        >
          <label
            htmlFor="s"
            className="settings-general-label"
            style={{
              marginLeft: '0rem',
              fontSize: '1.25rem',
            }}
          >
            Department Information
          </label>
          <div
            className="input-container roundBorder"
            style={{
              margin: '0px 0rem',
            }}
          >
            <Row>
              <Col sm={6}>
                <div
                  style={{
                    display: 'flex',
                    marginTop: '5px',
                  }}
                  className="settings-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Software Plan:</div>
                  {/* <div style={{ fontWeight: '500' }}>
                {formik.values.softwarePlan}
              </div> */}
                  <SoftwareBadge
                    softwarePlan={toTitleCase(formik.values.softwarePlan)}
                  />
                </div>
                <div
                  style={{ display: 'flex', marginTop: '5px' }}
                  className="settings-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Department Code:</div>
                  <div style={{ fontWeight: '500' }}>
                    {formik.values.departmentCode}
                  </div>
                  <CopyToClipboard text={formik.values.departmentCode} />
                </div>
                {activeDepartment.parentDep && (
                  <div
                    style={{ display: 'flex', marginTop: '5px' }}
                    className="settings-general-label"
                  >
                    <div style={{ marginRight: '10px' }}>
                      Parent Department:
                    </div>
                    <div style={{ fontWeight: '500' }}>
                      {activeDepartment.parentDep.name}
                    </div>
                  </div>
                )}
                {activeDepartment.isPublic && (
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '5px',
                      alignContent: 'flex-end',
                    }}
                  >
                    <div style={{ flexShrink: 0 }}>
                      <Tooltip
                        title={`This will make a public URL for anyone to access ${activeDepartment.name} protocol PDF's. This is useful for sharing protocols with anyone requiring to access it.`}
                        placement="bottom"
                        arrow
                      >
                        <label
                          htmlFor="isPublicAccess"
                          className="settings-general-label"
                          style={{ flexDirection: 'row' }}
                        >
                          Public URL:
                          <span>
                            <FaInfoCircle
                              color="#636363"
                              style={{ marginLeft: '5px', marginBottom: '2px' }}
                            />
                          </span>
                        </label>
                      </Tooltip>
                    </div>
                    <Tooltip
                      title={`URL: ${generateDepartmentURL(activeDepartment)}`}
                      placement="bottom"
                      arrow
                    >
                      <a
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                          marginLeft: '10px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          flexGrow: 1, // Allows the link to take up available space
                        }}
                        className="sidebarLink"
                        href={generateDepartmentURL(activeDepartment)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {generateDepartmentURL(activeDepartment)}
                      </a>
                    </Tooltip>
                    <>
                      {isCopied &&
                      isCopied === generateDepartmentURL(activeDepartment) ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="24px"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="24px"
                          className="copy_icon"
                          onClick={(e) =>
                            handleCopy(
                              generateDepartmentURL(activeDepartment),
                              e,
                              setIsCopied
                            )
                          }
                        />
                      )}
                    </>
                  </div>
                )}
                {activeDepartment.isNemsisConfig &&
                  activeDepartment.model?.createdAt && (
                    <div
                      style={{ display: 'flex' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Created:</div>
                      <div style={{ fontWeight: '500' }}>
                        {getFormattedDate(
                          activeDepartment.model.createdAt,
                          true
                        )}
                      </div>
                    </div>
                  )}
                {activeDepartment.isNemsisConfig &&
                  activeDepartment?.location && (
                    <div
                      style={{ display: 'flex' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Location:</div>
                      <div style={{ fontWeight: '500' }}>
                        {activeDepartment.location}
                      </div>
                    </div>
                  )}
              </Col>
              <Col sm={6}>
                {activeDepartment.isNemsisConfig ? (
                  <div>
                    <div
                      style={{ display: 'flex' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>GNIS Code:</div>
                      <div style={{ fontWeight: '500' }}>
                        {formik.values.gnisCode}
                      </div>
                    </div>
                    <div
                      style={{ display: 'flex' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>State ID:</div>
                      <div style={{ fontWeight: '500' }}>
                        {formik.values.stateIDEMS}
                      </div>
                    </div>
                    <div
                      style={{ display: 'flex' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>Agency Number:</div>
                      <div style={{ fontWeight: '500' }}>
                        {formik.values.agencyNumEMS}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    {activeDepartment.parentDep && (
                      <div
                        style={{ display: 'flex' }}
                        className="settings-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Parent:</div>
                        <div
                          style={{
                            fontWeight: '500',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            flexGrow: 1, // Allows the link to take up available space
                          }}
                        >
                          {activeDepartment.parentDep.name}
                        </div>
                      </div>
                    )}
                    {activeDepartment.model?.createdAt && (
                      <div
                        style={{ display: 'flex' }}
                        className="settings-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Created:</div>
                        <div style={{ fontWeight: '500' }}>
                          {getFormattedDate(
                            activeDepartment.model.createdAt,
                            true
                          )}
                        </div>
                      </div>
                    )}
                    {activeDepartment?.location && (
                      <div
                        style={{ display: 'flex' }}
                        className="settings-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Location:</div>
                        <div style={{ fontWeight: '500' }}>
                          {activeDepartment.location}
                        </div>
                      </div>
                    )}
                    {activeDepartment.renewalDate && (
                      <div
                        style={{ display: 'flex' }}
                        className="settings-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Renewal Date:</div>
                        <div style={{ fontWeight: '500' }}>
                          {getFormattedDate(activeDepartment.renewalDate, true)}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <label
            htmlFor="neonateCutoff"
            className="settings-general-label"
            style={{
              fontSize: '1.25rem',
              marginTop: '3rem',
              marginLeft: '0rem',
            }}
          >
            General
          </label>
          <label
            htmlFor="Name"
            className="settings-general-label"
            style={{
              marginTop: '1rem',
              marginLeft: '0px',
            }}
          >
            Name
            <span className="required-field ">
              *
              <BiRename
                className="refresh-icon"
                onClick={() => {
                  if (adminAccess)
                    formik.setFieldValue(
                      'name',
                      toTitleCase(formik.values.name)
                    );
                }}
                size="1.25rem"
                style={{ marginRight: '5px', cursor: 'pointer' }}
              />
            </span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '0px' }}>
            This is the name of the department that is reflected on the OneDose
            app.
          </p>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="name"
              name="name"
              data-testid="name"
              required={true}
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
              disabled={!adminAccess}
            />
            <div className="input-border"></div>
          </div>

          {user.type === 'ADMIN' && (
            <Row>
              <Col sm={6}>
                <label
                  htmlFor="neonateCutoff"
                  className="settings-general-label"
                >
                  Neonate Cutoff (Kg)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="neonateCutoff"
                    name="neonateCutoff"
                    data-testid="neonateCutoff"
                    required={true}
                    value={formik.values.neonateCutoff + ''}
                    onChange={(e: any) => {
                      let num = Number(e.target.value);
                      if (isNaN(num)) {
                        formik.setFieldValue('neonateCutoff', 0);
                        formik.setFieldError(
                          'neonateCutoff',
                          'Please enter a valid number'
                        );
                      } else formik.setFieldValue('neonateCutoff', num);
                    }}
                    disabled={!adminAccess}
                  />
                  <div className="input-border"></div>
                </div>
              </Col>
              <Col sm={6}>
                <label
                  htmlFor="pediatricCutoff"
                  className="settings-general-label"
                >
                  Pediatric Cutoff (Kg)
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="pediatricCutoff"
                    name="pediatricCutoff"
                    data-testid="pediatricCutoff"
                    required={true}
                    value={formik.values.pediatricCutoff + ''}
                    onChange={(e: any) => {
                      let num = Number(e.target.value);
                      if (isNaN(num)) {
                        formik.setFieldValue('pediatricCutoff', 0);
                        formik.setFieldError(
                          'pediatricCutoff',
                          'Please enter a valid number'
                        );
                      } else formik.setFieldValue('pediatricCutoff', num);
                    }}
                    disabled={!adminAccess}
                  />
                  <div className="input-border"></div>
                </div>
                {formik.errors.pediatricCutoff !== '' && (
                  <div
                    className="errorText"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                      fontSize: '16px',
                    }}
                  >
                    {formik.errors.pediatricCutoff}
                  </div>
                )}
              </Col>
              <p className="sidebarText" style={{ marginLeft: '30px' }}>
                This is the universal cutoff for neonate and pediatrics in the
                OneDose app.
              </p>
            </Row>
          )}
          <div
            style={{
              display: 'flex',
              width: '100%',
              // justifyContent: 'center',
              // alignItems: 'center',
              marginTop: '3rem',
              flexDirection: 'column',
            }}
          >
            <label
              htmlFor="neonateCutoff"
              className="settings-general-label"
              style={{
                fontSize: '1.25rem',
              }}
            >
              Sign-Up Pin
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              This is the pin that is used to verify which department that the
              user is signing up for.
            </p>
            <Button
              data-testid="set-Bttn"
              className="primary-button btn-rightMargin"
              disabled={!adminAccess}
              style={{
                marginTop: '0.5rem',
                width: '50%',
                marginLeft: '0.75rem',
              }}
              onClick={() => {
                setIsResetPinModal(true);
              }}
            >
              Reset Sign-Up Pin
            </Button>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              // justifyContent: 'center',
              // alignItems: 'center',
              marginTop: '3rem',
              flexDirection: 'column',
            }}
          >
            <label
              htmlFor="neonateCutoff"
              className="settings-general-label"
              style={{
                fontSize: '1.25rem',
              }}
            >
              Share New Account Link
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              This is the link that is used to share with users to create a new
              account.
            </p>
            <Button
              data-testid="set-Bttn"
              className="primary-button btn-rightMargin"
              disabled={!adminAccess}
              style={{
                marginTop: '0.5rem',
                width: '50%',
                marginLeft: '0.75rem',
              }}
              onClick={() => {
                setIsShareURLModalVisible(true);
              }}
            >
              Share Link
            </Button>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              // justifyContent: 'center',
              // alignItems: 'center',
              marginTop: '3rem',
              flexDirection: 'column',
            }}
          >
            <label
              htmlFor="neonateCutoff"
              className="settings-general-label"
              style={{
                fontSize: '1.25rem',
              }}
            >
              Public Access to Protocol Set
            </label>
            <label
              htmlFor="index"
              className={`notification-css-title`}
              style={{
                marginTop: '10px',
              }}
            >
              <span
                className="headerTextMargin"
                style={{ fontSize: '16px', marginLeft: '10px' }}
              >
                Current Access Level:
              </span>
              <span
                style={{
                  marginTop: '2px',
                  marginLeft: '6px',
                }}
              >
                <Status
                  status={
                    !department.isPublic
                      ? 'Private'
                      : department.keychainID
                        ? 'Protected'
                        : 'Public'
                  }
                  // onClick={() => {
                  //   setAccessVisible(true);
                  // }}
                />
              </span>
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              This is the settings for controlling access to{' '}
              {activeDepartment.name} protocol set. There are three levels,
              Public, Protected, and Private. Public allows anyone to access the
              protocol set, Protected allows only users with the keychain code
              to access the protocol set, and Private restricts it to only
              OneDose users.
            </p>
            <p className="sidebarText" style={{ marginLeft: '2rem' }}>
              1. Public allows anyone to access the protocol set.
            </p>
            <p className="sidebarText" style={{ marginLeft: '2rem' }}>
              2. Protected allows only users with the keychain code to access
              the protocol set.
            </p>
            <p className="sidebarText" style={{ marginLeft: '2rem' }}>
              3. Private restricts it to only OneDose users.
            </p>
            <Button
              data-testid="set-Bttn"
              className="primary-button btn-rightMargin"
              disabled={!adminAccess}
              style={{
                marginTop: '0.5rem',
                width: '50%',
                marginLeft: '0.75rem',
              }}
              onClick={() => {
                setAccessVisible(true);
              }}
            >
              Change Access Level
            </Button>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              // justifyContent: 'center',
              // alignItems: 'center',
              marginTop: '3rem',
              flexDirection: 'column',
            }}
          >
            <label
              htmlFor="neonateCutoff"
              className="settings-general-label"
              style={{
                fontSize: '1.25rem',
              }}
            >
              Active Status
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              This is toggles whether users on OneDose can see the department or
              if it is hidden from them.
            </p>
            <div className="radio-container">
              <label
                className="sidebarText"
                htmlFor="active_status_true"
                style={{
                  marginLeft: '10px',
                  cursor: formik.values.activeStatus !== false ? '' : 'pointer',
                }}
              >
                <RadioButton
                  inputId="active_status_true"
                  name="active_status_true"
                  data-testid="active_status_true"
                  className="radioBtnColorSettings"
                  style={{
                    marginRight: '10px',
                    marginTop: 0,
                    borderColor: '#00534C',
                  }}
                  checked={formik.values.activeStatus !== false ? true : false}
                  onChange={(e) => {
                    formik.setFieldValue('activeStatus', true);
                  }}
                  disabled={!adminAccess}
                />
                Activate this sub-department and users in the OneDose app will
                be able to choose this sub-department.
              </label>
            </div>
            <div className="radio-container">
              <label
                className="sidebarText"
                htmlFor="active_status_false"
                style={{
                  marginLeft: '10px',
                  cursor: formik.values.activeStatus === false ? '' : 'pointer',
                }}
              >
                <RadioButton
                  inputId="active_status_false"
                  name="active_status_false"
                  data-testid="active_status_false"
                  className="radioBtnColorSettings"
                  style={{ marginRight: '10px', marginTop: 0 }}
                  checked={formik.values.activeStatus === false ? true : false}
                  onChange={(e) => {
                    formik.setFieldValue('activeStatus', false);
                  }}
                  disabled={!adminAccess}
                />
                Hide this sub-department from users in the OneDose app.
              </label>
            </div>
            <label
              htmlFor="neonateCutoff"
              className="settings-general-label"
              style={{
                fontSize: '1.25rem',
                marginTop: '3rem',
              }}
            >
              Infusion Calculation
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              This toggles whether the infusion values should be calculated by
              weight or to just show the calculation basis. Normally departments
              with infusion pumps choose to just display the calculation basis.
            </p>
            <div className="radio-container">
              <label
                className="sidebarText"
                htmlFor="infusion_status_true"
                style={{
                  marginLeft: '10px',
                  cursor:
                    formik.values.infusionCalculation !== false
                      ? ''
                      : 'pointer',
                }}
              >
                <RadioButton
                  inputId="infusion_status_true"
                  name="infusion_status_true"
                  data-testid="infusion_status_true"
                  className="radioBtnColorSettings"
                  style={{ marginRight: '10px', marginTop: 0 }}
                  checked={
                    formik.values.infusionCalculation !== false ? true : false
                  }
                  onChange={(e) => {
                    formik.setFieldValue('infusionCalculation', true);
                  }}
                  disabled={!adminAccess}
                />
                Enable infusion calculation based of of weight for{' '}
                {activeDepartment?.name}. Ex. 80 mL/hr.
              </label>
            </div>
            <div className="radio-container">
              <label
                className="sidebarText"
                htmlFor="infusion_status_false"
                style={{
                  marginLeft: '10px',
                  cursor:
                    formik.values.infusionCalculation === false
                      ? ''
                      : 'pointer',
                }}
              >
                <RadioButton
                  inputId="infusion_status_false"
                  name="infusion_status_false"
                  data-testid="infusion_status_false"
                  className="radioBtnColorSettings"
                  style={{ marginRight: '10px', marginTop: 0 }}
                  checked={
                    formik.values.infusionCalculation === false ? true : false
                  }
                  onChange={(e) => {
                    formik.setFieldValue('infusionCalculation', false);
                  }}
                  disabled={!adminAccess}
                />
                Only show the calculation basis for {activeDepartment?.name}.
                Ex. 1 mg/kg/hr.
              </label>
            </div>
            <label
              htmlFor="neonateCutoff"
              className="settings-general-label"
              style={{
                fontSize: '1.25rem',
                marginTop: '3rem',
              }}
            >
              OneWeight Enabled
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              This toggles whether the ActualWeight tab and bluetooth
              capabilities are enabled on the OneDose app.
            </p>
            <div className="radio-container">
              <label
                className="sidebarText"
                htmlFor="oneweight_status_true"
                style={{
                  marginLeft: '10px',
                  cursor:
                    formik.values.oneweightEnabled !== false ? '' : 'pointer',
                }}
              >
                <RadioButton
                  inputId="oneweight_status_true"
                  name="oneweight_status_true"
                  data-testid="oneweight_status_true"
                  className="radioBtnColorSettings"
                  style={{ marginRight: '10px', marginTop: 0 }}
                  checked={
                    formik.values.oneweightEnabled !== false ? true : false
                  }
                  onChange={(e) => {
                    formik.setFieldValue('oneweightEnabled', true);
                  }}
                  disabled={!adminAccess}
                />
                Enable the Actual Weight and Bluetooth capabilities for{' '}
                {activeDepartment?.name}. This allows you to connect to the
                OneWeight scale.
              </label>
            </div>
            <div className="radio-container">
              <label
                className="sidebarText"
                htmlFor="oneweight_status_false"
                style={{
                  marginLeft: '10px',
                  cursor:
                    formik.values.oneweightEnabled === false ? '' : 'pointer',
                }}
              >
                <RadioButton
                  inputId="oneweight_status_false"
                  name="oneweight_status_false"
                  data-testid="oneweight_status_false"
                  className="radioBtnColorSettings"
                  style={{ marginRight: '10px', marginTop: 0 }}
                  checked={
                    formik.values.oneweightEnabled === false ? true : false
                  }
                  onChange={(e) => {
                    formik.setFieldValue('oneweightEnabled', false);
                  }}
                  disabled={!adminAccess}
                />
                Disable the Actual Weight and Bluetooth capabilities for{' '}
                {activeDepartment?.name}. This will remove the Actual Weight
                tab.
              </label>
            </div>
            <label
              htmlFor="neonateCutoff"
              className="settings-general-label"
              style={{
                fontSize: '1.25rem',
                marginTop: '3rem',
              }}
            >
              Age-Based Logic
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              This toggles whether age based logic is enabled on the OneDose
              app. This will allow medics to input a age of the patient and the
              app will filter out any protocols or treatment options that are
              not applicable to that age group.
            </p>
            <div className="radio-container">
              <label
                className="sidebarText"
                htmlFor="ageFilterEnabled_status_true"
                style={{
                  marginLeft: '10px',
                  cursor:
                    formik.values.ageFilterEnabled !== false ? '' : 'pointer',
                }}
              >
                <RadioButton
                  inputId="ageFilterEnabled_status_true"
                  name="ageFilterEnabled_status_true"
                  data-testid="ageFilterEnabled_status_true"
                  className="radioBtnColorSettings"
                  style={{ marginRight: '10px', marginTop: 0 }}
                  checked={
                    formik.values.ageFilterEnabled !== false ? true : false
                  }
                  onChange={(e) => {
                    formik.setFieldValue('ageFilterEnabled', true);
                  }}
                  disabled={!adminAccess}
                />
                Enable the age-based logic for {activeDepartment?.name}. This
                allows the users to use age as an input for the patient.
              </label>
            </div>
            <div className="radio-container">
              <label
                className="sidebarText"
                htmlFor="ageFilterEnabled_status_false"
                style={{
                  marginLeft: '10px',
                  cursor:
                    formik.values.ageFilterEnabled === false ? '' : 'pointer',
                }}
              >
                <RadioButton
                  inputId="ageFilterEnabled_status_false"
                  name="ageFilterEnabled_status_false"
                  data-testid="ageFilterEnabled_status_false"
                  className="radioBtnColorSettings"
                  style={{ marginRight: '10px', marginTop: 0 }}
                  checked={
                    formik.values.ageFilterEnabled === false ? true : false
                  }
                  onChange={(e) => {
                    formik.setFieldValue('ageFilterEnabled', false);
                  }}
                  disabled={!adminAccess}
                />
                Disable the age-based logic for {activeDepartment?.name}. This
                will remove the age input for the patient.
              </label>
            </div>
            {activeDepartment.parentDep && (
              <>
                <label
                  htmlFor="neonateCutoff"
                  className="settings-general-label"
                  style={{
                    fontSize: '1.25rem',
                    marginTop: '3rem',
                  }}
                >
                  Public Signup
                </label>
                <p className="sidebarText" style={{ marginLeft: '10px' }}>
                  This toggles whether this agency can be selected from the
                  parents signup page.
                </p>
                <div className="radio-container">
                  <label
                    className="sidebarText"
                    htmlFor="isPublicSignup_status_true"
                    style={{
                      marginLeft: '10px',
                      cursor:
                        formik.values.isPublicSignup !== false ? '' : 'pointer',
                    }}
                  >
                    <RadioButton
                      inputId="isPublicSignup_status_true"
                      name="isPublicSignup_status_true"
                      data-testid="isPublicSignup_status_true"
                      className="radioBtnColorSettings"
                      style={{ marginRight: '10px', marginTop: 0 }}
                      checked={
                        formik.values.isPublicSignup !== false ? true : false
                      }
                      onChange={(e) => {
                        formik.setFieldValue('isPublicSignup', true);
                      }}
                      disabled={!adminAccess}
                    />
                    Enable the public signup for {activeDepartment?.name}. This
                    allows the users to select this agency from the{' '}
                    {activeDepartment.parentDep.name} signup page.
                  </label>
                </div>
                <div className="radio-container">
                  <label
                    className="sidebarText"
                    htmlFor="isPublicSignup_status_false"
                    style={{
                      marginLeft: '10px',
                      cursor:
                        formik.values.isPublicSignup === false ? '' : 'pointer',
                    }}
                  >
                    <RadioButton
                      inputId="isPublicSignup_status_false"
                      name="isPublicSignup_status_false"
                      data-testid="isPublicSignup_status_false"
                      className="radioBtnColorSettings"
                      style={{ marginRight: '10px', marginTop: 0 }}
                      checked={
                        formik.values.isPublicSignup === false ? true : false
                      }
                      onChange={(e) => {
                        formik.setFieldValue('isPublicSignup', false);
                      }}
                      disabled={!adminAccess}
                    />
                    Disable the public signup for {activeDepartment?.name}. This
                    allows the users to select this agency from the{' '}
                    {activeDepartment.parentDep.name} signup page.
                  </label>
                </div>
              </>
            )}
            <>
              <label
                htmlFor="neonateCutoff"
                className="settings-general-label"
                style={{
                  fontSize: '1.25rem',
                  marginTop: '3rem',
                }}
              >
                GTTS Drip Set Infusion Calculator
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                This toggles whether this agency can calculate the GTTS drip set
                infusion rate.
              </p>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="gttsCalculations_status_true"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.isPublicSignup !== false ? '' : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="gttsCalculations_status_true"
                    name="gttsCalculations_status_true"
                    data-testid="gttsCalculations_status_true"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={formik.values.infusionGTTS !== null ? true : false}
                    onChange={(e) => {
                      formik.setFieldValue('infusionGTTS', []);
                    }}
                    disabled={!adminAccess}
                  />
                  Enable the GTTS drip set infusion calculator for{' '}
                  {activeDepartment?.name}. This allows the users to calculate
                  the GTTS drip set infusion rate based on the added Drop
                  Factors.
                </label>
              </div>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="gttsCalculations_status_false"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.infusionGTTS === null ? '' : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="gttsCalculations_status_false"
                    name="gttsCalculations_status_false"
                    data-testid="gttsCalculations_status_false"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={formik.values.infusionGTTS === null ? true : false}
                    onChange={(e) => {
                      formik.setFieldValue('infusionGTTS', null);
                    }}
                    disabled={!adminAccess}
                  />
                  Disable the GTTS drip set infusion calculator for{' '}
                  {activeDepartment?.name}. This will remove the GTTS drip set
                  infusion rate calculation.
                </label>
              </div>
              {formik.values.infusionGTTS && (
                <>
                  <label
                    htmlFor="gnisCode"
                    className="settings-general-label"
                    style={{ marginTop: '1rem' }}
                  >
                    Drop Factors:
                  </label>
                  <p className="sidebarText" style={{ marginLeft: '10px' }}>
                    Add the Drop Factors for the GTTS drip set infusion rate.
                    Ex. 10 gtts/mL. NOTE: Must be entered as a number format
                    '10'.
                  </p>
                  <div className="input-container">
                    <InputText
                      type="text"
                      className="form-control-general"
                      id="gnisCode"
                      name="gnisCode"
                      data-testid="gnisCode"
                      required={true}
                      value={gttsInput}
                      disabled={!adminAccess}
                      onChange={(e: any) => {
                        let n = Number(e.target.value);
                        if (isNaN(n)) {
                          setGttsInput('');
                        } else {
                          setGttsInput(e.target.value);
                        }
                      }}
                      onKeyDown={(e: any) => {
                        if (e.key === 'Enter' && gttsInput) {
                          let newArray = [...formik.values.infusionGTTS];
                          newArray.push(gttsInput);
                          formik.setFieldValue(
                            'infusionGTTS',
                            newArray.sort((a: number, b: number) => a - b)
                          );
                          setGttsInput('');
                        }
                      }}
                    />
                    <span
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: 'calc(50% - 6px)',
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (gttsInput) {
                          let newArray = [...formik.values.infusionGTTS];
                          newArray.push(gttsInput);
                          formik.setFieldValue(
                            'infusionGTTS',
                            newArray.sort((a: number, b: number) => a - b)
                          );
                          setGttsInput('');
                        }
                      }}
                    >
                      <IoSend size={20} color="#00534C" />
                    </span>
                    <div className="input-border"></div>
                  </div>
                  <div
                    className="input-container"
                    style={{
                      marginTop: '1rem',
                      gap: '0.5rem',
                      flexWrap: 'wrap',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    {formik.values.infusionGTTS.map(
                      (item: any, index: number) => (
                        <div
                          key={index.toLocaleString()}
                          className={`selectedGreybox ${'cursorPointer'}`}
                        >
                          <div>{item} gtts/mL</div>
                          {adminAccess && (
                            <span
                              className="close_icon_color"
                              data-testid={`deleteConcentration ${index}`}
                              onClick={(e) => {
                                let newArray = [...formik.values.infusionGTTS];
                                newArray.splice(index, 1);
                                formik.setFieldValue(
                                  'infusionGTTS',
                                  newArray.sort((a: number, b: number) => a - b)
                                );
                              }}
                            >
                              <AiOutlineCloseCircle className="icon-normal" />
                            </span>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </>
              )}
            </>
            <>
              <label
                htmlFor="neonateCutoff"
                className="settings-general-label"
                style={{
                  fontSize: '1.25rem',
                  marginTop: '3rem',
                }}
              >
                Enable Advanced Filters Toggle Button
              </label>
              <p className="sidebarText" style={{ marginLeft: '10px' }}>
                This enables the setting allowing users to toggle OneDose
                advanced filters off with a button. This will remove any weight
                and/or age range restrictions making every Treatment Reference
                available.
              </p>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="isToggleAdvancedFiltersEnabled_status_true"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.isToggleAdvancedFiltersEnabled !== false
                        ? ''
                        : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="isToggleAdvancedFiltersEnabled_status_true"
                    name="isToggleAdvancedFiltersEnabled_status_true"
                    data-testid="isToggleAdvancedFiltersEnabled_status_true"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={
                      formik.values.isToggleAdvancedFiltersEnabled !== false
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'isToggleAdvancedFiltersEnabled',
                        true
                      );
                    }}
                    disabled={!adminAccess}
                  />
                  Enable the toggle advanced filters for{' '}
                  {activeDepartment?.name}. This allows the users to toggle the
                  advanced filters off with a button on the 'Calculations' page.
                </label>
              </div>
              <div className="radio-container">
                <label
                  className="sidebarText"
                  htmlFor="isToggleAdvancedFiltersEnabled_status_false"
                  style={{
                    marginLeft: '10px',
                    cursor:
                      formik.values.isToggleAdvancedFiltersEnabled === false
                        ? ''
                        : 'pointer',
                  }}
                >
                  <RadioButton
                    inputId="isToggleAdvancedFiltersEnabled_status_false"
                    name="isToggleAdvancedFiltersEnabled_status_false"
                    data-testid="isToggleAdvancedFiltersEnabled_status_false"
                    className="radioBtnColorSettings"
                    style={{ marginRight: '10px', marginTop: 0 }}
                    checked={
                      formik.values.isToggleAdvancedFiltersEnabled === false
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'isToggleAdvancedFiltersEnabled',
                        false
                      );
                    }}
                    disabled={!adminAccess}
                  />
                  Disable the toggle advanced filters for{' '}
                  {activeDepartment?.name}. This will remove the button to
                  toggle the advanced filters off.
                </label>
              </div>
            </>
          </div>

          {user.type === 'ADMIN' && (
            <div
              style={{
                marginTop: '5rem',
              }}
            >
              <label
                htmlFor="s"
                className="settings-general-label"
                style={{
                  marginLeft: '0.5rem',
                  fontSize: '1.25rem',
                }}
              >
                ePCR Integration Settings
              </label>
              <div
                className="input-container "
                style={{
                  // margin: '0px 1rem',
                  padding: '1rem',
                }}
              >
                <Row>
                  <Col sm={4}>
                    <label
                      htmlFor="gnisCode"
                      className="settings-general-label"
                    >
                      GNIS Code:
                    </label>
                    <div className="input-container">
                      <InputText
                        type="text"
                        className="form-control-general"
                        id="gnisCode"
                        name="gnisCode"
                        data-testid="gnisCode"
                        required={true}
                        value={formik.values.gnisCode + ''}
                        onChange={(e: any) => {
                          formik.setFieldValue('gnisCode', e.target.value);
                        }}
                        disabled={!adminAccess}
                      />
                      <div className="input-border"></div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <label htmlFor="Name" className="settings-general-label">
                      State ID:
                    </label>
                    <div className="input-container">
                      <InputText
                        type="text"
                        className="form-control-general"
                        id="stateIDEMS"
                        name="stateIDEMS"
                        data-testid="stateIDEMS"
                        required={true}
                        value={formik.values.stateIDEMS + ''}
                        onChange={(e: any) => {
                          formik.setFieldValue('stateIDEMS', e.target.value);
                        }}
                        disabled={!adminAccess}
                      />
                      <div className="input-border"></div>
                    </div>
                  </Col>

                  <Col sm={4}>
                    <label
                      htmlFor="agencyNumEMS"
                      className="settings-general-label"
                    >
                      Agency Number:
                    </label>
                    <div className="input-container">
                      <InputText
                        type="text"
                        className="form-control-general"
                        id="agencyNumEMS"
                        name="agencyNumEMS"
                        data-testid="agencyNumEMS"
                        required={true}
                        value={formik.values.agencyNumEMS + ''}
                        onChange={(e: any) => {
                          formik.setFieldValue('agencyNumEMS', e.target.value);
                        }}
                        disabled={!adminAccess}
                      />
                      <div className="input-border"></div>
                    </div>
                  </Col>
                </Row>
                <p className="sidebarText" style={{ marginLeft: '10px' }}>
                  These are used for the ePCR Integration
                </p>
              </div>
            </div>
          )}
        </Col>
        <Col sm={4}>
          {options && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                // justifyContent: 'center',
                // alignItems: 'center',
                marginTop: '10px',
                flexDirection: 'column',
              }}
            >
              <label
                htmlFor="s"
                className="settings-general-label"
                style={{
                  marginLeft: '0.25rem',
                }}
              >
                Current Department
              </label>
              <div
                className="border hover-raise-elevation"
                onClick={(e) => {
                  if (!isSubDepChoiceOpen) {
                    setIsSubDepChoiceOpen(true);
                    setAnchorEl(e.currentTarget);
                  }
                }}
              >
                {activeDepartment && activeDepartment.logoVerifiedUrl && (
                  <img
                    className="department-logo"
                    src={activeDepartment.logoVerifiedUrl}
                    alt="Agency Logo"
                  />
                )}
                {activeDepartment && (
                  <div
                    className="departmentName no-select"
                    style={{
                      marginLeft: '1rem',
                      marginRight: '6px',
                      flex: 1,
                    }}
                  >
                    {activeDepartment.name}
                  </div>
                )}
                <FaChevronDown
                  size={12}
                  style={{
                    marginRight: '1rem',
                  }}
                />
              </div>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'center',
              flexDirection: 'row',
              padding: '0.25rem 1rem',
              gap: '0.5rem',
              borderRadius: '0.5rem',
              flex: 1,
              marginTop: options ? '1rem' : '1rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                // alignItems: 'center',
                // justifyContent: 'center',
                flexDirection: 'column',
              }}
              ref={dropRef}
              onClick={() => {
                if (adminAccess) {
                  const fileInput = document.getElementById('fileInput');
                  fileInput?.click();
                }
              }}
            >
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                className="pdfInputBox"
                id="fileInput"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
              />
              <label
                htmlFor="s"
                className="settings-general-label"
                style={{
                  marginLeft: '1.5rem',
                }}
              >
                {activeDepartment?.name} Logo
              </label>
              <img
                className="department-logo-settings"
                src={objURL ? objURL : ''}
                alt="Agency Logo"
                style={{
                  marginLeft: '3rem',
                }}
              />
              {/* Create a floating Edti Badge */}
              <div
                className={`edit-image-container ${!adminAccess ? 'disabled' : ''}`}
                style={{
                  top: options ? '17.5rem' : '11.5rem',
                }}
              >
                <PiPencil size="1.0rem" />
                <span
                  className="settings-general-label no-select"
                  style={{
                    fontSize: '1rem',
                    color: '#FFF',
                  }}
                >
                  Edit
                </span>
              </div>
            </div>
          </div>
          {/*  <div>
            <label
              htmlFor="s"
              className="settings-general-label"
              style={{
                marginLeft: '0.5rem',
              }}
            >
              Enable Settings
            </label>
            <div
              className="input-container roundBorder"
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '0.25rem',
                gap: '0.75rem',
              }}
            >
              <div
                className="input-container"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  // justifyContent: 'space-between',
                  // marginTop: '10px',
                }}
              >
                <label
                  htmlFor="isSolidOnly"
                  className="settings-general-label"
                  style={{ flexDirection: 'row' }}
                >
                  Active Status:
                </label>
                <InputSwitch
                  name="isSolidOnly"
                  style={{ marginLeft: '10px', height: '1.5rem' }}
                  // checked={true}
                  checked={formik.values.activeStatus === false ? false : true}
                  onChange={(e) => {
                    formik.setFieldValue('activeStatus', e.value);
                  }}
                />
              </div>
              <div
                className="input-container"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  // justifyContent: 'space-between',
                  // marginTop: '10px',
                }}
              >
                <label
                  htmlFor="isSolidOnly"
                  className="settings-general-label"
                  style={{ flexDirection: 'row' }}
                >
                  Infusion Calculation:
                </label>
                <InputSwitch
                  name="isSolidOnly"
                  style={{
                    marginLeft: '10px',
                    height: '1.5rem',
                    // width: '5rem',
                  }}
                  // checked={true}
                  checked={
                    formik.values.infusionCalculation === false ? false : true
                  }
                  onChange={(e) => {
                    formik.setFieldValue('infusionCalculation', e.value);
                  }}
                />
              </div>
            </div>
          </div> */}
          {user.type === 'ADMIN' && (
            <div>
              <label
                htmlFor="s"
                className="settings-general-label"
                style={{
                  marginLeft: '0.5rem',
                  marginTop: '1rem',
                }}
              >
                Super Admin Settings
              </label>
              <div
                className="input-container roundBorder"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '0.25rem',
                  gap: '0.75rem',
                }}
              >
                {activeDepartment && (
                  <>
                    <div
                      style={{ display: 'flex', marginTop: '5px' }}
                      className="settings-general-label"
                    >
                      <div style={{ marginRight: '10px' }}>UID:</div>
                      <div style={{ fontWeight: '500' }}>
                        {activeDepartment?.id}
                        <CopyToClipboard text={activeDepartment?.id} />
                      </div>
                    </div>
                    {activeDepartment?.parentDepID && (
                      <div
                        style={{ display: 'flex', marginTop: '5px' }}
                        className="settings-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Parent ID:</div>
                        <div style={{ fontWeight: '500' }}>
                          {activeDepartment?.parentDepID}
                          <CopyToClipboard
                            text={activeDepartment?.parentDepID}
                          />
                        </div>
                      </div>
                    )}
                    {activeDepartment?.organizationID && (
                      <div
                        style={{ display: 'flex', marginTop: '5px' }}
                        className="settings-general-label"
                      >
                        <div style={{ marginRight: '10px' }}>Org. ID:</div>
                        <div style={{ fontWeight: '500' }}>
                          {activeDepartment?.organizationID}
                          <CopyToClipboard
                            text={activeDepartment?.organizationID}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    // marginTop: '10px',
                  }}
                >
                  <label
                    htmlFor="isSolidOnly"
                    className="settings-general-label"
                    style={{ flexDirection: 'row' }}
                  >
                    Realtime Updating:
                  </label>
                  <InputSwitch
                    name="isSolidOnly"
                    style={{
                      marginLeft: '10px',
                      height: '1.5rem',
                      // width: '5rem',
                    }}
                    // checked={true}
                    checked={
                      formik.values.realTimeEnabled === false ? false : true
                    }
                    onChange={(e) => {
                      formik.setFieldValue('realTimeEnabled', e.value);
                    }}
                  />
                </div>
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    // marginTop: '10px',
                  }}
                >
                  <label
                    htmlFor="isSolidOnly"
                    className="settings-general-label"
                    style={{ flexDirection: 'row' }}
                  >
                    Is Top Enabled:
                  </label>
                  <InputSwitch
                    name="isTopEnabled"
                    style={{
                      marginLeft: '10px',
                      height: '1.5rem',
                      // width: '5rem',
                    }}
                    // checked={true}
                    checked={
                      formik.values.isTopEnabled === false ? false : true
                    }
                    onChange={(e) => {
                      formik.setFieldValue('isTopEnabled', e.value);
                    }}
                  />
                </div>
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    // marginTop: '10px',
                  }}
                >
                  <label htmlFor="Location" className="settings-general-label">
                    Software Plan:
                  </label>
                  <Dropdown
                    value={formik.values.softwarePlan}
                    options={[
                      { label: 'Starter', value: 'STARTER' },
                      { label: 'Professional', value: 'PROFESSIONAL' },
                      { label: 'Enterprise', value: 'ENTERPRISE' },
                      { label: 'Essentials', value: 'ESSENTIALS' },
                      { label: 'Plus', value: 'PLUS' },
                      { label: 'Premium', value: 'PREMIUM' },
                      { label: 'View Only', value: 'VIEW_ONLY' },
                      { label: 'Premium Null', value: null },
                    ]}
                    onChange={(value: any) => {
                      formik.setFieldValue('softwarePlan', value);
                    }}
                    style={{
                      marginTop: '-13px',
                      marginLeft: '16px',
                      padding: 0,
                    }}
                    buttonColor={'#E0EADD'}
                    textColor={'#00534C'}
                  />
                </div>
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    // marginTop: '10px',
                  }}
                >
                  <label htmlFor="Location" className="settings-general-label">
                    Location:
                  </label>
                  <Dropdown
                    value={formik.values.location}
                    options={USAStatesAbrev}
                    onChange={(value: any) => {
                      formik.setFieldValue('location', value);
                    }}
                    style={{
                      marginTop: '-13px',
                      marginLeft: '16px',
                      padding: 0,
                    }}
                    buttonColor={'#E0EADD'}
                    textColor={'#00534C'}
                  />
                </div>
                <label
                  htmlFor="uniquePublicURL"
                  className="settings-general-label"
                  style={{
                    marginBottom: '0px',
                  }}
                >
                  Public URL:
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="uniquePublicURL"
                    name="uniquePublicURL"
                    data-testid="uniquePublicURL"
                    required={true}
                    value={formik.values.uniquePublicURL + ''}
                    style={{ width: '90%', marginTop: '0px' }}
                    onChange={(e: any) => {
                      formik.setFieldValue('uniquePublicURL', e.target.value);
                    }}
                    disabled={!adminAccess}
                  />
                  <div className="input-border"></div>
                </div>
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    // marginTop: '10px',
                  }}
                >
                  {/* <label htmlFor="Location" className="settings-general-label">
                    Renewal Date:
                  </label> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    {/* <DemoContainer components={['DatePicker']}> */}
                    <DatePicker
                      label="Renewal Date"
                      onChange={(value: any) => {
                        formik.setFieldValue('renewalDate', new Date(value));
                      }}
                    />
                    {/* </DemoContainer> */}
                  </LocalizationProvider>
                </div>
                <Button
                  data-testid="set-Bttn"
                  className="primary-button btn-rightMargin"
                  disabled={false}
                  style={{
                    marginTop: '0.5rem',
                    width: '50%',
                    marginLeft: '0.75rem',
                    backgroundColor: '#8c1010',
                    borderColor: '#8c1010',
                  }}
                  onClick={() => {
                    setIsDeleteModal(true);
                  }}
                >
                  Delete Department
                </Button>
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    // marginTop: '10px',
                  }}
                >
                  <label
                    htmlFor="isSolidOnly"
                    className="settings-general-label"
                    style={{ flexDirection: 'row' }}
                  >
                    Debug Loading SubDeps:
                  </label>
                  <InputSwitch
                    name="debugLoadingSubDeps"
                    style={{
                      marginLeft: '10px',
                      height: '1.5rem',
                      // width: '5rem',
                    }}
                    // checked={true}
                    checked={debugLoadingSubDeps}
                    onChange={(e) => {
                      setDebugLoadingSubDeps(e.value);
                      localStorage.setItem(
                        'debugLoadingSubDeps',
                        e.value.toString()
                      );
                    }}
                  />
                </div>
                <div
                  className="input-container"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    // justifyContent: 'space-between',
                    // marginTop: '10px',
                  }}
                >
                  <label
                    htmlFor="isSolidOnly"
                    className="settings-general-label"
                    style={{ flexDirection: 'row' }}
                  >
                    Debug Loading Users:
                  </label>
                  <InputSwitch
                    name="debugLoadingUsers"
                    style={{
                      marginLeft: '10px',
                      height: '1.5rem',
                      // width: '5rem',
                    }}
                    // checked={true}
                    checked={debugLoadingUsers}
                    onChange={(e) => {
                      setDebugLoadingUsers(e.value);
                      localStorage.setItem(
                        'debugLoadingUsers',
                        e.value.toString()
                      );
                    }}
                  />
                </div>

                {formik.values.pin !== '' && (
                  <div>
                    <label
                      className="settings-general-label"
                      style={{ marginTop: '20px' }}
                    >
                      Hashed Pin:
                    </label>
                    <p className="sidebarText" style={{ marginLeft: '10px' }}>
                      {formik.values.hashedPin}
                      <CopyToClipboard text={formik.values.hashedPin} />
                    </p>
                    <label
                      className="settings-general-label"
                      style={{ marginTop: '20px' }}
                    >
                      Salt Pin:
                    </label>
                    <p className="sidebarText" style={{ marginLeft: '10px' }}>
                      {formik.values.saltedPin}
                      <CopyToClipboard text={formik.values.saltedPin} />
                    </p>
                    <label
                      className="settings-general-label"
                      style={{ marginTop: '20px' }}
                    >
                      Pin:
                    </label>
                    <p className="sidebarText" style={{ marginLeft: '10px' }}>
                      {formik.values.pin}
                      <CopyToClipboard text={formik.values.pin} />
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
          {options && (
            <Menu
              // id="lock-menu"
              anchorEl={anchorEl}
              open={isSubDepChoiceOpen}
              onClose={() => {
                setIsSubDepChoiceOpen(false);
                setAnchorEl(null);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              slotProps={{
                paper: {
                  style: {
                    maxHeight: '400px', // Add max height to enable scrolling
                  },
                },
              }}
            >
              <SearchBar
                value={searchTerm}
                onChange={(searchTerm: string) => setSearchTerm(searchTerm)}
                containerStyle={{
                  width: 'calc(100% - 2rem)',
                  marginLeft: '1rem',
                  alignSelf: 'center',
                }}
              />
              {options.map((option: any) => {
                return (
                  <li
                    className="c-multi-select-dropdown__option"
                    key={option.value.id}
                    onClick={(e) => {
                      // if (globals.debug) console.log('Option clicked');
                      e.stopPropagation();
                      setDepartment(option.value);
                      setActiveDepartment(option.value);
                      setIsSubDepChoiceOpen(false);
                      setAnchorEl(null);
                      // handleUserInfo();
                    }}
                  >
                    <div className={`c_dropdown_option`}>
                      {option && option.value.logoVerifiedUrl && (
                        <img
                          className="department-logo"
                          src={option.value.logoVerifiedUrl}
                          alt="Agency Logo"
                        />
                      )}
                      {option && (
                        <div
                          className="departmentName no-select"
                          style={{
                            marginLeft: '1rem',
                            marginRight: '6px',
                            flex: 1,
                          }}
                        >
                          {option.label}
                        </div>
                      )}
                      <FaChevronRight
                        size={12}
                        style={{
                          marginRight: '1rem',
                        }}
                      />
                      {/* <span>{labelField(option)}</span> */}
                    </div>
                  </li>
                );
              })}
            </Menu>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EditDepartmentPage;
