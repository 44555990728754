import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useMemo } from 'react';
import { FaChevronDown, FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import { User } from '../../models';
import { useSelector } from 'react-redux';
import { DatabaseResponse } from '../../data/AmplifyDB';
import ModelSubItem from '../../data/model/ModelSubItem';
import ModelItem from '../../data/model/ModelItem';
import CopyToClipboard from './CopyToClipboard';
import { getDepartmentsFromState } from '../_global/common/Utils';

interface AdminModelItemDetailsProps {
  modelItem: ModelItem<any> | ModelSubItem<any>;
}

/* 10-11-23 Arul: Created the CustomPdfUpload component for Protocol Modal */
const AdminModelItemDetails: React.FC<AdminModelItemDetailsProps> = (props) => {
  const { modelItem } = props;
  const user: User = useSelector((state: any) => state?.user);
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );

  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(database.department, reducerState);
  }, [database.department, reducerState]);

  const modifiedBy: User | null = useMemo(() => {
    if (modelItem) {
      let id = modelItem.modifiedByID ?? modelItem.createdByID;
      let user = (database.users || []).find((u) => u.id === id);
      if (user) return user;
      else return null;
    }
    return null;
  }, [modelItem, database.users]);

  const availableDepartments = useMemo(() => {
    let deps = allSubDeps.filter((d) => d.id !== database.department.id);
    return [database.department, ...deps];
  }, [allSubDeps, database.department]);

  const ownerDepartment = useMemo(() => {
    return availableDepartments.find((d) => d.id === modelItem.departmentID);
  }, [availableDepartments, modelItem]);

  if (!modelItem || user.type !== 'ADMIN') return null;
  return (
    <Accordion
      style={{
        borderRadius: '5px',
        boxShadow: 'none',
        alignContent: 'center',
        justifyContent: 'center',

        fontSize: '14px',
        marginTop: '20px',
        padding: '0px',
        backgroundColor: 'transparent',
        border: '1px solid #ccc',
      }}
      sx={{
        padding: '0px',
      }}
    >
      <AccordionSummary
        expandIcon={<FaChevronDown />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          padding: '0px 10px',
        }}
      >
        Admin Details
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            padding: '8px',
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
          }}
        >
          <div
            className="contentText"
            style={{
              marginTop: '10px',
            }}
          >
            ID: {modelItem.uid}
            <CopyToClipboard text={modelItem.uid} />
          </div>
          {modelItem.activeID != null && (
            <div
              className="contentText"
              style={{
                marginTop: '10px',
              }}
            >
              Actv. ID: {modelItem.activeID}
              <CopyToClipboard text={modelItem.activeID} />
              <span>
                {modelItem.activeItem != null ? (
                  <FaCircleCheck
                    color={'#037F02'}
                    size=".75rem"
                    className="copy_icon"
                  />
                ) : (
                  <FaCircleXmark
                    color={'#8c1010'}
                    size=".75rem"
                    className="copy_icon"
                  />
                )}
              </span>
            </div>
          )}
          {modelItem.overrideID != null && (
            <div
              className="contentText"
              style={{
                marginTop: '10px',
              }}
            >
              Ovrd. ID: {modelItem.overrideID}
              <CopyToClipboard text={modelItem.overrideID} />
              <span>
                {modelItem.overrideItem != null ? (
                  <FaCircleCheck
                    color={'#037F02'}
                    size=".75rem"
                    className="copy_icon"
                  />
                ) : (
                  <FaCircleXmark
                    color={'#8c1010'}
                    size=".75rem"
                    className="copy_icon"
                  />
                )}
              </span>
            </div>
          )}
          <div
            className="contentText"
            style={{
              marginTop: '10px',
            }}
          >
            Modified By: {modelItem.modifiedByID ?? modelItem.createdByID}
            {modifiedBy
              ? ' - ' + modifiedBy.firstName + ' ' + modifiedBy.lastName
              : ''}
            <CopyToClipboard
              text={modelItem.modifiedByID ?? modelItem.createdByID}
            />
          </div>

          {(modelItem as ModelSubItem<any>).parent && (
            <div
              className="contentText"
              style={{
                marginTop: '10px',
              }}
            >
              Parent: {(modelItem as ModelSubItem<any>).parent.uid} -{' '}
              {(modelItem as ModelSubItem<any>).parent.name}
              <CopyToClipboard
                text={(modelItem as ModelSubItem<any>).parent.uid}
              />
            </div>
          )}
          {(modelItem as ModelSubItem<any>).departmentID && (
            <div
              className="contentText"
              style={{
                marginTop: '10px',
              }}
            >
              Department: {(modelItem as ModelSubItem<any>).departmentID}
              {ownerDepartment ? ' - ' + ownerDepartment.name : ''}
              <CopyToClipboard
                text={(modelItem as ModelSubItem<any>).departmentID}
              />
            </div>
          )}
          {(modelItem as ModelSubItem<any>).parentProtocol && (
            <div
              className="contentText"
              style={{
                marginTop: '10px',
              }}
            >
              Protocol: {(modelItem as ModelSubItem<any>).parentProtocol.uid} -{' '}
              {(modelItem as ModelSubItem<any>).parentProtocol.name}
              <CopyToClipboard
                text={(modelItem as ModelSubItem<any>).parentProtocol.uid}
              />
            </div>
          )}
          {(modelItem as any).parentCPR != null && (
            <div
              className="contentText"
              style={{
                marginTop: '10px',
              }}
            >
              Parent CPR: {(modelItem as any).parentCPR.uid}
              <CopyToClipboard text={(modelItem as any).parentCPR.uid} />
            </div>
          )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdminModelItemDetails;
