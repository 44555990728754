import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { IoChevronForward } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryItem, {
  cloneCategory,
} from '../../../../../data/model/CategoryItem';
import ProtocolItem, {
  cloneProtocol,
} from '../../../../../data/model/ProtocolItem';
import {
  handleDataTableRowSelection,
  handleGetDepartment,
  handleSelectDataTable,
  handleUpdateEditProtocol,
} from '../../../../../store/actions';
import FolderProtocolModal from '../../../../components/Modal/FolderProtocolModal';
import ProtocolEditHeader from '../../ProtocolHeader';
import ReviewSideOut from '../../../../components/SideOut/reviewSideOut/ReviewSideOut';
import { User } from '../../../../../models';
import JSZip from 'jszip';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
// import { saveAs } from 'file-saver'; // To save the zip file

import {
  BatchQuery,
  DatabaseResponse,
  Response,
  ResponseType,
  fetchPDF,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import {
  CategoryJSON,
  createCategory,
  deleteCategory,
  duplicateCategory,
} from '../../../../../data/functions/CategoryDB';
import { ProgressStatus } from '../../../../../models';
import {
  findDepartmentOwner,
  findStartingIndex,
  getDepartmentsFromState,
  globals,
  hasAdminUserAccess,
  isOwnerDepartment,
  upgradeVersion,
} from '../../../../_global/common/Utils';
import {
  ProtocolJSON,
  createProtocol,
  deleteProtocol,
  duplicateProtocol,
  uploadInitialPDFToS3,
} from '../../../../../data/functions/ProtocolDB';
import ProtocolDataTable from './ProtocolDataTable';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import Loading from '../../../../components/Loading/Loading';
import EditFolderModal from '../../../../components/Modal/EditFolderModal';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import SearchBar from '../../../../components/Search/SearchBar';
import { RiUpload2Line } from 'react-icons/ri';
import MoveFolderModal from '../../../../components/Modal/MoveFolderModal';
import ModelItem from '../../../../../data/model/ModelItem';
import ModelSubItem from '../../../../../data/model/ModelSubItem';
import { isEqual, set, update } from 'lodash';
import { handleToggleEnabled } from '../../../../../data/functions/ModelDB';

/* 10-10-23 Arul: Created ProtocolEditMainPage Component for Protocol Edit Screen*/
const ProtocolEditMainPage = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDragActive, setIsDragActive] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState<File[] | undefined>(
    undefined
  );
  const reducerState = useSelector((state: any) => state.department);

  /**
   * The table information
   *  - breadcrumpData: The breadcrump data ("Home > Category > Subcategory > Protocol")
   *  - selectedRowData: The currently selected row data
   * -  tableData: The data to display in the table
   */
  const tableData = useSelector((state: any) => state.protocol.editDatatable);
  // const database: DatabaseResponse = useSelector((state: any) => state?.protocol?.departmentItem);
  // const categories: CategoryItem[] = database.categories;
  const user: User = useSelector((state: any) => state?.user);
  const dbState = useSelector((state: any) => state?.protocol?.departmentItem);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const { allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(database.department, reducerState);
  }, [database.department, reducerState]);
  const [categories, setCategories] = useState<CategoryItem[]>(
    database.categories
  );
  const dropRef = useRef<any>(null);

  const [searchQuery, setSearchQuery] = useState('');
  const [isSaveVisible, setIsSaveVisible] = useState(false);

  const [displayedFolderData, setDisplayedFolderData] = useState<
    (CategoryItem | ProtocolItem)[]
  >([]); //Holds the array of categories or protocols to display in the table
  const [selectedItem, setSelectedItem] =
    useState<(CategoryItem | ProtocolItem)[]>(); //Holds the currently selected folder
  const [breadcrumbItems, setBreadcrumbItems] = useState<any[]>([
    {
      label: '',
      icon: <FaHome className="breadCrumbIcon icon-large" />,
      data: null,
    },
  ]); //Holds the breadcrumb items
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isEditFolder, setIsEditFolder] = useState(false);
  /* ---- VIEW BASED STATE ---- */
  const [isShowSubmenu, setIsShowSubmenu] = useState(false); //Whether the submenu is visible

  const [title, setTitle] = useState('Editing Folder Path');

  const [isOpen, setIsOpen] = useState(false);
  const [modalName, setModalName] = useState('');
  const [isMoveModal, setIsMoveModal] = useState(false);

  /* 10-09-2023 Arul: To render Bread Crumb Home icon initially */
  const home = {
    label: '',
    icon: <FaHome className="breadCrumbIcon icon-large" />,
    data: null,
  };

  const [isSubFolder, setIsSubFolder] = useState(false);
  const [isRename, setIsRename] = useState(false);
  const [reviewSideOutVisible, setReviewSideOutVisible] = useState(false);
  const newButtonRef = useRef<any>(null);
  const [loading, setLoading] = useState<string | null>(null);
  const [newOrder, setNewOrder] = useState<
    (CategoryItem | ProtocolItem)[] | null
  >(null);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const selectedRowRef = useRef<any>(null);
  const selectedAdminAccess = useMemo(() => {
    return (
      tableData.selectedRowData &&
      tableData.selectedRowData.length > 0 &&
      tableData.selectedRowData?.every(
        (row: ModelItem<any> | ModelSubItem<any>) =>
          hasAdminUserAccess(database.department, reducerState, user, row) &&
          row.status !== ProgressStatus.DEACTIVATED
      )
    );
  }, [database.department, reducerState, user, tableData.selectedRowData]);

  const adminLevel = useMemo(() => {
    return database.department?.adminLevel ?? 4;
  }, [database]);

  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    (CategoryItem | ProtocolItem)[]
  >([]);

  const location = useLocation();
  const initialState = location.state;

  // Cleanup function to clear selection when component unmounts
  useEffect(() => {
    return () => {
      dispatch<any>(
        handleDataTableRowSelection({
          selectedRowData: null,
        })
      );
    };
  }, []); // Empty dependency array since this is needed only on mount/unmount

  // Update the selected item in the table when the component is mounted
  // Coming from Activity Log Sidebar
  useEffect(() => {
    if (initialState?.initialSelectedItem) {
      // Update local state first
      setSelectedItem([initialState.initialSelectedItem]);
      selectedRowRef.current = [initialState.initialSelectedItem];

      // Then update Redux in next tick to ensure local state is set
      setTimeout(() => {
        dispatch<any>(
          handleSelectDataTable({
            selectedRowData: [initialState.initialSelectedItem],
            breadcrumpData: [home],
            // tableData: database.categories,
          })
        );
      }, 0);
    }
  }, [initialState]);

  // Adding error boundary for selection state
  // This is needed when the selection is not in the current data
  // useEffect(() => {
  //   if (selectedItem?.length) {
  //     const validSelection = selectedItem.filter((item) =>
  //       displayedFolderData.some((data) => data.uid === item.uid)
  //     );

  //     if (!isEqual(validSelection, selectedItem)) {
  //       selectedRowRef.current = validSelection;
  //       setSelectedItem(validSelection);
  //       dispatch<any>(
  //         handleDataTableRowSelection({
  //           selectedRowData: validSelection,
  //         })
  //       );
  //     }
  //   }
  // }, [displayedFolderData]); // Add when displayed data changes

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Example: Detect "Ctrl + N"
      if (event.ctrlKey && event.key === 'n' && adminLevel > 1) {
        event.preventDefault();
        setModalName(
          breadcrumbItems?.length > 1 ? 'New Protocol' : 'New Folder'
        );
        setIsOpen(true);
        // Add your custom logic here
      } else if (
        event.ctrlKey &&
        event.key === 'r' &&
        selectedItem &&
        selectedItem.length > 0 &&
        adminLevel > 1
      ) {
        event.preventDefault();
        setIsRename(true);
        // Add your custom logic here
      } else if (
        event.ctrlKey &&
        event.key === 'm' &&
        selectedItem &&
        selectedItem.length > 0 &&
        adminLevel > 1
      ) {
        event.preventDefault();
        setIsMoveModal(true);
        // Add your custom logic here
      } else if (event.ctrlKey && event.key === '.') {
        event.preventDefault();
        console.log('Ctrl + . detected');
        //Find the next item in the list
        let index =
          selectedItem && selectedItem.length > 0
            ? displayedFolderData.findIndex(
                (item: CategoryItem | ProtocolItem) =>
                  item.uid === selectedItem[0].uid
              )
            : -1;
        if (index < displayedFolderData.length - 1)
          dispatch<any>(
            handleDataTableRowSelection({
              selectedRowData: [displayedFolderData[index + 1]],
            })
          );
        else if (index === displayedFolderData.length - 1)
          dispatch<any>(
            handleDataTableRowSelection({
              selectedRowData: [displayedFolderData[0]],
            })
          );
        // handleTabClick(displayedFolderData[index + 1], false);
        // Add your custom logic here
      } else if (event.ctrlKey && event.key === ',') {
        event.preventDefault();
        //Find the next item in the list
        let index =
          selectedItem && selectedItem.length > 0
            ? displayedFolderData.findIndex(
                (item: CategoryItem | ProtocolItem) =>
                  item.uid === selectedItem[0].uid
              )
            : displayedFolderData.length;
        if (index > 0 && index < displayedFolderData.length + 1)
          dispatch<any>(
            handleDataTableRowSelection({
              selectedRowData: [displayedFolderData[index - 1]],
            })
          );
        else if (index === 0)
          dispatch<any>(
            handleDataTableRowSelection({
              selectedRowData: [
                displayedFolderData[displayedFolderData.length - 1],
              ],
            })
          );
      } else if (
        event.ctrlKey &&
        event.key === 'b' &&
        breadcrumbItems.length > 1
      ) {
        event.preventDefault();
        //Move up the breadcrumb
        handleCrumbClick(
          breadcrumbItems[breadcrumbItems.length - 2],
          breadcrumbItems.length - 2
        );
        //Find the next item in the list
      } else if (event.ctrlKey && event.key === 'Enter' && selectedItem) {
        event.preventDefault();
        //Move up the breadcrumb
        handleTabClick(selectedItem[selectedItem.length - 1], true);

        //Find the next item in the list
      } else if (event.ctrlKey && event.key === 'e' && selectedItem) {
        event.preventDefault();
        setIsEditFolder(true);
        // if (selectedItem instanceof CategoryItem) {
        //   setIsOpen(true);
        //   setModalName('Edit Folder');
        // } else if (selectedItem instanceof ProtocolItem) {
        //   setIsOpen(true);
        //   setModalName('Edit Protocol');
        // }
      } else if (
        event.ctrlKey &&
        event.shiftKey &&
        event.key === 'O' &&
        selectedItem
      ) {
        event.preventDefault();
        //Open the selected items
        for (let i = 0; i < selectedItem.length; i++) {
          console.log('Selected Item [' + i + ']: ', selectedItem[i]);
        }
      }

      // // Example: Detect "Alt + Shift + A"
      // if (event.altKey && event.shiftKey && event.key === 'A') {
      //   event.preventDefault();
      //   console.log('Alt + Shift + A detected');
      //   // Add your custom logic here
      // }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Remove event listener on cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [breadcrumbItems, selectedItem, displayedFolderData, adminLevel]);

  // useEffect(() => {
  //   const checkIfClickedOutside = (e: MouseEvent) => {
  //     if (!isShowSubmenu) return;
  //     if (
  //       isShowSubmenu &&
  //       newButtonRef.current &&
  //       !newButtonRef.current.contains(e.target as Node)
  //     )
  //       setIsShowSubmenu(false);
  //   };
  //   document.addEventListener('click', checkIfClickedOutside);
  //   return () => {
  //     document.removeEventListener('click', checkIfClickedOutside);
  //   };
  // }, [isShowSubmenu, newButtonRef]);

  const reloadDatabase = async () => {
    // if (!loading) {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    loadDatabase(database, dispatch, true).then((resp: Response) => {
      if (resp.type === ResponseType.Success) {
        const newDB: DatabaseResponse = resp.data;
        setDatabase(newDB);
        setCategories(newDB.categories);
        dispatch<any>(handleGetDepartment(newDB));

        // /* Determine the current table data
        //  *  - If the breadcrumb items is empty, then we are at the root
        //  *  - If the breadcrumb items has > 1 items, then we are at a category
        //  *  - Replace the selcted item with the new item
        //  *  - Replace the table data with the new table data
        //  */
        // let selItem = null;
        // if (selectedItem != null) {
        //   if (selectedItem instanceof CategoryItem) {
        //     const newItem = newDB.categories.find(
        //       (category: CategoryItem) => category.uid === selectedItem.uid
        //     );
        //     if (newItem != null) selItem = newItem;
        //   } else if (selectedItem instanceof ProtocolItem) {
        //     const newItem = newDB.protocols.find(
        //       (protocol: ProtocolItem) => protocol.uid === selectedItem.uid
        //     );
        //     if (newItem != null) selItem = newItem;
        //   }
        // }

        /** 1-10-24 Hazlett: Do not change he breadcrumps unless they are equal - FIXES Edit Folder Path -> Protocol -> Edit Folder path routing bug */

        if (tableData?.breadcrumpData?.length === breadcrumbItems.length) {
          let tableData: any = {
            breadcrumpData: [home],
            selectedRowData: selectedRowRef.current,
            tableData: newDB.categories,
          };

          /* Loop through the breadcrumb items and update to the new database information */
          for (let i = 1; i < breadcrumbItems.length; i++) {
            const isLastItem = i === breadcrumbItems.length - 1;
            const item: CategoryItem = breadcrumbItems[i].data;

            /* Update the category item in the breadcrumps */
            if (!isLastItem) {
              const newItem = newDB.categories.find(
                (category: CategoryItem) =>
                  category.uid === item.uid || category.activeID === item.uid
              );
              if (newItem != null)
                tableData.breadcrumpData.push({
                  icon: undefined,
                  label: newItem.name,
                  data: newItem,
                });
            } else {
              /* Update the data in the table */
              const newItem = newDB.categories.find(
                (category: CategoryItem) =>
                  category.uid === item.uid || category.activeID === item.uid
              );
              if (newItem != null) {
                //  this is the case when we are inside the category and updating the selectedRow

                tableData.selectedRowData = selectedRowRef.current;
                tableData.breadcrumpData.push({
                  icon: undefined,
                  label: newItem.name,
                  data: newItem,
                });
                tableData.tableData = [
                  ...newItem.subCategories,
                  ...newItem.protocols,
                ];
              }
            }
          }
          dispatch<any>(handleSelectDataTable(tableData));
        }
      } else {
        console.error('ERROR LOADING DATABASE', resp.data);
      }
    });
    // }
  };

  // let debounceReload= debounce(reloadDatabase, 1000);

  /* 1-10-24 Hazlett:  Update the selected row data and breadcrumb data in state initially */
  useEffect(() => {
    if (globals.debug) console.log('RELOAD DATABASE');
    setAllCheckedBtn(false);
    reloadDatabase();
    /* 10-19-2023  Arul:  Update the selected row data and breadcrumb data in state*/
    // const handleUpdataDataTable = async () => {
    //   const data = {
    //     breadcrumpData: [],
    //     selectedRowData: null,
    //     tableData: database.categories,
    //   };
    //   await dispatch<any>(handleSelectDataTable(data));
    // };
    // if (!tableData?.breadcrumpData?.length) {
    //   handleUpdataDataTable();
    // }
    // dispatch<any>(handleUpdateEditFolderCategory(database.categories)); //NOT USED
  }, []);

  useEffect(() => {
    /* 10-19-2023  Arul: Retrive the table selection and breadcrumb values*/
    if (!tableData?.breadcrumpData) {
      tableData.breadcrumpData = [];
    }

    if (tableData?.breadcrumpData?.length)
      setBreadcrumbItems(tableData.breadcrumpData);

    // Only update if values are different
    if (!isEqual(selectedItem, tableData.selectedRowData)) {
      selectedRowRef.current = tableData.selectedRowData;
      setSelectedItem(tableData.selectedRowData);
    }

    let lastBreadCrumb =
      tableData?.breadcrumpData[tableData?.breadcrumpData?.length - 1];

    /* At the base folder */
    if (lastBreadCrumb) {
      if (lastBreadCrumb.data == null)
        setDisplayedFolderData(database.categories);
      else if (lastBreadCrumb.data.TAG === 'CategoryItem') {
        //Find the category in the database
        const category: CategoryItem | undefined = database.categories.find(
          (category: CategoryItem) => category.uid === lastBreadCrumb.data.uid
        );
        if (category != null) {
          setDisplayedFolderData([
            ...category.subCategories,
            ...category.protocols,
          ]);
        } else {
          console.error('ERROR FINDING CATEGORY', lastBreadCrumb.data);
        }
      }
    } else {
      setDisplayedFolderData(database.categories);
    }

    // if (tableData.isRowSelected) {
    //     setIsSubFolder(true)
    //     setSelectedColumn(tableData?.selectedRowData)
    // } else {
    //     setIsSubFolder(false)
    //     setIsRename(false);
    // }

    setIsShowSubmenu(false);
  }, [tableData, database]);

  /* 10-19-2023  Arul:  Update the selected row data and breadcrumb data in state*/
  const handleUpdataDataTable = useCallback(
    (tableData: any, selectedData: any, breadcrumb: any) => {
      const data = {
        breadcrumpData: breadcrumb,
        selectedRowData: selectedData,
        tableData: tableData,
      };
      dispatch<any>(handleSelectDataTable(data));
    },
    [tableData]
  );

  /* 10-10-23 Arul: function for handling the Add new folder*/
  const handleAddNewFolder = () => {
    setIsOpen(true);
    setModalName('New Folder');
  };

  /* 10-10-23 Arul: function for handling the Add new protocol*/
  const handleAddNewProtocol = () => {
    setIsOpen(true);
    setModalName('New Protocol');
  };

  const handleRenameCall = async (
    selectedItem: CategoryItem | ProtocolItem,
    value: string
  ): Promise<CategoryItem | ProtocolItem | undefined> => {
    /* Base Case: No change in name */

    selectedItem.name = value.trim();

    if (selectedItem.TAG === 'CategoryItem') {
      let newItem = cloneCategory(selectedItem as CategoryItem);
      newItem.name = value;
      newItem.activeID =
        selectedItem.status === ProgressStatus.ACTIVE
          ? selectedItem.uid
          : selectedItem.activeID;
      newItem.status = ProgressStatus.DRAFT;
      newItem.version = upgradeVersion(
        newItem.version ? selectedItem.version : 'v1.0.0'
      );
      newItem.modifiedBy = user;
      let results: Response = await createCategory(
        newItem,
        selectedItem as CategoryItem
      );

      if (results.type === ResponseType.Success) {
        let newCategory: CategoryItem = results.data;

        /* Reload the database -- OR use the subscribe method */
        reloadDatabase();
        return newCategory;
      } else {
        if (globals.debug)
          console.log(
            'ERROR CREATING DRAFT CATEGORY FOR RENAME:',
            results.data
          );
      }
    } else if (selectedItem.TAG === 'ProtocolItem') {
      /* Rename the protocol item as a DRAFT */
      if (globals.debug) console.log('RENAME PROTOCOL ITEM', value);
      let newItem = cloneProtocol(selectedItem as ProtocolItem);
      newItem.name = value;
      newItem.version = upgradeVersion(
        selectedItem.version != null ? selectedItem.version : 'v1.0.0'
      );
      newItem.activeID =
        selectedItem.status === ProgressStatus.ACTIVE
          ? selectedItem.uid
          : selectedItem.activeID;
      newItem.status = ProgressStatus.DRAFT;
      newItem.modifiedBy = user;

      createProtocol(newItem, selectedItem as ProtocolItem).then(
        (results: Response) => {
          if (results.type === ResponseType.Success) {
            let newProtocol: ProtocolItem = results.data;
            if (globals.debug)
              console.log('CREATED DRAFT PROTOCOL FOR RENAME:', newProtocol);

            //Replace the item in the displayedFolderData with the new item
            let index = displayedFolderData.findIndex(
              (item: CategoryItem | ProtocolItem) =>
                item.uid === newProtocol.uid ||
                item.activeID === newProtocol.uid
            );
            if (index >= 0) displayedFolderData[index] = newProtocol;
            reloadDatabase();
          }
        }
      );

      return newItem;
    }
    return undefined;
  };

  const handleMoveProtocol = async (
    protocol: ProtocolItem,
    category: CategoryItem
  ) => {
    let newProtocol = cloneProtocol(protocol);
    newProtocol.parent = category;
    newProtocol.index =
      category.protocols.length +
      findStartingIndex(database.department, reducerState, protocol);
    newProtocol.activeID =
      protocol.status === ProgressStatus.ACTIVE
        ? protocol.uid
        : protocol.activeID;
    newProtocol.status = ProgressStatus.DRAFT;
    newProtocol.version =
      protocol.status === ProgressStatus.ACTIVE
        ? upgradeVersion(protocol.version ? protocol.version : 'v1.0.0')
        : protocol.version;
    newProtocol.modifiedBy = user;

    if (globals.debug)
      console.log(
        'Moving Protocol',
        protocol.name,
        'To Folder',
        category.name,
        'from Folder',
        protocol.parent.name
      );
    let results: Response = await createProtocol(newProtocol, protocol);
    if (results.type === ResponseType.Success) {
      if (globals.debug) console.log('MOVED PROTOCOL', results.data);
      reloadDatabase();
    } else {
      console.error('ERROR MOVING PROTOCOL', results.data);
    }
  };

  /* 10-20-23 Arul:Function for rename the category and protocol name*/
  const handleNameUpdate = useCallback(
    (value: any) => {
      setIsRename(false);
      if (
        value &&
        value !== '' &&
        selectedItem &&
        selectedItem.length === 1 &&
        value !== selectedItem[0].name
      ) {
        selectedItem[0].name = value;
        handleRenameCall(selectedItem[0], value).then(
          (newItem: CategoryItem | ProtocolItem | undefined) => {
            if (newItem != null) {
              // setSelectedItem(newItem);
              dispatch<any>(
                handleDataTableRowSelection({
                  selectedRowData: [newItem],
                })
              );
            }
          }
        );
      }
    },
    [selectedItem, breadcrumbItems]
  );

  /* 10-11-23 Arul: function for handling the add folder and protocol submission*/
  const handleAdd = useCallback(
    async (data: any) => {
      setIsShowSubmenu(false);
      let index = displayedFolderData.length;
      if (database.department.parentDep?.parentDep) index += 10000;
      else if (database.department.parentDep) index += 1000;

      /* This is required for the previous databse logic */
      if (data.isRestrictive === false && globals.oldDBDoseStyle)
        data.pairedDeps = allSubDeps;

      /* Add the new protocol */
      if (modalName === 'New Protocol') {
        if (!data.value || !data.pdf || !data.nickName) {
          console.error('ERROR CREATING PROTOCOL: MISSING DATA', data);
          return;
        }
        setDroppedFiles(undefined);
        //Filter the added pdf out of the dropped files

        // if(globals.debug) console.log('BEGORE DROPPED FILES:', droppedFiles);
        // const files = droppedFiles.filter(
        //   (file) => file.name !== data.pdf.name
        // );
        // if(globals.debug) console.log('DROPPED FILES:', files);
        // setDroppedFiles([...files]);
        // if (files.length === 0) setIsOpen(false);

        setIsOpen(false);

        setLoading('Creating Protocol...');
        const breadcrumblength = breadcrumbItems?.length - 1;
        let parent: CategoryItem = breadcrumbItems[breadcrumblength]?.data;
        if (selectedItem && selectedItem.hasOwnProperty('subCategories'))
          parent = selectedItem[0] as CategoryItem;

        /* Upload the PDF to the S3 bucket */
        let result: Response = await uploadInitialPDFToS3(
          database.department,
          parent,
          data.value,
          data.pdf,
          (error: any) => {
            console.error('ERROR UPLOADING PDF TO S3', error);
          }
        );
        if (result.type === ResponseType.Failure) {
          console.error('ERROR UPLOADING PDF TO S3', result.data);
          return;
        }

        let pdfID: string = result.data;

        /* Create the new protocol item */
        let json: ProtocolJSON = {
          departmentID: data.owner.id,
          name: data.value,
          nickname: data.nickName,
          pdfID: pdfID,
          rangeLow: 0,
          rangeHigh: globals.MAX_VALUE,
          parentCategory: parent,
          pairedDepsIDs: (data.pairedDeps as DepartmentItem[]).map(
            (dep) => dep.id
          ),
          pairedProtocols: [],
          medications: [],
          infusions: [],
          equipment: [],
          electrical: [],
          forms: [],
          createdBy: user.id,
          status: ProgressStatus.DRAFT,
          activeID: null,
          version: 'v1.0.0',
          pdfVersion: 'v1.0.0',
          index: index,
          isPublic: database.department.isPublic,
          isRestrictive: data.isRestrictive ? data.isRestrictive : false,
          keychainID: null,
        };

        /* Create the protocol item */
        let results: Response = await createProtocol(json);
        if (results.type === ResponseType.Success) {
          let newProtocol: ProtocolItem = results.data;
          if (globals.debug)
            console.log('CREATED DRAFT PROTOCOL:', newProtocol);

          /* Reload the database -- OR use the subscribe method */
          reloadDatabase();
        } else {
          if (globals.debug)
            console.log('ERROR CREATING DRAFT PROTOCOL:', results.data);
        }
        setLoading(null);
      } else {
        /* Otherwise, add the new folder */
        //folder modal
        // const mockData: any = newCategoryItem(data.value, database)
        if (tableData.breadcrumpData?.length > 1) {
          if (globals.debug)
            console.log(
              'TODO: Creating new sub folder in the sub folder',
              data
            );

          // event for adding sub category
          // if(globals.debug) console.log("SUBcategory", currentrenderData);
          // currentrenderData?.addSubCategories(mockData);

          // handleUpdatedTable(currentrenderData, tableData?.isRowSelected);
          if (globals.debug)
            console.log('Creating new sub folder in the folder', data);
        } else {
          setIsOpen(false);

          if (globals.debug)
            console.log('Creating new folder in the base folder', data);
          let ids = data.pairedDeps
            ? data.pairedDeps.map((dep: DepartmentItem) => dep.id)
            : [];
          let json: CategoryJSON = {
            name: data.value,
            status: ProgressStatus.DRAFT,
            index: index,
            departmentID: data?.owner?.id ?? database.department.id,
            pairedDeps: ids,
            activeID: null,
            version: 'v1.0.0',
            createdBy: user.id,
            isPublic: database.department.isPublic,
            isRestrictive: data.isRestrictive ? data.isRestrictive : false,
            keychainID: null,
          };
          createCategory(json)
            .then((response: Response) => {
              if (response.type === ResponseType.Success) {
                reloadDatabase();
              } else {
                console.error('ERROR CREATING CATEGORY', response.data);
              }
            })
            .catch((error: any) => {
              console.error('ERROR CREATING CATEGORY', error);
            });
        }
      }
    },
    [
      isSubFolder,
      tableData,
      modalName,
      selectedItem,
      breadcrumbItems,
      displayedFolderData,
      allSubDeps,
    ]
  );

  const handleDuplicateProtocol = async (
    data: any,
    copyProtocol: ProtocolItem
  ) => {
    if (!data.value || !data.pdf || !data.nickName) {
      console.error('ERROR CREATING PROTOCOL: MISSING DATA', data);
      return;
    }
    setIsOpen(false);
    setLoading('Creating Protocol...');
    let parent: CategoryItem =
      breadcrumbItems[breadcrumbItems?.length - 1]?.data;
    if (selectedItem && selectedItem.hasOwnProperty('subCategories'))
      parent = selectedItem[0] as CategoryItem;

    /* Upload the PDF to the S3 bucket */
    let result: Response = await uploadInitialPDFToS3(
      database.department,
      parent,
      data.value,
      data.pdf,
      (error: any) => {
        console.error('ERROR UPLOADING PDF TO S3', error);
      }
    );
    if (result.type === ResponseType.Failure) {
      console.error('ERROR UPLOADING PDF TO S3', result.data);
      return;
    }

    let pdfID: string = result.data;
    let depIDs = data.pairedDeps.map((dep: DepartmentItem) => dep.id);
    let newProtocol = cloneProtocol(copyProtocol);
    newProtocol.name = data.value;
    newProtocol.nickname = data.nickName;
    newProtocol.pdfUrl = pdfID;
    newProtocol.pairedDepIDs = depIDs;
    newProtocol.createdBy = user;
    newProtocol.parent = parent;
    newProtocol.index =
      displayedFolderData.length +
      findStartingIndex(database.department, reducerState, copyProtocol);
    let results: Response = await duplicateProtocol(
      database.department,
      newProtocol,
      copyProtocol,
      user
    );
    if (results.type === ResponseType.Success) {
      if (globals.debug) console.log('DUPLICATED PROTOCOL', results.data);
      reloadDatabase();
    } else {
      console.error('ERROR DUPLICATING PROTOCOL', results.data);
    }
    setLoading(null);
  };

  const handleDuplicateFolder = async (data: any, copyFolder: CategoryItem) => {
    if (!data.value) {
      console.error('ERROR CREATING CATEGORY: MISSING DATA', data);
      return;
    }
    setIsOpen(false);
    setLoading('Creating Folder...');

    let depIDs = data.pairedDeps.map((dep: DepartmentItem) => dep.id);
    let newFolder = cloneCategory(copyFolder);
    newFolder.name = data.value;
    newFolder.pairedDepIDs = depIDs;
    newFolder.index =
      displayedFolderData.length +
      findStartingIndex(database.department, reducerState, copyFolder);
    let results: Response = await duplicateCategory(
      database.department,
      newFolder,
      copyFolder,
      user
    );
    if (results.type === ResponseType.Success) {
      if (globals.debug) console.log('DUPLICATED CATEGORY', results.data);
      reloadDatabase();
    } else {
      console.error('ERROR DUPLICATING PROTOCOL', results.data);
    }
    setLoading(null);
  };

  const handleReorderItems = (items: (CategoryItem | ProtocolItem)[]) => {
    /* Verify that the order is different then what is in displayedFolderData */
    let isDifferent = false;
    for (let i = 0; i < items.length; i++) {
      if (items[i].uid !== displayedFolderData[i].uid) {
        isDifferent = true;
        break;
      }
    }

    if (!isDifferent) {
      setNewOrder(null);
      return;
    }

    /* Show the save button */
    setNewOrder(items);
  };

  /* 10-11-23 Arul: function for handling to open submenu and new folder modal*/
  const handleNewButtonClick = () => {
    // if (tableData.isRowSelected) {
    setIsShowSubmenu(true);
    // } else {
    // 	if (breadcrumbItems?.length === 1) {
    // 		handleAddNewFolder();
    // 	} else {
    // 		handleAddNewProtocol();
    // 	}
    // }
  };

  /* 10-11-23 Arul: function for handling to open submenu and new folder modal*/
  const handleCancel = () => {
    setLoading('Navigating...');
    // Clear selection before navigating
    dispatch<any>(
      handleDataTableRowSelection({
        selectedRowData: null,
      })
    ).then(() => {
      setLoading(null);
      navigate(`/protocol`);
    });
  };

  /* 10-11-23 Arul: function for save button onclick*/
  const handleSave = async () => {
    if (newOrder) {
      setLoading('Saving...');
      let promiseFunctions: (() => Promise<Response>)[] = [];
      for (let i = 0; i < newOrder.length; i++) {
        let item = newOrder[i];
        let newIndex =
          findStartingIndex(database.department, reducerState, item) + i;
        if (
          !isOwnerDepartment(database.department, item) ||
          item.index === newIndex
        )
          continue;
        if (item.TAG === 'CategoryItem') {
          let newItem = cloneCategory(item as CategoryItem);
          newItem.index = newIndex;
          newItem.activeID =
            item.status === ProgressStatus.ACTIVE ? item.uid : item.activeID;
          newItem.status = ProgressStatus.DRAFT;
          newItem.version = upgradeVersion(
            item.version ? item.version : 'v1.0.0'
          );
          newItem.modifiedBy = user;
          promiseFunctions.push(() =>
            createCategory(newItem, item as CategoryItem)
          );
        } else if (item.TAG === 'ProtocolItem') {
          let newItem = cloneProtocol(item as ProtocolItem);
          newItem.index = newIndex;
          newItem.activeID =
            item.status === ProgressStatus.ACTIVE ? item.uid : item.activeID;
          newItem.status = ProgressStatus.DRAFT;
          newItem.version = upgradeVersion(
            item.version ? item.version : 'v1.0.0'
          );
          newItem.modifiedBy = user;
          promiseFunctions.push(() =>
            createProtocol(newItem, item as ProtocolItem)
          );
        }
      }
      let results = await BatchQuery(promiseFunctions);
      for (let i = 0; i < results.length; i++) {
        if (results[i].type === ResponseType.Success) {
          if (globals.debug) console.log('SAVED ITEM', results[i].data);
        } else {
          console.error('ERROR SAVING ITEM', results[i].data);
        }
      }
      reloadDatabase();
      setNewOrder(null);
      setLoading(null);
    }
    if (itemDeactivationStatus && itemDeactivationStatus.length > 0) {
      try {
        let promises: any[] = [];
        for (let i = 0; i < itemDeactivationStatus.length; i++) {
          promises.push(
            handleToggleEnabled(
              user,
              database.department,
              reducerState,
              itemDeactivationStatus[i]
            )
          );
        }
        let results = await Promise.all(promises);
        for (let i = 0; i < results.length; i++) {
          if (results[i].type === ResponseType.Success) {
            if (globals.debug) console.log('SAVED ITEM', results[i].data);
          } else {
            console.error('ERROR SAVING ITEM', results[i].data);
          }
        }
        setItemDeactivationStatus([]);
        reloadDatabase();
      } catch (error) {
        console.error('ERROR SAVING DEACTIVATE STATE', error);
      }
    }
  };

  /* 10-11-23 Arul: function for save review onclick*/
  const handleReview = () => {
    setReviewSideOutVisible(true);
  };

  /* 10-09-2023 Arul: To handle and populate Bread Crumb items label*/
  const handleTabClick = (
    item: CategoryItem | ProtocolItem,
    isDoubleClick: boolean
  ) => {
    if (item.TAG === 'CategoryItem') {
      setIsShowSubmenu(false);
      if (isDoubleClick) {
        // setIsSubFolder(true);
        const newItem = { label: item?.name, data: item };
        const tableData = [
          ...(item as CategoryItem).subCategories,
          ...(item as CategoryItem).getProtocols(),
        ];
        const breadCrumbData = [...breadcrumbItems, newItem];
        handleUpdataDataTable(tableData, null, breadCrumbData);
        setItemDeactivationStatus([]);
      }
    } else {
      if (isDoubleClick) {
        // setIsSubFolder(true);
        // Clear selection before navigating to protocol detail
        dispatch<any>(
          handleDataTableRowSelection({
            selectedRowData: null,
          })
        );
        const state = { selectedProtocol: item, editMode: true };
        const formattedString = (item as ProtocolItem).nickname.replace(
          /[\s\/]/g,
          '-'
        );
        navigate(`/${formattedString}/protocol-detail`, { state });
        dispatch<any>(handleUpdateEditProtocol(item));
      }
    }
  };

  const handleToggleEnabledUI = async (item: CategoryItem | ProtocolItem) => {
    let find = itemDeactivationStatus.find(
      (newItem) => newItem.uid === item.uid
    );
    if (find)
      setItemDeactivationStatus(
        itemDeactivationStatus.filter((newItem) => newItem.uid !== item.uid)
      );
    else setItemDeactivationStatus([...itemDeactivationStatus, item]);
  };

  /* 10-09-2023 Arul: Function to render respective data when the Bread Crumb items is clicked*/
  const handleCrumbClick = (item: any, index: number) => {
    /* Home Icon Seleced */
    if (index === 0) {
      // setIsSubFolder(false);
      // Clicked on the home icon
      const breadCrumbData = [home];
      handleUpdataDataTable(categories, null, breadCrumbData);
    } else {
      /* Otherwise, a category was selected */
      const newBreadcrumbItems = breadcrumbItems?.slice(0, index + 1);
      const selectedItem = newBreadcrumbItems[index]?.data;
      const result = [
        ...selectedItem?.subCategories,
        ...selectedItem?.getProtocols(),
      ];
      handleUpdataDataTable(
        result?.length ? result : [],
        null,
        newBreadcrumbItems
      );
    }
    setItemDeactivationStatus([]);
  };

  const handleDeleteItem = async (
    isPermanent: boolean,
    singlDelete = true,
    itemToDelete?: ProtocolItem | CategoryItem // Accept itemToDelete as an optional parameter
  ): Promise<Response> => {
    try {
      const selected = itemToDelete ?? selectedItem?.[0]; // Use itemToDelete if provided, otherwise use selectedItem[0]

      if (!selected) {
        setIsDeleteModal(false);
        return {
          type: ResponseType.Failure,
          data: { message: 'No item selected for deletion' },
        };
      }

      if (selected.TAG === 'CategoryItem') {
        console.log('DELETING CATEGORY', selected);
        let results: Response = await deleteCategory(
          database.department,
          selected as CategoryItem,
          user,
          !isPermanent,
          true
        );
        if (results.type === ResponseType.Success) {
          if (singlDelete) {
            setSnackbarMessage(`Successfully deleted ${selected.name}`);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
          }

          setSelectedItem(
            (prevItems: (ProtocolItem | CategoryItem)[] | undefined) => {
              const updatedItems = (prevItems ?? []).filter(
                (item) => item.uid !== selected.uid
              );

              if (updatedItems.length === 0) {
                setIsDeleteModal(false);
              }

              let newTableData = tableData.tableData.filter(
                (item: CategoryItem) => item.uid !== selected.uid
              );
              if (singlDelete) {
                dispatch<any>(
                  handleSelectDataTable({
                    ...tableData,
                    selectedRowData: updatedItems,
                    tableData: newTableData,
                  })
                );
              }
              return updatedItems;
            }
          );
          if (singlDelete) {
            reloadDatabase();
          }
          return {
            type: ResponseType.Success,
            data: { message: 'Successfully deleted category' },
          };
        } else {
          if (singlDelete) {
            setSnackbarMessage('Failed to delete category');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
          return {
            type: ResponseType.Failure,
            data: { message: 'Failed to delete category' },
          };
        }
      } else if (selected.TAG === 'ProtocolItem') {
        let results: Response = await deleteProtocol(
          database.department,
          selected as ProtocolItem,
          user,
          !isPermanent,
          true,
          true
        );
        if (results.type === ResponseType.Success) {
          if (singlDelete) {
            setSnackbarMessage(`Successfully deleted ${selected.name}`);
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
          }

          setSelectedItem(
            (prevItems: (ProtocolItem | CategoryItem)[] | undefined) => {
              const updatedItems = (prevItems ?? []).filter(
                (item) => item.uid !== selected.uid
              );

              if (updatedItems.length === 0) {
                setIsDeleteModal(false);
              }

              let newTableData = tableData.tableData.filter(
                (item: ProtocolItem) => item.uid !== selected.uid
              );
              if (singlDelete) {
                dispatch<any>(
                  handleSelectDataTable({
                    ...tableData,
                    selectedRowData: updatedItems,
                    tableData: newTableData,
                  })
                );
              }
              return updatedItems;
            }
          );
          if (singlDelete) {
            reloadDatabase();
          }
          return {
            type: ResponseType.Success,
            data: { message: 'Successfully deleted protocol' },
          };
        } else {
          console.error('ERROR DELETING PROTOCOL', results.data);
          if (singlDelete) {
            setSnackbarMessage('Failed to delete protocol');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
          }
          return {
            type: ResponseType.Failure,
            data: { message: 'Failed to delete protocol' },
          };
        }
      }
    } catch (error) {
      console.error('ERROR DELETING ITEM', error);
      return {
        type: ResponseType.Failure,
        data: { message: 'Failed to delete item' },
      };
    }

    return {
      type: ResponseType.Failure,
      data: { message: 'No item selected for deletion' },
    };
  };

  const handleConfirmDeleteItems = async () => {
    if (allCheckedBtn && selectedItem) {
      try {
        let successCount = 0;
        let failureCount = 0;
        let type =
          selectedItem[0].TAG === 'CategoryItem' ? 'folder' : 'protocol';
        for (const parmMedication of selectedItem) {
          // const isDraft =
          //   parmMedication.status === ProgressStatus.DRAFT &&
          //   parmMedication.activeID == null;
          let response = await handleDeleteItem(false, false, parmMedication); // Pass parmMedication as itemToDelete
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        if (successCount > 0) {
          setSnackbarMessage(`Successfully deleted ${successCount} ${type}s.`);
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(`Failed to delete ${failureCount} ${type}s.`);
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);

        let newTableData = tableData.tableData.filter(
          (item: CategoryItem | ProtocolItem) =>
            !selectedItem.some(
              (selected: CategoryItem | ProtocolItem) =>
                selected.uid === item.uid
            )
        );
        dispatch<any>(
          handleSelectDataTable({
            ...tableData,
            selectedRowData: [],
            tableData: newTableData,
          })
        );
        reloadDatabase();
      } catch (error) {
        setSnackbarMessage('Failed to delete medication');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsDeleteModal(false);
    } else {
      const isDraft =
        selectedItem &&
        selectedItem.length > 0 &&
        selectedItem[0].status === ProgressStatus.DRAFT &&
        selectedItem[0].activeID == null;

      handleDeleteItem(!isDraft);
    }
  };

  const handleEditFolder = async (data: any) => {
    if (selectedItem == null || selectedItem.length !== 1) {
      console.error('ERROR EDITING FOLDER: NO ITEM SELECTED');
      return;
    }
    const item = selectedItem[0];
    const { value, pairedDeps, index, nickName, owner } = data;
    if (item.TAG === 'CategoryItem') {
      let newItem = cloneCategory(item as CategoryItem);
      let depIDs = pairedDeps.map((dep: DepartmentItem) => dep.id);
      newItem.departmentID = owner.id;
      newItem.name = value;
      newItem.index = index;
      newItem.activeID =
        item.status === ProgressStatus.ACTIVE ? item.uid : item.activeID;
      newItem.status = ProgressStatus.DRAFT;
      newItem.version = upgradeVersion(item.version ? item.version : 'v1.0.0');
      newItem.pairedDepIDs = depIDs;
      newItem.modifiedBy = user;
      newItem.isRestrictive =
        data.isRestrictive != null ? data.isRestrictive : false;
      newItem.departmentID = data.owner.id;

      let results: Response = await createCategory(
        newItem,
        item as CategoryItem
      );

      if (results.type === ResponseType.Success) {
        let newCategory: CategoryItem = results.data;

        /* Reload the database -- OR use the subscribe method */
        reloadDatabase();
      } else {
        if (globals.debug)
          console.log(
            'ERROR CREATING DRAFT CATEGORY FOR RENAME:',
            results.data
          );
      }
      setIsEditFolder(false);
    } else if (item.TAG === 'ProtocolItem') {
      let depIDs = pairedDeps.map((dep: DepartmentItem) => dep.id);
      let newProtocol = cloneProtocol(item as ProtocolItem);
      newProtocol.departmentID = owner.id;
      newProtocol.name = value;
      newProtocol.nickname = nickName;
      newProtocol.index = index;
      newProtocol.activeID =
        item.status === ProgressStatus.ACTIVE ? item.uid : item.activeID;
      newProtocol.status = ProgressStatus.DRAFT;
      newProtocol.version = upgradeVersion(
        item.version ? item.version : 'v1.0.0'
      );
      newProtocol.pairedDepIDs = depIDs;
      newProtocol.modifiedBy = user;
      newProtocol.isRestrictive = data.isRestrictive;
      newProtocol.departmentID = data.owner.id;
      newProtocol.pairedDeps = pairedDeps;
      let results: Response = await createProtocol(
        newProtocol,
        item as ProtocolItem
      );

      if (results.type === ResponseType.Success) {
        /* Reload the database -- OR use the subscribe method */
        reloadDatabase();
      } else {
        if (globals.debug)
          console.log(
            'ERROR CREATING DRAFT CATEGORY FOR RENAME:',
            results.data
          );
      }
      setIsEditFolder(false);
    } else {
      console.error('ERROR EDITING FOLDER:', item);
    }
  };

  const handleSearch = (searchTerm: string) => {
    let list: (CategoryItem | ProtocolItem)[] = [];
    let lastBreadCrumb =
      tableData?.breadcrumpData[tableData?.breadcrumpData?.length - 1];

    /* At the base folder */
    if (lastBreadCrumb) {
      if (lastBreadCrumb.data == null) list = database.categories;
      else if (lastBreadCrumb.data.TAG === 'CategoryItem') {
        //Find the category in the database
        const category: CategoryItem | undefined = database.categories.find(
          (category: CategoryItem) => category.uid === lastBreadCrumb.data.uid
        );
        if (category != null) {
          list = [...category.subCategories, ...category.protocols];
        } else {
          console.error('ERROR FINDING CATEGORY', lastBreadCrumb.data);
          list = [];
        }
      }
    } else list = database.categories;

    const searchResults = list.filter((item: any) => {
      return item.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    // // Keep selected items that are still in search results
    // const newSelection = selectedItem?.filter((item) =>
    //   searchResults.some((result) => result.uid === item.uid)
    // );

    // if (!isEqual(newSelection, selectedItem)) {
    //   dispatch<any>(
    //     handleDataTableRowSelection({
    //       selectedRowData: newSelection,
    //     })
    //   );
    // }

    setDisplayedFolderData(searchResults);
  };

  const handleDrop = (e: any) => {
    if (isOpen) return;
    e.preventDefault();
    /* Check if the file is a pdf */
    //Validate every file is a pdf
    let files: File[] = [];
    const dropped: FileList = e.dataTransfer?.files;
    for (let i = 0; i < dropped.length; i++) {
      const file: File = dropped[i];
      if (file.type === 'application/pdf') files.push(file);
    }
    if (files.length === 0) setDroppedFiles(undefined);
    else {
      setDroppedFiles(files);
      if (!isOpen) {
        setModalName('New Protocol');
        setIsOpen(true);
      }
    }

    // const file: File = e.dataTransfer?.files[0];
    // if (file.type === 'application/pdf') {
    //   if(globals.debug) console.log('DROPPED FILE:', file);
    //   setDroppedFile(file);
    //   if (!isOpen) {
    //     setModalName('New Protocol');
    //     setIsOpen(true);
    //   }
    // }
    setIsDragActive(false);
  };

  const downloadProtcolsZip = async (protocols: ProtocolItem[]) => {
    try {
      setLoading('Downloading PDFs...');
      let zip = new JSZip();
      let folder = zip.folder('Protocols');
      if (folder == null) {
        console.error('ERROR DOWNLOADING CATEGORY: CANNOT CREATE FOLDER');
        setLoading(null);
        return;
      }

      let filenames: string[] = [];
      for (let i = 0; i < protocols.length; i++) {
        let protocol: ProtocolItem = protocols[i];
        let result: Response = await fetchPDF(protocol.pdfUrl, protocol, true);
        if (result.type === ResponseType.Failure) {
          console.error('ERROR DOWNLOADING PDF:', result.data);
          setLoading(null);
          return;
        }
        let pdf: Blob = result.data;
        let name = protocol.name.replace(/[^a-zA-Z0-9]/g, '_') + '.pdf';

        if (filenames.includes(name)) {
          let j = 1;
          while (filenames.includes(`${protocol.name}_(${j}).pdf`)) {
            j++;
          }
          name = `${protocol.name} (${j}).pdf`;
        }
        filenames.push(name);
        folder.file(name, pdf, { binary: true });
        setLoading(
          'Downloading PDFs... (' + (i + 1) + '/' + protocols.length + ')'
        );
      }

      let content = await zip.generateAsync({ type: 'blob' }); //.then((content) => {
      //   saveAs(content, `${selectedData.name}.zip`);
      // });
      // Create a link element
      const link = document.createElement('a');

      // Set the link's href to point to the Blob URL
      link.href = window.URL.createObjectURL(content);
      link.download = `Protocols.zip`;

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link);

      // Trigger the download by simulating a click
      link.click();

      // Clean up by removing the link
      setTimeout(() => {
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }, 0);
      setLoading(null);
    } catch (error) {
      console.error('ERROR DOWNLOADING PDF:', error);
      setLoading(null);
    }
  };

  const handleDownload = async () => {
    if (!selectedItem) return;
    //  check if the selected item is a Protocol
    let protocolItems = selectedItem.filter(
      (item: any) => item.TAG === 'ProtocolItem'
    );
    if (protocolItems.length > 5) {
      downloadProtcolsZip(protocolItems as ProtocolItem[]);
      return;
    }

    for (let selectedData of selectedItem) {
      if (selectedData.TAG === 'ProtocolItem') {
        setLoading('Downloading PDF...');
        let protocol: ProtocolItem = selectedData as ProtocolItem;
        let result: Response = await fetchPDF(protocol.pdfUrl, protocol, true);
        if (result.type === ResponseType.Failure) {
          console.error('ERROR DOWNLOADING PDF:', result.data);
          setLoading(null);
          return;
        }

        let pdf: Blob = result.data;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdf);
        link.download = `${protocol.name.replace(/[^a-zA-Z0-9]/g, '_')}.pdf`;
        document.body.appendChild(link);
        link.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }, 0);
      } else if (selectedData.TAG === 'CategoryItem') {
        /* Download all the protocols in the category */
        let protocols: ProtocolItem[] = (
          selectedData as CategoryItem
        ).getProtocols();
        if (protocols.length === 0) {
          console.error('ERROR DOWNLOADING CATEGORY: NO PROTOCOLS');
          setLoading(null);
          return;
        }
        setLoading('Downloading PDFs... (0/' + protocols.length + ')');

        let zip = new JSZip();
        let folder = zip.folder(
          selectedData.name.replace(/[^a-zA-Z0-9]/g, '_')
        );
        if (folder == null) {
          console.error('ERROR DOWNLOADING CATEGORY: CANNOT CREATE FOLDER');
          setLoading(null);
          return;
        }

        let filenames: string[] = [];
        for (let i = 0; i < protocols.length; i++) {
          let protocol: ProtocolItem = protocols[i];
          let result: Response = await fetchPDF(
            protocol.pdfUrl,
            protocol,
            true
          );
          if (result.type === ResponseType.Failure) {
            console.error('ERROR DOWNLOADING PDF:', result.data);
            setLoading(null);
            return;
          }
          let pdf: Blob = result.data;
          let name = protocol.name.replace(/[^a-zA-Z0-9]/g, '_') + '.pdf';

          if (filenames.includes(name)) {
            let j = 1;
            while (filenames.includes(`${protocol.name}_(${j}).pdf`)) {
              j++;
            }
            name = `${protocol.name} (${j}).pdf`;
          }
          filenames.push(name);
          folder.file(name, pdf, { binary: true });
          setLoading(
            'Downloading PDFs... (' + (i + 1) + '/' + protocols.length + ')'
          );
        }

        let content = await zip.generateAsync({ type: 'blob' }); //.then((content) => {
        //   saveAs(content, `${selectedData.name}.zip`);
        // });
        // Create a link element
        const link = document.createElement('a');

        // Set the link's href to point to the Blob URL
        link.href = window.URL.createObjectURL(content);
        link.download = `${selectedData.name.replace(/[^a-zA-Z0-9]/g, '_')}.zip`;

        // Append the link to the body (required for Firefox)
        document.body.appendChild(link);

        // Trigger the download by simulating a click
        link.click();

        // Clean up by removing the link
        setTimeout(() => {
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }, 0);
      }
    }
    setLoading(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div
      className="screen-container"
      ref={dropRef}
      onDragOver={(e) => {
        if (!isOpen) {
          e.preventDefault();
          setIsDragActive(true);
        }
      }}
      onDragLeave={(e) => {
        if (!isOpen) {
          e.preventDefault();
          setIsDragActive(false);
        }
      }}
      onDrop={handleDrop}
    >
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {isDragActive && (
        <div className="drag-drop-overlay">
          <div
            className="cursorPointer dragDropDotGridModalWorkbook"
            style={{
              display: 'flex',
              maxWidth: '50vw',
              background: 'rgba(0, 0, 0, 0.3)',
              padding: '20px 0px',
              border: '1px dashed #00534C',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
              }}
            >
              <RiUpload2Line className="drag-drop-icon green_color" />
            </div>
            <div
              className="light_grey_text"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                marginBottom: '5px',
                fontSize: '24px',
                // color: 'white',
              }}
            >
              Drag and Drop into OneDose
            </div>
            <div
              style={{ fontSize: '12px' }}
              className="light_grey_text ketamine-general-label"
            >
              Support: PDF ONLY
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <>
          <FolderProtocolModal
            isVisible={isOpen}
            title={modalName}
            breadCrumbs={breadcrumbItems}
            handleClose={() => {
              if (droppedFiles) setDroppedFiles(undefined);
              setIsOpen(false);
            }}
            department={database.department}
            handleAdd={handleAdd}
            initialPDF={droppedFiles ? droppedFiles[0] : undefined}
            handleDuplicateProtocol={handleDuplicateProtocol}
            handleDuplicateFolder={handleDuplicateFolder}
          />
        </>
      )}

      {isEditFolder && selectedItem && selectedItem.length === 1 && (
        <EditFolderModal
          isVisible={isEditFolder}
          handleClose={() => {
            setIsEditFolder(false);
          }}
          handleAdd={handleEditFolder}
          department={database.department}
          item={selectedItem[0]}
        />
      )}

      {selectedItem &&
        selectedItem.length === 1 &&
        selectedItem[0].TAG === 'ProtocolItem' && (
          <MoveFolderModal
            isVisible={isMoveModal}
            handleClose={() => {
              setIsMoveModal(false);
            }}
            handleMove={handleMoveProtocol}
            department={database.department}
            item={selectedItem[0] as ProtocolItem}
          />
        )}

      <ReviewSideOut
        database={database}
        isVisible={reviewSideOutVisible} // Change 'visible' to 'isVisible'
        handleClose={() => {
          setReviewSideOutVisible(false);
        }}
        setVisible={setReviewSideOutVisible}
        handleAdd={() => {
          setReviewSideOutVisible(false);
        }}
        onPublish={() => {
          setReviewSideOutVisible(false);
          reloadDatabase();
          handleCancel();
        }}
      />

      {selectedItem && selectedItem.length > 0 && (
        <ConfirmModal
          isVisible={isDeleteModal}
          title={
            allCheckedBtn
              ? 'Delete ' +
                selectedItem.length +
                ' ' +
                (selectedItem[0].TAG === 'CategoryItem'
                  ? 'Folders'
                  : 'Protocols') +
                '?'
              : 'Delete ' +
                (selectedItem[0].TAG === 'CategoryItem'
                  ? 'Folder '
                  : 'Protocol ') +
                selectedItem[0].name +
                (selectedItem[0].status === ProgressStatus.DRAFT
                  ? ' (Draft)'
                  : '') +
                '?'
          }
          handleClose={() => {
            setAllCheckedBtn(false);
            setIsDeleteModal(false);
          }}
          handleSubmit={() => {
            handleConfirmDeleteItems();
            setAllCheckedBtn(false);
          }}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure that you want to delete ${selectedItem.length} ${selectedItem[0].TAG === 'CategoryItem' ? 'folders' : 'protocols'}?`
              : selectedItem[0].TAG === 'CategoryItem'
                ? `Are you sure that you want to delete folder ${selectedItem[0].name}? There is ${selectedItem[0].sum} item(s) that will be deleted with the folder. The items will be able to be able to recover from the trash bin.`
                : `Are you sure that you want to delete protocol ${selectedItem[0].name}? The item will be able to be able to recover from the trash bin.`
          }
          isSelectAllBtn={selectedItem.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
          isCheckedAll={allCheckedBtn}
        />
      )}

      <div className="fixedHeader">
        <ProtocolEditHeader
          name={title}
          // isDuplicateButton={selectedItem != null}
          isRenameButton={
            selectedItem != null &&
            selectedItem.length === 1 &&
            selectedAdminAccess &&
            adminLevel > 2
          }
          isFolderEditButton={
            selectedItem != null &&
            selectedItem.length === 1 &&
            selectedAdminAccess &&
            adminLevel > 2
          }
          isDeleteButton={
            selectedItem != null &&
            selectedItem.length > 0 &&
            selectedAdminAccess &&
            adminLevel > 2
          }
          isMoveButton={
            selectedItem != null &&
            selectedItem.length === 1 &&
            selectedItem[0].TAG === 'ProtocolItem' &&
            selectedAdminAccess &&
            adminLevel > 2
          }
          handleMove={() => {
            setIsMoveModal(true);
          }}
          isDownloadButton={selectedItem != null && selectedItem.length > 0}
          isSaveButton={newOrder != null || itemDeactivationStatus.length > 0}
          isSaveActive={newOrder != null || itemDeactivationStatus.length > 0}
          handleDownload={handleDownload}
          handleDelete={() => setIsDeleteModal(true)}
          isBaseFolder={breadcrumbItems?.length === 1}
          // isSubmenu={isShowSubmenu}
          isReviewActive={selectedAdminAccess && adminLevel > 2}
          isNewButton={adminLevel > 2}
          isNewActive={selectedAdminAccess && adminLevel > 2}
          isCancelButton={true}
          isReviewButton={adminLevel > 2}
          handleRename={() => {
            setIsRename(!isRename);
          }}
          handleFolderEdit={() => {
            setIsEditFolder(true);
          }}
          handleNewFolder={handleAddNewFolder}
          handleNewProtocol={handleAddNewProtocol}
          handleNew={handleNewButtonClick}
          handleCancel={handleCancel}
          handleSave={handleSave}
          handleReview={handleReview}
          newButtonRef={newButtonRef}
          description={
            breadcrumbItems?.length === 1
              ? undefined
              : 'Drag & drop a PDF to add a new protocol.'
          }
        />
      </div>
      <div className="headerContainer">
        <div className="breadCrumbsContainer" style={{ marginBottom: '10px' }}>
          {breadcrumbItems.map((item, index) => (
            <div style={{ display: 'flex', cursor: 'pointer' }} key={index}>
              <span
                className="btn-rightMargin breadCrumbText"
                onClick={() => handleCrumbClick(item, index)}
              >
                {item?.label}
                {item?.icon}
              </span>
              {index <= breadcrumbItems?.length - 1 && (
                <span className="btn-rightMargin btn-leftMargin breadCrumbIcon">
                  <IoChevronForward className="icon-large" />{' '}
                </span>
              )}
            </div>
          ))}
        </div>
        <SearchBar
          containerStyle={{ flex: 1 }}
          value={searchQuery}
          onChange={(searchTerm: string) => {
            setSearchQuery(searchTerm);
            handleSearch(searchTerm);
          }}
          onSubmit={(searchTerm: string) => {}}
          placeholder={'Search...'}
        />
      </div>
      <ProtocolDataTable
        handleToggleEnabled={handleToggleEnabledUI}
        handleTabClick={handleTabClick}
        handleRename={handleNameUpdate}
        isEditFolderModalOpen={isEditFolder}
        isMoveModalOpen={isMoveModal}
        isModalOpen={isOpen}
        isRename={isRename}
        protocolData={displayedFolderData}
        newButtonRef={newButtonRef}
        isDeleteModalOpen={isDeleteModal}
        handleReorder={handleReorderItems}
      />
      {loading && <Loading type="bubbles" message={loading} />}
    </div>
  );
};

export default ProtocolEditMainPage;
