import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa6';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../data/AmplifyDB';
import { User } from '../../../models';
import WorkbookItem from '../../../data/model/WorkbookItem';
import ProtocolHeader from '../protocol/ProtocolHeader';
import {
  CalculateByteSting,
  findDepartmentOwner,
  getFormattedDate,
  globals,
} from '../../_global/common/Utils';
import {
  deleteWorkbook,
  fetchWorkbooks,
} from '../../../data/functions/WorkbookDB';
import OwnerImage from '../../components/OwnerImage/OwnerImage';
import DataList, { Header } from '../database/lists/DataList';
import HMCheckbox from '../../components/general/HMCheckbox';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { Alert, Snackbar } from '@mui/material';
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ProtocolSetList = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const reducerState = useSelector((state: any) => state.department);
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);
  const department = database.department;
  const user: User = useSelector((state: any) => state.user);
  const [workbookList, setWorkbookList] = useState<WorkbookItem[]>([]);
  const [list, setList] = useState<WorkbookItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<WorkbookItem[]>([]);
  const [isDelete, setIsDelete] = useState(false);
  const paginationModel = { page: 0, pageSize: 50 };
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });
  const getDetails = useCallback(async () => {
    const result: Response = await fetchWorkbooks(department, true, true);
    if (result.type === ResponseType.Success) {
      setWorkbookList(result.data);
      setList(result.data);
    } else if (globals.debug)
      console.log('Error getting Workbooks', result.data);
  }, [department.id]);

  useEffect(() => {
    getDetails();
  }, [getDetails, department.id]);

  const handleItemClick = (wb: WorkbookItem) => {
    const state = {
      list: workbookList,
      selectedItem: wb,
    };
    navigate(`/protocol-sets/edit`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      list: workbookList,
    };
    navigate(`/protocol-sets/new`, { state });
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  const handleSelectionChange = (workbook: WorkbookItem) => {
    if (selectedItems.find((item) => item.uid === workbook.uid)) {
      setSelectedItems((prevItems: WorkbookItem[]) => {
        const updatedItems = prevItems.filter(
          (item: WorkbookItem) => item.uid !== workbook.uid
        );

        if (updatedItems.length === 0) {
          setAllCheckedBtn(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, workbook];
      let available = workbookList.filter(
        (item) => department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setAllCheckedBtn(true);
      } else if (allCheckedBtn) {
        setAllCheckedBtn(false);
      }
    }
  };

  const headers: Header<WorkbookItem>[] = useMemo(
    () => [
      {
        key: 'input',
        name: '',
        sortable: false,
        flex: 0.25,
        render: (item: WorkbookItem) => {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
              onClick={(e: any) => {
                e.stopPropagation();
                handleSelectionChange(item);
              }}
            >
              <HMCheckbox
                checked={selectedItems.some(
                  (selected) => selected.id === item.id
                )}
                onChange={() => handleSelectionChange(item)}
              />
            </div>
          );
        },
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 3,
        render: (item: WorkbookItem) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.name}
          </div>
        ),
      },
      {
        key: 'modified_date',
        name: 'Modified Date',
        sortable: true,
        flex: 2,
        render: (item: WorkbookItem) =>
          getFormattedDate(
            item.model?.updatedAt
              ? new Date(item.model.updatedAt)
              : item.model?.createdAt
                ? new Date(item.model.createdAt)
                : new Date()
          ),
      },
      {
        key: 'modified_by',
        name: 'Modified By',
        sortable: true,
        flex: 2,
        render: (item: WorkbookItem) => {
          return item.modifiedBy
            ? `${item.modifiedBy.firstName} ${item.modifiedBy.lastName}`
            : 'Hinckley Medical';
        },
      },
      {
        key: 'owner',
        name: 'Owner',
        sortable: true,
        flex: 1,
        render: (item: WorkbookItem) => {
          if (department) {
            const departmentOwner = findDepartmentOwner(
              department,
              reducerState,
              item
            );
            return departmentOwner ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <OwnerImage owner={departmentOwner} size={32} />
              </div>
            ) : null;
          }
          return null;
        },
      },
      ...(user.type === 'ADMIN'
        ? [
            {
              key: 'ai_parsed',
              name: 'AI Parsed',
              sortable: true,
              flex: 1,
              render: (item: WorkbookItem) =>
                item.aiPdfParserResults != null ? 'True' : '-',
            },
          ]
        : []),
      {
        key: 'file_size',
        name: 'File Size',
        sortable: true,
        flex: 1,
        render: (item: WorkbookItem) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {CalculateByteSting(item.fileSize)}
            <FaChevronRight className="icon-normal" style={{ margin: '4px' }} />
          </div>
        ),
      },
    ],
    [department, reducerState, user.type, selectedItems, allCheckedBtn]
  );

  const handleSelectAll = (e: any, isSelected: boolean) => {
    if (isSelected) setSelectedItems(workbookList);
    else setSelectedItems([]);
  };

  const handleSortItem = (
    key: string,
    direction: string,
    a: WorkbookItem,
    b: WorkbookItem
  ) => {
    if (key === 'name') {
      return direction === 'asc'
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (key === 'modified_date') {
      if (!a.model?.updatedAt || !b.model?.updatedAt) return 0;
      const aDate = new Date(a.model.updatedAt);
      const bDate = new Date(b.model.updatedAt);
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'modified_by') {
      const aName = a.modifiedBy
        ? `${a.modifiedBy.firstName} ${a.modifiedBy.lastName}`
        : 'Hinckley Medical';
      const bName = b.modifiedBy
        ? `${b.modifiedBy.firstName} ${b.modifiedBy.lastName}`
        : 'Hinckley Medical';
      return direction === 'asc'
        ? aName.localeCompare(bName)
        : bName.localeCompare(aName);
    } else if (key === 'owner') {
      const aOwner =
        findDepartmentOwner(department, reducerState, a)?.name ?? '';
      const bOwner =
        findDepartmentOwner(department, reducerState, b)?.name ?? '';
      return direction === 'asc'
        ? aOwner.localeCompare(bOwner)
        : bOwner.localeCompare(aOwner);
    } else if (key === 'file_size') {
      return direction === 'asc'
        ? a.fileSize - b.fileSize
        : b.fileSize - a.fileSize;
    } else if (key === 'ai_parsed') {
      return direction === 'asc'
        ? a.aiPdfParserResults != null
          ? 1
          : -1
        : a.aiPdfParserResults != null
          ? -1
          : 1;
    }
    return 0;
  };

  const getRowClassName = useCallback(
    (params: WorkbookItem) => {
      const isSelected = selectedItems.some((item) => item.id === params.id);
      if (isSelected) return 'selectedRow';
      // Default return for other cases
      return '';
    },
    [selectedItems]
  );

  const handleConfirmDeleteProtocolSets = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const protocolSet of selectedItems) {
          let response = await deleteWorkbook(protocolSet, false);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbar({
            open: true,
            message:
              `Successfully deleted ${successCount} protocol set` +
              (successCount === 1 ? '' : 's'),
            severity: 'success',
          });
        }
        if (failureCount > 0) {
          setSnackbar({
            open: true,
            message:
              `Failed to delete ${failureCount} protocol set` +
              (failureCount === 1 ? '' : 's'),
            severity: 'error',
          });
        }
        // Clear selectedItems after all deletions
        setSelectedItems([]);
        getDetails();
      } catch (error) {
        setSnackbar({
          open: true,
          message: 'Failed to delete protocol set',
          severity: 'error',
        });
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextProtocolSet(); // Call the refactored function
    }
  };

  const deleteNextProtocolSet = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentProtocolSet = selectedItems[0];

      // Delete the medication
      let response = await deleteWorkbook(currentProtocolSet, false);
      if (response.type === ResponseType.Success) {
        setSnackbar({
          open: true,
          message: `Successfully deleted ${currentProtocolSet.name}`,
          severity: 'success',
        });

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: WorkbookItem[]) => {
          const updatedItems = prevItems.filter(
            (item: WorkbookItem) => item.uid !== currentProtocolSet.uid
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbar({
          open: true,
          message: 'Failed to delete protocol set',
          severity: 'error',
        });
      }

      // Reload the database and check if there are any items left
      getDetails();
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Failed to delete protocol set',
        severity: 'error',
      });
    }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() =>
          setSnackbar({ open: false, message: '', severity: 'success' })
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() =>
            setSnackbar({ open: false, message: '', severity: 'success' })
          }
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Protocol Set?'}
          handleClose={() => {
            setIsDelete(false);
            setAllCheckedBtn(false);
          }}
          handleSubmit={handleConfirmDeleteProtocolSets}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} protocol sets?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}
      <ProtocolHeader
        handleCancel={handleBack}
        name={'Protocol Sets: ' + list.length + ' items'}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={adminLevel > 2}
        isCreateActive={true}
        isDeleteButton={selectedItems.length > 0}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />

      <DataList<WorkbookItem, never>
        items={list}
        headers={headers}
        onItemClick={handleItemClick}
        searchPlaceholder="Search Protocol Sets..."
        sortItem={handleSortItem}
        getRowClassName={getRowClassName}
        selectedItems={selectedItems}
        onSelectionChange={setSelectedItems}
        handleSelectAll={handleSelectAll}
      />
    </div>
  );
};

export default ProtocolSetList;
