import { API, graphqlOperation } from 'aws-amplify';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  checkSubDeps,
  executeQuery,
  executeSingleQuery,
  findDepartmentLogo,
  mapItems,
  mapModelItems,
  timeoutPromise,
} from './AmplifyDB';
import {
  categoriesByDepartmentID,
  departmentsByParentDepID,
  departmentsByUniqueCode,
  dripsByDepartmentID,
  electricalShocksByDepartmentID,
  equipmentByDepartmentID,
  getAmbulance,
  getCategory,
  getContact,
  getDrip,
  getElectrical,
  getElectricalShock,
  getEquipment,
  getForm,
  getInputForm,
  getKeychain,
  getMedication,
  getMedicShift,
  getPatientInteraction,
  getProtocol,
  getUser,
  getVitals,
  getWeightObject,
  medicationsByDepartmentID,
  protocolsByCategoryID,
  vitalsByDepartmentID,
} from '../graphql/queries';
import {
  Medication,
  UserType,
  Drip,
  Electrical,
  Category,
  Department,
  Keychain,
  Protocol,
  User,
  Equipment,
  CreateUserInput,
  UserStatus,
} from '../API';
import { getHashedPin, getSalt } from '../ui/_global/common/Encrypt';
import { createUser } from '../graphql/mutations';
import DepartmentItem from './model/DepartmentItem';
import CategoryItem from './model/CategoryItem';
import ProtocolItem from './model/ProtocolItem';
import { globals } from '../ui/_global/common/Utils';
import KeychainItem from './model/KeychainItem';
import MedicationItem from './model/MedicationItem';
import InfusionItem from './model/InfusionItem';
import ElectricalItem from './model/ElectricalItem';
import EquipmentItem from './model/EquipmentItem';
import ModelItem from './model/ModelItem';
import VitalItem from './model/VitalItem';
import {
  departmentsByUniquePublicURL,
  getDepartment,
  listDepartments,
} from './QueryTypes';
import { fetchKeychains } from './functions/KeychainDB';
import { Dispatch } from 'react';

export const fetchDepartmentByName = async (
  departmentName: string,
  dispatch?: Dispatch<any>,
  lazyLoadCallback?: () => void
): Promise<Response> => {
  try {
    let urlName = departmentName.toLowerCase().trim();
    let department: Department | null = await executeSingleQuery(
      departmentsByUniquePublicURL,
      {
        uniquePublicURL: urlName,
      }
    );

    if (department == null)
      return { type: ResponseType.Failure, data: 'Department not found' };

    if (!department.isPublic) {
      return {
        type: ResponseType.Failure,
        data: 'Department is not public.',
      };
    }

    let dep = new DepartmentItem(department as any);
    await dep.checkParentDep();

    findDepartmentLogo(dep).then((logoResult) => {
      if (logoResult.type === ResponseType.Success)
        dep.logoVerifiedUrl = logoResult.data;
      else console.error('Error fetching department logo:', logoResult.data);
    });

    if (dep.keychainID) {
      const keychainData: any = await executeSingleQuery(getKeychain, {
        id: department.keychainID,
      });
      let keychain = new KeychainItem(keychainData);
      dep.keychain = keychain;
      if (!keychain.isUnlocked) {
        return {
          type: ResponseType.Success,
          data: {
            department: dep,
            keychain: keychain,
          },
        };
      }
    }

    /* Get the sub departments & the last selected department */
    let subDepsMap: Map<string, DepartmentItem[]> = new Map();
    let allSubDepsMap: Map<string, DepartmentItem[]> = new Map();
    let { subDeps, allSubDeps } = await checkSubDeps(
      dep,
      subDepsMap,
      allSubDepsMap,
      undefined,
      dispatch,
      undefined,
      false,
      true
    );
    let id = localStorage.getItem('lastSelectedDepID');
    if (id && subDeps && subDeps?.find((d) => d.id === id)) {
      let lastSelectedDep = subDeps?.find((d) => d.id === id);
      dep = lastSelectedDep ? lastSelectedDep : dep;
    }

    let topDepartment = dep.getTopLevelDep();
    if (
      !dep.isTopEnabled &&
      subDeps &&
      subDeps.length > 0 &&
      dep.id === topDepartment.id
    ) {
      dep = subDeps[0];
    }

    let response = await loadPublicDepartmentDatabase(
      dep,
      subDepsMap,
      allSubDepsMap
    );
    if (response.type === ResponseType.Failure) return response;

    return {
      type: ResponseType.Success,
      data: {
        db: response.data,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const BATCH_SIZE = 100;
const CONCURRENT_BATCH_SIZE = 5; // Number of concurrent requests
const departmentCache = new Map<string, DepartmentItem[]>();
export const fetchDepartments = async (
  parent: DepartmentItem | null,
  curLayer: number = 1,
  onlyPublic: boolean = true,
  lazyLoadDepartments?: (
    parent: DepartmentItem | null,
    childDepartments: DepartmentItem[],
    isComplete: boolean
  ) => void,
  batchSize: number = BATCH_SIZE,
  concurrentBatchSize: number = CONCURRENT_BATCH_SIZE,
  loadedDepartments: DepartmentItem[] = []
): Promise<Response> => {
  try {
    const parentId = parent?.id || 'ROOT';
    const cacheKey = `${parentId}-${curLayer}-${onlyPublic}`;

    // Check cache first
    if (departmentCache.has(cacheKey)) {
      const cachedResult = departmentCache.get(cacheKey)!;
      console.log(
        'Cached result:',
        cachedResult.map((d) => d.name)
      );
      if (lazyLoadDepartments != null) {
        lazyLoadDepartments(parent, cachedResult, true);
      }
      return { type: ResponseType.Success, data: cachedResult };
    }

    const filter = {
      and: {
        _deleted: { ne: true },
        activeStatus: { ne: false },
        ...(onlyPublic && { isPublicSignup: { eq: true } }),
        // parentDepID: { eq: parentId },
      },
    };

    // Batch processing setup
    let allDepartments: DepartmentItem[] = [];
    let nextToken: string | null = null;

    do {
      // console.log('Fetching departments for parent:', parentId, nextToken);
      const promise: Promise<any> | any = API.graphql(
        graphqlOperation(departmentsByParentDepID, {
          indexedParentDepID: parentId,
          sortDirection: 'ASC',
          filter,
          limit: batchSize,
          nextToken,
        })
      );

      const queryResult: any = await timeoutPromise(promise, 10000);

      const departments: Department[] =
        queryResult.data.departmentsByParentDepID.items;
      const departmentModels = departments.map(
        (dep) => new DepartmentItem(dep as any)
      );
      allDepartments.push(...departmentModels);
      //Filter duplicates
      allDepartments = allDepartments.filter(
        (dep, index, self) => index === self.findIndex((t) => t.id === dep.id)
      );

      // Notify progress if callback provided
      if (lazyLoadDepartments != null) {
        lazyLoadDepartments(
          parent,
          [...loadedDepartments, ...departmentModels],
          false
        );
      }

      nextToken = queryResult.data.departmentsByParentDepID.nextToken;
    } while (nextToken != null);

    if (lazyLoadDepartments != null) {
      lazyLoadDepartments(
        parent,
        [...loadedDepartments, ...allDepartments],
        false
      );
    }

    // Process sub-departments in parallel with controlled concurrency
    if (curLayer > 1) {
      const batchedPromises: Promise<Response>[][] = [];

      for (let i = 0; i < allDepartments.length; i += concurrentBatchSize) {
        const batch = allDepartments.slice(i, i + concurrentBatchSize);
        const batchPromises = batch.map((dep) =>
          fetchDepartments(
            dep,
            curLayer - 1,
            onlyPublic,
            lazyLoadDepartments,
            batchSize,
            concurrentBatchSize,
            allDepartments
          )
        );
        batchedPromises.push(batchPromises);
      }

      // Process batches sequentially to control memory usage
      for (const batch of batchedPromises) {
        const responses = await Promise.all(batch);
        for (const resp of responses) {
          if (resp.type === ResponseType.Success) {
            allDepartments.push(...(resp.data as DepartmentItem[]));
            //Filter duplicates
            allDepartments = allDepartments.filter(
              (dep, index, self) =>
                index === self.findIndex((t) => t.id === dep.id)
            );
          }
        }
      }
    }

    // Cache the results
    if (parentId) {
      departmentCache.set(cacheKey, allDepartments);
    }

    // Final notification with complete data
    if (lazyLoadDepartments)
      lazyLoadDepartments(
        parent,
        [...loadedDepartments, ...allDepartments],
        true
      );

    return {
      type: ResponseType.Success,
      data: allDepartments,
    };
  } catch (error) {
    console.error('Error in fetchDepartments:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Fetch departments by their IDs. This is used to fetch departments that are paired with the current department.
 * @param departmentIDs - The IDs of the departments to fetch.
 * @returns A promise that resolves to a Response object containing the fetched departments.
 */
export const fetchDepartmentsByIDs = async (
  departmentIDs: string[]
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let departments: DepartmentItem[] = [];
      let promises: any[] = [];
      for (let i = 0; i < departmentIDs.length; i++) {
        promises.push(
          executeSingleQuery(getDepartment, { id: departmentIDs[i] })
        );
        if (promises.length > CONCURRENT_BATCH_SIZE) {
          let results = await Promise.all(promises);
          for (let i = 0; i < results.length; i++) {
            if (results[i]) departments.push(new DepartmentItem(results[i]));
          }
          promises = [];
        }
      }
      resolve({ type: ResponseType.Success, data: departments });
    } catch (error) {
      reject({ type: ResponseType.Failure, data: error });
    }
  });
};

export const loadPublicDepartmentDatabase = async (
  department: DepartmentItem,
  subDepsMap: Map<string, DepartmentItem[]>,
  allSubDepsMap: Map<string, DepartmentItem[]>
): Promise<Response> => {
  try {
    if (globals.debug)
      console.log('Parent Department:', department.name, department.id);

    let promises: any[] = [];
    promises.push(fetchCategoriesAndProtocols_v2(department, 'ACTIVE'));
    promises.push(fetchKeychains(department, false));
    let [catResp, keychainResp] = await Promise.all(promises);
    if (catResp.type === ResponseType.Failure) return catResp;
    if (keychainResp.type === ResponseType.Failure) return keychainResp;

    let db: DatabaseResponse = {
      department: department,
      subDepsMap: subDepsMap,
      allSubDepsMap: allSubDepsMap,
      cprModel: undefined,
      categories: catResp.data.categories,
      protocols: catResp.data.protocols,
      ambulances: [],
      users: [],
      oneWeights: [],
      medications: [],
      medicationDoses: [],
      infusions: [],
      infusionDoses: [],
      equipment: [],
      electrical: [],
      electricalDoses: [],
      checklists: [],
      vitals: [],
      logs: [],
      notifications: [],
      contacts: [],
      weightObjects: [],
      keychains: keychainResp.data,
      groups: [],
    };

    /* Map the protocols to the keychains */
    for (let i = 0; i < db.keychains.length; i++) {
      let keychain = db.keychains[i];
      let protocols: ProtocolItem[] = db.protocols.filter(
        (p: ProtocolItem) => p.keychainID === keychain.uid
      );
      let categories: CategoryItem[] = db.categories.filter(
        (c: CategoryItem) => c.keychainID === keychain.uid
      );
      keychain.categories = categories.filter(
        (c: CategoryItem, index: number, self: CategoryItem[]) =>
          index === self.findIndex((t) => t.uid === c.uid)
      );
      //Filter duplicates
      keychain.protocols = protocols.filter(
        (p: ProtocolItem, index: number, self: ProtocolItem[]) =>
          index === self.findIndex((t) => t.uid === p.uid)
      );
    }

    if (globals.debug) {
      console.log('Loaded Public Database:', department.name);
      console.log('Categories:', db.categories);
      console.log('Protocols:', db.protocols.length);
      console.log('Keychains:', db.keychains.length);
    }

    mapItems(db, [], [], []); //TODO MAP MED DOSES

    return {
      type: ResponseType.Success,
      data: db,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const fetchCategoriesAndProtocols_v2 = async (
  department: DepartmentItem,
  _status: string = 'ACTIVE',
  fetchAll: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [department.id];
    if (department.parentDep) depIDs.push(department.parentDep.id);
    if (department.parentDep?.parentDep)
      depIDs.push(department.parentDep.parentDep.id);
    if (globals.debug) console.log('Department IDs:', depIDs);

    let categoryList: Category[] = [];
    let categoryPromises: Promise<any>[] = [];
    for (let departmentID of depIDs) {
      let filter: any = {
        and: [
          {
            or: [
              /* Did not need the departmentID because it is already filtered out in the query */
              {
                isRestrictive: { eq: false },
              },
              {
                and: [
                  {
                    isRestrictive: { eq: true },
                  },
                  {
                    pairedDepIDs: { contains: department.id },
                  },
                ],
              },
            ],
          },
          {
            status: { eq: _status ? _status : 'ACTIVE' },
            _deleted: { ne: true },
            isPublic: { eq: true },
          },
        ],
      };
      try {
        categoryPromises.push(
          executeQuery(
            categoriesByDepartmentID,
            {
              departmentID: departmentID,
              filter: filter,
            },
            {
              timeout: globals.maxDatabaseDelayMS,
              loadLimit: 100,
              limit: 500,
            }
          )
        );
        let results: any = await Promise.all(categoryPromises);
        for (let i = 0; i < results.length; i++)
          categoryList = categoryList.concat(results[i]);
      } catch (error) {
        console.log(error);
        return {
          type: ResponseType.Failure,
          data: error,
        };
      }
    }
    // let filter: any = {
    //   and: {
    //     status: { eq: _status ? _status : 'ACTIVE' },
    //     _deleted: { ne: true },
    //     isPublic: { eq: true },
    //   },
    // };

    // if (department.activeSubDep != null) {
    //   filter = {
    //     and: {
    //       pairedDepIDs: { contains: department.activeSubDep.id },
    //       _deleted: { ne: true },
    //       status: { eq: _status ? _status : 'ACTIVE' },
    //       isPublic: { eq: true },
    //     },
    //   };
    // }

    let promises = [];
    let categories: CategoryItem[] = [];
    for (let i = 0; i < categoryList.length; i++) {
      let data: any = categoryList[i];
      let category = new CategoryItem(data);
      mapModelItems(category, categories, category.status, department);
      // categories.push(category);
      promises.push(fetchProtocols_v2(category, department, _status, true));
    }

    let protocolResponses = await Promise.all(promises);
    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < protocolResponses.length; i++) {
      let data = protocolResponses[i].data as ProtocolItem[];
      // categories[i].protocols = data;
      protocols = protocols.concat(data);
    }

    /* Make an API call to get all departments. Without being authenticated yet */
    // let categories: CategoryItem[] = [];
    // let protocols: ProtocolItem[] = [];
    // for (let i = 0; i < categoryList.length; i++) {
    //   let data: any = categoryList[i];
    //   let category = new CategoryItem(data);

    //   if (category.status === 'DRAFT') {
    //     /* Take out the active version if there is one */
    //     if (category.activeID !== null)
    //       categories = categories.filter(
    //         (c: CategoryItem) => c.uid !== category.activeID
    //       );
    //   } else if (category.status === 'ACTIVE') {
    //     /* Make sure the draft version is not in the list */
    //     let isAlreadyDraft = categories.find(
    //       (c: CategoryItem) => c.activeID === category.uid
    //     );
    //     if (isAlreadyDraft) continue;
    //   }

    //   categories.push(category);

    //   /* Get the protocols for the category */
    //   let prots: Protocol[] = data.Protocols.items;
    //   let catProtocols: ProtocolItem[] = [];
    //   for (let j = 0; j < prots.length; j++) {
    //     let protocol = new ProtocolItem(prots[j], category);
    //     if (protocol.status === 'DRAFT') {
    //       /* Take out the active version if there is one */
    //       if (protocol.activeID != null)
    //         categories = categories.filter(
    //           (c: CategoryItem) => c.uid !== protocol.activeID
    //         );
    //     } else if (protocol.status === 'ACTIVE') {
    //       /* Make sure the draft version is not in the list */
    //       let isAlreadyDraft = categories.find(
    //         (c: CategoryItem) => c.activeID === protocol.uid
    //       );
    //       if (isAlreadyDraft) continue;
    //     }

    //     catProtocols.push(protocol);
    //   }
    //   catProtocols.sort((a: ProtocolItem, b: ProtocolItem) => {
    //     if (a.index === b.index) return a.name.localeCompare(b.name);
    //     return a.index - b.index;
    //   });
    //   category.protocols = catProtocols;
    //   protocols = protocols.concat(catProtocols);
    // }

    categories.sort((a: CategoryItem, b: CategoryItem) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });

    protocols.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (a.parent.index === b.parent.index)
        return a.name.localeCompare(b.name);
      return a.parent.index - b.parent.index;
    });

    return {
      type: ResponseType.Success,
      data: {
        categories: categories,
        protocols: protocols,
      },
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const fetchProtocols_v2 = async (
  category: CategoryItem,
  department: DepartmentItem,
  _status: string = 'ACTIVE',
  isFetchAll: boolean = false
): Promise<Response> => {
  try {
    /* Make an API call to get all departments. Without being authenticated yet */
    let depIDs = [department.id];
    if (department.parentDep) depIDs.push(department.parentDep.id);
    if (department.parentDep?.parentDep)
      depIDs.push(department.parentDep.parentDep.id);
    if (globals.debug) console.log('Department IDs:', depIDs);

    let protocolList: Protocol[] = [];
    let protocolPromises: Promise<any>[] = [];
    for (let departmentID of depIDs) {
      let filter: any = {
        and: [
          {
            or: [
              {
                and: [
                  {
                    departmentID: { eq: departmentID },
                  },
                  {
                    isRestrictive: { eq: false },
                  },
                ],
              },
              {
                and: [
                  {
                    isRestrictive: { eq: true },
                  },
                  {
                    pairedDepIDs: { contains: department.id },
                  },
                ],
              },
            ],
          },
          {
            status: { eq: _status ? _status : 'ACTIVE' },
            _deleted: { ne: true },
            isPublic: { eq: true },
          },
        ],
      };
      try {
        protocolPromises.push(
          executeQuery(
            protocolsByCategoryID,
            {
              categoryID: category.uid,
              filter: filter,
            },
            {
              timeout: globals.maxDatabaseDelayMS,
              loadLimit: 100,
              limit: 500,
            }
          )
        );
        let results: any = await Promise.all(protocolPromises);
        for (let i = 0; i < results.length; i++)
          protocolList = protocolList.concat(results[i]);
      } catch (error) {
        console.log(error);
        return {
          type: ResponseType.Failure,
          data: error,
        };
      }
    }

    let protocols: ProtocolItem[] = [];
    for (let i = 0; i < protocolList.length; i++) {
      let data: any = protocolList[i];
      let protocol = new ProtocolItem(data, category);
      mapModelItems(protocol, protocols, protocol.status, department);
    }

    protocols.sort((a: ProtocolItem, b: ProtocolItem) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });

    category.setProtocols(protocols);

    return {
      type: ResponseType.Success,
      data: protocols,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchItemFromAPI = async (
  department: DepartmentItem,
  type: 'Medications' | 'Drips' | 'Electrical' | 'Equipment' | 'Vitals',
  overrideID?: string
): Promise<Response> => {
  try {
    let query: any | undefined = undefined;
    switch (type) {
      case 'Medications':
        query = medicationsByDepartmentID;
        break;
      case 'Drips':
        query = dripsByDepartmentID;
        break;
      case 'Electrical':
        query = electricalShocksByDepartmentID;
        break;
      case 'Equipment':
        query = equipmentByDepartmentID;
        break;
      case 'Vitals':
        query = vitalsByDepartmentID;
        break;
      default:
        return {
          type: ResponseType.Failure,
          data: 'Invalid type',
        };
    }
    let items: ModelItem<any>[] = [];

    const data: any = await executeQuery(query, {
      departmentID: overrideID ? overrideID : department.id,
      filter: {
        _deleted: { ne: true },
        status: { eq: 'ACTIVE' },
      },
    });

    let list: any[] = data;

    for (let i = 0; i < list.length; i++) {
      let item = list[i];
      switch (type) {
        case 'Medications':
          items.push(new MedicationItem(item as any));
          break;
        case 'Drips':
          items.push(new InfusionItem(item as any));
          break;
        case 'Electrical':
          items.push(new ElectricalItem(item as any));
          break;
        case 'Equipment':
          items.push(new EquipmentItem(item as any));
          break;
        case 'Vitals':
          items.push(new VitalItem(item as any));
          break;
        default:
          break;
      }
    }

    items.sort((a: ModelItem<any>, b: ModelItem<any>) =>
      a.name.localeCompare(b.name)
    );

    return {
      type: ResponseType.Success,
      data: items,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const validateDepartmentCode = async (
  departmentCode: string,
  code?: string,
  lazyLoadDepartments?: (
    parent: DepartmentItem | null,
    childDepartments: DepartmentItem[],
    isComplete: boolean
  ) => void,
  skipFetchingSubDepartments: boolean = false
): Promise<Response> => {
  try {
    const result = await executeSingleQuery(
      departmentsByUniqueCode,
      { uniqueCode: departmentCode },
      5000
    );

    console.log('Result:', result);

    const department = result;
    if (!department) {
      return { type: ResponseType.Failure, data: 'Department not found' };
    }

    console.log('Department:', department);

    // Check PIN only if code is provided
    if (code) {
      const hashedPin = getHashedPin(code, department.saltedPin);
      if (hashedPin !== department.hashedPin) {
        return { type: ResponseType.Failure, data: 'Invalid PIN' };
      }
    }

    // If no code provided or PIN check passed, proceed with department processing
    let depModel = new DepartmentItem(department);
    await depModel.checkParentDep();
    /* Fetch the department logo from the S3 Bucket*/
    let logoResponse = await findDepartmentLogo(depModel);
    if (logoResponse.type === ResponseType.Success) {
      depModel.logoVerifiedUrl = logoResponse.data;
    } else {
      console.log('Error fetching department logo:', logoResponse.data);
    }

    await depModel.checkParentDep();

    /* Check whether to lazy load the sub-departments or not */
    if (skipFetchingSubDepartments !== true) {
      if (lazyLoadDepartments) {
        if (globals.debug)
          console.log('Lazy loading sub-departments', depModel.name);
        fetchDepartments(depModel, 2, true, lazyLoadDepartments);
      } else {
        let resp: Response = await fetchDepartments(depModel, 1, true);
        if (resp.type === ResponseType.Success) {
          let deps = resp.data as DepartmentItem[];
          // depModel.subDeps = deps;
          console.log('Sub-departments:', deps);
        } else {
          console.log('Error fetching sub-departments:', resp.data);
        }
      }
    }

    return { type: ResponseType.Success, data: depModel };
  } catch (error) {
    console.error(error);
    return {
      type: ResponseType.Failure,
      data: error || 'An error occurred',
    };
  }
};

export const getDepartmentCode = async (userID: string): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Make an API call to get all departments. Without being authenticated yet */
      const userData: any = await executeSingleQuery(getUser, {
        id: userID,
      });
      let user = userData;
      if (user == null)
        return resolve({
          type: ResponseType.Failure,
          data: 'User not found',
        });

      return resolve({
        type: ResponseType.Success,
        data: user.hashedPin,
      });
    } catch (error) {
      console.log(error);
      return reject({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

export const createUserGraphQL = async (
  type: UserType,
  subID: string,
  firstName: string,
  lastName: string,
  pin: string,
  department: Department | DepartmentItem,
  pairedDepartments: Department[] | DepartmentItem[],
  depAdmins: DepartmentItem[] = []
): Promise<Response> => {
  try {
    let saltPin = getSalt();
    let hashedPin = getHashedPin(pin, saltPin);

    let pairedDepIDs: string[] = pairedDepartments.map(
      (d: Department | DepartmentItem) => d.id
    );
    let depAdminIDs: string[] = depAdmins?.map((d: DepartmentItem) => d.id);

    let topDepID: string | null = null;
    if ((department as DepartmentItem).TAG != null) {
      await (department as DepartmentItem).checkParentDep();
      topDepID = (department as DepartmentItem).getTopLevelDep().id;
    } else {
      let depItem = new DepartmentItem(department as any);
      await depItem.checkParentDep();
      topDepID = depItem.getTopLevelDep().id;
    }

    if (topDepID == null) {
      return {
        type: ResponseType.Failure,
        data: 'Top level department not found',
      };
    }

    let input: CreateUserInput = {
      firstName: firstName,
      lastName: lastName,
      type: type,
      cognitoID: subID.toLowerCase(),
      departmentID: department.id,
      indexedParentDepID: topDepID,
      hashedPin: hashedPin,
      saltPin: saltPin,
      pairedDepIDs: pairedDepIDs,
      oneDoseVersion: '',
      notificationTokens: [],
      status: UserStatus.ACTIVE,
      depAdmins: depAdminIDs,
    };
    /* Make an API call to get all departments. Without being authenticated yet */
    const userData: any = await executeSingleQuery(createUser, {
      input: input,
    });

    let user = userData;

    return {
      type: ResponseType.Success,
      data: user,
    };
  } catch (error) {
    console.log(error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Get an item by its ID from the database
 * @param type ItemType of the item to get
 * @param id ID of the item to get
 * @returns A promise that resolves to a Response object
 */
export const getItemByID = (
  key: string,
  type: string,
  id: string
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let query: any;
      switch (type) {
        case 'Department':
          query = getDepartment;
          break;
        case 'Protocol':
          query = getProtocol;
          break;
        case 'Category':
          query = getCategory;
          break;
        case 'Medication':
          query = getMedication;
          break;
        case 'Drip':
          query = getDrip;
          break;
        case 'Equipment':
          query = getEquipment;
          break;
        case 'Form':
          query = getForm;
          break;
        case 'Electrical':
          query = getElectrical;
          break;
        case 'ElectricalShock':
          query = getElectricalShock;
          break;
        case 'Vitals':
          query = getVitals;
          break;
        case 'Checklist':
          query = getUser;
          break;
        case 'Ambulance':
          query = getAmbulance;
          break;
        case 'Contact':
          query = getContact;
          break;
        case 'InputForm':
          query = getInputForm;
          break;
        case 'User':
          query = getUser;
          break;
        case 'WeightObject':
          query = getWeightObject;
          break;
        case 'PatientInteraction':
          query = getPatientInteraction;
          break;
        case 'MedicShift':
          query = getMedicShift;
          break;
        default:
          console.log('Invalid type:', type, 'for key:', key, 'and id:', id);
          return resolve({
            type: ResponseType.Failure,
            data: 'Invalid type',
          });
      }

      const value = await executeSingleQuery(
        graphqlOperation(query, { id: id }),
        1500
      );
      // const value = result.data[`get${type}`];
      return resolve({
        type: ResponseType.Success,
        data: {
          value: value,
          id: id,
          key: key,
        },
      });
    } catch (error) {
      console.log(error);
      return reject({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

/**
 * Find the highest department ID by recursively searching through the parent departments
 * @param parentDepartment DepartmentItem to start searching from
 * @returns The highest department ID
 */
export const findHighestDepartmentID = async (
  parentDepartment: DepartmentItem
): Promise<string> => {
  if (parentDepartment.parentDepID) {
    let resp: Department | undefined = await executeSingleQuery(getDepartment, {
      id: parentDepartment.parentDepID,
    });
    if (resp) return findHighestDepartmentID(new DepartmentItem(resp as any));
    else return parentDepartment.id;
  }
  return parentDepartment.id;
};
