import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { User, UserStatus } from '../../../../models';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import { UserType } from '../../../../models';
import {
  compareVersions,
  getDepartmentsFromState,
  getFormattedDate,
  globals,
} from '../../../_global/common/Utils';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  fetchPaginatedUsers,
  fetchUsers,
  fetchUserStatsAPI,
  unsubscribeUser,
  updateUserStatus,
  UserStats,
} from '../../../../data/functions/UserDB';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import ShareURLModal from '../../../components/Modal/ShareURLModal';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Loading from '../../../components/Loading/Loading';
import HMCheckbox from '../../../components/general/HMCheckbox';
import '../scss/ListModel.scss';
import { Header } from './DataList';
import ReactLoading from 'react-loading';

import DataList from './DataList';
import { handleCacheUsers } from '../../../../store/actions';
const userStatusTypes = [
  { label: 'Active', value: UserStatus.ACTIVE },
  { label: 'Suspended', value: UserStatus.SUSPENDED },
];

const getUserType = (type: UserType | keyof typeof UserType) => {
  switch (type) {
    case UserType.ADMIN:
      return 'Super Admin';
    case UserType.DEPT_ADMIN:
      return 'Admin';
    case UserType.USER:
      return 'User';
    case UserType.DEPT:
      return 'Department';
    case UserType.DEPT_SUPER_ADMIN:
      return 'Super Admin';
    case UserType.DEVELOPER:
      return 'Developer';
    default:
      return '';
  }
};

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListUsers = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const department: DepartmentItem = database.department;
  const currentUser: User = useSelector((state: any) => state?.user);

  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const [departmentFilters, setDepartmentFilters] = useState<DepartmentItem[]>(
    []
  );
  const [selectedItems, setSelectedItems] = useState<User[]>([]);
  const [userStatus, setUserStatus] = useState<UserStatus>(UserStatus.ACTIVE);

  const [isDelete, setIsDelete] = useState(false);
  const [isPermanentlyDelete, setIsPermanentlyDelete] = useState(false);
  const [isUnsubscribe, setIsUnsubscribe] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [isEnable, setIsEnable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShareURLModalVisible, setIsShareURLModalVisible] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const cacheUsers = useSelector((state: any) => state.cacheUsers);

  const [state, setState] = useState({
    users: (cacheUsers ? cacheUsers : []) as User[],
    isCompleted: false,
    pageSize: 50,
  });

  const [userStats, setUserStats] = useState<UserStats>(
    localStorage.getItem('userStats')
      ? JSON.parse(localStorage.getItem('userStats') as string)
      : undefined
  );

  // Add this useEffect hook near the top of the component, after state declarations
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    const fetchData = async () => {
      let promises: Promise<any>[] = [];
      promises.push(fetchUserStats());
      promises.push(fetchAllUsers());
      let results = await Promise.all(promises);
    };
    fetchData();
  }, []);

  const handleBack = () => {
    navigate(`/database`);
  };

  const handleUserClick = (item: any) => {
    navigate(`/database/edit/user`, {
      state: {
        department: department,
        value: item,
        database: database,
      },
    });
  };

  const fetchUserStats = async () => {
    const stats = await fetchUserStatsAPI(department);
    localStorage.setItem('userStats', JSON.stringify(stats));
    setUserStats(stats);
    return stats;
  };

  const fetchAllUsers = async () => {
    const users = await fetchUsers(
      department,
      false,
      UserStatus.ACTIVE,
      cacheUsers == null || cacheUsers.length === 0
        ? (users: User[], isCompleted) => {
            setState({ ...state, users: users, isCompleted: isCompleted });
          }
        : undefined
    );
    dispatch<any>(handleCacheUsers(users.data));
    setState({ ...state, users: users.data, isCompleted: true });
  };

  const handleUpdateUser = async (
    parmUser: User,
    status: UserStatus
  ): Promise<Response> => {
    let promises: Promise<Response>[] = [];
    setIsLoading(true); // Show loading spinner or state

    // Push both API call promises into the array
    promises.push(updateUserStatus(parmUser, status)); // API call 1: Update user status
    promises.push(updateCognitoUser(parmUser.cognitoID, status)); // API call 2: Update Cognito user status

    try {
      // Await the completion of both API calls
      let results = await Promise.all(promises);

      // Check the response of the first API call (user status update)
      if (results[0] && results[0].type === ResponseType.Success) {
        if (globals.debug) console.log('User status updated successfully');
      } else {
        console.error('Error updating user status:', results[0]?.data);
      }

      // Check the response of the second API call (Cognito user update)
      if (results[1] && results[1].type === ResponseType.Success) {
        if (globals.debug) console.log('Cognito user updated successfully');
      } else {
        console.error('Error updating Cognito user:', results[1]?.data);
      }

      // Determine the overall response based on success/failure
      const overallSuccess = results.every(
        (result) => result.type === ResponseType.Success
      );

      setIsLoading(false); // Hide loading spinner

      // Return success or failure response
      return {
        type: overallSuccess ? ResponseType.Success : ResponseType.Failure,
        data: results, // Return all results data
      };
    } catch (error) {
      console.error('Error in handling user update:', error);
      setIsLoading(false); // Hide loading spinner

      // Return an error response if something failed during the API calls
      return {
        type: ResponseType.Failure,
        data: error,
      };
    }
  };

  const updateCognitoUser = async (
    username: string,
    status: UserStatus
  ): Promise<Response> => {
    try {
      const url =
        process.env.REACT_APP_API_GATEWAY_URL_USER ??
        (function () {
          throw new Error('API URL is not defined');
        })();
      if (globals.debug) console.log('API URL:', url);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          action:
            status === UserStatus.ACTIVE
              ? 'enable'
              : status === UserStatus.SUSPENDED
                ? 'disable'
                : 'delete',
        }),
      });

      if (!response.ok) {
        throw new Error('API call failed with status ' + response.status);
      }
      if (globals.debug) console.log('API call successful', response);
      if (globals.debug) console.log('Response Bdy:', response.body);
      const data = await response.json();
      if (globals.debug) console.log('Response data:', data);
      if (globals.debug) console.log('Response body:', data.body);
      if (globals.debug) console.log('Response body', data.message);
      return {
        type: ResponseType.Success,
        data: data.message,
      };
    } catch (error) {
      console.error('Error calling the API:', error);
      return {
        type: ResponseType.Failure,
        data: error,
      };
    }
  };

  // Function to handle individual user updates or deletions
  const updateNextUser = async (enableUser?: UserStatus) => {
    try {
      if (selectedItems.length === 0) {
        setIsDelete(false); // If no items left, close the modal
        return;
      }
      const currentUser = selectedItems[0]; // Get the current user
      const userStatus =
        enableUser ||
        (isPermanentlyDelete ? UserStatus.DELETED : UserStatus.SUSPENDED);
      let response = await handleUpdateUser(currentUser, userStatus);

      if (response.type === ResponseType.Success) {
        setSnackbar({
          open: true,
          message: `Successfully updated user: ${currentUser.firstName} ${currentUser.lastName}`,
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: `Failed to updated user: ${currentUser.firstName + ' ' + currentUser.lastName}`,
          severity: 'error',
        });
      }
      // Remove the successfully updated user from the list
      setSelectedItems((prevItems) => {
        const updatedItems = prevItems.filter(
          (item) => item.id !== currentUser.id
        );
        if (updatedItems.length === 0) {
          setIsDelete(false); // Close modal if no users are left
        }
        return updatedItems;
      });

      // reloadDatabase(); // Reload the database after update
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to updated user.`,
        severity: 'error',
      });
    }
  };

  const handleConfirmUpdateUsers = async (enableUser?: UserStatus) => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmUser of selectedItems) {
          // Perform update based on whether it's permanent deletion or suspension
          const userStatus =
            enableUser ||
            (isPermanentlyDelete ? UserStatus.DELETED : UserStatus.SUSPENDED);
          let response = await handleUpdateUser(parmUser, userStatus);

          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbar({
            open: true,
            message: `Successfully updated ${successCount} users.`,
            severity: 'success',
          });
        }
        if (failureCount > 0) {
          setSnackbar({
            open: true,
            message: `Failed to updated ${failureCount} users.`,
            severity: 'error',
          });
        }

        setSelectedItems([]); // Clear selectedItems after all updates
        // reloadDatabase(); // Reload the database after the operations
      } catch (error) {
        setSnackbar({
          open: true,
          message: `Failed to updated users.`,
          severity: 'error',
        });
      }
      setIsDelete(false); // Close modal after all operations
    } else {
      enableUser === UserStatus.ACTIVE
        ? updateNextUser(UserStatus.ACTIVE)
        : updateNextUser();
    }
  };

  const handleUnsubscribe = async () => {
    if (selectedItems.length > 0) {
      if (allCheckedBtn) {
        let promises: Promise<Response>[] = [];
        for (const parmUser of selectedItems) {
          promises.push(unsubscribeUser(department, parmUser));
        }
        let results = await Promise.all(promises);
        let successCount = 0;
        let failureCount = 0;
        for (const result of results) {
          if (result.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }
        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbar({
            open: true,
            message: `Successfully unsubscribed ${successCount} users.`,
            severity: 'success',
          });
        }
        if (failureCount > 0) {
          setSnackbar({
            open: true,
            message: `Failed to unsubscribed ${failureCount} users.`,
            severity: 'error',
          });
        }
        setSelectedItems([]); // Clear selectedItems after all updates
        // reloadDatabase(); // Reload the database after the operations
      } else {
        updateNextUnsubscribe();
      }
    }
  };

  // Function to handle individual user updates or deletions
  const updateNextUnsubscribe = async () => {
    try {
      if (selectedItems.length === 0) {
        setIsDelete(false); // If no items left, close the modal
        return;
      }
      const currentUser = selectedItems[0];
      let response = await unsubscribeUser(department, currentUser);

      if (response.type === ResponseType.Success) {
        setSnackbar({
          open: true,
          message: `Successfully updated user: ${currentUser.firstName} ${currentUser.lastName}`,
          severity: 'success',
        });
      } else {
        setSnackbar({
          open: true,
          message: `Failed to updated user: ${currentUser.firstName + ' ' + currentUser.lastName}`,
          severity: 'error',
        });
      }

      // Remove the successfully updated user from the list
      setSelectedItems((prevItems) => {
        const updatedItems = prevItems.filter(
          (item) => item.id !== currentUser.id
        );
        if (updatedItems.length === 0) {
          setIsDelete(false); // Close modal if no users are left
        }
        return updatedItems;
      });

      // reloadDatabase(); // Reload the database after update
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Failed to updated user.`,
        severity: 'error',
      });
    }
  };

  const handleSelectAll = (e: any, checked: boolean) => {
    setIsSelectAll(checked);
    if (checked) {
      setSelectedItems(state.users);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSelectionChange = (user: User) => {
    if (selectedItems.find((item) => item.id === user.id)) {
      setSelectedItems((prevItems: User[]) => {
        const updatedItems = prevItems.filter(
          (item: User) => item.id !== user.id
        );
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, user];
      setSelectedItems(items);
    }
  };

  const headers = useMemo<Header<User>[]>(
    () => [
      {
        key: 'input',
        name: 'Input',
        sortable: false,
        flex: 1.5,
        style: { marginLeft: '0px', padding: 0 },
        render: (item: User) => (
          <HMCheckbox
            checked={selectedItems.some((selected) => selected.id === item.id)}
            onChange={(e, checked) => handleSelectionChange(item)}
          />
        ),
      },
      {
        key: 'name',
        name: 'Name',
        sortable: true,
        flex: 10,
        render: (item: User) => {
          let isCurrentUser = item.id === currentUser.id;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>{item.firstName + ' ' + item.lastName}</div>
              {isCurrentUser && (
                <span
                  className="new-badge"
                  style={{
                    fontSize: '10px',
                    marginLeft: '0.5rem',
                  }}
                >
                  Me
                </span>
              )}
            </div>
          );
        },
      },
      { key: 'cognitoID', name: 'Username', sortable: true, flex: 6 },
      {
        key: 'lastActivity',
        name: 'Last Activity',
        sortable: true,
        flex: 6,
        render: (item: User) =>
          getFormattedDate(item.updatedAt ?? item.createdAt, false),
      },
      {
        key: 'type',
        name: 'Type',
        sortable: true,
        flex: 4,
        render: (item: User) => getUserType(item.type),
      },
      {
        key: 'onedoseVersion',
        name: 'OneDose Version',
        sortable: true,
        flex: 5,
        render: (item: User) =>
          item.oneDoseVersion ? 'v' + item.oneDoseVersion : '-',
      },
      {
        key: 'pushNotify',
        name: 'Push Notify',
        sortable: true,
        flex: 4,
        render: (item: User) =>
          (item.notificationTokens ?? []).length > 0 ? 'Active' : '-',
      },
      {
        key: 'departments',
        name: 'Departments',
        sortable: true,
        flex: 4,
        render: (item: User) => {
          let count = item.pairedDepIDs?.length ?? 0;
          if (count === 0) count = 1;
          return count;
        },
      },
    ],
    [isSelectAll, selectedItems]
  );

  const handleSortItem = (key: string, direction: string, a: User, b: User) => {
    if (key === 'name') {
      let aName = a.firstName + ' ' + a.lastName;
      let bName = b.firstName + ' ' + b.lastName;
      return direction === 'asc'
        ? aName.localeCompare(bName)
        : bName.localeCompare(aName);
    } else if (key === 'cognitoID') {
      return direction === 'asc'
        ? a.cognitoID.localeCompare(b.cognitoID)
        : b.cognitoID.localeCompare(a.cognitoID);
    } else if (key === 'lastActivity') {
      const aDate = new Date(a.updatedAt ?? new Date());
      const bDate = new Date(b.updatedAt ?? new Date());
      return direction === 'asc'
        ? aDate.getTime() - bDate.getTime()
        : bDate.getTime() - aDate.getTime();
    } else if (key === 'type') {
      return direction === 'asc'
        ? getUserType(a.type).localeCompare(getUserType(b.type))
        : getUserType(b.type).localeCompare(getUserType(a.type));
    } else if (key === 'onedoseVersion') {
      return direction === 'asc'
        ? compareVersions(
            a.oneDoseVersion ?? 'v1.0.0',
            b.oneDoseVersion ?? 'v1.0.0'
          )
        : compareVersions(
            b.oneDoseVersion ?? '1.0.0',
            a.oneDoseVersion ?? '1.0.0'
          );
    } else if (key === 'pushNotify') {
      return direction === 'asc'
        ? (a.notificationTokens ?? []).length -
            (b.notificationTokens ?? []).length
        : (b.notificationTokens ?? []).length -
            (a.notificationTokens ?? []).length;
    } else if (key === 'departments') {
      return direction === 'asc'
        ? (a.pairedDepIDs ?? []).length - (b.pairedDepIDs ?? []).length
        : (b.pairedDepIDs ?? []).length - (a.pairedDepIDs ?? []).length;
    }
    return 0;
  };

  const getRowClassName = useCallback(
    (params: User) => {
      // If the row is selected, give it the selected class.
      const isSelected = selectedItems.some((item) => item.id === params.id);
      if (isSelected) return 'selectedRow';

      // Default return for other cases
      return '';
    },
    [selectedItems]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() =>
          setSnackbar({ open: false, message: '', severity: 'success' })
        }
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() =>
            setSnackbar({ open: false, message: '', severity: 'success' })
          }
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {isLoading && <Loading type="bubbles" />}

      {selectedItems.length > 0 && (
        <ConfirmModal
          isVisible={isEnable}
          title={'Re-Enable User?'}
          handleClose={() => {
            setAllCheckedBtn(false);
            setIsEnable(false);
          }}
          handleSubmit={() => {
            handleConfirmUpdateUsers(UserStatus.ACTIVE);
            setAllCheckedBtn(false);
          }}
          primaryBtnName="Cancel"
          secondaryBtnName={'Enable'}
          primaryDescription={
            allCheckedBtn
              ? 'Do you want to re-enable ' + selectedItems.length + ' users?'
              : 'Do you want to re-enable ' +
                selectedItems[0].firstName +
                ' ' +
                selectedItems[0].lastName +
                '?'
          }
          secondaryDescription={
            'This will give this user access again to the OneDose App and OneDose Admin Console.'
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
          isCheckedAll={allCheckedBtn}
        />
      )}

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={isPermanentlyDelete ? 'Delete User?' : 'Disable User?'}
          handleClose={() => {
            setIsDelete(false);
            setAllCheckedBtn(false);
            setIsPermanentlyDelete(false);
          }}
          handleSubmit={() => {
            handleConfirmUpdateUsers();
            setAllCheckedBtn(false);
            setIsPermanentlyDelete(false);
          }}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName={isPermanentlyDelete ? 'Delete' : 'Disable'}
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} users?`
              : isPermanentlyDelete
                ? `Are you sure you would like to delete ${selectedItems[0].firstName + ' ' + selectedItems[0].lastName}?`
                : `Are you sure you would like to disable ${selectedItems[0].firstName + ' ' + selectedItems[0].lastName}?`
          }
          secondaryDescription={
            isPermanentlyDelete
              ? 'This is a PERMANENT action and cannot be undone. Deleting user will remove all access to the OneDose App and OneDose Admin Console.'
              : 'Disabling user will make it so he cannot access the OneDose App or OneDose Admin Console. You can re-enable the user in the future.'
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
          isCheckedAll={allCheckedBtn}
          isPermanentlyDeleteBtn={true}
          handleCheck={(checked: boolean) => {
            setIsPermanentlyDelete(checked);
          }}
        />
      )}

      {isUnsubscribe && selectedItems.length > 0 && (
        <ConfirmModal
          isVisible={isUnsubscribe}
          title={
            'Unsubscribe ' +
            (allCheckedBtn
              ? selectedItems.length + ' users'
              : selectedItems[0].firstName + ' ' + selectedItems[0].lastName) +
            '?'
          }
          handleClose={() => {
            setIsUnsubscribe(false);
            setAllCheckedBtn(false);
          }}
          handleSubmit={() => {
            handleUnsubscribe();
            setAllCheckedBtn(false);
            setIsUnsubscribe(false);
          }}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName={'Unsubscribe'}
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to unsubscribe ${selectedItems.length} users from ${department.name}?`
              : `Are you sure you would like to unsubscribe ${selectedItems[0].firstName + ' ' + selectedItems[0].lastName} from ${department.name}?`
          }
          secondaryDescription={
            'If they are subscribed to other agencies, they will still have access to those. If this is the last agency on their account, their account will move to suspended.'
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
          isCheckedAll={allCheckedBtn}
        />
      )}

      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={
          userStats == null ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <div>Users</div>
              <div style={{ marginTop: '-16px' }}>
                <ReactLoading
                  className="load"
                  type={'spinningBubbles'}
                  height={24}
                  width={24}
                />
              </div>
            </div>
          ) : selectedItems.length === 0 ? (
            'Users: ' + userStats?.totalUsers + ' items'
          ) : (
            'Users: ' +
            selectedItems.length +
            ' / ' +
            userStats?.totalUsers +
            ' items'
          )
        }
        page={department.name}
        customDescription={
          userStats ? (
            <div>
              <h6 className="departmentItemText" style={{ color: 'grey' }}>
                {currentUser.type === UserType.ADMIN
                  ? 'Admin: ' + userStats.superAdminUsers + ' - Dept '
                  : ''}
                Admin: {userStats.deptAdminUsers} - User: {userStats.userUsers}{' '}
                - Department: {userStats.deptUsers}
              </h6>
              <h6 className="departmentItemText" style={{ color: 'grey' }}>
                Users Created Today: {userStats.createdToday} - Week:{' '}
                {userStats.createdThisWeek} - Month:{' '}
                {userStats.createdThisMonth}
              </h6>
            </div>
          ) : undefined
        }
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={
          currentUser.type === UserType.ADMIN ||
          currentUser.type === UserType.DEPT_ADMIN
        }
        isCreateActive={true}
        handleCreate={() => {
          navigate(`/database/create/user`, {
            state: {
              department: department,
              database: database,
              list: state.users,
            },
          });
        }}
        handleEdit={() => {}}
        isDeleteButton={
          selectedItems.length > 0 && department.parentDep == null
        }
        isUnsubscribeButton={
          selectedItems.length > 0 && department.parentDep != null
        }
        handleUnsubscribe={() => setIsUnsubscribe(true)}
        isUnsubscribeActive={true}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        isEnableButton={
          selectedItems.length > 0 && userStatus === UserStatus.SUSPENDED
        }
        handleEnable={() => {
          setIsEnable(true);
        }}
        isCustomButton={true}
        customText={'Share'}
        isCustomActive={true}
        handleCustom={() => {
          setIsShareURLModalVisible(true);
        }}
        type={'protocol'}
      />
      <DataList<User, DepartmentItem>
        items={state.users}
        headers={headers}
        onItemClick={handleUserClick}
        onSelectionChange={setSelectedItems}
        searchPlaceholder="Search Users..."
        maxHeight="61vh"
        pageSize={state.pageSize}
        sortItem={handleSortItem}
        getRowClassName={getRowClassName}
        handleSelectAll={handleSelectAll}
        selectedItems={selectedItems}
        totalCount={
          state.isCompleted ? state.users.length : userStats?.totalUsers
        }
        isLoading={isLoading}
        isLazyLoading={!state.isCompleted}
      />
      {isShareURLModalVisible && (
        <ShareURLModal
          isVisible={isShareURLModalVisible}
          handleClose={() => setIsShareURLModalVisible(false)}
          department={department}
        />
      )}
    </div>
  );
};

export default ListUsers;
