import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import {
  IoArrowBack,
  IoDocuments,
  IoHeart,
  IoMedkit,
  IoWater,
} from 'react-icons/io5';
import {
  FaBell,
  FaBookOpen,
  FaListCheck,
  FaSyringe,
  FaTrash,
} from 'react-icons/fa6';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { FaSearch } from 'react-icons/fa';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const DatabaseActionsPage = (props: any) => {
  const navigate = useNavigate();

  /* Uncomment when the are finished */
  const actionList = [
    // 'Department',
    // 'User',
    { name: 'Delete Item', icon: <FaTrash /> },
    { name: 'Find Item', icon: <FaSearch /> },

    // { name: 'List Items', icon: <FaBookOpen /> },

    // 'Ambulance',
    // 'Notification',
  ];

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (item: any) => {
    let name = (item.name as string).replaceAll(' ', '-').toLowerCase();
    navigate(`/actions/database-actions/${name}`);
  };

  const handleBack = () => {
    navigate(`/actions`);
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '8fr 1fr',
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Actions'}
        name={'Database Actions'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewButton={false}
        isReviewActive={false}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <div className="grid-container">
        {actionList.map((item, index) => {
          return (
            <div
              key={index}
              className="grid-item"
              onClick={() => handleItemClick(item)}
            >
              <div className="item-name">{item.name}</div>
              {item.icon}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DatabaseActionsPage;
