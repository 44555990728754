import { Dialog } from 'primereact/dialog';

interface LearnMoreReviewModalProps {
  isVisible: boolean;
  handleClose: any;
  handleSubmit?: any;
}

/* 10-09-23 Arul: Created the DraftConfirmModal component for globally for Edit Folder Page */
const LearnMoreReviewModal: React.FC<LearnMoreReviewModalProps> = (
  props: any
) => {
  const { isVisible, handleClose, handleSubmit } = props;

  return (
    <Dialog
      visible={isVisible}
      style={{ backgroundColor: 'white', width: '80vw', maxWidth: '1000px' }}
      onHide={handleClose}
      header={'Introduction to OneDose Clinical Review'}
      className="confirmDialog routeConfirmModal"
    >
      <div style={{ background: 'white' }}>
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%',
            height: '0',
          }}
        >
          <iframe
            src="https://www.loom.com/embed/a89a3afbe67f41a78535f98e4023be8c"
            frameBorder="0"
            allowFullScreen
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
            }}
          ></iframe>
        </div>
      </div>
    </Dialog>
  );
};

export default LearnMoreReviewModal;
