import { DatabaseResponse } from '../data/AmplifyDB';
import DepartmentItem from '../data/model/DepartmentItem';
import {
  detectedChangesMockData,
  notificationist,
} from '../ui/_global/constants/Protocol_constants';
import { equipmentDose } from '../ui/_global/constants/model';
import {
  HANDLE_DATATABLE_ROW_SELECTION,
  HANDLE_DEP_CACHE,
  HANDLE_GET_DEPARTMENT_LIST,
  HANDLE_GET_NOTIFICATION,
  HANDLE_HM_CACHE,
  HANDLE_LAODING_FAVICON,
  HANDLE_LOGIN,
  HANDLE_LOGOUT,
  HANDLE_NOTIFICATION_CACHE,
  HANDLE_USER_DEPARTMENTS,
  HANDLE_PUBLIC_SELECTED_ITEM,
  HANDLE_REVIEWALS_CACHE,
  HANDLE_SELECTED_DOSE_VALUE,
  HANDLE_SELECT_DATATABLE,
  HANDLE_SET_PROTOCOL_DATA,
  HANDLE_SHOW_SIDEBAR,
  SET_SIDEBAR_MODE,
  HANDLE_UPDATE_COGNITO_USER_DATA,
  HANDLE_UPDATE_EDIT_FOLDER_DATA,
  HANDLE_UPDATE_EDIT_PROTOCOL,
  HANDLE_UPDATE_EQUIPMENT_OPTION_VALUES,
  HANDLE_UPDATE_EQUIPMENT_PROTOCOL_VALUES,
  HANDLE_UPDATE_ROUTES_VALUES_LIST,
  HANDLE_UPDATE_SELECTED_ACCORDION,
  HANDLE_UPDATE_USER_DATA,
  UPDATE_CATEGORIES_DATA,
  HANDLE_SHOW_ACTIVITY_LOG,
  HANDLE_PUBLIC_LOADED,
  HANDLE_PAIRED_DEPARTMENTS,
  HANDLE_CACHE_USERS,
} from './types';

const initialState = {
  isLoggedIn: false,
  cognitoUser: null,
  waitForSync: false,
  user: null,
  sidebar: true,
  isMini: false,
  activityLogSidebar: false,
  isEditMode: false,
  departmentCache: new Map(),
  displayLoadingIcon: false,

  /* Department Data */
  department: {
    userDepartments: undefined,
    department: undefined,
    allSubDeps: new Map<string, DepartmentItem[]>(),
    subDeps: new Map<string, DepartmentItem[]>(),
  },

  fullyLoadedPublic: 0,
  protocol: {
    departmentItem: [],
    keychains: [],
    categoriesData: [],
    editFolderData: [],
    draft: detectedChangesMockData,
    editProtocol: {},
    selectedProtocol: '',
    selectedAccordion: [],
    dose: {
      routeList: [],
      selectedDose: '',
      equipmentDose: equipmentDose,
    },
    editDatatable: {
      breadcrumpData: [],
      selectedRowData: [],
      tableData: [],
    },
  },
  notification: {
    notificationList: [],
  },
  public: {
    selectedItem: '',
  },
  hinckley: {
    medications: undefined,
    infusions: undefined,
    equipment: undefined,
    electricals: undefined,
    vitals: undefined,
  },
  review: {
    reviewals: [],
  },
  cacheUsers: undefined,
};

const Reducers = (prevState: any = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case HANDLE_LOGOUT:
      return initialState;

    case HANDLE_LAODING_FAVICON:
      return {
        ...prevState,
        displayLoadingIcon: payload.displayLoadingIcon,
      };

    case HANDLE_DEP_CACHE:
      const database = payload.database as DatabaseResponse;
      if (database == null) {
        return {
          ...prevState,
          departmentCache: new Map(),
        };
      }
      const department = database.department;
      const departmentCache = prevState.departmentCache;
      let id = department.activeSubDep
        ? department.activeSubDep.id
        : department.id;
      departmentCache.set(id, database);
      return {
        ...prevState,
        departmentCache,
      };

    case HANDLE_NOTIFICATION_CACHE:
      return {
        ...prevState,
        notification: {
          ...prevState.notification,
          notificationList: payload.notifications,
        },
      };

    case HANDLE_UPDATE_USER_DATA:
      return {
        ...prevState,
        user: payload.user,
      };
    case HANDLE_UPDATE_COGNITO_USER_DATA:
      return {
        ...prevState,
        cognitoUser: payload.cognitoUser,
        isLoggedIn: payload.cognitoUser !== null,
        waitForSync: payload.waitForSync,
      };
    case HANDLE_SHOW_SIDEBAR:
      return {
        ...prevState,
        sidebar: payload.sidebar,
      };
    case SET_SIDEBAR_MODE:
      return {
        ...prevState,
        isMini: payload.isMini,
      };

    case HANDLE_UPDATE_EDIT_PROTOCOL:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          editProtocol: payload.editProtocol,
        },
      };
    case HANDLE_UPDATE_EDIT_FOLDER_DATA:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          editFolderData: payload.editFolderData,
        },
      };

    case HANDLE_PAIRED_DEPARTMENTS:
      const departmentId: string = payload.department.id;
      return {
        ...prevState,
        department: {
          department: payload.department,
          subDeps: (
            prevState.department.subDeps as Map<string, DepartmentItem[]>
          ).set(departmentId, payload.subDeps),
          allSubDeps: (
            prevState.department.allSubDeps as Map<string, DepartmentItem[]>
          ).set(departmentId, payload.allSubDeps),
          userDepartments:
            payload.userDepartments == null
              ? prevState.department.userDepartments
              : payload.userDepartments,
        },
      };

    case HANDLE_GET_DEPARTMENT_LIST:
      let db = payload.departmentItem as DatabaseResponse;
      db.users = (prevState.cacheUsers || db.users) as any[];
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          departmentItem: db,
        },
      };

    case HANDLE_PUBLIC_LOADED:
      return {
        ...prevState,
        fullyLoadedPublic: prevState.fullyLoadedPublic + 1,
      };

    case HANDLE_SELECT_DATATABLE:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          editDatatable: {
            ...prevState.protocol.editDatatable,
            breadcrumpData: payload.breadcrumpData,
            selectedRowData: payload.selectedRowData,
            tableData: payload.tableData,
          },
        },
      };
    case HANDLE_DATATABLE_ROW_SELECTION:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          editDatatable: {
            ...prevState.protocol.editDatatable,
            selectedRowData: payload.selectedRowData,
          },
        },
      };
    case HANDLE_UPDATE_EQUIPMENT_PROTOCOL_VALUES:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          editProtocol: {
            ...prevState.protocol.editProtocol,
            equipment: payload.equipment,
          },
        },
      };
    case HANDLE_UPDATE_EQUIPMENT_OPTION_VALUES:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          dose: {
            ...prevState.protocol.dose,
            equipmentDose: payload.equipmentDose,
          },
        },
      };

    case HANDLE_SELECTED_DOSE_VALUE:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          dose: {
            ...prevState.protocol.dose,
            selectedDose: payload.selectedDose,
          },
        },
      };
    case HANDLE_SET_PROTOCOL_DATA:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          selectedProtocol: payload.selectedProtocol,
        },
      };
    case HANDLE_UPDATE_SELECTED_ACCORDION:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          selectedAccordion: payload.selectedAccordion,
        },
      };
    case HANDLE_UPDATE_ROUTES_VALUES_LIST:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          dose: {
            ...prevState.protocol.dose,
            routeList: payload.routes,
          },
        },
      };
    case HANDLE_GET_NOTIFICATION:
      return {
        ...prevState,
        notification: {
          ...prevState.protocol,
          notificationList: payload.notifications,
        },
      };

    case UPDATE_CATEGORIES_DATA:
      return {
        ...prevState,
        protocol: {
          ...prevState.protocol,
          departmentItem: {
            ...prevState.protocol.departmentItem,
            categories: payload.categories,
          },
        },
      };

    case HANDLE_HM_CACHE:
      return {
        ...prevState,
        hinckley: {
          medications: payload.medications,
          infusions: payload.infusions,
          equipment: payload.equipment,
          electricals: payload.electricals,
          vitals: payload.vitals,
        },
      };

    case HANDLE_REVIEWALS_CACHE:
      return {
        ...prevState,
        review: {
          reviewals: payload.reviewals,
        },
      };

    case HANDLE_SHOW_ACTIVITY_LOG:
      return {
        ...prevState,
        activityLogSidebar: payload.activityLogSidebar,
      };

    case HANDLE_CACHE_USERS:
      return {
        ...prevState,
        cacheUsers: payload.users,
      };

    /** Public Actions  */
    case HANDLE_PUBLIC_SELECTED_ITEM:
      return {
        ...prevState,
        public: {
          ...prevState.public,
          selectedItem: payload.selectedItem,
        },
      };
    default:
      return prevState;
  }
};

export default Reducers;
